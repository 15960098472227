import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import classNames from "classnames";
import { createBrowserHistory } from "history";
import "./ripple.js";
import "./CustomerOrder.scss";
import "./common.css";
import "./PinField.scss";
import PageVisibility from 'react-page-visibility';
import LoadingOverlay from "./core/components/LoadingOverlay.js";
import { MenuOrdering } from "./order/MenuOrdering.js";
import CustomerOrderTopbar from "./core/layout/CustomerOrderTopbar.js";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as notify from "./core/service/NotificationService";
import { showloading, stoploading } from "./core/service/LoadingService";
import { getOrderTableByCode, placeOrder, requestChecking, callWaiter, makePayment, cancelPayment, splitPayment, customerCancelAndReplanSplit, customerMakingPrepaymentWhileEating, cancelPaymentRequest, cancelPaymentProgress } from "./service/CustomerOrderService";
import { loadPublicConfiguration } from "./service/ConfigurationService";
import * as socketService from './core/service/CustomerWebEventService';
import { TABLE_REQUEST_STATUS, PAYMENT_METHOD, PAYMENT_OPTION, CHANNEL_TYPE, USER_EVENTS, USE_FOR_PURPOSE, ORDER_PAY_MODE, PAYMENT_STATUS, PAYMENT_STATE, ORDER_ITEM_STATUS, VOUCHER_STATUS, ORDER_ACTIVITY_TYPE, PAYMENT_GATEWAY, PAYMENT_METHODS_OBJECT } from "./constants.js";
import { RadioButton } from "primereact/radiobutton";
import { Fieldset } from "primereact/fieldset";
import { Message } from "primereact/message";
import { moneyFormat, getGroupOrderItems, getTableOrderSummary, getDecimalPlaces, getCustomerId, getCurrencySymbol, getSeletecLocale, getDefaultLocale, isPriceIncludedTaxOrService, calculateSplitLimit, getApplicationUrl } from "./core/service/CommonService.js";
import { OrderItemForm } from "./order/OrderItemForm.js";
import { removeOrderItems, applyPromotionCoupon, cancelPromotionCoupon, applyVoucherToOrder, confirmVoucherToOrder, processInvoiceForOrder } from "./service/OrderService.js";
import { getOnlinePaymentGateway } from "./service/PaymentService.js";
import { toFixed } from "accounting-js";
import { Checkbox } from 'primereact/checkbox';
import { Slider } from 'primereact/slider';
import { SplitPayment } from "./core/components/payment/SplitPayment.js";
import { getAppliedVoucherByInvoice } from "./service/VoucherService";
import { getMenuData } from "./service/MenuService.js";
import { parseMessage } from "./core/utils/TranslationUtils.js";
import axios from 'axios';
import { API_BASE_URL } from '../src/constants';
import { getNotifications, saveNotification } from "../src/service/OrderActivityService";
import { OrderConfirmAction } from "./order/OrderConfirmAction.js";
import { CheckoutPayment } from "./core/components/payment/CheckoutPayment.js";
import { PinAccess } from "./order/PinAccess.js";

class CustomerOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resConfig: {},
      topbarHeight: 0,
      activeTopbarItem: null,
      layoutMode: "static",
      mobileMenuActive: null,
      topbarMenuActive: null,
      menuActive: false,
      wsNotifications: [],
      userNotifications: [],
      refreshNotification: false,
      isRequirePinCode: false,
      isValidPinCode: true,
      paymentMode: ORDER_PAY_MODE.post_pay,
      table: {
        name: null
      },
      tableSize: 0,
      menuData: null,
      draftOrderItems: [],
      draftGroupOrderItems: [],
      confirmingGroupOrderItems: [],
      inServiceGroupOrderItems: [],
      language: 'en-US',
      frmCartVisible: false,
      frmOrderItemVisible: false,
      cFlagImage: '',
      isPayment: false,
      orderSummary: {},
      tipAmount: '',
      invoices: [],
      activePayInvoice: null,
      paidTotal: 0,
      numOfPaidInvoice: 0,
      totalInvoice: 0,
      couponCode: '',
      voucherCode: '',
      isVoucherConfirm: false,
      appliedVoucher: null,
      prePayInvoice: null,
      isPaidSome: false,
      isMakingEarlypayment: false,
      earlyPaymentItemIds: [],
      earlyPaymentInvoice: null,
      earlyPaymentAmount: 0,
      earlyPaymentPercent: 0,
      listNoti: [],
      cusEmail: '',
      mergeInvoiceIds: [],
      mergeInvoices: [],
      onlinePaymentGateway: {},
      onsiteMethodOptions: [],
      gatewayOnsiteMethods: [],
      generalOnsiteMethods: [],
      ableMakePayment: false,
      paymentOption: '',
      paymentMethod: '',
      hasMultiPaymentOptions: false
    };

    this.notificationCount = 0;

    this._history = createBrowserHistory();
    this.unlisten = this._history.listen(() => { });
  }

  async componentDidMount() {
    const resConfig = await loadPublicConfiguration();

    this.getTableDetails();
    this.loadPaymentConfig(resConfig);
    // await this.getAPProduct();

    const { i18n } = this.props;
    let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

    i18n.changeLanguage(locale);
    this.setState({
      channel: CHANNEL_TYPE.restaurant,
      resConfig: resConfig,
      language: locale
    });
  }

  loadPaymentConfig = async (config) => {
    let paymentGateWay = null
    let gatewayOnsiteMethods = []
    let generalOnsiteMethods = config.useOnSitePayment ? config.onSiteMethods : []

    if (config && config.useOnlinePayment && config.resCardPaymentGateway) {
      paymentGateWay = await getOnlinePaymentGateway(config.resCardPaymentGateway)

      if (paymentGateWay) {
        if (paymentGateWay.gateway === PAYMENT_GATEWAY.HITPAY) {
          gatewayOnsiteMethods = paymentGateWay.settings.onsiteMethods
        }
      }
    }

    let onsiteMethodOptions = generalOnsiteMethods.concat(gatewayOnsiteMethods)

    let ableMakePayment = true
    let hasMultiPaymentOptions = false

    let paymentOption = PAYMENT_OPTION.Online
    let paymentMethod = PAYMENT_METHOD.online

    if (!paymentGateWay) {
      paymentOption = PAYMENT_OPTION.Onsite

      if (onsiteMethodOptions.length > 0) {
        paymentMethod = onsiteMethodOptions[0]
      } else {
        ableMakePayment = false
      }
    }

    if (paymentGateWay && (onsiteMethodOptions.length > 0))
      hasMultiPaymentOptions = true

    this.setState({
      onlinePaymentGateway: paymentGateWay,
      onsiteMethodOptions: onsiteMethodOptions,
      gatewayOnsiteMethods: gatewayOnsiteMethods,
      generalOnsiteMethods: generalOnsiteMethods,
      ableMakePayment: ableMakePayment,
      paymentOption: paymentOption,
      paymentMethod: paymentMethod,
      hasMultiPaymentOptions: hasMultiPaymentOptions
    })
  }

  registerServiceWorker = async (id) => {
    const swRegistration = await navigator.serviceWorker.register('/ws.js');
    const applicationServerKey = this.urlB64ToUint8Array(
      'BEA7vL2l8GG7ZeJEzAzGDkhgnplnkziHYBeFoCswtK6lBj6tz1whu1lQBhLNrNuRdEqATXjwOmU5qCT0BcqCzZ4'
    )
    try {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        var options = {
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey
        };
        serviceWorkerRegistration.pushManager.subscribe(options).then((pushSubscription) => {
          return axios.post(`${API_BASE_URL}/api/webPush/save/${id}`, null, {
            params: {
              sub: JSON.stringify(pushSubscription)
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => res.data).catch(error => console.log(error));
        }, (error) => { console.log(error) }
        );
      });
    } catch (err) {
      console.log('Error', err)
    }
    return swRegistration
  }

  urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  check = () => {
    if (!('serviceWorker' in navigator)) {
      // throw new Error('No Service Worker support!')
      return false
    }
    if (!('PushManager' in window)) {
      // throw new Error('No Push API Support!')
      return false
    }
    return true
  }

  requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission()
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== 'granted') {
      throw new Error('Permission not granted for Notification')
    }
  }

  onFocus = () => {
    if (!socketService.connected()) {
      window.location.reload();
    }
  }

  hadleVisibilityChange = isVisible => {
    if (isVisible) {
      if (!socketService.connected()) {
        window.location.reload();
      }
    }
  }

  renderCombineInvoices = () => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    const mergeInvoiceIds = this.state.mergeInvoiceIds;
    const mergeInvoices = this.state.mergeInvoices;

    return (
      <React.Fragment>
        {mergeInvoiceIds.map((id, idx) => {
          const inv = mergeInvoices.find(i => i.id === id)
          return (
            <div key={idx} className="p-grid">
              <div style={{ paddingLeft: "5%" }} className="p-col-8 food-detail">{trans('res.menu.invoice')}#{id}</div>
              <div className="p-col-4 p-r food-detail">{inv ? moneyFormat(inv.checkout) : 'res.menu.invalid_invoice'}</div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  getTableDetails = (pinCode) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    this.getOrder(pinCode, (tCode) => {
      socketService.initConnection(tCode);

      socketService.customerEvent.on(USER_EVENTS.tableCustomer, (data) => {
        this.setState({ tableSize: data.customers });
      });

      socketService.customerEvent.on(USER_EVENTS.tableRequestStatusChanged, (data) => {
        if (data.tableRequest === TABLE_REQUEST_STATUS.complete_payment) {
          socketService.close();
          if (this.state.paymentMode === ORDER_PAY_MODE.post_pay) {
            this.props.history.push(`/thankyou/${this.state.table.orderNumber}`);
            saveNotification(this.state.table.customer.orderNumber, ORDER_ACTIVITY_TYPE.orderCompleted, "");
          } else {
            this.props.history.push(`/order/inform/${this.state.table.orderNumber}`);
          }
        } else {
          if (data.tableRequest !== TABLE_REQUEST_STATUS.cleaned)
            this.getOrder();

          if (data.tableRequest === TABLE_REQUEST_STATUS.payment_splitted)
            this.setState({ frmCartVisible: true });
        }
      });

      socketService.customerEvent.on(USER_EVENTS.tableOrderPlaced, () => {
        this.getOrder();
        notify.showSuccessNotify(trans('res.mgt.order_send'));
      });

      socketService.customerEvent.on(USER_EVENTS.tableNotification, (data) => {
        if (data.messNum === 0) {
          notify.showSuccessNotify(data.customText);
        } else if (data.messNum === 1) {
          notify.showSuccessNotify(trans('res.mgt.complete_notify'));
        } else {
          notify.showSuccessNotify(trans('res.mgt.cancel_notify'));
        }
      });

      socketService.customerEvent.on(USER_EVENTS.tableNotifyMessage, (data) => {
        let list = this.state.listNoti ? this.state.listNoti : [];
        if (list.length > 0) {
          list.unshift(data.notification);
        } else {
          list.push(data.notification)
        }
        this.setState({
          listNoti: list
        })
      });

      socketService.customerEvent.on(USER_EVENTS.tableProcessPayment, (data) => {
        const customerId = getCustomerId();
        this.setState({
          table: { ...this.state.table, requestStatus: data.tableRequest, userPayment: data.customerId ? data.customerId : null }
        });

        if (!this.state.frmCartVisible && data.tableRequest === TABLE_REQUEST_STATUS.making_payment && customerId === data.customerId)
          this.setState({ frmCartVisible: data.tableRequest === TABLE_REQUEST_STATUS.making_payment && customerId === data.customerId ? true : false });
      });

      socketService.customerEvent.on(USER_EVENTS.itemAdded, (data) => {
        if (data.isCustomer) {
          this.updateDraftOrder(data.item, true, false, false);
          if (this.state.frmOrderItemVisible) {
            this.frmOrderItems.updateItems(data.item, true, false, false);
          }
          notify.showSuccessNotify(trans('res.menu.add_food_order'));
        } else {
          notify.showSuccessNotify(trans('res.menu.order_modified_by_server'));
        }
        this.getOrder();
      });

      socketService.customerEvent.on(USER_EVENTS.itemUpdated, (data) => {
        if (data.isCustomer) {
          this.updateDraftOrder(data.item, false, true, false);
          if (this.state.frmOrderItemVisible) {
            this.frmOrderItems.updateItems(data.item, false, true, false);
          }
          notify.showSuccessNotify(trans('res.menu.updated_food_order'));
        } else {
          notify.showSuccessNotify(trans('res.menu.order_modified_by_server'));
        }
        this.getOrder();
      });

      socketService.customerEvent.on(USER_EVENTS.itemRemoved, (data) => {
        if (data.isCustomer) {
          this.updateDraftOrder(data.items, false, false, true);
          if (this.state.frmOrderItemVisible) {
            this.frmOrderItems.updateItems(data.items, false, false, true);
          }
          notify.showSuccessNotify(trans('res.menu.updated_food_order'));
        } else {
          notify.showSuccessNotify(trans('res.menu.order_modified_by_server'));
        }
        this.getOrder();
      });

      socketService.customerEvent.on(USER_EVENTS.orderUpdated, (data) => {
        if (!data.isCustomer) {
          this.getOrder();
          notify.showSuccessNotify(trans('res.menu.order_modified_by_server'));
        }
      });

      socketService.customerEvent.on(USER_EVENTS.requestMakePaymentForInvoice, (data) => {
        let tmpInvoices = [...this.state.invoices];
        let activePayInvoice = this.state.activePayInvoice;

        tmpInvoices.length > 0 && tmpInvoices.every(inv => {
          if (inv.id === data.invoiceId) {
            inv.userPayment = data.customerId

            if (!data.customerId) {
              if (activePayInvoice && activePayInvoice.id === data.invoiceId)
                activePayInvoice = null
            } else {
              if (!activePayInvoice && data.customerId === getCustomerId()) {
                activePayInvoice = inv

                this.getAppliedVoucher(inv.id)
              }
            }

            return false
          }

          return true
        })

        this.setState({
          invoices: tmpInvoices,
          activePayInvoice: activePayInvoice
        })
      });

      socketService.customerEvent.on(USER_EVENTS.invoiceStatusChange, (data) => {
        let tmpInvoices = [...this.state.invoices]
        let activePayInvoice = this.state.activePayInvoice;
        const processingInvoice = data.invoice

        const invIndex = tmpInvoices.findIndex(i => i.id === processingInvoice.id)

        if (invIndex !== -1)
          tmpInvoices[invIndex] = processingInvoice
        else
          tmpInvoices.push(processingInvoice)

        if (activePayInvoice && activePayInvoice.id === processingInvoice.id) {
          activePayInvoice = processingInvoice.status !== PAYMENT_STATE.completed ? processingInvoice : null
        }

        this.setState({
          invoices: tmpInvoices,
          activePayInvoice: activePayInvoice
        })

        //TODO: recheck for case early pay
        // if(this.state.earlyPaymentInvoice && this.state.earlyPaymentInvoice.status === PAYMENT_STATE.awaiting && (requestStatus === TABLE_REQUEST_STATUS.confirm_request || requestStatus === TABLE_REQUEST_STATUS.order_confirmed)) {
        //   this.setState({
        //     isMakingEarlypayment: false,
        //     earlyPaymentItemIds: [],
        //     earlyPaymentInvoice: null,
        //     earlyPaymentAmount: 0,
        //     earlyPaymentPercent: 0
        //   });
        //   this.getOrder();
        // }
      });

      socketService.customerEvent.on(USER_EVENTS.makingPrepaymentWhileEating, () => {
        this.getOrder();
      });

      socketService.customerEvent.on(USER_EVENTS.completePrepaymentWhileEating, (data) => {
        let invoices = [...this.state.invoices];
        if (invoices.length > 0) {
          let earlyPaymentInvoice = invoices.find(invoice => invoice.id === data.invoiceId);
          if (earlyPaymentInvoice && earlyPaymentInvoice.userPayment === getCustomerId()) {
            this.props.history.push(`/thankyou/${this.state.table.orderNumber}`);
          } else {
            this.getOrder();
          }
        }
      })
    });
  };

  getActivePayment = (id, cb) => {
    getOnlinePaymentGateway(id)
      .then(res => {
        this.setState({ onlinePaymentGateway: res ? res : {} }, () => cb && cb(res));
      });
  }

  getOrder = async (pinCode, cb) => {
    if (this.props.match.params.tCode) {
      getOrderTableByCode(this.props.match.params.tCode, getCustomerId(), pinCode)
        .then(async (res) => {
          if (res) {
            if (res.isResClosing) {
              this.props.history.push(`/menu`);
            } else {
              if (res.resTable) {
                if (res.resTable.customer.orderNumber) {
                  localStorage.setItem("customerId", res.resTable.customer.customerId);
                  localStorage.setItem("customerColor", res.resTable.customer.customerColor);

                  let href = getApplicationUrl() + 'order/' + res.resTable.customer.orderNumber + '?tCode=' + this.props.match.params.tCode;
                  window.location.replace(href);
                }
              } else {
                this.setState({
                  isRequirePinCode: res.isRequirePinCode,
                  isValidPinCode: res.isValidPinCode !== undefined ? res.isValidPinCode : true
                });
              }
            }
          } else {
            this.props.history.push(`/404`);
          }
        });
    } else {
      const params = new URLSearchParams(this.props.location.search);
      const tCode = params.get('tCode');
      showloading()
      getOrderTableByCode(tCode, getCustomerId(), pinCode)
        .then(async (res) => {
          if (res) {
            if (res.isResClosing) {
              this.props.history.push(`/menu`);
            } else {
              const table = res.resTable

              if (table) {
                this.setState({ isRequirePinCode: false })

                const tableOrder = res.tableOrder
                const requestStatus = table.requestStatus

                let menuData = this.state.menuData
                if (!menuData) {
                  menuData = await getMenuData(table.id, true)
                  this.setState({ menuData: menuData })

                  this.menuOrdering.popularMenuData(menuData);
                }

                if (requestStatus === TABLE_REQUEST_STATUS.complete_payment) {
                  if (table.paymentMode === ORDER_PAY_MODE.post_pay) {
                    this.props.history.push(`/thankyou/${table.orderNumber}`);
                  } else {
                    this.props.history.push(`/order/inform/${table.orderNumber}`);
                  }
                } else {
                  let order = table.orders && table.orders.filter(o => o.channel === CHANNEL_TYPE.restaurant)[0];

                  let draftOrderItems = [];
                  let confirmingOrderItems = [];
                  let inServiceOrderItems = [];

                  if (order) {
                    order.items.map(item => {
                      if (item.newStatus === ORDER_ITEM_STATUS.added) {
                        draftOrderItems.push(item);
                      } else if (item.newStatus === ORDER_ITEM_STATUS.confirming) {
                        confirmingOrderItems.push(item);
                      } else {
                        inServiceOrderItems.push(item);
                      }
                    });
                  }

                  this.getListNoti(table.orderNumber);

                  this.setState({
                    table: table,
                    tableOrder: tableOrder,
                    paymentMode: table.paymentMode,
                    orderId: order.id,
                    draftOrderItems: draftOrderItems,
                    draftGroupOrderItems: draftOrderItems.length > 0 ? getGroupOrderItems(draftOrderItems, menuData.categories, true) : [],
                    confirmingGroupOrderItems: confirmingOrderItems.length > 0 ? getGroupOrderItems(confirmingOrderItems, menuData.categories, true) : [],
                    inServiceGroupOrderItems: inServiceOrderItems.length > 0 ? getGroupOrderItems(inServiceOrderItems, menuData.categories, true) : [],
                    orderSummary: getTableOrderSummary(table.orders, table.paymentMode, tableOrder.mergeInvoices, tableOrder.invoices),
                    btnActionItems: draftOrderItems.length > 0 ? this.buttonActionItems(draftOrderItems) : [],
                    isPaymentSplited: requestStatus === TABLE_REQUEST_STATUS.payment_splitted ? true : false,
                    mergeInvoiceIds: tableOrder.mergeInvoiceIds,
                    mergeInvoices: tableOrder.mergeInvoices,
                    invoices: tableOrder.invoices
                  });

                  if (table.customer && table.customer.customerId && table.customer.customerId !== getCustomerId()) {
                    localStorage.setItem("customerId", table.customer.customerId);
                    localStorage.setItem("customerColor", table.customer.customerColor);
                  }

                  if (tableOrder.invoices && tableOrder.invoices.length > 0) {
                    this.processInvoiceForPayment(tableOrder.invoices, requestStatus, table)
                  }

                  if (table.paymentMode === ORDER_PAY_MODE.post_pay) {
                    if (requestStatus === TABLE_REQUEST_STATUS.payment_splitted
                      || ([TABLE_REQUEST_STATUS.making_payment, TABLE_REQUEST_STATUS.splitting_payment].includes(requestStatus) && table.userPayment === getCustomerId())) {
                      this.setState({ frmCartVisible: true });
                    }
                  }

                  cb && cb(table.paymentMode === ORDER_PAY_MODE.post_pay ? table.code : table.code + "_" + table.orderNumber, table.paymentMode);
                }
              } else {
                this.setState({
                  isRequirePinCode: res.isRequirePinCode,
                  isValidPinCode: res.isValidPinCode !== undefined ? res.isValidPinCode : true
                });
              }

              stoploading();
            }
          } else {
            this.props.history.push(`/404`);
          }
        });
    }

  }

  updateDraftOrder = (data, isAdd, isUpdate, isRemove) => {
    let draftOrderItems = [...this.state.draftOrderItems];

    if (isAdd) {
      draftOrderItems.push(data);
    }

    if (isUpdate) {
      const idx = draftOrderItems.findIndex(item => item.id === data.id);
      if (idx !== -1) {
        draftOrderItems[idx] = data;
      }
    }

    if (isRemove) {
      const removeSet = new Set(data);

      draftOrderItems = draftOrderItems.filter(item => !removeSet.has(item.id));
    }

    this.setState({
      draftOrderItems: draftOrderItems,
      draftGroupOrderItems: getGroupOrderItems(draftOrderItems, this.state.menuData.categories, true)
    });
  }

  updateHistoryOrder = (data) => {
    const order = data.filter(o => o.channel === CHANNEL_TYPE.restaurant)[0];

    let confirmingOrderItems = [];
    let inServiceOrderItems = [];

    if (order) {
      order.items.map(item => {
        if (item.newStatus === ORDER_ITEM_STATUS.confirming) {
          confirmingOrderItems.push(item);
        } else {
          inServiceOrderItems.push(item);
        }
      });
    }

    this.setState({
      table: { ...this.state.table, orders: data },
      confirmingGroupOrderItems: getGroupOrderItems(confirmingOrderItems, this.state.menuData.categories, true),
      inServiceGroupOrderItems: getGroupOrderItems(inServiceOrderItems, this.state.menuData.categories, true),
      orderSummary: getTableOrderSummary(data, this.state.paymentMode, this.state.mergeinvoices, this.state.invoices),
    });
  }

  changeLanguage = (e) => {
    this.setState({ language: e });
    const { i18n } = this.props;
    i18n.changeLanguage(e);
    localStorage.setItem('selectedLocale', e);
  };

  componentWillUnmount() {
    this.unlisten();
    // notificationEvent.remove('USER_NOTIFICATION', this.onUserNotificationReceived);
  }

  onRemoveCartItems = (items) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    let removeData = items && items.map(i => (i.id));

    showloading();
    removeOrderItems(removeData, true, getCustomerId(), this.state.orderId)
      .then(res => {
        if (res.errorCode) {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
      })
      .finally(() =>
        stoploading()
      );
  }

  getTotalCartItemCount = () => {
    let qty = 0;

    this.state.draftOrderItems.map((item) => {
      qty += item.quantity;
    });

    return qty;
  };

  askForConfirmAction = (type) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    let content = '';

    switch (type) {
      case TABLE_REQUEST_STATUS.confirm_request:
        content = trans('res.menu.place_order_confirmation_content');
        break;
      case TABLE_REQUEST_STATUS.check_request:
        content = `${trans('res.menu.check_request_confirmation_content')}`;
        break;
      case TABLE_REQUEST_STATUS.making_payment:
        content = trans('res.menu.request_take_payment_confirmation_content');
        break;
      case TABLE_REQUEST_STATUS.splitting_payment:
        content = trans('res.menu.request_take_splitting_payment_confirmation_content');
        break;
      default:
        break;
    }

    this.orderConfirmAction.open(type, content);
  }

  placeOrder = () => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    if (this.state.table.enableOrdering) {
      this.setState({ btnPlaceOrderDisable: true })

      placeOrder(this.state.table.orderNumber, this.state.orderId)
        .then((res) => {
          if (!res.errorCode) {
            if (this.state.paymentMode === ORDER_PAY_MODE.post_pay || (this.state.paymentMode === ORDER_PAY_MODE.pre_pay && !this.state.resConfig.autoConfirmAndSendToKitchenWhenOrderPlaced))
              this.onCloseCartDialog();
          } else {
            notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
          }
        })
        .finally(() => {
          this.setState({ btnPlaceOrderDisable: false });
          saveNotification(this.state.table.customer.orderNumber, ORDER_ACTIVITY_TYPE.orderPlaced, "");
        })
    } else {
      notify.showErrorNotify(trans('res.menu.place_order_disabled_for_table_notify'));
    }
  };

  requestCheck = async (invoiceIds) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    this.setState({ btnRequestCheckDisable: true });
    await requestChecking(this.state.table.orderNumber, invoiceIds)
      .then((res) => {
        if (!res.errorCode) {
          // this.setState({ table: res });
          notify.showSuccessNotify(trans('res.menu.request_has_sent'));
          saveNotification(this.state.table.orderNumber, ORDER_ACTIVITY_TYPE.orderCheckout, "");
        } else {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
      })
      .finally(() =>
        this.setState({ btnRequestCheckDisable: false })
      );
  }

  onCancelPaymentProgress = () => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    cancelPaymentProgress(this.state.table.orderNumber)
      .then(res => {
        if (!res.errorCode) {
          this.onCloseCartDialog();
        } else {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
      })
  }

  makePayment = (invoiceId) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    const isPaymentSplited = this.state.isPaymentSplited

    if (this.state.paymentMode === ORDER_PAY_MODE.post_pay) {
      makePayment(this.state.table.orderNumber, getCustomerId(), isPaymentSplited ? invoiceId : null)
        .then(res => {
          if (!res.errorCode) {
            if (!isPaymentSplited)
              this.setState({ activePayInvoice: res })
          } else {
            notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
          }
        });
    } else {
      if (this.state.activePayInvoice) {
        this.setState({
          isPayment: true
        })
      } else {
        processInvoiceForOrder(this.state.table.orderNumber, getCustomerId())
          .then(res => {
            if (!res.errorCode) {
              this.setState({
                isPayment: true,
                activePayInvoice: res
              })
            } else {
              notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
            }
          });
      }
    }
  }

  splitPayment = () => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    splitPayment(this.state.table.orderNumber, getCustomerId())
      .then(res => {
        if (res.errorCode) {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
      });
  }

  cancelPayment = (isEarlypaymentWhileEating) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    if (this.state.paymentMode === ORDER_PAY_MODE.post_pay) {
      let invoiceId = !isEarlypaymentWhileEating ? this.state.activePayInvoice.id : this.state.earlyPaymentInvoice.id

      cancelPayment(this.state.table.orderNumber, invoiceId, isEarlypaymentWhileEating ? this.state.earlyPaymentItemIds : null)
        .then(res => {
          if (res.errorCode) {
            notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
          }
        })
    } else {
      this.setState({ isPayment: false });
      if (this.state.frmCartVisible)
        this.setState({ frmCartVisible: false });
    }
  }

  onCloseCartDialog = () => {
    this.setState({ frmCartVisible: false });
  };

  callWaiter = async () => {
    showloading();
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    await callWaiter(this.state.table.code)
      .then(res => {
        if (!res.errorCode) {
          notify.showSuccessNotify(trans('res.menu.request_has_sent'));
        } else {
          notify.showErrorNotify(res.errorMessage);
        }
      })
      .finally(stoploading());
  }

  buttonActionItems = (draftOrderItems) => {
    let tempButton = {};
    draftOrderItems.map(item => {
      tempButton = { ...tempButton, [item.id]: { btnUpdateDisable: false, btnRemoveDisable: false } }
    })
    return tempButton;
  }

  isBlockOrdering = () => {
    const requestStatus = this.state.table.requestStatus

    if (this.state.paymentMode === ORDER_PAY_MODE.post_pay) {
      if (requestStatus && requestStatus !== TABLE_REQUEST_STATUS.confirm_request && requestStatus !== TABLE_REQUEST_STATUS.order_confirmed)
        return true;
    } else {
      if (requestStatus)
        return true;
    }

    return false;
  }

  isDisableOrder = () => {
    return !this.state.table || (this.state.table && !this.state.table.enableOrdering)
  }

  onPaymentOptionChange = (e) => {
    const paymentOption = e.value;
    let paymentMethod = '';
    const onSiteMethodOptions = this.state.onsiteMethodOptions;

    if (paymentOption === PAYMENT_OPTION.Online) {
      paymentMethod = PAYMENT_METHOD.online;
    } else {
      paymentMethod = onSiteMethodOptions && onSiteMethodOptions.length > 0 ? onSiteMethodOptions[0] : '';
    }

    this.setState({
      paymentOption: paymentOption,
      paymentMethod: paymentMethod,
      tipAmount: ''
    });
  }

  onCartClick = () => {
    this.setState({ frmCartVisible: true });
  }

  renderOrderItemView = (orderData, isDraftOrder, isWaitingConfirm, isShowPrice, trans, defaultLocale, selectedLocale) => {
    const requestStatus = this.state.table.requestStatus
    return (
      <React.Fragment>
        {!this.isBlockOrdering() &&
          <React.Fragment>
            {isDraftOrder ?
              <h2 className="page-title ">{trans('res.menu.current_order')}</h2>
              :
              isWaitingConfirm ?
                <h2 className="page-title p-margin-top-20">{trans('res.menu.waiting_confirm')}</h2>
                :
                <h2 className="page-title p-margin-top-20">{trans('res.menu.order_history')}</h2>
            }
          </React.Fragment>
        }

        <div className="p-hr p-margin-top-5 p-margin-bottom-5" />

        <div className="p-grid table-title">
          {/* <div className="p-col-1">#</div> */}
          <div className="p-col-7 p-xl-8 p-md-8 p-lg-8 p-sm-7 p-w-bold">{trans('res.mgt.food')}</div>
          <div className="p-col-1 p-xl-1 p-md-1 p-lg-1 p-sm-1 p-w-bold p-c">{'Qty'}</div>
          {isShowPrice &&
            <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-w-bold p-r">{trans('res.mgt.total')}</div>}
        </div>

        <div className="p-hr p-margin-bottom-10" />

        {isDraftOrder && orderData.length === 0 &&
          <div className="p-grid food-detail">
            <div className="p-col-12">{trans('res.menu.no_food_selected')}</div>
          </div>
        }

        {(() => {

          return (
            <React.Fragment>
              {orderData.map((v, i) => {
                return (
                  <React.Fragment key={"cate_" + i}>
                    <div className="order-item-view">
                      {(v.products.length > 0 || (!isDraftOrder && !isWaitingConfirm && v.adjustItems && v.adjustItems.length > 0)) &&
                        <span className="cate-title">{v.cateName ? v.cateNames[selectedLocale] ? v.cateNames[selectedLocale] : v.cateNames[defaultLocale] ? v.cateNames[defaultLocale] : v.cateName : trans('res.menu.cate_other')}</span>
                      }

                      {v.products.length > 0 && v.products.map((p, pIdx) => {

                        return (
                          <React.Fragment key={"prod_" + pIdx}>
                            {(() => {
                              if (p.items.length === 1) {
                                const item = p.items[0];

                                return (
                                  <React.Fragment>
                                    <div className="p-grid p-vertical-c">
                                      {!isDraftOrder && this.state.paymentMode === ORDER_PAY_MODE.post_pay && requestStatus === TABLE_REQUEST_STATUS.order_confirmed && this.state.isMakingEarlypayment && !item.earlyPaid &&
                                        <div className="p-col-1">
                                          <Checkbox className="p-float-right" value={item.id} onChange={(e) => this.onSelectItemForPrepayment(e)} checked={this.state.earlyPaymentItemIds.includes(item.id)}></Checkbox>
                                        </div>
                                      }
                                      <div className="p-col-7 p-xl-8 p-md-8 p-lg-8 p-sm-7 food-detail">
                                        <div>{Object.keys(p.names).length > 0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}</div>
                                        {item.itemOptionValueTrans.length > 0 &&
                                          <div className="p-size-11 p-margin-top-5">
                                            - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ? opt.names[selectedLocale] : opt.names[defaultLocale])).join(', ')}
                                          </div>
                                        }
                                        {item.note && <div className="p-size-11 p-margin-top-5">- {item.note}</div>}
                                      </div>
                                      <div className="p-col-1 p-xl-1 p-md-1 p-lg-1 p-sm-1 p-c"><span>{item.quantity}</span></div>
                                      {isShowPrice && <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r">{moneyFormat(item.total)}</div>}
                                      {isDraftOrder &&
                                        <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r">
                                          <Button icon="pi-md-edit" className="p-button-info btn-s p-margin-right-10" onClick={() => { this.frmOrderItems.openForm(item.orderItems); this.setState({ frmOrderItemVisible: true }) }} />
                                          <Button icon="pi-md-close" className="p-button-danger btn-s" style={{ color: 'white' }} onClick={() => this.onRemoveCartItems(item.orderItems)} />
                                        </div>
                                      }
                                    </div>
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment>
                                    <div className="p-grid">
                                      {/* <div className="p-col-1">#{num}</div> */}
                                      <div className="p-col-12 p-margin-bottom-5 food-detail">
                                        {Object.keys(p.names).length > 0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}
                                      </div>
                                    </div>

                                    {p.items.map((item, idx) => {
                                      return (
                                        <React.Fragment>
                                          <div key={i + "_" + idx + "_" + item.id} className="p-grid p-vertical-c">
                                            {!isDraftOrder && this.state.paymentMode === ORDER_PAY_MODE.post_pay && requestStatus === TABLE_REQUEST_STATUS.order_confirmed && this.state.isMakingEarlypayment && !item.earlyPaid &&
                                              <div className="p-col-1">
                                                <Checkbox className="p-float-right" value={item.id} onChange={(e) => this.onSelectItemForPrepayment(e)} checked={this.state.earlyPaymentItemIds.includes(item.id)}></Checkbox>
                                              </div>
                                            }
                                            <div className="p-col-7 p-xl-8 p-md-8 p-lg-8 p-sm-7" style={{ borderBottom: idx < p.items.length - 1 ? '1px solid #d8d8d8' : '' }}>
                                              {item.itemOptionValueTrans.length === 0 && !item.note ?
                                                <div className="p-size-11">{trans('res.mgt.food_select_standard')}</div>
                                                :
                                                <>
                                                  {item.itemOptionValueTrans.length > 0 &&
                                                    <div className="p-size-11 p-margin-top-5">
                                                      - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ? opt.names[selectedLocale] : opt.names[defaultLocale])).join(', ')}
                                                    </div>
                                                  }
                                                  {item.note && <div className={"p-size-11 " + (item.productVariantName || item.productVariantNames ? "p-margin-top-5" : "")}>- {item.note}</div>}
                                                </>
                                              }
                                            </div>
                                            <div className="p-col-1 p-xl-1 p-md-1 p-lg-1 p-sm-1 p-c"><span>{item.quantity}</span></div>
                                            {isShowPrice && <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r">{moneyFormat(item.total)}</div>}
                                            {isDraftOrder &&
                                              <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r">
                                                <Button icon="pi-md-edit" className="p-button-info btn-s p-margin-right-10" onClick={() => { this.frmOrderItems.openForm(item.orderItems); this.setState({ frmOrderItemVisible: true }) }} />
                                                <Button icon="pi-md-close" className="p-button-danger btn-s" style={{ color: 'white' }} onClick={() => this.onRemoveCartItems(item.orderItems)} />
                                              </div>
                                            }
                                          </div>
                                        </React.Fragment>

                                      )
                                    })}
                                  </React.Fragment>
                                )
                              }
                            })()}

                            {/* {isShowPrice && p.adjustments.length>0 && p.adjustments.map(adjust => {
                                return (
                                    <div className="p-grid p-vertical-c">
                                        <div className="p-col-1"></div>
                                        <div className="p-col-6">
                                          {adjust.trans?adjust.trans.nameDisplay[selectedLocale]?adjust.trans.nameDisplay[selectedLocale]:adjust.trans.nameDisplay[defaultLocale]:adjust.rule.nameDisplay}
                                        </div>
                                        <div className="p-col-2 p-r"></div>
                                        <div className="p-col-3 p-r">
                                          {moneyFormat(adjust.amount)}
                                        </div>
                                    </div>
                                )
                            })} */}
                          </React.Fragment>
                        )
                      })}

                      {!isDraftOrder && !isWaitingConfirm && v.adjustItems && v.adjustItems.length > 0 && v.adjustItems.map(item => {
                        return (
                          <div className="p-grid p-vertical-c">
                            {/* <div className="p-col-1">#{num}</div> */}
                            <div className="p-col-7 p-xl-8 p-md-8 p-lg-8 p-sm-7">
                              <div>{item.productName}</div>
                            </div>
                            <div className="p-col-1 p-xl-1 p-md-1 p-lg-1 p-sm-1 p-c"><span>{item.quantity}</span></div>
                            {isShowPrice && <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r">{moneyFormat(item.total)}</div>}
                          </div>
                        )
                      })}
                    </div>
                  </React.Fragment>
                )
              })}

              {(this.state.mergeInvoiceIds && this.state.mergeInvoiceIds.length > 0 && requestStatus && !(
                requestStatus === TABLE_REQUEST_STATUS.making_payment ||
                requestStatus === TABLE_REQUEST_STATUS.splitting_payment ||
                requestStatus === TABLE_REQUEST_STATUS.payment_splitted ||
                requestStatus === TABLE_REQUEST_STATUS.completing_payment)) &&
                <React.Fragment>
                  <div className="p-grid">
                    <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 p-w-bold food-detail">{trans('res.menu.total_from_store')}:</div>
                    <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r food-detail"></div>
                  </div>
                  {this.renderCombineInvoices()}
                </React.Fragment>
              }

              <div className={"p-hr p-padding-top-0" + (!isDraftOrder && !orderData && !isWaitingConfirm ? 'p-margin-bottom-15' : '')}></div>
            </React.Fragment>
          )
        })()}
      </React.Fragment>
    )

  }

  processInvoiceForPayment = async (invoices, requestStatus, table) => {
    const customerId = getCustomerId()
    const payingStatuses = [PAYMENT_STATE.awaiting, PAYMENT_STATE.completing]

    let activeInvoice = null
    let voucherApplied = null
    let paidTotal = 0
    let numOfPaidInvoice = 0
    let totalInvoice = invoices ? invoices.length : 0

    if (invoices.length > 0) {
      if (table.paymentMode === ORDER_PAY_MODE.post_pay) {
        if (requestStatus === TABLE_REQUEST_STATUS.payment_splitted) {
          invoices.forEach(inv => {
            if (inv.userPayment === customerId && payingStatuses.includes(inv.status)) {
              activeInvoice = inv;
            } else if (inv.status === PAYMENT_STATE.completed) {
              paidTotal += inv.total;
              numOfPaidInvoice++;
            }
          })
        } else {
          activeInvoice = invoices[0]
        }
      } else {
        activeInvoice = invoices[0]
      }
    }

    if (activeInvoice) {
      voucherApplied = await getAppliedVoucherByInvoice(activeInvoice.id)
    }

    this.setState({
      invoices: invoices,
      activePayInvoice: activeInvoice,
      tipAmount: activeInvoice && activeInvoice.tip ? activeInvoice.tip : '',
      appliedVoucher: voucherApplied,
      paidTotal: paidTotal,
      numOfPaidInvoice: numOfPaidInvoice,
      totalInvoice: totalInvoice,
    }, () => {
      const total = this.getFinalAmount(activeInvoice, voucherApplied)

      if (total === 0) {
        this.setState({
          paymentOption: PAYMENT_OPTION.Onsite,
          paymentMethod: PAYMENT_METHOD.cash,
        });
      }
    });

    if (table.paymentMode === ORDER_PAY_MODE.pre_pay && activeInvoice) {
      this.setState({ frmCartVisible: true })
    }
  }

  onCancelAndReplanSplitPayment = (trans, customerId) => {
    customerCancelAndReplanSplit(this.state.table.orderNumber, customerId)
      .then(res => {
        if (res.errorCode) {
          if (res.errorCode === 404)
            notify.showErrorNotify(trans('res.menu.errors.cannot_cancel_payment'));
        }
      });
  }

  applyPromotionCoupon = (trans) => {
    showloading();
    applyPromotionCoupon(this.state.orderId, this.state.table.orderNumber, this.state.couponCode)
      .then(res => {
        if (!res.errorCode) {
          this.updateInvoiceData();
          notify.showSuccessNotify(trans('res.menu.coupon_applied'));
        } else {
          if (res.errorCode === 400)
            this.setState({ errors: res.errorObj })
          notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
        }
      })
      .finally(() => {
        stoploading();
      });
  }

  cancelPromotionCoupon = (trans, couponCode) => {
    showloading();
    cancelPromotionCoupon(this.state.orderId, this.state.table.orderNumber, couponCode)
      .then(res => {
        if (!res.errorCode) {
          this.setState({ couponCode: '' });
          this.updateInvoiceData();
          notify.showSuccessNotify(trans('res.menu.coupon_canceled'));
        } else {
          notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
        }
      })
      .finally(() => {
        stoploading();
      });
  }

  applyVoucher = (trans) => {
    applyVoucherToOrder(this.state.voucherCode)
      .then(res => {
        if (!res.errorCode) {
          // this.getTableDetails();
          this.setState({
            appliedVoucher: res,
            isVoucherConfirm: true
          });
          notify.showSuccessNotify(trans('res.menu.voucher_verified'));
        } else {
          if (res.errorCode === 409)
            notify.showErrorNotify(trans('res.menu.voucher_used'));
          else
            notify.showErrorNotify(trans('res.menu.errors.invalid_voucher'));
        }
      });
  }

  confirmVoucher = (trans) => {
    let invoiceId = this.state.activePayInvoice.id;

    confirmVoucherToOrder(this.state.voucherCode, invoiceId, this.state.table.orderNumber)
      .then(res => {
        if (!res.errorCode) {
          const total = this.getFinalAmount(this.state.activePayInvoice, res)

          this.setState({
            isVoucherConfirm: false,
            voucherCode: null,
            appliedVoucher: res
          });

          if (total === 0) {
            this.setState({
              paymentOption: PAYMENT_OPTION.Onsite,
              paymentMethod: PAYMENT_METHOD.cash,
            });
          }

          notify.showSuccessNotify(trans('res.menu.voucher_applied'));
        } else {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
      });
  }

  getAppliedVoucher = (invoiceId) => {
    getAppliedVoucherByInvoice(invoiceId)
      .then(res => {
        this.setState({ appliedVoucher: res ? res : null })
      })
  }

  getFinalAmount = (invoice, voucher) => {
    const tipAmount = this.state.tipAmount ? parseFloat(this.state.tipAmount) : 0
    const voucherAmount = voucher ? voucher.value : 0

    const amount = invoice ? ((invoice.total - invoice.tip) - voucherAmount > 0 ? (invoice.total - invoice.tip) - voucherAmount : 0) + tipAmount : 0

    return amount
  }

  onSelectItemForPrepayment = (e) => {
    let earlyPaymentItemIds = [...this.state.earlyPaymentItemIds];
    let orderDetails = [...this.state.inServiceGroupOrderItems];
    let earlyPaymentAmount = this.state.earlyPaymentAmount;
    let earlyPaymentPercent = this.state.earlyPaymentPercent;
    if (e.checked) {
      earlyPaymentItemIds.push(e.value);
      orderDetails.length > 0 && orderDetails.map(v => {
        v.products.map((p) => {
          p.items.map(item => {
            if (e.value === item.id) {
              earlyPaymentAmount += item.totalFinal
            }
          });
        });
      });
    } else {
      earlyPaymentItemIds.splice(earlyPaymentItemIds.indexOf(e.value), 1);
      orderDetails.length > 0 && orderDetails.map(v => {
        v.products.map((p) => {
          p.items.map(item => {
            if (e.value === item.id) {
              earlyPaymentAmount = earlyPaymentAmount - item.totalFinal;
            }
          });
        });
      });
    }
    earlyPaymentPercent = earlyPaymentAmount / this.state.orderSummary.orderTotal * 100

    this.setState({
      earlyPaymentItemIds: earlyPaymentItemIds,
      earlyPaymentAmount: earlyPaymentAmount,
      earlyPaymentPercent: earlyPaymentPercent
    });
  }

  onMakePrepayment = () => {
    let orderDetails = [...this.state.inServiceGroupOrderItems];
    let earlyPaymentItemIds = [...this.state.earlyPaymentItemIds];
    let earlyPaymentAmount = 0;
    let earlyPaymentPercent = this.state.earlyPaymentPercent;
    orderDetails.length > 0 && orderDetails.map(v => {
      v.products.map((p) => {
        p.items.map(item => {
          if (getCustomerId() === item.customerId && !item.earlyPaid) {
            earlyPaymentItemIds.push(item.id);
            earlyPaymentAmount += item.totalFinal;
          }
        });
      });
    });
    earlyPaymentPercent = earlyPaymentAmount / this.state.orderSummary.orderTotal * 100

    this.setState({
      earlyPaymentItemIds: earlyPaymentItemIds,
      isMakingEarlypayment: true,
      earlyPaymentAmount: earlyPaymentAmount,
      earlyPaymentPercent: earlyPaymentPercent
    });
  }

  onCancelPrepayment = () => {
    this.setState({
      isMakingEarlypayment: false,
      earlyPaymentItemIds: []
    });
  }

  requestForPrePayment = (customerId) => {
    customerMakingPrepaymentWhileEating(this.state.table.orderNumber, this.state.table.id, customerId, this.state.earlyPaymentItemIds, this.state.earlyPaymentAmount)
      .then(res => {
        if (!res.errorCode) {
          this.setState({
            earlyPaymentInvoice: res
          });
        }
      });
  }

  onChangeEarlyPaymentPercent = (e) => {
    let earlyPaymentAmount = (this.state.orderSummary.orderTotal * (e.value / 100)).toFixed(2)
    this.setState({
      earlyPaymentPercent: e.value,
      earlyPaymentAmount: earlyPaymentAmount
    })
  }

  updateInvoiceData = () => {
    processInvoiceForOrder(this.state.table.orderNumber, getCustomerId())
      .then(data => {
        if (data)
          this.setState({ activePayInvoice: data })
      });
  }

  getListNoti = (orderNumber) => {
    if (orderNumber !== undefined) {
      getNotifications(orderNumber, ORDER_ACTIVITY_TYPE.resMgt).then(res => {
        this.setState({
          listNoti: res
        })
      })
    }
  }

  countTotalQty = (orders) => {
    let qty = 0
    orders && orders.map((order) => {
      order.items && order.items.length > 0 && order.items.map((item) => {
        qty += item.quantity
      })
    })

    return qty
  }

  cancelPaymentRequest = (trans) => {
    cancelPaymentRequest(this.state.table.orderNumber, getCustomerId(), this.state.activePayInvoice ? this.state.activePayInvoice.id : null)
      .then((res) => {
        if (!res.errorCode) {
          // this.onCloseCartDialog();
          notify.showSuccessNotify(trans('res.menu.request_has_sent'));
        } else {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
      })
  }

  render() {
    let layoutPadding = (this.state.topbarHeight + 'px 15px 0px 15px');
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    let wrapperClass = classNames("layout-wrapper", {
      "layout-wrapper-static": this.state.layoutMode === "static",
      "layout-wrapper-active": this.state.mobileMenuActive,
      "layout-menu-horizontal": this.state.layoutMode === "horizontal",
    });

    const currency = getCurrencySymbol();
    const selectedLocale = getSeletecLocale();
    const defaultLocale = getDefaultLocale();

    const resConfig = localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {};
    const disableOrder = this.isDisableOrder();
    const blockOrdering = this.isBlockOrdering();

    const requestStatus = this.state.table.requestStatus;

    const customerId = getCustomerId()
    const isEarlypaymentWhileEating = this.state.paymentMode === ORDER_PAY_MODE.post_pay && (requestStatus === TABLE_REQUEST_STATUS.confirm_request || requestStatus === TABLE_REQUEST_STATUS.order_confirmed) && this.state.earlyPaymentInvoice;

    return (
      <PageVisibility onChange={this.hadleVisibilityChange}>
        <div className={wrapperClass}>
          <OrderConfirmAction ref={(el) => this.orderConfirmAction = el}
            header={trans('res.menu.confirmation')}
            submitText={trans('res.menu.submit_confirm')}
            cancelText={trans('res.menu.cancel')}
            accept={(e) => e.type === TABLE_REQUEST_STATUS.check_request ? this.requestCheck(e.mergeInvoiceIds) : e.type === TABLE_REQUEST_STATUS.making_payment ? this.makePayment() : e.type === TABLE_REQUEST_STATUS.splitting_payment ? this.splitPayment() : ''}
            trans={trans}
          />

          <OrderItemForm ref={(el) => this.frmOrderItems = el} visible={this.state.frmOrderItemVisible} currency={currency} isCustomer={true} trans={trans} useFor={USE_FOR_PURPOSE.res_dine_in} onHide={() => this.setState({ frmOrderItemVisible: false })} />

          <Sidebar visible={this.state.frmCartVisible} position="right" className="p-sidebar-lg customer" style={{ overflowY: "auto", paddingLeft: '12px', paddingRight: '12px' }}
            blockScroll={true} baseZIndex={999} onHide={() => this.onCloseCartDialog()}>
            <h2 className="table-name p-margin-bottom-15">{trans('res.menu.table')}: {this.state.table.name}</h2>

            {this.state.inServiceGroupOrderItems.length > 0 &&
              <React.Fragment>
                {requestStatus &&
                  <div className="p-grid">
                    <div className="p-col-12">
                      {requestStatus !== TABLE_REQUEST_STATUS.confirm_request && requestStatus !== TABLE_REQUEST_STATUS.order_confirmed &&
                        //p-hr
                        <React.Fragment>
                          <div className="p-margin-bottom-10">
                            <h2 className="page-title">{trans('res.menu.order_summary')}</h2>
                          </div>

                          {requestStatus && (requestStatus === TABLE_REQUEST_STATUS.making_payment ||
                            requestStatus === TABLE_REQUEST_STATUS.splitting_payment ||
                            requestStatus === TABLE_REQUEST_STATUS.payment_splitted || requestStatus === TABLE_REQUEST_STATUS.completing_payment ||
                            (requestStatus === TABLE_REQUEST_STATUS.waiting_payment && this.state.isPayment)) &&
                            <React.Fragment>
                              {this.renderOrderItemView(this.state.inServiceGroupOrderItems, false, false, requestStatus && (requestStatus === TABLE_REQUEST_STATUS.making_payment ||
                                requestStatus === TABLE_REQUEST_STATUS.splitting_payment ||
                                requestStatus === TABLE_REQUEST_STATUS.payment_splitted || requestStatus === TABLE_REQUEST_STATUS.completing_payment ||
                                (requestStatus === TABLE_REQUEST_STATUS.waiting_payment && this.state.isPayment)),
                                trans, defaultLocale, selectedLocale)
                              }

                              <div className="p-grid p-margin-top-10">
                                <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 p-w-bold food-detail">{trans('res.menu.item_total')}</div>
                                <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-w-bold p-r food-detail">{moneyFormat(this.state.orderSummary.subTotal)}</div>
                              </div>

                              {isPriceIncludedTaxOrService() && (this.state.orderSummary.taxTotal > 0 || this.state.orderSummary.serviceTotal > 0) &&
                                <div className="p-grid">
                                  <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 p-w-bold food-detail">{trans('res.menu.total_without_tax')}</div>
                                  <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-w-bold p-r food-detail">{moneyFormat(this.state.orderSummary.subTotalNoTax)}</div>
                                </div>
                              }

                              {this.state.orderSummary.orderAdjusts.length > 0 && this.state.orderSummary.orderAdjusts.map((adjust, idx) => {
                                return (
                                  <div key={idx} className="p-grid">
                                    <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 food-detail">{adjust.label}</div>
                                    <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r food-detail">{moneyFormat(adjust.amount)}</div>
                                  </div>
                                )
                              })}

                              {this.state.orderSummary.orderDiscounts.length > 0 && this.state.orderSummary.orderDiscounts.map((discount, idx) => {
                                return (
                                  <div key={idx} className="p-grid">
                                    <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 food-detail">{discount.rule ? discount.rule.nameDisplay : discount.label}</div>
                                    <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r food-detail">{moneyFormat(discount.amount)}</div>
                                  </div>
                                )
                              })}

                              {this.state.orderSummary.serviceTotal > 0 &&
                                <div className="p-grid">
                                  <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 food-detail">{trans('res.menu.service')} ({this.state.orderSummary.serviceRate}%)</div>
                                  <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r food-detail">{moneyFormat(this.state.orderSummary.serviceTotal)}</div>
                                </div>
                              }

                              {this.state.orderSummary.taxs &&
                                Object.values(this.state.orderSummary.taxs).map((item, idx) => {
                                  return (
                                    <div key={idx} className="p-grid">
                                      <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 food-detail">{item.name} ({item.rate}%)</div>
                                      <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r food-detail">{moneyFormat(item.total)}</div>
                                    </div>
                                  )
                                })
                              }

                              {(this.state.mergeInvoiceIds && this.state.mergeInvoiceIds.length > 0) &&
                                <React.Fragment>
                                  <div className="p-grid">
                                    <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 p-w-bold food-detail">{trans('res.menu.total_from_store')}:</div>
                                    <div className="p-col-4 p-xl-3 p-md-3 p-lg-3 p-sm-4 p-r food-detail"></div>
                                  </div>

                                  {this.renderCombineInvoices()}
                                </React.Fragment>
                              }

                              {this.state.orderSummary.tipTotal > 0 &&
                                <div className="p-grid">
                                  <div className="p-col-8 p-xl-9 p-md-9 p-lg-9 p-sm-8 p-w-bold">{trans('res.menu.tip')}</div>
                                  <div className="p-col-3 p-w-bold p-r">{moneyFormat(this.state.orderSummary.tipTotal)}</div>
                                </div>
                              }

                              <div className="p-hr p-margin-bottom-10" />
                              {requestStatus && (requestStatus === TABLE_REQUEST_STATUS.making_payment ||
                                requestStatus === TABLE_REQUEST_STATUS.splitting_payment ||
                                requestStatus === TABLE_REQUEST_STATUS.payment_splitted || requestStatus === TABLE_REQUEST_STATUS.completing_payment ||
                                (requestStatus === TABLE_REQUEST_STATUS.waiting_payment && this.state.isPayment)) &&
                                <div className="p-margin-bottom-20 box-total">
                                  <div className="p-grid p-margin-none">
                                    <div className="p-col-6 p-w-bold p-size-16">{trans('res.menu.order_total')}:</div>
                                    <div className="p-col-6 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                                  </div>
                                </div>
                              }
                            </React.Fragment>
                          }
                        </React.Fragment>
                      }

                      {(() => {
                        if (requestStatus !== TABLE_REQUEST_STATUS.order_confirmed || isEarlypaymentWhileEating) {
                          if (requestStatus !== TABLE_REQUEST_STATUS.confirm_request || isEarlypaymentWhileEating) {
                            if (requestStatus === TABLE_REQUEST_STATUS.waiting_payment || requestStatus === TABLE_REQUEST_STATUS.making_payment || requestStatus === TABLE_REQUEST_STATUS.splitting_payment || requestStatus === TABLE_REQUEST_STATUS.payment_splitted || isEarlypaymentWhileEating) {
                              if (requestStatus === TABLE_REQUEST_STATUS.waiting_payment && ((this.state.paymentMode === ORDER_PAY_MODE.post_pay && !this.state.table.userPayment) || (this.state.paymentMode === ORDER_PAY_MODE.pre_pay && !this.state.isPayment))) {
                                return (
                                  <div className="p-grid p-fluid">
                                    <div className="p-col-12 ">
                                      <Button className="btn-make-payment" label={trans('res.menu.make_payment')} icon="pi-md-payment" onClick={() => this.makePayment()} />
                                    </div>

                                    {resConfig.useSplitPayment && resConfig.useSplitForCustomer && this.state.paymentMode === ORDER_PAY_MODE.post_pay && !this.state.orderSummary.couponDiscount &&
                                      <div className="p-col-12">
                                        <Button icon="pi-md-call-split" className="btn-split-payment" label={trans('res.menu.split_payment')} onClick={() => this.splitPayment()} />
                                      </div>
                                    }
                                    {this.state.paymentMode === ORDER_PAY_MODE.post_pay &&
                                      <div className="p-col-12">
                                        <Button icon="pi-md-undo" label={trans('res.menu.continue_to_order_dish')} className="p-round" onClick={() => this.onCancelPaymentProgress()} />
                                      </div>
                                    }
                                  </div>
                                );
                              } else {
                                if (this.state.paymentMode === ORDER_PAY_MODE.post_pay && this.state.table.userPayment !== customerId && !this.state.isPaymentSplited && !isEarlypaymentWhileEating) {
                                  return (
                                    <div className="p-grid">
                                      {requestStatus === TABLE_REQUEST_STATUS.splitting_payment ?
                                        <React.Fragment>
                                          <div className="p-col-12 p-c">
                                            <Message severity="warn" text={trans('res.menu.someone_splitting_payment')} />
                                          </div>
                                          <div className="p-col-12 p-c">
                                            <Button label={trans('res.menu.take_over_split_payment')} icon="pi-md-call-split" onClick={() => this.askForConfirmAction(TABLE_REQUEST_STATUS.splitting_payment)} />
                                          </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                          <div className="p-col-12 p-c">
                                            <Message severity="warn" text={trans('res.menu.someone_making_payment')} />
                                          </div>
                                          <div className="p-col-12 p-c">
                                            <Button label={trans('res.menu.take_over_payment')} icon="pi-md-payment" onClick={() => this.askForConfirmAction(TABLE_REQUEST_STATUS.making_payment)} />
                                          </div>
                                        </React.Fragment>
                                      }

                                    </div>
                                  );
                                } else {
                                  return (
                                    <React.Fragment>
                                      {resConfig.allowUsingCoupon && requestStatus !== TABLE_REQUEST_STATUS.payment_splitted && !this.state.isPaidSome && !isEarlypaymentWhileEating &&
                                        <Fieldset legend={trans('res.menu.coupon_section')} className="p-fieldset-sm" style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                          <div className="p-grid p-fluid">
                                            {this.state.orderSummary.couponDiscount ?
                                              <div className="p-col-12">
                                                <div>
                                                  {trans('res.menu.coupon_applied')}: <strong>{this.state.orderSummary.couponDiscount.trans ? this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale] ? this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale] : this.state.orderSummary.couponDiscount.trans.nameDisplay[defaultLocale] : this.state.orderSummary.couponDiscount.rule.nameDisplay}</strong>
                                                  <Button className="p-display-inline-block btn-sm p-margin-left-20 p-button-danger" label={trans('res.menu.cancel_coupon')} onClick={() => this.cancelPromotionCoupon(trans, this.state.orderSummary.couponDiscount.coupon.code)} style={{ width: 'auto' }} />
                                                </div>
                                              </div>
                                              :
                                              <div className="p-col-12 p-xl-6 p-lg-8">
                                                <div className="p-inputgroup">
                                                  {/* <span className="p-inputgroup-addon">{trans('res.menu.enter_coupon')}: </span> */}
                                                  <InputText value={this.state.couponCode} onChange={(e) => this.setState({ couponCode: e.target.value })} />
                                                  <span className="p-inputgroup-addon">
                                                    <Button className="btn-apply-voucher" disabled={this.state.couponCode ? false : true} label={trans('res.menu.apply_coupon')} onClick={() => this.applyPromotionCoupon(trans)} style={{ width: 'auto' }} />
                                                  </span>
                                                </div>
                                                <div className="p-form-error">{this.state.errors && this.state.errors.coupon && parseMessage(trans, this.state.errors.coupon.code)}</div>
                                              </div>
                                            }

                                          </div>
                                        </Fieldset>
                                      }

                                      {resConfig.allowUsingVoucher && (requestStatus === TABLE_REQUEST_STATUS.making_payment ||
                                        (isEarlypaymentWhileEating && this.state.earlyPaymentInvoice.status !== PAYMENT_STATE.completing) ||
                                        (this.state.paymentMode === ORDER_PAY_MODE.pre_pay && requestStatus === TABLE_REQUEST_STATUS.waiting_payment) ||
                                        (requestStatus === TABLE_REQUEST_STATUS.payment_splitted && this.state.activePayInvoice && this.state.activePayInvoice.status !== PAYMENT_STATE.completing)) &&
                                        <Fieldset legend={trans('res.menu.voucher_section')} style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                          <div className="p-grid">
                                            {this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ?
                                              <React.Fragment>
                                                <div className="p-col-12 p-lg-6 p-c">
                                                  <Message severity="info" text={trans('res.menu.voucher_applied')} />
                                                </div>
                                                <div className="p-col-12 p-lg-6 p-c">
                                                  <div className="p-margin-bottom-5">{trans('res.menu.voucher_value')}: <strong>{moneyFormat(this.state.appliedVoucher.value)}</strong></div>
                                                  <div>{trans('res.menu.remain_total')}: <strong>{this.state.activePayInvoice && moneyFormat(this.getFinalAmount(this.state.activePayInvoice, this.state.appliedVoucher))}</strong></div>
                                                </div>
                                              </React.Fragment>
                                              :
                                              <React.Fragment>
                                                <div className="p-col-12 p-xl-6 p-lg-8">
                                                  <div className="p-inputgroup p-fluid">
                                                    {/* <span className="p-inputgroup-addon">{trans('res.menu.enter_voucher')}: </span> */}
                                                    <InputText value={this.state.voucherCode} onChange={(e) => this.setState({ voucherCode: e.target.value })} style={{ marginTop: '-20px' }} />
                                                    <span className="p-inputgroup-addon">
                                                      {this.state.isVoucherConfirm ?
                                                        <React.Fragment>
                                                          <Button className="btn-apply-voucher" icon="pi pi-check" label={trans('res.menu.voucher_confirm')} onClick={() => this.confirmVoucher(trans, isEarlypaymentWhileEating)} style={{ width: 'auto' }} />
                                                          <Button className="btn-cancel-voucher" icon="pi pi-times" label={trans('res.menu.cancel')} onClick={() => this.setState({ appliedVoucher: null, voucherCode: '', isVoucherConfirm: false })} style={{ width: 'auto' }} />
                                                        </React.Fragment>
                                                        :
                                                        <Button className="btn-apply-voucher" disabled={this.state.voucherCode ? false : true} label={trans('res.menu.apply_coupon')} onClick={() => this.applyVoucher(trans)} style={{ width: 'auto' }} />
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                                {this.state.appliedVoucher &&
                                                  <div className="p-col-12 p-xl-6 p-lg-4">
                                                    <div>{trans('res.menu.voucher_value')}: <strong>{moneyFormat(this.state.appliedVoucher.value)}</strong></div>
                                                    <div>{trans('res.menu.remain_total')}: <strong>{this.state.activePayInvoice && moneyFormat(this.getFinalAmount(this.state.activePayInvoice, this.state.appliedVoucher))}</strong>
                                                    </div>
                                                  </div>
                                                }
                                              </React.Fragment>
                                            }
                                          </div>
                                        </Fieldset>
                                      }

                                      <Fieldset legend={requestStatus === TABLE_REQUEST_STATUS.splitting_payment ? trans('res.menu.payment_plan') : trans('res.menu.payment')} style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                        <div className="p-grid">
                                          {(() => {
                                            if (!resConfig.useOnSitePayment && !resConfig.useOnlinePayment) {
                                              return (
                                                <div className="p-col-12 p-c">
                                                  <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                                </div>
                                              );
                                            } else if ((this.state.activePayInvoice && this.state.activePayInvoice.status === PAYMENT_STATE.completing) || (this.state.earlyPaymentInvoice && this.state.earlyPaymentInvoice.status === PAYMENT_STATE.completing)) {
                                              return (
                                                <React.Fragment>
                                                  <div className="p-col-12 p-c">
                                                    <Message severity="warn" text={trans('res.menu.waiting_for_confirmation')} />
                                                  </div>
                                                  <div className="p-col-12 p-c">
                                                    <Button label={trans('res.menu.change_payment_request')} icon="pi-md-undo" onClick={() => this.cancelPaymentRequest(trans)} />
                                                  </div>
                                                </React.Fragment>
                                              );
                                            } else if (requestStatus === TABLE_REQUEST_STATUS.splitting_payment) {
                                              return (
                                                <div className="p-col-12">
                                                  <SplitPayment
                                                    trans={trans}
                                                    orderNumber={this.state.table.orderNumber}
                                                    orderTotal={this.state.orderSummary.orderTotal}
                                                    paidTotal={this.state.paidTotal}
                                                    splitNumber={this.state.tableSize <= 1 ? 2 : this.state.tableSize}
                                                    splitLimit={resConfig.lowestRatePerSplit > 0 ? calculateSplitLimit(resConfig.lowestRatePerSplit, this.state.table.seats, this.state.orderSummary.orderTotal) : this.state.table.seats}
                                                    cancel={() => this.cancelPayment()}
                                                  />
                                                </div>
                                              );
                                            } else if (requestStatus === TABLE_REQUEST_STATUS.payment_splitted && !this.state.activePayInvoice && this.state.invoices.length > 0) {
                                              return (
                                                <React.Fragment>
                                                  <div className="p-col-12 p-l">
                                                    <div>{trans('res.menu.total_invoices')}: <strong>{this.state.totalInvoice}</strong></div>
                                                    <div>{trans('res.menu.completed_invoices')}: <strong>{this.state.numOfPaidInvoice}</strong></div>
                                                  </div>
                                                  <div className="p-col-12 p-md-5 p-c">
                                                    {this.state.invoices.map((invoice, iIdx) => {
                                                      if (invoice.status === PAYMENT_STATUS.completed) {
                                                        return (
                                                          <Button key={iIdx}
                                                            disabled={true}
                                                            className="p-button-info p-margin-bottom-5 p-margin-right-5 btn-make-payment"
                                                            icon={"pi-md-local-atm"}
                                                            label={trans('res.menu.paid') + " (" + moneyFormat(invoice.total) + ")"}
                                                          />
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                  <div className="p-col-12 p-md-5 p-c">
                                                    {this.state.invoices.map((invoice, iIdx) => {
                                                      if (invoice.status !== PAYMENT_STATUS.completed) {
                                                        //TODO: recheck invoice status
                                                        return (
                                                          <Button key={iIdx}
                                                            disabled={[PAYMENT_STATE.pending, PAYMENT_STATE.completing].includes(invoice.status) ||
                                                              (invoice.userPayment && invoice.userPayment !== getCustomerId())
                                                            }
                                                            className={`${invoice.status === PAYMENT_STATUS.complete && "p-button-info"} p-margin-bottom-5 p-margin-right-5 btn-make-payment`}
                                                            icon={"pi-md-local-atm"}
                                                            label={((([PAYMENT_STATE.pending, PAYMENT_STATE.completing].includes(invoice.status)) ? trans('res.menu.verifying') : invoice.userPayment && invoice.userPayment !== getCustomerId() ? trans('res.menu.paying') : trans('res.menu.pay')) + " (" + moneyFormat(invoice.total) + ")")}
                                                            onClick={() => this.makePayment(invoice.id)} />
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                  <div className="p-col-12 p-md-2 p-c">
                                                    <Button icon="pi-md-close" label={trans('res.menu.cancel_and_replan_split_payment')} className="btn-cancel p-button-secondary" onClick={() => this.onCancelAndReplanSplitPayment(trans, customerId)} />
                                                  </div>
                                                </React.Fragment>

                                              );
                                            } else {
                                              return (
                                                <React.Fragment>
                                                  {this.state.isPaymentSplited && this.state.activePayInvoice && this.state.activePayInvoice.status === PAYMENT_STATE.awaiting &&
                                                    <React.Fragment>
                                                      <div className="p-col-8 p-w-bold p-size-16">{trans('res.menu.invoice_total')}</div>
                                                      <div className="p-col-4 p-w-bold p-r p-size-16">{moneyFormat(this.state.activePayInvoice.total)}</div>
                                                    </React.Fragment>
                                                  }

                                                  {this.getFinalAmount(this.state.activePayInvoice, this.state.appliedVoucher) > 0 && this.state.hasMultiPaymentOptions &&
                                                    <div className="p-col-12 ">
                                                      <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_option')}:</label>
                                                      <div className="p-grid">
                                                        <div className="p-col-12 p-md-6">
                                                          <RadioButton inputId="rbPayOption1" name="paymentOption" value={PAYMENT_OPTION.Online} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Online} />
                                                          <label htmlFor="rbPayOption1" className="p-radiobutton-label">{trans('res.menu.online_payment')}</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                          <RadioButton inputId="rbPayOption0" name="paymentOption" value={PAYMENT_OPTION.Onsite} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Onsite} />
                                                          <label htmlFor="rbPayOption0" className="p-radiobutton-label">{trans('res.menu.service_counter')}</label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  }

                                                  {(() => {
                                                    if (!this.state.ableMakePayment) {
                                                      return (
                                                        <div className="p-col-12 p-c">
                                                          <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                                        </div>
                                                      );
                                                    } else {
                                                      return (
                                                        <React.Fragment>
                                                          {(() => {
                                                            if (this.getFinalAmount(this.state.activePayInvoice, this.state.appliedVoucher) > 0 && this.state.paymentOption === PAYMENT_OPTION.Onsite) {
                                                              if (this.state.onsiteMethodOptions.length > 1) {
                                                                return (
                                                                  <div className="p-col-12">
                                                                    <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_method')}:</label>
                                                                    <div className="p-grid">
                                                                      {this.state.generalOnsiteMethods.length > 0 && this.state.generalOnsiteMethods.map((method, mIdx) => {
                                                                        return (
                                                                          <React.Fragment>
                                                                            <div className="p-col-12 p-md-6">
                                                                              <RadioButton inputId={"rbPayMethod" + mIdx} name="paymentMethod" value={method} onChange={(e) => this.setState({ paymentMethod: e.value, tipAmount: '' })} checked={this.state.paymentMethod === method} />
                                                                              <label htmlFor={"rbPayMethod" + mIdx} className="p-radiobutton-label p-margin-right-20">{trans(PAYMENT_METHODS_OBJECT[method].label)}</label>
                                                                            </div>
                                                                          </React.Fragment>
                                                                        )
                                                                      })}

                                                                      {this.state.gatewayOnsiteMethods.length > 0 &&
                                                                        <React.Fragment>
                                                                          <div className="p-col-12 p-padding-bottom-0">
                                                                            <label className="p-label">{trans('res.menu.methods_via_gateway_with_staff_assisted', { gateway: this.state.onlinePaymentGateway.gateway })}:</label>
                                                                          </div>

                                                                          {this.state.gatewayOnsiteMethods.map((method, mIdx) => {
                                                                            return (
                                                                              <React.Fragment>
                                                                                <div className="p-col-12 p-md-6">
                                                                                  <RadioButton inputId={"rbPayMethod" + mIdx} name="paymentMethod" value={method} onChange={(e) => this.setState({ paymentMethod: e.value, tipAmount: '' })} checked={this.state.paymentMethod === method} />
                                                                                  <label htmlFor={"rbPayMethod" + mIdx} className="p-radiobutton-label p-margin-right-20">{trans(PAYMENT_METHODS_OBJECT[method].label)}</label>
                                                                                </div>
                                                                              </React.Fragment>
                                                                            )
                                                                          })}
                                                                        </React.Fragment>
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                )
                                                              } else {
                                                                return (
                                                                  <div className="p-col-12">
                                                                    <label>{trans('res.menu.payment_method')}: <strong>{trans(PAYMENT_METHODS_OBJECT[this.state.paymentMethod].label)}</strong></label>
                                                                  </div>
                                                                )
                                                              }
                                                            }
                                                          })()}

                                                          {((this.state.paymentOption === PAYMENT_OPTION.Onsite && resConfig.useTipInOnSitePayment) || (this.state.paymentOption === PAYMENT_OPTION.Online && resConfig.useTipInOnlinePayment)) &&
                                                            <div className="p-col-12">
                                                              <Fieldset legend={trans('res.menu.tip')} style={{ paddingLeft: '7.5px', paddingRight: '7.5px', width: '100%' }}>
                                                                <div className="p-grid p-fluid">
                                                                  <div className="p-col-7"><label>{trans('res.menu.tip_amount')}:</label></div>
                                                                  <div className="p-col-5 p-r">
                                                                    <InputText value={this.state.tipAmount} className="p-r"
                                                                      keyfilter="money" maxLength="12"
                                                                      onFocus={(e) => this.setState({ tipAmount: e.target.value == '0' ? '' : e.target.value })}
                                                                      onBlur={(e) => this.setState({ tipAmount: e.target.value ? toFixed(parseFloat(e.target.value), getDecimalPlaces()) : '0' })}
                                                                      onChange={(e) => this.setState({ tipAmount: e.target.value })} style={{ width: '100%' }}
                                                                      placeholder={trans('res.menu.input_tip_here')} />
                                                                  </div>
                                                                  {resConfig.showTipSuggestion && resConfig.suggestedTipRates.length > 0 &&
                                                                    <div className="p-col-12">
                                                                      <div><label>{trans('res.menu.suggested_tip')}</label></div>
                                                                      {resConfig.suggestedTipRates.map(tip => {
                                                                        return (<div className="p-margin-top-5"><label>{trans('res.menu.tip')} {tip}% :</label><label className="p-r p-margin-left-20">{moneyFormat(((this.state.orderSummary.orderTotal * parseFloat(tip)) / 100))}</label></div>);
                                                                      })}
                                                                    </div>
                                                                  }
                                                                </div>
                                                              </Fieldset>
                                                            </div>
                                                          }

                                                          <div className="p-col-12">
                                                            <CheckoutPayment trans={trans}
                                                              tableType={this.state.table.type}
                                                              invoice={this.state.activePayInvoice}
                                                              voucher={this.state.appliedVoucher}
                                                              orderNumber={this.state.table.orderNumber}
                                                              gateway={this.state.paymentOption === PAYMENT_OPTION.Onsite ? PAYMENT_GATEWAY.ONSITE : this.state.onlinePaymentGateway ? this.state.onlinePaymentGateway.gateway : null}
                                                              paymentGateway={this.state.onlinePaymentGateway}
                                                              method={this.state.paymentMethod}
                                                              tipAmount={this.state.tipAmount}
                                                              onCancel={() => this.cancelPayment(isEarlypaymentWhileEating)}
                                                            />
                                                          </div>
                                                        </React.Fragment>
                                                      )
                                                    }
                                                  })()}
                                                </React.Fragment>
                                              )
                                            }
                                          })()}
                                        </div>
                                      </Fieldset>
                                    </React.Fragment>
                                  );
                                }
                              }
                            } else if (requestStatus === TABLE_REQUEST_STATUS.completing_payment) {
                              return (
                                <div className="p-grid">
                                  <div className="p-col-12 p-c">
                                    <Message severity="warn" text={this.state.tableOrder.paymentGateway === PAYMENT_GATEWAY.ONSITE ? trans('res.menu.wait_waiter_complete_payment') : trans('res.menu.wait_bank_verify_payment')} />
                                  </div>
                                  {this.state.tableOrder.paymentGateway === PAYMENT_GATEWAY.ONSITE &&
                                    <div className="p-col-12 p-c">
                                      <Button label={trans('res.menu.change_payment_request')} icon="pi-md-undo" onClick={() => this.cancelPaymentRequest(trans)} />
                                    </div>
                                  }
                                </div>
                              );
                            } else if (requestStatus && requestStatus === TABLE_REQUEST_STATUS.check_request) {
                              return (
                                <div className="p-grid">
                                  <div className="p-col-12 p-c">
                                    <Message severity="warn" text={trans('res.menu.wait_waiter_confirm_checking')} />
                                  </div>
                                </div>
                              );
                            }
                          }
                        }
                      })()}
                    </div>
                  </div>
                }

                {!isEarlypaymentWhileEating && requestStatus && requestStatus === TABLE_REQUEST_STATUS.order_confirmed &&
                  <div className="p-grid">
                    <div className="p-col-12" style={{ display: 'grid' }}>
                      {this.state.isMakingEarlypayment &&
                        <div className="p-col-12">
                          <Fieldset legend="Early Payment Amount">
                            <div className="p-grid">
                              <div className="p-col-6 p-l">{this.state.earlyPaymentPercent.toFixed()}%</div>
                              <div className="p-col-6 p-r">
                                {moneyFormat(this.state.earlyPaymentAmount)}
                              </div>
                              <div className="p-col-12">
                                <Slider value={this.state.earlyPaymentPercent} onChange={(e) => this.onChangeEarlyPaymentPercent(e)} min={0} max={100} step={1} />
                              </div>
                            </div>
                          </Fieldset>
                        </div>
                      }
                      {this.state.isMakingEarlypayment ?
                        <React.Fragment>
                          <Button icon="pi-md-check" className="btn-make-payment" disabled={this.state.earlyPaymentPercent === 0} label={trans('res.menu.confirm_and_pay')} onClick={() => this.requestForPrePayment(customerId)} />
                          <Button icon="pi-md-close" label={trans('res.menu.cancel')} className="p-margin-top-5 p-button-secondary btn-cancel" onClick={() => this.onCancelPrepayment()} />
                          <br></br>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          {requestStatus && requestStatus === TABLE_REQUEST_STATUS.order_confirmed &&
                            <Button label={this.state.btnRequestCheckDisable ? trans('res.menu.processing') : trans('res.menu.request_bill')} icon={this.state.btnRequestCheckDisable ? "pi pi-spin pi-spinner p-size-18" : "pi-md-call"}
                              className="btn-request-bill" onClick={() => this.askForConfirmAction(TABLE_REQUEST_STATUS.check_request)} />
                          }
                          {resConfig.useEarlyPayment &&
                            <Button icon="pi-md-payment" className="p-margin-top-5 btn-payment" label={trans('res.menu.early_payment')}
                              // onClick={() => this.askForConfirmAction(TABLE_REQUEST_STATUS.check_request)}
                              onClick={() => this.onMakePrepayment()}
                            />
                          }
                        </React.Fragment>
                      }
                    </div>
                  </div>
                }

                {!isEarlypaymentWhileEating && requestStatus &&
                  !(requestStatus === TABLE_REQUEST_STATUS.making_payment ||
                    requestStatus === TABLE_REQUEST_STATUS.splitting_payment ||
                    requestStatus === TABLE_REQUEST_STATUS.payment_splitted || requestStatus === TABLE_REQUEST_STATUS.completing_payment ||
                    (requestStatus === TABLE_REQUEST_STATUS.waiting_payment && this.state.isPayment)) &&
                  <React.Fragment>
                    {this.renderOrderItemView(this.state.inServiceGroupOrderItems, false, false, requestStatus && (requestStatus === TABLE_REQUEST_STATUS.waiting_payment || requestStatus === TABLE_REQUEST_STATUS.making_payment || requestStatus === TABLE_REQUEST_STATUS.splitting_payment || requestStatus === TABLE_REQUEST_STATUS.payment_splitted || requestStatus === TABLE_REQUEST_STATUS.completing_payment), trans, defaultLocale, selectedLocale)}
                  </React.Fragment>
                }
              </React.Fragment>
            }

            {!isEarlypaymentWhileEating && ((this.state.orderId && this.state.draftGroupOrderItems.length > 0) || (this.state.orderId && this.state.draftGroupOrderItems.length === 0 && (this.state.confirmingGroupOrderItems.length > 0 || this.state.inServiceGroupOrderItems.length > 0))) && !blockOrdering &&
              <div className="p-margin-top-30">
                {this.state.draftGroupOrderItems.length > 0 && !isEarlypaymentWhileEating && this.state.table.enableOrdering &&
                  <Button label={this.state.btnPlaceOrderDisable ? trans('res.menu.processing') : trans('res.menu.place_order')}
                    className="p-margin-right-10 btn-place-order" onClick={() => this.placeOrder()} />
                }
                {this.renderOrderItemView(this.state.draftGroupOrderItems, true, false, false, trans, defaultLocale, selectedLocale)}
              </div>
            }

            {!isEarlypaymentWhileEating && this.state.confirmingGroupOrderItems.length > 0 &&
              this.renderOrderItemView(this.state.confirmingGroupOrderItems, false, true, false, trans, defaultLocale, selectedLocale)
            }
          </Sidebar>

          <div className="layout-main customer">
            <CustomerOrderTopbar layoutMode={this.state.layoutMode}
              config={resConfig ? resConfig : {}}
              blockOrdering={blockOrdering}
              useFor={USE_FOR_PURPOSE.res_dine_in}
              cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
              isOrderPage={true} setHeight={(e) => this.setState({ topbarHeight: e })}
              table={this.state.table} hasOrders={this.state.inServiceGroupOrderItems.length > 0}
              onCallWaiter={() => this.callWaiter()}
              requestCheck={() => this.askForConfirmAction(TABLE_REQUEST_STATUS.check_request)}
              handleMakePayment={() => this.makePayment()}
              onCartClick={() => this.setState({ frmCartVisible: true })} cartItemCount={this.getTotalCartItemCount()}
              trans={trans}
              isEarlypaymentWhileEating={isEarlypaymentWhileEating}
              listNoti={this.state.listNoti}
              totalQty={this.countTotalQty(this.state.table.orders)}
            />
            <div className="layout-content" id="layout-content-container">
              <LoadingOverlay />
              {this.state.isRequirePinCode ?
                <PinAccess trans={trans}
                  padding={layoutPadding}
                  isValid={this.state.isValidPinCode}
                  access={(e) => this.getTableDetails(e)} />
                :
                <MenuOrdering ref={(el) => this.menuOrdering = el} cLang={this.state.language} trans={trans}
                  config={resConfig ? resConfig : {}}
                  disableOrder={disableOrder}
                  blockOrdering={blockOrdering}
                  useFor={USE_FOR_PURPOSE.res_dine_in}
                  table={this.state.table} currency={currency} orderId={this.state.orderId}
                  padding={layoutPadding}
                  topbarHeight={this.state.topbarHeight}
                />
              }
            </div>
          </div>
        </div>
      </PageVisibility>
    );
  }
}

export default withNamespaces("message")(CustomerOrder);
