import React, { Component, useContext, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import CustomerOrder from './CustomerOrder';
import CustomerInform from './CustomerInform';
import TakeAwayOrder from './take-away/TakeAwayOrder';
import { USE_FOR_PURPOSE, INFORM_TYPE } from './constants';
import StandAloneMenu from './stand-alone-menu/StandAloneMenu';
import TimeOrder from './time-order/TimeOrder';
import DeliveryMode from './DeliveryMode';
import DeliverNow from './delivery-now/DeliveryNow';
import { AppContext } from './core/context/AppContext';
import { loadPublicConfiguration } from './service/ConfigurationService';
import { I18nContext, useTranslation, withNamespaces } from 'react-i18next';
import { getDefaultLocale, getSeletecLocale } from './core/service/CommonService';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

class Main extends Component {
  constructor(props){
    super(props)
    this.state = {
      appConfig: null,
      cLang: 'en-US'
    }
  }

  async componentDidMount(){
    this.loadAppConfig()

    const locale = getSeletecLocale()??getDefaultLocale()
    this.changeLanguage(locale);
  }

  loadAppConfig = async () => {
    const appConfig = await loadPublicConfiguration()
    this.setState({appConfig: appConfig})
  }

  changeLanguage = (e) => {
    this.setState({ cLang: e });
    const { i18n } = this.props;
    i18n.changeLanguage(e);
    localStorage.setItem('selectedLocale', e);
  }

  render() {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    return (
      <AppContext.Provider value={{
        trans: trans,
        appConfig: this.state.appConfig,
        cLang: this.state.cLang,
        dLang: getDefaultLocale(),
        changeLanguage: (e) => this.changeLanguage(e),
        reloadConfig: () => this.loadAppConfig()
      }}>
        <Switch>
          <Redirect exact from="/" to="/menu" />
          <Route path='/menu' component={StandAloneMenu} exact/>

          <Route path='/:tCode/order' component={CustomerOrder}/>
          <Route path='/thankyou/:orderNumber' render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_dine_in} type={INFORM_TYPE.thankyou}/>}/>
          <Route path='/order/inform/:orderNumber' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_dine_in} type={INFORM_TYPE.order_inform}/>}/>
          <Route path='/order/:orderNum' component={CustomerOrder}/>
          <Route path='/delivery/take-away/inform' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_take_out} type={INFORM_TYPE.order_inform}/>}/>
          <Route path='/delivery/take-away/access-denied' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_take_out} type={INFORM_TYPE.access_denied}/>}/>
          <Route path='/delivery/take-away/:orderNumber' component={TakeAwayOrder} exact/>
          <Route path='/delivery/take-away' component={TakeAwayOrder} exact/>
          <Route path='/delivery/time-order/inform' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_time_order} type={INFORM_TYPE.order_inform}/>}/>
          <Route path='/delivery/time-order/:orderNumber' component={TimeOrder} exact/>
          <Route path='/delivery/time-order' component={TimeOrder} exact/>
          <Route path='/delivery/delivery-now/inform' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_delivery_now} type={INFORM_TYPE.order_inform}/>}/>
          <Route path='/delivery/delivery-now/:orderNumber' component={DeliverNow} exact/>
          <Route path='/delivery/delivery-now' component={DeliverNow} exact/>
          <Route path='/delivery' component={DeliveryMode} exact/>
          <Route path='/access-denied/:tCode' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_dine_in} type={INFORM_TYPE.access_denied}/>}/>
          <Route path='/404' render={(props) => <CustomerInform {...props} type={INFORM_TYPE.not_found}/>}/>
        </Switch>

        {this.state.appConfig && this.state.appConfig.enabledWhatsApp && this.state.appConfig.whatsAppPhoneNumber && this.state.appConfig.whatsAppAccountName && 
        <FloatingWhatsApp phoneNumber={this.state.appConfig.whatsAppPhoneNumber} accountName={this.state.appConfig.whatsAppAccountName} allowEsc allowClickAway notification notificationSound/>
        }
      </AppContext.Provider>
    )
  }
}
export default withNamespaces('message')(Main)

// export const Main = () => {
//   const {t, i18n} = useContext(I18nContext)
//   const [appConfig, setAppConfig] = useState()
//   const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
  
//   console.log(i18n)

//   useEffect(() => {
//     const config = loadPublicConfiguration()
//     setAppConfig(config.data)
//   }, [])

//   return (
//     <AppContext.Provider value={{
//       trans: trans,
//       appConfig: appConfig
//     }}>
//       <Switch>
//         <Redirect exact from="/" to="/menu" />

//         <Route path='/:tCode/order' component={CustomerOrder}/>
        
//         <Route path='/menu' component={StandAloneMenu} exact/>
//         <Route path='/thankyou/:orderNumber' render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_dine_in} type={INFORM_TYPE.thankyou}/>}/>
//         <Route path='/order/inform/:orderNumber' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_dine_in} type={INFORM_TYPE.order_inform}/>}/>
//         <Route path='/order/:orderNum' component={CustomerOrder}/>
//         <Route path='/delivery/take-away/inform' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_take_out} type={INFORM_TYPE.order_inform}/>}/>
//         <Route path='/delivery/take-away/access-denied' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_take_out} type={INFORM_TYPE.access_denied}/>}/>
//         <Route path='/delivery/take-away/:orderNumber' component={TakeAwayOrder} exact/>
//         <Route path='/delivery/take-away' component={TakeAwayOrder} exact/>
//         <Route path='/delivery/time-order/inform' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_time_order} type={INFORM_TYPE.order_inform}/>}/>
//         <Route path='/delivery/time-order/:orderNumber' component={TimeOrder} exact/>
//         <Route path='/delivery/time-order' component={TimeOrder} exact/>
//         <Route path='/delivery/delivery-now/inform' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_delivery_now} type={INFORM_TYPE.order_inform}/>}/>
//         <Route path='/delivery/delivery-now/:orderNumber' component={DeliverNow} exact/>
//         <Route path='/delivery/delivery-now' component={DeliverNow} exact/>
//         <Route path='/delivery' component={DeliveryMode} exact/>
//         <Route path='/access-denied/:tCode' exact render={(props) => <CustomerInform {...props} useFor={USE_FOR_PURPOSE.res_dine_in} type={INFORM_TYPE.access_denied}/>}/>
//         <Route path='/404' render={(props) => <CustomerInform {...props} type={INFORM_TYPE.not_found}/>}/>
//       </Switch>
//     </AppContext.Provider>
//   );
// }
