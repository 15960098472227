import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { withNamespaces } from "react-i18next";
import { MaskedCalendar } from "./core/components/calendar/MaskedCalendar";
import { Dropdown } from "primereact/dropdown";
import { saveDateForTimeOrder, getTimeOrderTimes } from "./service/TimeOrderService";
import moment from 'moment';

export class PickupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            orderTime: '',
            timeSelect: '',
            errors: {},
            tableId: '',
            orderId: '',
            timeList: [],
            timeListOption: [],
            productLimit: 0,
            minDate: new Date(),
            orderPickUpTime: '',
            reachLimit: false
        }
    }

    action = (date, time, tableId, orderId) => {
        let list = [];
        let listOption = [];
        let dishLimit = 0;
        let currentTotalUnit = 0;
        let availableUnit = 0;
        let totalLimit = 0;
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1)
        if(date && time){
            getTimeOrderTimes(moment(date).format('yyyy-MM-DD HH:mm:ss'), tableId).then(res => {
                if (res) {
                    res.map((i) => {
                        let timeObj = {time: i.s + " - " + i.e,
                                productLimit: i.productLimit,
                                currentTotalUnit: i.currentTotalUnit,
                                availableUnit: i.availableUnit,
                                unitLimit: i.unitLimit
                            };
                        let timeOption = i.s + " - " + i.e;
                        if(time === timeObj.time){
                            dishLimit = i.productLimit;
                            currentTotalUnit = i.currentTotalUnit
                            availableUnit = i.availableUnit
                            totalLimit = i.unitLimit
                        }
                        if(totalLimit === 0 || (currentTotalUnit < availableUnit)){
                            list.push(timeObj);
                            listOption.push(timeOption);
                        }
                        
                    })
                    this.setState({
                        timeList: list ? list : [],
                        timeListOption: listOption ? listOption : [],
                        visible: true,
                        orderTime: date ?  moment(date).toDate() : '',
                        orderPickUpTime: date ?  moment(date).toDate() : '',
                        timeSelect: time ? time : '',
                        tableId: tableId ? tableId : '',
                        orderId: orderId ? orderId : '',
                        productLimit: dishLimit,
                        currentTotalUnit: currentTotalUnit,
                        availableUnit: availableUnit,
                        totalLimit: totalLimit,
                        minDate: yesterday,
                        reachLimit: availableUnit <= 0 && totalLimit !== 0 ? true : false
                    })
                }
            })
        }else{
            this.setState({
                visible: true,
                orderTime: date ?  moment(date).toDate() : '',
                orderPickUpTime: date ? moment(date).toDate() : '',
                timeSelect: time ? time : '',
                tableId: tableId ? tableId : '',
                orderId: orderId ? orderId : '',
                minDate: yesterday
            })
        }
    }

    onError = (errors) => {
        this.setState({ errors: errors });
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    showTimeSlotLabel = () => {
        const trans = this.props.trans;
        let timeListOption = this.state.timeListOption;
        let timeSelect = this.state.timeSelect;
        if(timeListOption.indexOf(timeSelect) === -1){
            return(
                <label>{trans('res.menu.selected_slot')}: {timeSelect}</label>
            )
        }
    }

    onStartTimeChange = (e) => {
        let value = e.value;
        let date = moment(value).format('yyyy-MM-DD HH:mm:ss');
        let list = [];
        let listOption = [];
        this.setState({
            orderTime: e.value,
        })

        getTimeOrderTimes(date, this.state.tableId).then(res => {
            if (res) {
                res.map((i) => {
                    let time = {time: i.s + " - " + i.e,
                                productLimit: i.productLimit,
                                currentTotalUnit: i.currentTotalUnit,
                                availableUnit: i.availableUnit,
                                unitLimit: i.unitLimit
                            };
                    let timeOption = i.s + " - " + i.e;
                    if(i.unitLimit === 0 || (i.currentTotalUnit < i.availableUnit)){
                        list.push(time);
                        listOption.push(timeOption);
                    }
                    
                })
                this.setState({
                    timeList: list ? list : [],
                    timeListOption: listOption ? listOption : [],
                    productLimit: 0,
                    currentTotalUnit: 0,
                    availableUnit: 0,
                    totalLimit: 0,
                    timeSelect: '',
                    orderPickUpTime: ''
                })
            }
        })
    }

    // onOrderPickUpTimeChange = (value, orderTime) => {
    //     let pickUpTime = moment(value).format(moment.HTML5_FMT.TIME)
    //     let pickUpDate = moment(orderTime?orderTime:this.state.orderTime).format('yyyy-MM-DD')
    //     let timeSelect = this.state.timeSelect

    //     if(timeSelect !== "") {
    //         const regex = /[0-9][0-9][:][0-9][0-9]\s[a-z][a-z]/gm;
    //         let result = timeSelect.match(regex)

    //         let startTimeSelect;
    //         let endTimeSelect;
    //         let currentTimeSelect;

    //         if(result && result.length > 0) {
    //             startTimeSelect =  Date.parse(moment(moment(pickUpDate + result[0], 'YYYY-MM-DDLT').format('YYYY-MM-DD hh:mm:ss')).toDate())
    //             endTimeSelect =  Date.parse(moment(moment(pickUpDate + result[1], 'YYYY-MM-DDLT').format('YYYY-MM-DD hh:mm:ss')).toDate())
    //             currentTimeSelect = Date.parse(moment(moment(pickUpDate + pickUpTime, 'YYYY-MM-DDLT').format('YYYY-MM-DD hh:mm:ss')).toDate())

    //             // console.log(startTimeSelect, currentTimeSelect, endTimeSelect);

    //             if(currentTimeSelect > endTimeSelect || currentTimeSelect < startTimeSelect) return
    //         }
    //     }
        
    //     // tell moment how to parse the input string
    //     var momentObj = moment(pickUpDate + pickUpTime, 'YYYY-MM-DDLT');

    //     // conversion
    //     var dateTime = momentObj.format('YYYY-MM-DD HH:mm:ss');

    //     // console.log(value);
        
    //     this.setState({
    //         orderPickUpTime: value,
    //         orderTime: moment(dateTime).toDate()
    //     })
    // }

    onChageTimeSelect = (e) => {
        let dishLimit = null;
        let currentTotalUnit = 0;
        let availableUnit = 0;
        let totalLimit = 0;
        let timeList = this.state.timeList;
        let pickUpDate = moment(this.state.orderTime).format('yyyy-MM-DD')
        timeList.map(t => {
            if(t.time === e.value){
                dishLimit = t.productLimit
                currentTotalUnit = t.currentTotalUnit
                availableUnit = t.availableUnit
                totalLimit = t.unitLimit
            }
        })

        // console.log(pickUpDate);

        const regex = /[0-9][0-9][:][0-9][0-9]\s[a-z][a-z]/gm;
        let result = e.value.match(regex)
        let endTimeSelect =  moment(pickUpDate + result[1], 'YYYY-MM-DDLT').toDate()

        this.setState({
            timeSelect: e.value,
            productLimit: dishLimit ? dishLimit : 0,
            orderPickUpTime: endTimeSelect,
            orderTime: endTimeSelect,
            currentTotalUnit: currentTotalUnit,
            availableUnit: availableUnit,
            totalLimit: totalLimit,
            reachLimit: availableUnit <= 0 && totalLimit !== 0 ? true : false
        });
    }

    handelSubmit = () => {
        saveDateForTimeOrder(this.state.orderId, this.state.timeSelect, moment(this.state.orderTime).format('yyyy-MM-DD HH:mm:ss')).then(res => {
            let flag = this.props.updateDateTime(this.state.orderTime, this.state.timeSelect, this.state.currentTotalUnit, this.state.totalLimit);
            if(flag === false) return;
            this.onHide();
        })
        
    }

    render() {
        const trans = this.props.trans;
        const footer = (
            <div>
                <Button disabled={this.state.orderTime === '' || this.state.timeSelect === '' || this.state.reachLimit} label={trans('res.mgt.submit')} icon="pi pi-check" onClick={() => this.handelSubmit()} />
                {/* {this.state.mode === 'add' ?
                    <Button disabled={this.state.weekdays.length === 0 || this.state.startValue === '' ||  this.state.endValue === '' } label={trans('res.mgt.submit')} icon="pi pi-check" onClick={() => this.handleGenerateSlot()} />:
                    <Button disabled={this.state.weekdays.length === 0 || this.state.startValue === '' ||  this.state.endValue === '' } label={trans('res.mgt.submit')} icon="pi pi-check" onClick={() => this.handleEditSlot()} />
                } */}
                
                {/* <Button label={trans('res.mgt.cancel')} icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/> */}
            </div>
        );

        return(
            <div>
                <Dialog contentStyle={{width: "500px"}} header={trans('res.mgt.time_order')} footer={footer} visible={this.state.visible} baseZIndex={1000000} width="1050px" responsive={true} modal={true} onHide={this.onHide} closable={false}>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label>{trans('res.menu.select_date')}: </label>
                            <MaskedCalendar value={this.state.orderTime} minDate={this.state.minDate} showIcon={true} style={{width: 'inherit', paddingLeft: "1%" }} onChange={this.onStartTimeChange} hourFormat="12" />
                            <div className="p-form-error">{this.state.errors.start}</div>

                        </div>
                        {this.state.orderTime !== '' &&
                            <div className="p-col-12">
                                <label>{trans('res.menu.select_a_slot')}: </label>
                                {this.state.timeList.length > 0 ?
                                    <Dropdown
                                        value={this.state.timeSelect}
                                        options={this.state.timeListOption}
                                        onChange={e => this.onChageTimeSelect(e)}
                                        style={{ width: "20%", paddingLeft: "1%" }}
                                    /> :
                                    <label>{trans('res.mgt.not_available')}</label>
                                }
                            </div>
                        }

                        {this.state.timeSelect &&
                            <React.Fragment>
                                <div className="p-col-12" style={{display:"inline-flex"}}>
                                    {this.showTimeSlotLabel()}
                                </div>
                                <div className="p-col-12" style={{display:"inline-flex"}}>
                                    <label><strong><u>{trans('res.menu.pick_up_at')}:</u></strong> {moment(this.state.orderPickUpTime).format("hh:mm A")}</label>
                                </div>
                                {this.state.reachLimit ?
                                    <div className="p-col-12 p-form-error" style={{display:"inline-flex"}}>
                                        <label>{`${trans('res.menu.available_dishes_to_order')}: ${this.state.currentTotalUnit}/${this.state.totalLimit}`}</label>
                                    </div>:
                                this.state.totalLimit === 0 ?
                                    '':
                                    <div className="p-col-12" style={{display:"inline-flex"}}>
                                        <label>{`${trans('res.menu.available_dishes_to_order')}: ${this.state.currentTotalUnit}/${this.state.totalLimit}`}</label>
                                    </div>
                                }
                            </React.Fragment>
                            
                        }

                        {/* {this.state.timeSelect !== '' &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label>{trans('res.menu.pick_up_at')}: </label>
                                    <MaskedCalendar value={this.state.orderPickUpTime} showIcon={true} onChange={(e) => this.onOrderPickUpTimeChange(e.value)} showTime={true} hourFormat="24" timeOnly={true} />
                                    <span>{moment(this.state.orderPickUpTime).format("hh:mm A")}</span>
                                </div>
                            </React.Fragment>
                            
                        }
                        {this.state.productLimit !== 0 &&
                            <div className="p-col-12 p-md-6">
                                <label>{trans('res.mgt.dish_limit')}: {this.state.productLimit}</label>
                            </div>
                        } */}
                        
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default withNamespaces("message")(PickupForm);
