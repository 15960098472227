import React, { Component } from 'react';
import { createPaymentRequestHitPay } from '../../../service/OrderService';
import { moneyFormat } from '../../service/CommonService';
export default class HitPayCheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hitPayRequest: {
                amount: 0,
                currency: this.props.currencyCode
            }
        };
    }

    componentDidMount() {}

    validationCustomerInput = (cusData) => {
        let errors = {};
        if (!cusData.cusName)
            errors.cusName = { code: 2030 };
        if (!cusData.cusPhone)
            errors.cusPhone = { code: 2030 };

        return errors;
    }

    createPaymentRequest = (amount, redirectUrl, paymentMethods=["paynow_online", "card", "wechat", "alipay"]) => {
        if(this.props.customerData){
            const cusErrors = this.validationCustomerInput(this.props.customerData);

            if(Object.keys(cusErrors).length>0){
                this.props.onCustomerDataError(cusErrors);
                return;
            }
        }

        this.setState({
            hitPayRequest: {...this.state.hitPayRequest,
                amount: amount,
                redirect_url: redirectUrl,
                name: this.props.customerData?this.props.customerData.cusName:'',
                phone: this.props.customerData?this.props.customerData.cusPhone:'',
                payment_methods: paymentMethods,
                email: this.props.email?this.props.email:'',
            }
        }, () => {
            createPaymentRequestHitPay(this.state.hitPayRequest, this.props.orderNumber, this.props.method, this.props.gateway, this.props.tipAmount, this.props.orderId, this.props.invoiceId, this.props.cusId, this.props.useFor)
            .then(res => {
                if(!res.errorCode) {
                    res.url && window.location.assign(res.url)
                }
            })
        })
    }



    render() {
        const { amount, activePayment, trans, redirectUrl, paymentMethods } = this.props;

        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12">
                        <button className="p-button p-component p-button-text-icon-left btn-make-payment" onClick={() => this.createPaymentRequest(amount, redirectUrl, paymentMethods)}>
                            <span className="pi-md-payment p-c p-button-icon-left"></span>
                            <span className="p-button-text p-c">{trans('res.menu.pay')} ({moneyFormat(amount)})</span>
                        </button>
                        <button className="p-button p-component p-button-secondary  btn-cancel" type="button" onClick={() => this.props.onHandleCancel()}>
                            {/* <span className="pi-md-close p-c p-button-icon-left"></span>p-button-text-icon-left */}
                            <span className="p-button-text p-c">{trans('res.menu.cancel')}</span>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
