import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'tippy.js/themes/light.css';
import { BrowserRouter } from 'react-router-dom'
import Main from './Main';
import Notification from './core/components/Notification';
import Message from './core/components/Message';
import ScrollToTop from './core/layout/ScrollToTop';
import './core/interceptors/interceptors';

import {I18nextProvider} from 'react-i18next';
import { reactI18nextModule } from "react-i18next";
import i18next from 'i18next';

import message_en from "./translations/message_en.json";
import message_fr from "./translations/message_fr.json";
import message_vi from "./translations/message_vi.json";
import message_fi from "./translations/message_fi.json";
import message_sv from "./translations/message_sv.json";
import message_de from "./translations/message_de.json";
import message_es from "./translations/message_es.json";
import message_in from "./translations/message_in.json";
import message_it from "./translations/message_it.json";
import message_ja from "./translations/message_ja.json";
import message_ko from "./translations/message_ko.json";
import message_pt from "./translations/message_pt.json";
import message_zh from "./translations/message_zh.json";

i18next
    // .use(detector)
    .use(reactI18nextModule)
    .init({
        interpolation: { escapeValue: false },
        lng: 'en',
        fallbackLng: "en",
        resources: {
            'en': { message: message_en },
            'en-US': { message: message_en },
            'en-UK': { message: message_en },
            'fr': { message: message_fr },
            'fr-FR': { message: message_fr },
            'vi': { message: message_vi },
            'fi': { message: message_fi },
            'sv': { message: message_sv },
            'sv-FE': { message: message_sv },
            'de':{ message: message_de },
            'es':{ message: message_es },
            'in':{ message: message_in },
            'it':{ message: message_it },
            'ja':{ message: message_ja },
            'ko':{ message: message_ko },
            'pt':{ message: message_pt },
            'pt-BR':{ message: message_pt },
            'zh-CN':{ message: message_zh },
            'zh-TW':{ message: message_zh },
            'ru':{ message: message_en }
        },
    }
);

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <ScrollToTop>
                <Message />
                <Main></Main>
                <Notification />
            </ScrollToTop>
        </BrowserRouter>
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
