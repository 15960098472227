import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-number-input'
import classNames from 'classnames'

export const PhoneNumberInput = (props) => {
    const [focus, setFocus] = useState(false)

    useEffect(() => {
        let phoneinput = document.getElementsByClassName('PhoneInputInput')

        if(phoneinput)
            phoneinput[0].classList.add('p-component', 'p-inputtext')
    }, [])

    return(
        <PhoneInput value={props.value} defaultCountry={props.defaultCountry} onChange={(e) => props.onChange(e)} onFocus={() => setFocus(true)} onBlur={() => !props.value && setFocus(false)} className={classNames(props.className, {'p-inputwrapper-focus': focus})} style={props.style}/>
    )
}