import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { CHANNEL_TYPE, HITPAY_PAYMENT_METHODS, PAYMENT_GATEWAY, TABLE_TYPE, VOUCHER_STATUS } from '../../../constants'
import { completeOrderPayment, requestPayOnsite } from '../../../service/CustomerOrderService'
import { processInvoiceBeforePayment } from '../../../service/OrderService'
import { getCustomerId, moneyFormat } from '../../service/CommonService'
import { showErrorNotify, showSuccessNotify } from '../../service/NotificationService'
import { parseMessage } from '../../utils/TranslationUtils'
import { HitPayPayment } from './HitPayPayment'
import { MidtransPayment } from './MidtransPayment'
import { PaymentContext } from '../../context/PaymentContext'
import { showloading, stoploading } from '../../service/LoadingService'
import { PhoneNumberInput } from '../phoneinput/PhoneNumberInput'
import { AppContext } from '../../context/AppContext'
import { useHistory } from 'react-router-dom'

export const CheckoutPayment = (props) => {
	const {trans, appConfig} = useContext(AppContext)
	const history = useHistory()

	const tableType = props.tableType
	const paymentGateway = props.paymentGateway
	const invoice = props.invoice
	const voucher = props.voucher
	const orderNumber = props.orderNumber
	const method = props.method
	const gateway = props.gateway
	const tipAmount = props.tipAmount?parseFloat(props.tipAmount):0
	const voucherAmount = voucher && voucher.status===VOUCHER_STATUS.Used ? voucher.value : 0
	
	const amount = invoice?((invoice.total-invoice.tip) - voucherAmount > 0 ? (invoice.total-invoice.tip) - voucherAmount : 0) + tipAmount:0

	const [cusInfo, setCusInfo] = useState({})

	const [errors, setErrors] = useState({})

	const cpnTxtName = useRef(null)
	const cpnTxtPhone = useRef(null)
	const cpnTxtEmail = useRef(null)
	const cpnHitPay = useRef(null)
	const cpnMidtrans = useRef(null)

	const [processing, setProcessing] = useState(false)

	useEffect(() => {
		let email = invoice ? invoice.payorEmail : ''

		if(!email) email = appConfig.bypassEnterBillingEmail && appConfig.defaultBillingEmail ? appConfig.defaultBillingEmail : ''

		setCusInfo({
			cusEmail: email
		})
	}, [invoice])

	useEffect(() => {
		// if(Object.keys(errors).length>0){
		// 	const firstErr = Object.keys(errors)[0];
		// 		switch(firstErr){
		// 			case 'cusName':
		// 				cpnTxtName.current.focus()
		// 				break
		// 			case 'cusPhone':
		// 				cpnTxtPhone.current.focus()
		// 				break;
		// 			case 'cusEmail':
		// 				console.log(cpnTxtEmail)
		// 				cpnTxtEmail.current.focus()
		// 				break;
		// 			default:
		// 				break;
		// 		}
		// }
	}, [errors])

	const isRequireEmail = () => {
		let flagRequire = false

		if(!appConfig.bypassEnterBillingEmail || !appConfig.defaultBillingEmail){
			if([PAYMENT_GATEWAY.HITPAY, PAYMENT_GATEWAY.MIDTRANS].includes(gateway)) {
				flagRequire = true
			} else if(gateway===PAYMENT_GATEWAY.ONSITE) {
				if(paymentGateway && paymentGateway.gateway===PAYMENT_GATEWAY.HITPAY && HITPAY_PAYMENT_METHODS.includes(method)){
					flagRequire = true
				}
			}
		}
		
		return flagRequire
	}

	const onsiteCheckout = async () => {
		let result;

		if(amount===0)
			result = await completeOrderPayment(orderNumber, invoice.id, null, gateway, null, null, getCustomerId(), cusInfo.cusName, cusInfo.cusPhone, cusInfo.cusEmail)
		else
			result = await requestPayOnsite(orderNumber, invoice.id, method, tipAmount, getCustomerId(), cusInfo.cusName, cusInfo.cusPhone, cusInfo.cusEmail)

		if(!result.errorCode){
			if(tableType===TABLE_TYPE.dine_in){
				if(amount!==0)
					showSuccessNotify(trans('res.menu.success'));
			}else{
				let redirectType = 'take-away'
				if(tableType===TABLE_TYPE.time_order)
					redirectType = 'time-order'
				else if(tableType===TABLE_TYPE.delivery_now)
					redirectType = 'delivery-now'

				history.push(`/delivery/${redirectType}/inform`)
			}
		}else{
			if(result.errorCode!==400)
				showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
			else{
				setErrors(result.errorObj)
			}
		}

		setProcessing(false)

		// if(amount===0){
		// 	completeOrderPayment(orderNumber, invoice.id, null, gateway, null, null, getCustomerId(), cusInfo.cusName, cusInfo.cusPhone, cusInfo.cusEmail)
		// 	.then(res => {
		// 		if (res.errorCode){
		// 			showErrorNotify(res.errorMessage);
		// 		}
		// 		setProcessing(false)
		// 	})
		// }else{
		// 	requestPayOnsite(orderNumber, invoice.id, method, tipAmount, getCustomerId(), cusInfo.cusName, cusInfo.cusPhone, cusInfo.cusEmail)
		// 	.then((res) => {
		// 		if (!res.errorCode) {
		// 			showSuccessNotify(trans('res.menu.success'));
		// 		} else {
		// 			if(res.errorCode===400){
		// 				setErrors(res.errorObj)
		// 			}else{
		// 				showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
		// 			}
		// 		}

		// 		setProcessing(false)
		// 	})
		// }
	}

	const completeCheckoutPayment = async (token) => {
		showloading()

		const res = await completeOrderPayment(orderNumber, invoice.id, null, gateway, token, null)

		if (res.errorCode){
			showErrorNotify(res.errorMessage);
		} else {
			if(tableType!==TABLE_TYPE.dine_in){
				let redirectType = 'take-away'
				if(tableType===TABLE_TYPE.time_order)
					redirectType = 'time-order'
				else if(tableType===TABLE_TYPE.delivery_now)
					redirectType = 'delivery-now'

				history.push(`/delivery/${redirectType}/inform`)
			}
		}

		setProcessing(false)
		stoploading()
	}

	const onlineCheckout = async () => {
		const resInvoice = await processInvoiceBeforePayment(orderNumber, invoice.id, gateway, tipAmount, cusInfo.cusName, cusInfo.cusPhone, cusInfo.cusEmail)

		if(!resInvoice.errorCode){
			switch(gateway){
				case PAYMENT_GATEWAY.HITPAY:
					cpnHitPay.current.showHitPayPayment()
					break
				case PAYMENT_GATEWAY.MIDTRANS:
					cpnMidtrans.current.retrieveSnapToken(invoice.id)
					break
				default: 
					break
			}
		}else{
			if(resInvoice.errorCode===400){
				setErrors(resInvoice.errorObj)
			}else{
				showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
			}

			setProcessing(false)
		}
	}

	const processCheckout = async () => {
		setProcessing(true)
		setErrors({})

		if(gateway===PAYMENT_GATEWAY.ONSITE){
			onsiteCheckout()
		}else{
			onlineCheckout()
		}
	}

	return (
		amount >= 0 && 
		<PaymentContext.Provider value={{
			trans: trans,
			isProcessing: processing,
			stopProcess: () => setProcessing(false),
			paymentGateway: paymentGateway,
			invoice: invoice,
			amount: amount,
			onCheckout: () => processCheckout(),
			onCompletePayment: (data) => completeCheckoutPayment(data)
		}}>
			<div className='p-grid'>
				{(tableType!==TABLE_TYPE.dine_in || isRequireEmail()) && 
				<div className='p-col-12 p-margin-top-20'>
					<div className='p-grid p-fluid'>
						{tableType!==TABLE_TYPE.dine_in && 
						<React.Fragment>
							<div className="p-col-12 p-md-6">
								<label className="p-label">* {trans('res.menu.your_name')} *</label>
								<InputText ref={cpnTxtName} value={cusInfo.cusName} onChange={(e) => setCusInfo({...cusInfo, cusName: e.target.value})}/>
								<div className="p-form-error">{errors.cusName && parseMessage(trans, errors.cusName.code)}</div>
							</div>
							<div className="p-col-12 p-md-6">
								<label className="p-label">* {trans('res.menu.your_phone')} *</label>
								<PhoneNumberInput  ref={cpnTxtPhone} value={cusInfo.cusPhone} defaultCountry={appConfig.countryCode} onChange={(e) => setCusInfo({...cusInfo, cusPhone: e})}/>
								<div className="p-form-error">{errors.cusPhone && parseMessage(trans, errors.cusPhone.code)}</div>
							</div>
						</React.Fragment>
						}

						{isRequireEmail() && 
						<div className="p-col-12">
							<label className="p-label">* {trans('res.menu.your_email')} *</label>
							<InputText ref={cpnTxtEmail} value={cusInfo.cusEmail} keyfilter="email" onChange={(e) => setCusInfo({...cusInfo, cusEmail: e.target.value})}/>
							<div className="p-form-error">{errors.cusEmail && parseMessage(trans, errors.cusEmail.code)}</div>
						</div>
						}
					</div>
				</div>
				}

				<div className='p-col-12 p-margin-top-10'>
					{[PAYMENT_GATEWAY.ONSITE, PAYMENT_GATEWAY.HITPAY, PAYMENT_GATEWAY.MIDTRANS].includes(gateway) &&
					<React.Fragment>
						{gateway===PAYMENT_GATEWAY.HITPAY && <HitPayPayment ref={cpnHitPay} />}
						
						{gateway===PAYMENT_GATEWAY.MIDTRANS && <MidtransPayment ref={cpnMidtrans}/>}

						{gateway===PAYMENT_GATEWAY.ONSITE && 
						<Button icon={processing?"pi pi-spin pi-spinner":amount===0?"pi pi-check":"pi-md-local-atm"} disabled={processing} 
							label={processing?trans('res.menu.processing'):amount===0?trans('res.menu.complete_free_payment'):(trans('res.menu.pay') + " (" + moneyFormat(amount) + ")")} 
							onClick={() => processCheckout()}
							className="btn-make-payment"/>
						}
						
						<Button disabled={processing} label={trans('res.menu.cancel')} className="p-button-secondary btn-cancel p-margin-left-20" onClick={() => props.onCancel()}/>
					</React.Fragment>
					}
				</div>
			</div>
		</PaymentContext.Provider>
	)
}