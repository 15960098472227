import axios from 'axios';
import { RATING_BASE_URL } from '../constants';

export function getRatingByCondition(objId, objType, cusId){
    return axios.get(`${RATING_BASE_URL}/get-by-condition`, {
        params: {objId, objType, cusId}
    }).then(res => res.data).catch(error => console.log(error));
}

export function customerSaveRating(data){
    return axios.post(`${RATING_BASE_URL}/customer/save`, data).then(res => res.data).catch(error => console.log(error));
}

export function getRatings(objectId, objectType){
    return axios.get(`${RATING_BASE_URL}/list`, {params: {objectId, objectType}}).then(res => res.data).catch(error => console.log(error));
}