import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { moneyFormat } from '../../service/CommonService';
import { Button } from 'primereact/button';
import { PAYMENT_USE_MODE } from '../../../constants';
import { showloading, stoploading } from '../../service/LoadingService';

const appId = 'sandbox-sq0idb-EtUAXcKX1riav5tSXLgURw';
const locationId = 'J36TJKKCXMNBH';

export default class SquareCheckoutForm extends Component {

    static propTypes = {
        onHandleCardSuccess: PropTypes.func,
        amount: PropTypes.any,
        currency: PropTypes.string,
        applicationId: PropTypes.string,
        locationId: PropTypes.string,
    }

    static defaultProps = {
        onHandleCardSuccess: PropTypes.func,
        amount: PropTypes.any,
        currency: PropTypes.string,
        applicationId: PropTypes.string,
        locationId: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            card: null,
            errorMessages: [],
        }
    }

    setUpSquare = () => {
        if (!window.Square) {
            alert('Square.js failed to load properly');
        }
    }

    async componentDidMount() {

        // this.setUpSquare()
        // this.getActivePayment(resConfig.resCardPaymentGateway, (res) => {
        //   if(res.gateway === PAYMENT_GATEWAY.SQUARE) {
        //     let squareJs = document.createElement('script');
        //     squareJs.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
        //     squareJs.id = 'squarev1';
        //     squareJs.async = true;
        //     document.body.appendChild(squareJs);
        //   }
        // });

        showloading()
        // document.addEventListener('DOMContentLoaded', async function () {
            if (!window.Square) {
                throw new Error('Square.js failed to load properly');
            }

            let payments;
            try {
                console.log("Init");
                payments = window.Square.payments(this.props.applicationId, this.props.locationId);
            } catch {
                console.log("Error");
                const statusContainer = document.getElementById(
                    'payment-status-container'
                );
                statusContainer.className = 'missing-credentials';
                statusContainer.style.visibility = 'visible';
                stoploading()
                return;
            }

            let card;
            try {
                card = await this.initializeCard(payments);
            } catch (e) {
                console.error('Initializing Card failed', e);
                stoploading()
                return;
            }

            this.setState({
                card: card
            })

            // Checkpoint 2.

            // const cardButton = document.getElementById('card-button');
            // cardButton.addEventListener('click', async function (event) {
            //     await handlePaymentMethodSubmission(event, card);
            // });
        // });
        stoploading()
    }

    validationCustomerInput = (cusData) => {
        let errors = {};
        if (!cusData.cusName)
            errors.cusName = { code: 2030 };
        if (!cusData.cusPhone)
            errors.cusPhone = { code: 2030 };

        return errors;
    }

    displayPaymentResults = (status) => {
        const statusContainer = document.getElementById(
            'payment-status-container'
        );
        if (status === 'SUCCESS') {
            statusContainer.classList.remove('is-failure');
            statusContainer.classList.add('is-success');
        } else {
            statusContainer.classList.remove('is-success');
            statusContainer.classList.add('is-failure');
        }

        statusContainer.style.visibility = 'visible';
    }

    initializeCard = async (payments) => {
        const card = await payments.card();
        await card.attach('#card-container');

        return card;
    }

    createPayment = async (token) => {
        if (this.props.customerData) {
            const cusErrors = this.validationCustomerInput(this.props.customerData);

            if (Object.keys(cusErrors).length > 0) {
                this.props.onCustomerDataError(cusErrors);
                stoploading()
                return;
            }
        }

        // if (errors && errors[0] !== null) {
        //     this.setState({ errorMessages: errors.map(error => error.message) })
        //     return
        // }

        this.setState({ errorMessages: [] })
        this.props.onHandleCardSuccess(token);
        // const body = JSON.stringify({
        //     locationId,
        //     sourceId: token,
        // });

        // const paymentResponse = await fetch('/payment', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body,
        // });

        // if (paymentResponse.ok) {
        //     return paymentResponse.json();
        // }

        // const errorBody = await paymentResponse.text();
        // throw new Error(errorBody);
    }

    tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }

            throw new Error(errorMessage);
        }
    }

    handlePaymentMethodSubmission = async () => {
        stoploading()
        try {
            // disable the submit button as we await tokenization and make a payment request.
            // cardButton.disabled = true;
            const token = await this.tokenize(this.state.card);
            const paymentResults = await this.createPayment(token);
            this.displayPaymentResults('SUCCESS');

            console.debug('Payment Success', paymentResults);
        } catch (e) {
            // cardButton.disabled = false;
            this.displayPaymentResults('FAILURE');
            console.error(e.message);
            stoploading()
        }
        stoploading()
    }

    render() {
        const { amount, applicationId, locationId, activePayment, trans } = this.props;
        const loadingView = <div className="sq-wallet-loading"></div>
        const unavailableView = <div className="sq-wallet-unavailable">Unavailable</div>

        return (
            <div>
                <form id="payment-form">
                    <div id="card-container"></div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <button className="p-button p-component p-button-text-icon-left btn-make-payment" id="card-button" onClick={() => this.handlePaymentMethodSubmission()} type="button">
                                <span className="pi-md-payment p-c p-button-icon-left"></span>
                                <span className="p-button-text p-c">{trans('res.menu.pay')} ({moneyFormat(amount)})</span>
                            </button>
                        </div>
                        <div className="p-col-12">
                            <button className="p-button p-component p-button-secondary  btn-cancel" type="button" onClick={() => this.props.onHandleCancel()}>
                                {/* <span class="pi-md-close p-c p-button-icon-left"></span>p-button-text-icon-left */}
                                <span className="p-button-text p-c">{trans('res.menu.cancel')}</span>
                            </button>
                        </div>
                    </div>
                </form>
                <div id="payment-status-container"></div>
            </div>
        )
    }
}
