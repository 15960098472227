import axios from 'axios';
import { ORDER_BASE_URL } from '../constants';

//General for waiter and customer
export function getDish(id, isCustomer){
  let url = ORDER_BASE_URL + (isCustomer?'/customer':'');
  return axios.get(`${url}/dish/${id}`)
  .then(res => {return res.data}).catch(error => console.log(error));
}

export function removeOrderItems(data, isCustomer, customerId=null, orderId, isConfirmedOrder, reason){
  let url = ORDER_BASE_URL + (isCustomer?'/customer':'');
  return axios.post(`${url}/items/remove`, data, {
    params: {customerId, orderId, isConfirmedOrder, reason}
  })
  .then(res => res.data).catch(error => console.log(error));
}

export function saveOrderItem(data, isCustomer, customerId=null){
  let url = ORDER_BASE_URL + (isCustomer?'/customer':'');
  return axios.post(`${url}/items`, data, {
    params: {customerId}
  }).then(res => res.data).catch(error => console.log(error));
}

export function getSnapToken(orderNumber, amount, invoiceId, numOfMakingPayment){
  return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/snap/token`, null, {
    params: {amount, invoiceId, numOfMakingPayment}
  })
  .then(res => res.data).catch(error => console.log(error));
}

export function processInvoiceForOrder(orderNumber, customerId) {
  return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/process-invoice`, null, {
    params: {customerId}
  }).then(res => res.data).catch(error => console.log(error));
}

export function applyPromotionCoupon(orderId, orderNumber, couponCode){
  return axios.post(`${ORDER_BASE_URL}/customer/${orderId}/${orderNumber}/coupon/apply/${couponCode}`, null)
  .then(res => res.data).catch(error => console.log(error));
}

export function cancelPromotionCoupon(orderId, orderNumber, couponCode){
  return axios.post(`${ORDER_BASE_URL}/customer/${orderId}/${orderNumber}/coupon/cancel/${couponCode}`, null)
  .then(res => res.data).catch(error => console.log(error));
}

export function applyVoucherToOrder(voucherCode) {
  return axios.post(`${ORDER_BASE_URL}/customer/voucher/${voucherCode}/applying`).then(res => res.data).catch(error => console.log(error));
}

export function confirmVoucherToOrder(voucherCode, invoiceId, orderNumber) {
  return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/voucher/${voucherCode}/confirm`, {}, {params: { invoiceId, orderNumber }}).then(res => res.data).catch(error => console.log(error));
}

export function createPaymentRequestHitPay(request, orderNumber, method, gateway, tipAmount, orderId, invoiceId, cusId, useFor){
  return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/hitpay/create-payment`, request, {
    params: {method, gateway, tipAmount, orderId, invoiceId, cusId, useFor}
  })
  .then(res => res.data).catch(error => console.log(error));
}

export function processInvoiceBeforePayment(orderNumber, invoiceId, gateway, tipAmount, cusName, cusPhone, cusEmail){
  return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/${invoiceId}/prepare-invoice-payment`, {}, {
    params: {gateway, tipAmount, cusName, cusPhone, cusEmail}
  }).then(res => res.data).catch(error => console.log(error))
}

export function generatePdfReceipt(orderNumber, invoiceId){
  return axios.get(`${ORDER_BASE_URL}/customer/${orderNumber}/pdf-receipt`, {
    responseType: "blob",
    params: {invoiceId}
  })
  .then(res => res.data);
}