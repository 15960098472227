import axios from 'axios';
import { MENU_BASE_URL } from '../constants';

export function getMenuData(tableId, useForTable) {
    let params = {}
    if (tableId) {
        params['tableId'] = tableId
    }
    if (useForTable) {
        params['useForTable'] = useForTable
    }
    return axios.get(`${MENU_BASE_URL}/menu`, { params })
    .then(res => res.data).catch(error => console.log(error));
}

export function cleanMenuDataCache() {
    axios.delete(`${MENU_BASE_URL}/menu`);
}