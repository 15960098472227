import React, { Component } from 'react';
import moment from "moment";
import classNames from "classnames";
import { Button } from 'primereact/button';
import { withNamespaces } from 'react-i18next';
import { getDineInTableOrder } from './service/CustomerOrderService';
import CustomerOrderTopbar from './core/layout/CustomerOrderTopbar';
import "./CustomerOrder.scss";
import { loadPublicConfiguration } from './service/ConfigurationService';
import { showloading, stoploading } from './core/service/LoadingService';
import { callWaiter } from './service/CustomerOrderService';
import * as notify from "./core/service/NotificationService";
import * as socketService from './core/service/CustomerWebEventService';
import { USE_FOR_PURPOSE, INFORM_TYPE, PAYMENT_STATE, SHIPPING_STATE, USER_EVENTS, OBJECT_TYPE, ORDER_PAY_MODE, RECAPTCHA_GG_SITEKEY, TABLE_REQUEST_STATUS, GRAB_DELIVERY_STATUS } from './constants';
import { getAllTakeAwayOrderByCustomer, getAllTimeOrderByCustomer, getAllDeliveryNowByCustomer} from './service/TakeAwayOrderService';
import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { moneyFormat, convertQueueNumber } from './core/service/CommonService';
import { InputTextarea } from 'primereact/inputtextarea';
import { customerSaveRating } from './service/RatingService';
import Rating from 'react-rating';
import { getDeliveryRequest } from './service/GrabService'
import { generatePdfReceipt } from './service/OrderService';

const FileDownload = require('js-file-download');
const bellSound = new Audio('/assets/sounds/bell.wav');

class CustomerInform extends Component{
    constructor(props) {
        super(props);
        this.state = {
            layoutMode: "static",
            language: 'en-US',
            topbarHeight: 0,
            dineInOrder: null,
            paymentMode: null,
            openTakeAwayOrders: [],
            closedTakeAwayOrders: [],
            btnDownloadReceiptDisabled: false,
            ratings: {},
            refresh: null
        }
    }

    async componentDidMount() {
        await loadPublicConfiguration();

        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        const { i18n } = this.props;
        let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

        i18n.changeLanguage(locale);
        this.setState({language: locale});

        const customerId = localStorage.getItem("customerId");
        const takeAwayTable = localStorage.getItem('takeAwayTableCode');
        const timeOrderTable = localStorage.getItem('timeOrderTableCode');
        const deliveryNowTable = localStorage.getItem('deliveryNowTableCode');
        
        // const params = new URLSearchParams(this.props.location.search);
        // const paymentStatus = params.get('paymentStatus');
        // if(paymentStatus && paymentStatus === 'canceled') {
        //     this.props.history.push(`/delivery/take-away`);
        // }

        if(customerId && this.props.useFor){
            if(this.props.useFor===USE_FOR_PURPOSE.res_take_out && this.props.type===INFORM_TYPE.order_inform && customerId && takeAwayTable){
                this.getCustomerTakeAwayOrder(() => {
                    socketService.initConnection(takeAwayTable+"_"+customerId);

                    socketService.customerEvent.on(USER_EVENTS.orderPaymentStatusChange, (data) => {
                        this.updateTakeAwayOrderStatus(data.orderNumber, data.paymentState, null, customerId, trans);
                        // bellSound.play();
                    });
        
                    socketService.customerEvent.on(USER_EVENTS.orderShippingStatusChange, (data) => {
                        this.updateTakeAwayOrderStatus(data.orderNumber, null, data.shippingState, customerId, trans);
                        if(data.shippingState === SHIPPING_STATE.ready || data.shippingState === SHIPPING_STATE.pending  || data.shippingState === SHIPPING_STATE.shipping){
                                bellSound.play();
                            }

                    });
                    socketService.customerEvent.on(USER_EVENTS.tableOrderPlaced, () => {
                        this.getCustomerTakeAwayOrder();
                    })
                });
            }else if(this.props.useFor===USE_FOR_PURPOSE.res_dine_in){
                const orderNumber = this.props.match.params.orderNumber;

                if(orderNumber){
                    this.getDineInOrder(orderNumber, customerId, (tableCode)=> {
                        socketService.initConnection(tableCode);

                        socketService.customerEvent.on(USER_EVENTS.orderPaymentStatusChange, (data) => {
                            this.updateDineInOrderStatus(data.paymentState, null, customerId, trans);
                        });

                        socketService.customerEvent.on(USER_EVENTS.orderShippingStatusChange, (data) => {
                            this.updateDineInOrderStatus(null, data.shippingState, customerId, trans);
                        });
                    });
                }
            }else if(this.props.useFor===USE_FOR_PURPOSE.res_time_order && this.props.type===INFORM_TYPE.order_inform && customerId && timeOrderTable){
                this.getCustomerTimeOrder(() => {
                    socketService.initConnection(timeOrderTable+"_"+customerId);
    
                    // socketService.customerEvent.on(USER_EVENTS.orderPaymentStatusChange, (data) => {
                    //     this.updateTakeAwayOrderStatus(data.orderNumber, data.paymentState, null, customerId, trans);
                    //     // bellSound.play();
                    // });
        
                    // socketService.customerEvent.on(USER_EVENTS.orderShippingStatusChange, (data) => {
                    //     this.updateTakeAwayOrderStatus(data.orderNumber, null, data.shippingState, customerId, trans);
                    //     if(data.shippingState === SHIPPING_STATE.ready || data.shippingState === SHIPPING_STATE.pending  || data.shippingState === SHIPPING_STATE.shipping){
                    //             bellSound.play();
                    //         }
    
                    // });
                });
            }else if(this.props.useFor===USE_FOR_PURPOSE.res_delivery_now && this.props.type===INFORM_TYPE.order_inform && customerId && deliveryNowTable){
                this.getCustomerDeliveryNow(() => {
                    socketService.initConnection(deliveryNowTable+"_"+customerId);
                });
            }else{
                this.props.history.push(this.props.useFor===USE_FOR_PURPOSE.res_dine_in?`/access-denied`:`/delivery/take-away/access-denied`);
            }
        }
    }

    updateDineInOrderStatus = (paymentStatus, shippingStatus, customerId, trans) => {
        let tmpOrder = this.state.dineInOrder;

        if(paymentStatus) {
            tmpOrder.paymentStatus=paymentStatus;
            if(paymentStatus===PAYMENT_STATE.completed)
                tmpOrder.shippingStatus=SHIPPING_STATE.pending;
        }
        if(shippingStatus) tmpOrder.shippingStatus=shippingStatus;

        let ratings = {...this.state.ratings};

        if(tmpOrder.shippingStatus===SHIPPING_STATE.completed){
            ratings[tmpOrder.id] = {
                objRating: {
                    objectType: OBJECT_TYPE.order,
                    objectId: tmpOrder.id,
                    customerId: customerId,
                    rating: 0
                },
                btnSubmitRatingDisabled: false
            }
        }

        this.setState({
            dineInOrder: tmpOrder,
            ratings: ratings
        });

        notify.showWarnNotify(trans('res.menu.your_order_has_been_updated'));
    }

    updateTakeAwayOrderStatus = (orderNumber, paymentStatus, shippingStatus, customerId, trans) => {
        let orders = [...this.state.openTakeAwayOrders];

        let tmpOrder = orders.find(o => o.orderNumber===orderNumber);

        if(paymentStatus) tmpOrder.paymentStatus=paymentStatus;
        if(shippingStatus) tmpOrder.shippingStatus=shippingStatus;

        let ratings = {...this.state.ratings};

        if(tmpOrder.paymentStatus===PAYMENT_STATE.completed && tmpOrder.shippingStatus===SHIPPING_STATE.completed){
            ratings[tmpOrder.id] = {
                objRating: {
                    objectType: OBJECT_TYPE.order,
                    objectId: tmpOrder.id,
                    customerId: customerId,
                    rating: 0
                },
                btnSubmitRatingDisabled: false
            }
        }

        this.setState({
            openTakeAwayOrders: orders,
            ratings: ratings
        });

        notify.showWarnNotify(trans('res.menu.your_order_has_been_updated'));
    }

    hadleVisibilityChange = isVisible => {
        if (isVisible){
            if(!socketService.connected()) {
                window.location.reload();
            }
        }
    }

    getDineInOrder = (orderNumber, cusId, cb) => {
        getDineInTableOrder(orderNumber, cusId)
        .then(res => {
            if(res){
                
                const order = res.tableOrder;
                const tableCode = order.table.code;
                const paymentMode = order.table.paymentMode;
                const invoiceList = res.tableOrder.invoices;
                let rating = res.rating;

                if(!rating){
                    rating = {
                        objectType: OBJECT_TYPE.order,
                        objectId: order.id,
                        customerId: cusId,
                        rating: 0 
                    };

                }

                this.setState({
                    dineInOrder: order,
                    paymentMode: paymentMode,
                    invoiceList: invoiceList,
                    ratings: {
                        [order.id]: {
                            objRating: rating,
                            btnSubmitRatingDisabled: false
                        }
                    }
                }, () => {
                    if(cb && paymentMode===ORDER_PAY_MODE.pre_pay) cb(tableCode+"_"+orderNumber);
                });
            }else{
                this.props.history.push(`/404`);
            }
        });
    }

    getCustomerTakeAwayOrder = (cb) => {
        const customerId = localStorage.getItem("customerId");

        if(customerId){
            showloading();
            getAllTakeAwayOrderByCustomer(customerId)
            .then(res => {
                if(res){
                    this.setState({
                        openTakeAwayOrders: res.openOrders,
                        closedTakeAwayOrders: res.closedOrders,
                        refresh: null
                    });

                    cb && cb();
                    stoploading();
                }else {
                    this.setState({
                        refresh: "takeAway"
                    })
                }
            });
        }else{
            this.props.history.push(`/delivery/take-away/access-denied`);
        }
    }

    getCustomerTimeOrder = (cb) => {
        const customerId = localStorage.getItem("customerId");

        if(customerId){
            showloading();
            getAllTimeOrderByCustomer(customerId)
            .then(res => {
                if(res){
                    this.setState({
                        openTakeAwayOrders: res.openOrders,
                        closedTakeAwayOrders: res.closedOrders
                    });

                    cb && cb();
                    stoploading();
                }else {
                    this.props.history.push(`/delivery/take-away/access-denied`);
                }
            });
        }else{
            this.props.history.push(`/delivery/take-away/access-denied`);
        }
    }

    getCustomerDeliveryNow = (cb) => {
        const customerId = localStorage.getItem("customerId");

        if(customerId){
            showloading();
            getAllDeliveryNowByCustomer(customerId)
            .then(res => {
                if(res){
                    res.openOrders && res.openOrders.length > 0 && res.openOrders.map((order, idx) => {
                        if(order.deliveryId) {
                            getDeliveryRequest(order.deliveryId).then(res => {
                                order['grabDeliveryStatus'] = res.status
                            })
                        }
                    })
                    
                    this.setState({
                        openTakeAwayOrders: res.openOrders,
                        closedTakeAwayOrders: res.closedOrders
                    });

                    cb && cb();
                    stoploading();
                }else {
                    this.props.history.push(`/delivery/take-away/access-denied`);
                }
            });
        }else{
            this.props.history.push(`/delivery/take-away/access-denied`);
        }
    }

    // getDeliveryRequestStatus= (deliveryId, idx) => {
    //     getDeliveryRequest(deliveryId).then(res => {
    //         this.setState({
    //             [`deliveryStatus${idx}`]: res.status
    //         })
    //     })
    // }

    changeLanguage = (e) => {
        this.setState({ language: e });
        const { i18n } = this.props;
        i18n.changeLanguage(e.value);
        localStorage.setItem('selectedLocale', e);
    };

    downloadPdfReceipt = (orderNumber, invoiceId, customerId, idx) => {
        let btnDownloadReceiptDisabled = `btnDownloadReceiptDisabled`;
        if(idx) btnDownloadReceiptDisabled = `btnDownloadReceiptDisabled${idx}`;
        this.setState({ 
                [btnDownloadReceiptDisabled]: true 
            },() => {
                generatePdfReceipt(orderNumber, invoiceId).then(res => {
                    FileDownload(res, `receipt-${orderNumber}.pdf`);
                }).finally(() => this.setState({ [btnDownloadReceiptDisabled]: false }));
            }
        );
    }

    callWaiter = async (tableCode) => {
        showloading();
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        await callWaiter(tableCode)
        .then(res => {
            if (!res.errorCode) {
                notify.showSuccessNotify(trans('res.menu.request_has_sent'));
            } else {
                notify.showErrorNotify(res.errorMessage);
            }
        })
        .finally(stoploading());
    }

    translatePrePayOrderStatus = (trans, paymentStatus, shippingStatus) => {
        if(paymentStatus===PAYMENT_STATE.completing){
            return trans('res.menu.please_pay_cashier_for_order_to_be_processed');
        }else if(paymentStatus===PAYMENT_STATE.completed){
            switch (shippingStatus){
                case SHIPPING_STATE.pending:
                    return trans('res.menu.order_has_been_paid_and_being_sent_to_kitchen');
                case SHIPPING_STATE.preparing:
                    return trans('res.menu.order_is_being_prepared');
                case SHIPPING_STATE.ready:
                    return trans('res.menu.order_is_ready_and_can_collect_it');
                case SHIPPING_STATE.completed:
                    return trans('res.menu.thankyou');
                default: break;
            }
        }
    }

    saveRating = (objId) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        this.setState({
            ratings: {
                ...this.state.ratings,
                [objId]: {
                    ...this.state.ratings[objId],
                    btnSubmitRatingDisabled: true
                }
            }
        },() => {
            customerSaveRating(this.state.ratings[objId].objRating)
            .then(res => {
                if(res){
                    this.setState({
                        ratings: {
                            ...this.state.ratings,
                            [objId]: {
                                ...this.state.ratings[objId],
                                objRating: res
                            }
                        }
                    });

                    notify.showSuccessNotify(trans("res.menu.thank_for_your_feedback"));
                }else{
                    notify.showErrorNotify(trans("res.menu.fail_to_submit_feedback"));
                }
            })
            .finally(() => 
                this.setState({ 
                    ratings: {
                        ...this.state.ratings,
                        [objId]: {
                            ...this.state.ratings[objId],
                            btnSubmitRatingDisabled: false
                        }
                    }
                })
            );
        })
    }

    onChangeRating = (value, objId) => {
        this.setState({
            ratings: {
                ...this.state.ratings,
                [objId]: {
                    ...this.state.ratings[objId],
                    objRating: {
                        ...this.state.ratings[objId].objRating,
                        rating: value
                    }
                }
            }
        },() => {
            this.saveRating(objId);
        })
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        const useFor = this.props.useFor;
        const orderNumber = useFor===USE_FOR_PURPOSE.res_dine_in?this.props.match.params.orderNumber:null;

        const layoutPadding = (this.state.topbarHeight + 'px 15px 0px 15px');
        const customerId =  localStorage.getItem("customerId")?parseInt(localStorage.getItem("customerId")):null;

        const params = new URLSearchParams(this.props.location.search);
        const isMonitoring = params.get('isMonitoring')?params.get('isMonitoring'):false;
        
        let wrapperClass = classNames("layout-wrapper", {
            "layout-wrapper-static": this.state.layoutMode === "static",
            "layout-wrapper-active": this.state.mobileMenuActive,
            "layout-menu-horizontal": this.state.layoutMode === "horizontal",
        });

        const resConfig = localStorage.getItem('resConfig')?JSON.parse(localStorage.getItem('resConfig')):{};
        const isPrepaymentWhileEating = this.state.paymentMode === ORDER_PAY_MODE.post_pay && (this.state.dineInOrder.table.requestStatus === TABLE_REQUEST_STATUS.confirm_request || this.state.dineInOrder.table.requestStatus === TABLE_REQUEST_STATUS.order_confirmed);
        let addHeight = 0;
        if(resConfig.showResName && resConfig.resName)
            addHeight= addHeight + 15
        if(resConfig.showBrandingName && resConfig.brandingName)
            addHeight= addHeight + 15

        
        return (
            <div>
                <PageVisibility onChange={this.hadleVisibilityChange}>
                    <div className={wrapperClass}>
                        <div className="layout-main customer">
                            <CustomerOrderTopbar ref={(el) => this.topBar = el} layoutMode={this.state.layoutMode}
                                config={resConfig?resConfig:{}}
                                useFor={useFor}
                                cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
                                isOrderPage={false} setHeight={(e) => this.setState({topbarHeight: e})}
                                goToTakeAwayOrder={() => {socketService.close(); this.props.history.push(`/delivery/take-away`);}}
                                trans={trans}
                            />
                            <div className="layout-content" id="layout-content-container">
                                {/* <div className="p-col-12" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <div className="topbar-header p-c" ref={el => this.resBanner = el} style={{backgroundColor: resConfig.resTopbarBackgroundColor, color: resConfig.resTopbarTextColor,  padding: this.props.padding, paddingTop: this.state.topbarHeight, boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)"}}>
                                        {resConfig.resLogo &&
                                            <img className="topbar-header-logo" height={resConfig.resLogoHeight?resConfig.resLogoHeight:65} alt="LOGO" src={`/restaurant/assets/restaurant_logo.png`}/>}
                                        {resConfig.showResName && resConfig.resName && 
                                            <h1 className="topbar-header-title">{resConfig.resName}</h1>}
                                        {resConfig.showBrandingName && resConfig.brandingName &&
                                            <h2 className="topbar-header-branding">{resConfig.brandingName}</h2>}
                                    </div>
                                </div> */}
                                <div className="p-col-12 processing-section" style={{ padding: layoutPadding}}>
                                    <div className="p-margin-top-20 p-padding-all-20 p-c panel-card">
                                        {this.props.type===INFORM_TYPE.access_denied || this.props.type===INFORM_TYPE.not_found ?
                                            useFor===USE_FOR_PURPOSE.res_dine_in ?
                                            <React.Fragment>
                                                <img src="/assets/fromlabs/icon_clock1.png" />
                                                <h1 className="p-margin-top-20 p-size-36">{this.props.type===INFORM_TYPE.access_denied?trans('res.menu.table_access_denied'):trans('res.menu.table_not_found')}</h1>
                                                {/* <div><i className="pi-md-mood-bad p-size-60" style={styles.errorIcon}></i></div>                                         */}
                                                <p>{this.props.type==='access_denied'?trans('res.menu.table_access_denied_notify'):trans('res.menu.table_not_found_notify')}</p>

                                                {this.props.type===INFORM_TYPE.access_denied &&
                                                <React.Fragment>
                                                    <p>{trans('res.menu.call_waiter_notify')}</p>
                                                    <p className="p-margin-top-30">
                                                        <Button label={trans('res.menu.back_to_order_page')} icon="pi-md-arrow-back" className="p-button" onClick={() => this.props.history.push(`/${this.props.match.params.tCode}/order`)} />
                                                        <Button label={trans('res.menu.call_server')} icon="pi-md-call" className="p-button-warning" onClick={() => this.callWaiter(this.props.match.params.tCode)} />
                                                    </p>
                                                </React.Fragment>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <img src="/assets/fromlabs/icon_close.png" />
                                                <h1 className="p-margin-top-30 p-size-30">{this.props.type===INFORM_TYPE.access_denied?trans('res.menu.order_access_denied'):trans('res.menu.request_not_found')}</h1>
                                                <p>{trans('res.menu.request_not_found_notify')}</p>
                                            </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {this.props.useFor===USE_FOR_PURPOSE.res_dine_in && this.state.dineInOrder?
                                            <React.Fragment>
                                                {isMonitoring || this.state.paymentMode===ORDER_PAY_MODE.post_pay?
                                                    <React.Fragment>
                                                    <img src="/assets/fromlabs/icon_check_alt.png" />
                                                    <p style={{marginBottom:'-13px'}}>{'Payment Success'}</p>
                                                    <h1 className="">{trans('res.menu.thankyou')}</h1>
                                                  {/* <div><i className="pi-md-sentiment-very-satisfied p-size-60" style={styles.successIcon}></i></div> */}
                                                  {/* <p>{trans('res.menu.see_you_again')}</p> */}
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <h1 className="p-margin-top-20 p-size-20">{trans('res.menu.your_order_number')}:</h1>
                                                    <h2 className="p-size-18 p-margin-5-0 p-w-normal">{this.state.dineInOrder.batchNumber}</h2>
                                                    <h1 className="p-size-36 p-padding-0-15" style={{display: 'inline', border: '2px solid #d3d3d3', borderRadius: '5px'}}>
                                                        {convertQueueNumber(this.state.dineInOrder.queueNumber)}
                                                    </h1>
                                                    <div className="p-margin-top-15">
                                                        {this.state.dineInOrder.paymentStatus===PAYMENT_STATE.completed && this.state.dineInOrder.shippingStatus===SHIPPING_STATE.completed?
                                                        <i className="pi-md-sentiment-very-satisfied p-size-36" style={styles.successIcon}></i>
                                                        :
                                                        <i className="pi-md-warning p-size-36" style={styles.warningIcon}></i>
                                                        }
                                                    </div>
                                                    <h2 className="p-size-18 p-margin-top-0">{this.translatePrePayOrderStatus(trans, this.state.dineInOrder.paymentStatus, this.state.dineInOrder.shippingStatus)}</h2>

                                                </React.Fragment>
                                                }

                                                {isMonitoring == false && orderNumber && this.state.dineInOrder &&
                                                <React.Fragment>
                                                    {(this.state.dineInOrder.paymentStatus===PAYMENT_STATE.completed || isPrepaymentWhileEating) &&
                                                    <React.Fragment>
                                                        <p>{trans('res.menu.your_receipt_here')}</p>
                                                        {this.state.invoiceList && this.state.invoiceList.length > 0 && this.state.invoiceList.some(invoice => invoice.userPayment === customerId) ?
                                                            <React.Fragment>
                                                                {this.state.invoiceList.map((invoice, idx) => {
                                                                        if(invoice.userPayment === customerId) {
                                                                            return (
                                                                                <p className="p-margin-top-5">
                                                                                    <span className="p-margin-right-10">{trans('res.menu.receipt_no')}: {(this.state.dineInOrder.batchNumber+"-"+convertQueueNumber(this.state.dineInOrder.queueNumber))}-{idx+1}</span>
                                                                                <Button className="btn-payment" label={this.state[`btnDownloadReceiptDisabled${idx}`]?trans('res.menu.processing'):trans('res.menu.download_receipt')} icon={this.state[`btnDownloadReceiptDisabled${idx}`]?"pi pi-spin pi-spinner p-size-18":"pi-md-file-download"} 
                                                                                disabled={this.state[`btnDownloadReceiptDisabled${idx}`]?this.state[`btnDownloadReceiptDisabled${idx}`]:false} onClick={() => this.downloadPdfReceipt(orderNumber, invoice.id, customerId, idx)} /></p>
                                                                            )
                                                                        }
                                                                    })
                                                                }   
                                                            </React.Fragment>
                                                            :
                                                            <p className="p-margin-top-30"><Button style={{color:'white'}} label={this.state.btnDownloadReceiptDisabled?trans('res.menu.processing'):trans('res.menu.download_receipt')} icon={this.state.btnDownloadReceiptDisabled?"pi pi-spin pi-spinner p-size-18":"pi-md-file-download"} disabled={this.state.btnDownloadReceiptDisabled} onClick={() => this.downloadPdfReceipt(orderNumber, null, null, null)} /></p>
                                                        }
                                                    </React.Fragment>
                                                    }
                                                    {this.state.dineInOrder.paymentStatus!==PAYMENT_STATE.completed && isPrepaymentWhileEating && <Button label={trans('res.menu.back_to_order_page')} icon="pi-md-arrow-back" className="p-button p-margin-right-10" onClick={() => this.props.history.push(`/${this.state.dineInOrder.table.code}/order`)} />}
                                                    {(this.state.dineInOrder.shippingStatus===SHIPPING_STATE.completed || isPrepaymentWhileEating) &&
                                                    <div className="p-margin-top-10 p-c">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-4"></div>
                                                            <div className="p-col-12 p-md-4">
                                                                {/* <div className="p-hr" style={{display: 'inline-block', width: 300}} /> */}
                                                                <p>{trans('res.menu.rating_message')}</p>
                                                                {/* <Rating className="p-rating-custom" value={this.state.ratings.rating} cancel={false} onChange={(e) => this.onChangeRating(e.value)} /> */}
                                                                <Rating className="p-rating p-rating-custom" onChange={(value) => this.onChangeRating(value, this.state.dineInOrder.id)}
                                                                    emptySymbol="pi-md-star-border p-size-36" fullSymbol="pi-md-star p-size-36"
                                                                    fractions={1} initialRating={this.state.ratings[this.state.dineInOrder.id].objRating.rating}
                                                                />
                                                                {this.state.ratings[this.state.dineInOrder.id].objRating && this.state.ratings[this.state.dineInOrder.id].objRating.id &&
                                                                    <div className="p-margin-top-10">
                                                                        <p>{trans('res.menu.feedback')}</p>
                                                                        <InputTextarea rows={5} cols={30} value={this.state.ratings[this.state.dineInOrder.id].objRating.feedback} onChange={(e) => this.setState({ratings: {...this.state.ratings, [this.state.dineInOrder.id]: {...this.state.ratings[this.state.dineInOrder.id], objRating: {...this.state.ratings[this.state.dineInOrder.id].objRating, feedback: e.target.value}}}})} placeholder={trans('res.menu.input_your_feedback_here')} 
                                                                        style={{width: '100%', borderColor:"#0a0a0a"}}/>
                                                                        <p><Button className="btn-make-payment" label={this.state.ratings[this.state.dineInOrder.id].btnSubmitRatingDisabled?trans('res.menu.processing'):trans('res.menu.feedback_submit')} icon={this.state.ratings[this.state.dineInOrder.id].btnSubmitRatingDisabled?"pi pi-spin pi-spinner p-size-18":""} disabled={this.state.ratings[this.state.dineInOrder.id].btnSubmitRatingDisabled} onClick={(e) => this.saveRating(this.state.dineInOrder.id)} /></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </React.Fragment>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {(this.state.openTakeAwayOrders.length>0 || this.state.closedTakeAwayOrders.length>0) && 
                                                <React.Fragment>
                                                    {this.state.refresh === "takeAway" &&
                                                        <Button className="p-col-2 p-r" label={trans('res.mgt.refresh')} onClick={(e) => this.getCustomerTakeAwayOrder()}/>
                                                    }
                                                    <div className="p-grid processing-section">
                                                        {this.state.openTakeAwayOrders.map((order, idx) => {
                                                            return(
                                                                <div className="p-col-12">
                                                                    <h1 className="p-margin-top-20 p-size-20">{trans('res.menu.your_order_number')}:</h1>
                                                                    <h2 className="p-size-18 p-margin-5-0 p-w-normal">{order.batchNumber}</h2>
                                                                    {order.pickUpDate && <h2 className="p-size-18 p-margin-5-0 p-w-normal">{trans('res.mgt.pickup_date')}: {order.pickUpDate}</h2>}
                                                                    {order.pickUpTime && <h2 className="p-size-18 p-margin-5-0 p-w-normal">{trans('res.mgt.time')}: {order.pickUpTime}</h2>}
                                                                    {order.grabDeliveryStatus && <h2 className="p-size-18 p-margin-5-0 p-w-normal">{trans('res.mgt.delivery_status')}: {order.grabDeliveryStatus}</h2>}
                                                                    <h1 className="p-size-36 p-padding-0-15" style={{display: 'inline', border: '2px solid #d3d3d3', borderRadius: '5px'}}>
                                                                        {convertQueueNumber(order.queueNumber)}
                                                                    </h1>
                                                                    <div className="p-margin-top-15">
                                                                        {order.paymentStatus===PAYMENT_STATE.completed && order.shippingStatus===SHIPPING_STATE.completed?
                                                                        <i className="pi-md-sentiment-very-satisfied p-size-36" style={styles.successIcon}></i>
                                                                        :
                                                                        <i className="pi-md-warning p-size-36" style={styles.warningIcon}></i>
                                                                        }
                                                                    </div>
                                                                    <h2 className="p-size-18 p-margin-top-0">{this.translatePrePayOrderStatus(trans, order.paymentStatus, order.shippingStatus)}</h2>
                                                                    {order.paymentStatus===PAYMENT_STATE.completed &&
                                                                    <React.Fragment>
                                                                        <p className="p-margin-top-20">
                                                                            <Button label={trans('res.menu.download_receipt')} style={{color:'white'}}
                                                                            icon={this.state.btnDownloadReceiptDisabled?"pi pi-spin pi-spinner p-size-18":"pi-md-file-download"} 
                                                                            disabled={this.state.btnDownloadReceiptDisabled} onClick={() => this.downloadPdfReceipt(order.orderNumber)} />
                                                                        </p>
                                                                        {order.shippingStatus===SHIPPING_STATE.completed &&
                                                                        <React.Fragment>
                                                                            <div className="p-margin-top-10 p-c">
                                                                                <div className="p-grid">
                                                                                    <div className="p-col-12 p-md-4"></div>
                                                                                    <div className="p-col-12 p-md-4">
                                                                                        <div className="p-hr" style={{display: 'inline-block', width: 300}} />
                                                                                        <p>{trans('res.menu.rating_message')}</p>
                                                                                        {/* <Rating className="p-rating-custom" value={this.state.ratings[order.id].objRating.rating} cancel={false} onChange={(e) => this.onChangeRating(e.value)} /> */}
                                                                                        <Rating className="p-rating p-rating-custom" onChange={(value) => this.onChangeRating(value, order.id)}
                                                                                            emptySymbol="pi-md-star-border p-size-36" fullSymbol="pi-md-star p-size-36"
                                                                                            fractions={2} initialRating={this.state.ratings[order.id].objRating.rating}
                                                                                        />
                                                                                        {this.state.ratings[order.id].objRating && this.state.ratings[order.id].objRating.id &&
                                                                                            <div className="p-margin-top-10">
                                                                                                <p>{trans('res.menu.feedback')}</p>
                                                                                                <InputTextarea rows={5} cols={30} value={this.state.ratings[order.id].objRating.feedback} onChange={(e) => this.setState({ratings: {...this.state.ratings, [order.id]: {...this.state.ratings[order.id], objRating: {...this.state.ratings[order.id].objRating, feedback: e.target.value}}}})} placeholder={trans('res.menu.input_your_feedback_here')} style={{width: '100%'}}/>
                                                                                                <p><Button label={this.state.ratings[order.id].btnSubmitRatingDisabled?trans('res.menu.processing'):trans('res.menu.feedback_submit')} icon={this.state.ratings[order.id].btnSubmitRatingDisabled?"pi pi-spin pi-spinner p-size-18":""} disabled={this.state.ratings[order.id].btnSubmitRatingDisabled} onClick={(e) => this.saveRating(order.id)} /></p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                    }
                                                                    <div className="p-hr"></div>
                                                                </div>
                                                            )
                                                        })}

                                                        {this.state.closedTakeAwayOrders.length>0 &&
                                                            <div className="p-col-12 p-margin-top-20">
                                                                <DataTable 
                                                                    lazy={true} value={this.state.closedTakeAwayOrders}
                                                                    header={<div>{trans('res.menu.your_history_orders')}</div>}
                                                                    responsive={true}
                                                                >
                                                                    <Column field="batchNumber" header={trans('res.menu.batch_number')}/>
                                                                    <Column field="queueNumber" header={trans('res.menu.order_number')} />
                                                                    <Column field="updatedAt" header={trans('res.menu.date')} body={(rowData) => rowData.updatedAt && moment(rowData.updatedAt).format('MM-DD-YYYY hh:mm A')}/>
                                                                    <Column field="total" header={trans('res.menu.total')} body={(rowData) => moneyFormat(rowData.total)}/>
                                                                    <Column field="" body={(rowData) => <Button label={trans('res.menu.download_receipt')} style={{color:'white'}} icon={this.state.btnDownloadReceiptDisabled?"pi pi-spin pi-spinner p-size-18":"pi-md-file-download"} disabled={this.state.btnDownloadReceiptDisabled} onClick={() => this.downloadPdfReceipt(rowData.orderNumber)} />}/>
                                                                </DataTable>
                                                            </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                                }
                                                
                                                {this.state.refresh && this.state.refresh === "takeAway" &&
                                                    <React.Fragment>
                                                        <img src="/assets/fromlabs/icon_clock1.png" />
                                                        <h1 style={{marginBottom:'-13px'}}>{'Payment In-process'}</h1>
                                                        <p className="">{trans('res.mgt.if_too_long_click_refresh')}</p>
                                                        <Button label={trans('res.mgt.refresh')} onClick={(e) => this.getCustomerTakeAwayOrder()}/>
                                                    </React.Fragment>
                                                }
                                                
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageVisibility>
            </div>
            
        );
    }
}

const styles = {
    successIcon: {
        color: '#4ADE8D'
    },
    warningIcon: {
        color: '#FFC107'
    },
    errorIcon: {
        color: '#cc2929'
    }
}

export default withNamespaces('message')(CustomerInform)
