import React, { Component } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { Button } from 'primereact/button';

export default class PayPalCheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    validationCustomerInput = (cusData) => {
        let errors = {};
        if(!cusData.cusName)
            errors.cusName = {code: 2030};
        if(!cusData.cusPhone)
            errors.cusPhone = {code: 2030};
        
        return errors;
    }

    render() {
        const { amount, currencyCode, clientId, activePayment, trans } = this.props;

        return (
            <React.Fragment>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12 p-md-6">
                        <PayPalButton
                            style={{
                                shape: 'rect',
                                color: 'blue',
                                layout: 'horizontal',
                                label: 'paypal',
                                tagline: 'false'
                            }}
                            createOrder={(data, actions) => {
                                if(this.props.customerData){
                                    const cusErrors = this.validationCustomerInput(this.props.customerData);
                                    
                                    if(Object.keys(cusErrors).length>0){
                                        this.props.onCustomerDataError(cusErrors);
                                        return;
                                    }
                                }
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            currency_code: currencyCode,
                                            value: amount
                                        }
                                    }]
                                });
                            }}
                            onApprove={(data, actions) => {
                                return this.props.onHandleCardSuccess(data.orderID);
                            }}
                            options={{
                                clientId: clientId,
                                currency: currencyCode
                            }}
                        />
                    </div>
                </div>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-6">
                        <Button label={trans('res.menu.cancel')} className="p-button-secondary btn-lg btn-cancel" onClick={() => this.props.onHandleCancel()} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
