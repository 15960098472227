import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import classNames from "classnames";
import { createBrowserHistory } from "history";
import "../ripple.js";
import "../CustomerOrder.scss";
import "../common.css";
import PageVisibility from 'react-page-visibility';
import LoadingOverlay from "../core/components/LoadingOverlay.js";
import {MenuOrdering} from "../order/MenuOrdering.js";
import CustomerOrderTopbar from "../core/layout/CustomerOrderTopbar.js";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as notify from "../core/service/NotificationService";
import { showloading, stoploading } from "../core/service/LoadingService";
import { loadPublicConfiguration } from "../service/ConfigurationService";
import * as socketService from '../core/service/CustomerWebEventService';
import { TABLE_REQUEST_STATUS, PAYMENT_METHOD, PAYMENT_OPTION, USE_FOR_PURPOSE, VOUCHER_STATUS, ORDER_ACTIVITY_TYPE, PAYMENT_GATEWAY, PAYMENT_METHODS_OBJECT, CHANNEL_TYPE } from "../constants.js";
import { RadioButton } from "primereact/radiobutton";
import { Fieldset } from "primereact/fieldset";
import { Message } from "primereact/message";
import { moneyFormat, getGroupOrderItems, getTableOrderSummary, getCustomerId, isPriceIncludedTaxOrService } from "../core/service/CommonService.js";
import { ConfirmNotification } from '../core/components/ConfirmNotification';
import { OrderItemForm } from "../order/OrderItemForm.js";
import { getTakeAwayOrderTable, requestPayOnsiteForTakeAway, completeOrderPaymentForTakeAway} from "../service/TakeAwayOrderService.js";
import { removeOrderItems, applyPromotionCoupon, cancelPromotionCoupon, applyVoucherToOrder, confirmVoucherToOrder, processInvoiceForOrder } from "../service/OrderService.js";
import { getOnlinePaymentGateway } from "../service/PaymentService.js";
import { parseMessage } from "../core/utils/TranslationUtils.js";
import { getMenuData } from "../service/MenuService.js";
import { getAppliedVoucherByInvoice } from "../service/VoucherService.js";
import { saveNotification } from "../service/OrderActivityService";
import { PinAccess } from "../order/PinAccess.js";
import { CheckoutPayment } from "../core/components/payment/CheckoutPayment.js";

class TakeAwayOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resConfig: {},
      topbarHeight: 0,
      layoutMode: "static",
      mobileMenuActive: null,
      isRequirePinCode: false,
      isValidPinCode: true,
      table: {
        name: null
      },
      tableSize: 0,
      menuData: null,
      order: {
        items: []
      },
      groupOrderItems: [],
      orderSummary: {},
      language: 'en-US',
      frmCartVisible: false,
      frmOrderItemVisible: false,
      cFlagImage: '',
      isPayment: false,
      activePayment: {},
      refToken: '',
      cusName: '',
      cusPhone: '',
      defaultCountry: 'us',
      errors: {},
      snapResult: {},
      couponCode: null,
      invoice: null,
      voucherCode: null,
      isVoucherConfirm: false,
      appliedVoucher: null,
      cusEmail: '',
      onlinePaymentGateway: {},
      onsiteMethodOptions: [],
      gatewayOnsiteMethods: [],
      generalOnsiteMethods: [],
      ableMakePayment: false,
      paymentOption: '',
      paymentMethod: '',
      hasMultiPaymentOptions: false
    };

    this.notificationCount = 0;

    this._history = createBrowserHistory();
    this.unlisten = this._history.listen((location, action) => { });
  }

  async componentDidMount() {
    const resConfig = await loadPublicConfiguration();

    this.getTableDetails();
    this.loadPaymentConfig(resConfig);

    // this.getTableDetails((tCode) => {
    //   socketService.initConnection(tCode);
    // });

    const { i18n } = this.props;
    let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

    i18n.changeLanguage(locale);
    this.setState({
      chanel: CHANNEL_TYPE.restaurant_2,
      resConfig: resConfig,
      language: locale,
      defaultCountry: localStorage.getItem("countryCode"),
      cusPhoneCode: localStorage.getItem("countryCode")
    });
  }

  loadPaymentConfig = async (config) => {
    let paymentGateWay = null
    let gatewayOnsiteMethods = []
    let generalOnsiteMethods = config.useOnSitePayment ? config.onSiteMethods : []

    if(config && config.useOnlinePayment && config.resCardPaymentGateway){
        paymentGateWay = await getOnlinePaymentGateway(config.resCardPaymentGateway)

        if(paymentGateWay){
          if(paymentGateWay.gateway===PAYMENT_GATEWAY.HITPAY){
            gatewayOnsiteMethods = paymentGateWay.settings.onsiteMethods
          }
        }
    }
    
    let onsiteMethodOptions = generalOnsiteMethods.concat(gatewayOnsiteMethods)

    let ableMakePayment = true
    let hasMultiPaymentOptions = false

    let paymentOption = PAYMENT_OPTION.Online
    let paymentMethod = PAYMENT_METHOD.online

    if(!paymentGateWay){
      paymentOption = PAYMENT_OPTION.Onsite

      if(onsiteMethodOptions.length > 0){
          paymentMethod = onsiteMethodOptions[0]
      }else{
        ableMakePayment = false
      }
    }

    if(paymentGateWay && (onsiteMethodOptions.length>0))
        hasMultiPaymentOptions = true

    this.setState({
      onlinePaymentGateway: paymentGateWay,
      onsiteMethodOptions: onsiteMethodOptions,
      gatewayOnsiteMethods: gatewayOnsiteMethods,
      generalOnsiteMethods: generalOnsiteMethods,
      ableMakePayment: ableMakePayment,
      paymentOption: paymentOption,
      paymentMethod: paymentMethod,
      hasMultiPaymentOptions: hasMultiPaymentOptions
    })
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onFocus = () => {
    if(!socketService.connected()) {
      window.location.reload();
    }
  }

  hadleVisibilityChagne = isVisible => {
    if (isVisible){
      if(!socketService.connected()) {
        window.location.reload();
      }
    }
  }

  getTableDetails = (pinCode) => {
    this.getOrder(pinCode);
  };

  getActivePayment = () => {
    getOnlinePaymentGateway()
      .then(res => {
        this.setState({ activePayment: res });
      });
  }

  getOrder = (pinCode) => {
    getTakeAwayOrderTable(getCustomerId(), pinCode)
    .then((res) => {
      if (res) {
        if(res.resTable){
          this.setState({isRequirePinCode: false});
          const table = res.resTable;
          const order = table.draftOrder;
          if(!this.props.match.params.orderNumber){
            let href = localStorage.getItem('resApplicationUrl')+'delivery/take-away/'+res.resTable.orderNumber;
              window.location.replace(href);
          }
          if(!this.state.menuData){
            showloading()
            getMenuData(table.id, true).then(menuData => {
              this.setState({menuData: menuData});
              
              this.menuOrdering.popularMenuData(menuData);

              this.setState({
                table: table,
                order: order,
                groupOrderItems: order.items.length>0?getGroupOrderItems(order.items, menuData.categories, true):[],
                orderSummary: getTableOrderSummary([order]),
                cusName: table.customer && table.customer.customerName?table.customer.customerName:'',
                cusPhone: table.customer && table.customer.customerPhone?table.customer.customerPhone:''
              });
            })
          }else{
            this.setState({
              table: table,
              order: order,
              groupOrderItems: order.items.length>0?getGroupOrderItems(order.items, this.state.menuData.categories, true):[],
              orderSummary: getTableOrderSummary([order]),
              cusName: table.customer && table.customer.customerName?table.customer.customerName:'',
              cusPhone: table.customer && table.customer.customerPhone?table.customer.customerPhone:''
            });
          }

          if(table.customer && table.customer.customerId && table.customer.customerId !== getCustomerId()){
            localStorage.setItem("customerId", table.customer.customerId);
            // localStorage.setItem("customerColor", res.customer.customerColor);
          }
        }else{
          this.setState({
            isRequirePinCode: res.isRequirePinCode,
            isValidPinCode: res.isValidPinCode!==undefined?res.isValidPinCode:true
          });
        }

        stoploading();
      } else {
        this.props.history.push(`/404`);
      }
    });
  }

  updateDraftOrder = (data) => {
    this.setState({
      order: data,
      groupOrderItems: data.items.length>0?getGroupOrderItems(data.items, this.state.menuData.categories, true):[],
      orderSummary: getTableOrderSummary([data])
    }, () => {
      if(data.items.length===0){
        this.setState({frmCartVisible: false});
      }
    });
  }

  changeLanguage = (e) => {
    this.setState({ language: e });
    const { i18n } = this.props;
    i18n.changeLanguage(e);
    localStorage.setItem('selectedLocale', e);
  };

  onRemoveCartItems = (items) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    let removeData = items.map(i => (i.id));

    showloading();
    removeOrderItems(removeData, true, localStorage.getItem("customerId"), this.state.order.id)
    .then(res => {
      if (!res.errorCode) {
        this.updateDraftOrder(res.order);
        notify.showSuccessNotify(trans('res.menu.updated_food_order'));
      }else{
        notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
      }
    })
    .finally(() => {
      stoploading();
    });
  }

  getTotalCartItemCount = () => {
    let qty = 0;

    this.state.order.items.map((item) => {
      qty += item.quantity;
    });

    return qty;
  };

  askForConfirmAction = (type) => {
    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

    let content = '';

    switch (type){
      case TABLE_REQUEST_STATUS.confirm_request:
        content = trans('res.menu.place_order_confirmation_content');
        break;
      default:
        break;
    }

    this.confirmDialog.confirm(type, content);
  }

  validationCustomerInput = () => {
    let errors = {};
    if(!this.state.cusName)
      errors.cusName = {code: 2030};
    if(!this.state.cusPhone)
      errors.cusPhone = {code: 2030};
    
    return errors;
  }

  onCloseCartDialog = () => {
    this.setState({ frmCartVisible: false });
  };

  isBlockOrdering = () => {
    return false;
  }

  onPaymentOptionChange = (e) => {
    const paymentOption = e.value;
    let paymentMethod = '';
    const onSiteMethodOptions = this.state.onsiteMethodOptions;

    if(paymentOption===PAYMENT_OPTION.Online){
      paymentMethod = PAYMENT_METHOD.online;
    }else{
      paymentMethod = onSiteMethodOptions && onSiteMethodOptions.length>0 ? onSiteMethodOptions[0] : '';
    }

    this.setState({
      paymentOption: paymentOption,
      paymentMethod: paymentMethod,
      tipAmount: ''
    });
  }

  onCartClick = () => {
    this.setState({ frmCartVisible: true });
  }

  renderOrderItemView = (orderData, isDraftOrder, trans, defaultLocale, selectedLocale) => {
    return (
      <React.Fragment>
      {/* {isDraftOrder?
      <h3 className="p-margin-bottom-5">{trans('res.menu.current_order')}</h3>
      :
      <h3 className="p-margin-bottom-5 p-margin-top-30">{trans('res.menu.order_history')}</h3>
      } */}

      <div className="p-hr p-margin-bottom-10" />

      <div className="p-grid">
        <div className={isDraftOrder?"p-col-2":"p-col-1"}>{!isDraftOrder&&'#'}</div>
        <div className={(isDraftOrder?"p-col-5":"p-col-6") + " p-w-bold"}>{trans('res.mgt.food')}</div>
        <div className="p-col-1 p-w-bold p-r">{trans('res.mgt.qty')}</div>
        <div className="p-col-4 p-w-bold p-r">{trans('res.mgt.total')}</div>
      </div>

      <div className="p-hr p-padding-top-0 p-margin-bottom-10" />

      {isDraftOrder && orderData.length===0 &&
      <div className="p-grid">
        <div className="p-col-12">{trans('res.menu.no_food_selected')}</div>
      </div>
      }

      {(()=>{
        let num = 0;

        return (
          <React.Fragment>
            {orderData.map((v, i) => {
              return (
                <React.Fragment key={"cate_"+i}>
                  <div className="order-item-view">
                    {(v.products.length>0 || (!isDraftOrder && v.adjustItems && v.adjustItems.length>0)) &&
                      <h3 className="cate-title">{v.cateName?v.cateNames[selectedLocale]?v.cateNames[selectedLocale]:v.cateNames[defaultLocale]?v.cateNames[defaultLocale]:v.cateName:trans('res.menu.cate_other')}</h3>
                    }

                    {v.products.length>0 && v.products.map((p, pIdx) => {
                      num += 1;

                      return (
                        <React.Fragment key={"prod_"+pIdx}>
                          {(()=>{
                            if(p.items.length===1){
                              const item = p.items[0];

                              return (
                                <div className="p-grid p-vertical-c">
                                  {isDraftOrder?
                                  <div className="p-col-2 p-nowrap">
                                    <Button icon="pi-md-edit" className="p-button-info btn-xxs" onClick={() => {this.frmOrderItems.openForm(item.orderItems); this.setState({frmOrderItemVisible: true})}}/>
                                    <Button icon="pi-md-close" className="p-button-danger btn-xxs" onClick={() => this.onRemoveCartItems(item.orderItems)}/>
                                  </div>
                                  :
                                  <div className="p-col-1">#{num}</div>
                                  }
                                  
                                  <div className={isDraftOrder?"p-col-5":"p-col-6"}>
                                    <div>{Object.keys(p.names).length>0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}</div>
                                    {item.itemOptionValueTrans.length>0 && 
                                    <div className="p-size-11 p-margin-top-5">
                                        - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale]?opt.names[selectedLocale]:opt.names[defaultLocale])).join(', ')}
                                    </div>
                                    }
                                    {item.note && <div className="p-size-11 p-margin-top-5">- {item.note}</div>}
                                  </div>
                                  <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                  <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                </div>
                              )
                            }else{
                              return (
                                <React.Fragment>
                                  <div className="p-grid">
                                    <div className={isDraftOrder?"p-col-2":"p-col-1"}>{!isDraftOrder&&"#"+num}</div>
                                    <div className={(isDraftOrder?"p-col-10":"p-col-11") + " p-margin-bottom-5"}>
                                      {Object.keys(p.names).length>0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}
                                    </div>
                                  </div>

                                  {p.items.map((item, idx) => {
                                    return (
                                      <div key={i+"_"+idx+"_"+item.id} className="p-grid p-vertical-c">
                                        {isDraftOrder?
                                        <div className="p-col-2 p-nowrap">
                                          <Button icon="pi-md-edit" className="p-button-info btn-xxs" onClick={() => {this.frmOrderItems.openForm(item.orderItems); this.setState({frmOrderItemVisible: true})}}/>
                                          <Button icon="pi-md-close" className="p-button-danger btn-xxs" onClick={() => this.onRemoveCartItems(item.orderItems)}/>
                                        </div>
                                        :
                                        <div className="p-col-1">#{num}</div>
                                        }
                                        <div className={isDraftOrder?"p-col-5":"p-col-6"} style={{borderBottom: idx<p.items.length-1?'1px solid #d8d8d8':''}}>
                                          {item.itemOptionValueTrans.length===0 && !item.note?
                                              <div className="p-size-11">{trans('res.mgt.food_select_standard')}</div>
                                          :
                                          <>
                                              {item.itemOptionValueTrans.length>0 && 
                                              <div className="p-size-11 p-margin-top-5">
                                                  - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale]?opt.names[selectedLocale]:opt.names[defaultLocale])).join(', ')}
                                              </div>
                                              }
                                              {item.note && <div className={"p-size-11 " + (item.productVariantName || item.productVariantNames?"p-margin-top-5":"")}>- {item.note}</div>}
                                          </>
                                          }
                                        </div>
                                        <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                      </div>
                                    )
                                  })}
                                </React.Fragment>
                              )
                            }
                          })()}

                          {p.adjustments.length>0 && p.adjustments.map(adjust => {
                            return (
                              <div className="p-grid p-vertical-c p-size-12" style={{marginTop: '-1em'}}>
                                <div className="p-col-2"></div>
                                <div className="p-col-5 p-fs-italic">
                                  {adjust.trans?adjust.trans.nameDisplay[selectedLocale]?adjust.trans.nameDisplay[selectedLocale]:adjust.trans.nameDisplay[defaultLocale]:adjust.rule.nameDisplay} {adjust.coupon?' ('+adjust.coupon.code+')':''}
                                </div>
                                <div className="p-col-2 p-r"></div>
                                <div className="p-col-3 p-r">
                                  {moneyFormat(adjust.amount)}
                                </div>
                              </div>
                            )
                          })}
                        </React.Fragment>
                      )
                    })}

                    {!isDraftOrder && v.adjustItems && v.adjustItems.length>0 && v.adjustItems.map(item => {
                      num += 1;

                      return (
                        <div className="p-grid p-vertical-c">
                          <div className="p-col-1">#{num}</div>
                          <div className="p-col-6">
                            <div>{item.productName}</div>
                          </div>
                          <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                          <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                        </div>
                      )
                    })}
                  </div>
                </React.Fragment>
              )
            })}
            <div className="p-hr p-padding-top-0 p-margin-bottom-10 "></div>
          </React.Fragment>
        )
      })()}
      </React.Fragment>
    )
  }

  applyPromotionCoupon = (trans) => {
    showloading();
    applyPromotionCoupon(this.state.order.id, this.state.order.orderNumber, this.state.couponCode)
    .then(res => {
      if (!res.errorCode) {
        this.updateDraftOrder(res);
        notify.showSuccessNotify(trans('res.menu.coupon_applied'));
      }else{
        if (res.errorCode === 400)
          this.setState({ errors: res.errorObj })
        notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
      }
    })
    .finally(() => {
      stoploading();
    });
  }

  cancelPromotionCoupon = (trans, couponCode) => {
    showloading();
    cancelPromotionCoupon(this.state.order.id, this.state.order.orderNumber, couponCode)
    .then(res => {
      if (!res.errorCode) {
        this.updateDraftOrder(res);
        this.setState({couponCode:''});
        notify.showSuccessNotify(trans('res.menu.coupon_canceled'));
      }else{
        notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
      }
    })
    .finally(() => {
      stoploading();
    });
  }

  onConfirmAndPay = (trans) => {
    processInvoiceForOrder(this.state.table.orderNumber, getCustomerId())
    .then(res => {
      if(!res.errorCode){
        this.setState({
          isPayment: true,
          invoice: res
        }, () => {
          this.getAppliedVoucher(res.id);
        });
      }else{
        notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
      }
    });
  }

  applyVoucher = (trans) => {
    applyVoucherToOrder(this.state.voucherCode)
    .then(res => {
      if (!res.errorCode) {
        this.getTableDetails();
        this.setState({
          appliedVoucher: res,
          isVoucherConfirm: true
        });
        notify.showSuccessNotify(trans('res.menu.voucher_verified'));
      }else{
        if(res.errorCode===409)
          notify.showErrorNotify(trans('res.menu.voucher_used'));
        else
          notify.showErrorNotify(trans('res.menu.errors.invalid_voucher'));
      }
    });
  }

  confirmVoucher = (trans) => {
    confirmVoucherToOrder(this.state.voucherCode, this.state.invoice.id, this.state.order.orderNumber)
    .then(res => {
      if (!res.errorCode) {
        const total = this.getFinalAmount(this.state.invoice, res)

        this.setState({
          isVoucherConfirm: false,
          voucherCode: null,
          appliedVoucher: res
        });

        if(total===0){
          this.setState({
            paymentOption: PAYMENT_OPTION.Onsite,
            paymentMethod: PAYMENT_METHOD.cash,
          });
        }
        
        notify.showSuccessNotify(trans('res.menu.voucher_applied'));
      }else{
        notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
      }
    });
  }

  getAppliedVoucher = (invoiceId) => {
    getAppliedVoucherByInvoice(invoiceId)
    .then(res => {
      if(res) {
        this.setState({
          appliedVoucher: res
        });
      }
    })
  }

  getFinalAmount = (invoice, voucher) => {
    const tipAmount = this.state.tipAmount?parseFloat(this.state.tipAmount):0
    const voucherAmount = voucher ? voucher.value : 0
    
    const amount = invoice?((invoice.total-invoice.tip) - voucherAmount > 0 ? (invoice.total-invoice.tip) - voucherAmount : 0) + tipAmount:0

    return amount
  }

  countTotalQty = (order) => {
    let qty = 0
    order.items&&order.items.length>0&&order.items.map((item) => {
      qty += item.quantity
    })
    return qty
  }

  cancelPayment = () => {
    this.setState({isPayment: false})
  }

  render() {
    let layoutPadding = (this.state.topbarHeight + 'px 15px 0px 15px');

    const { t } = this.props;
    const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
    let wrapperClass = classNames("layout-wrapper", {
      "layout-wrapper-static": this.state.layoutMode === "static",
      "layout-wrapper-active": this.state.mobileMenuActive,
      "layout-menu-horizontal": this.state.layoutMode === "horizontal",
    });

    const currency = localStorage.getItem('currencySymbol');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const defaultLocale = localStorage.getItem('defaultLocale');

    const resConfig =  localStorage.getItem('resConfig')?JSON.parse(localStorage.getItem('resConfig')):{};

    const blockOrdering = this.isBlockOrdering();

    return (
      <PageVisibility onChange={this.hadleVisibilityChagne}>
        <div className={wrapperClass}>
          <ConfirmNotification ref={(el) => this.confirmDialog = el}
              header={trans('res.menu.confirmation')}
              submitText={trans('res.menu.submit_confirm')}
              cancelText={trans('res.menu.cancel')}
              submitConfirm={(e) => e===TABLE_REQUEST_STATUS.confirm_request?this.setState({isPayment: true}):''}
          />

          <OrderItemForm ref={(el) => this.frmOrderItems = el} 
            visible={this.state.frmOrderItemVisible} currency={currency} isCustomer={true} 
            trans={trans} useFor={USE_FOR_PURPOSE.res_take_out} 
            updateCartOrder={(data, isAdd, isUpdate, isRemove) => this.updateDraftOrder(data, isAdd, isUpdate, isRemove)}
            onHide={() => this.setState({frmOrderItemVisible: false})}/>

          <Sidebar visible={this.state.frmCartVisible} position="right" style={{ overflowY: "auto", paddingLeft: '7.5px', paddingRight: '7.5px' }} className="p-sidebar-lg customer"
            blockScroll={true} baseZIndex={1000000} onHide={(e) => this.onCloseCartDialog()}
          >
            <h2>{trans('res.menu.take_away_order')}</h2>

            {this.state.order && this.state.order.items.length > 0 &&
              <React.Fragment>
                <div className="p-grid">
                  <div className="p-col-12">
                    {!this.state.isPayment ?
                    <Button label={trans('res.menu.confirm_and_pay')} icon="pi pi-check" disabled={blockOrdering} className="p-margin-right-15 btn-make-payment full-width" onClick={() => this.onConfirmAndPay(trans)} />
                    :
                    <React.Fragment>
                      {this.state.order && this.state.order.items.length > 0 &&
                        <div className="p-margin-bottom-20 box-total">
                          <div className="p-grid p-margin-none">
                            <div className="p-col-6 p-w-bold p-size-16">{trans('res.menu.order_total')}:</div>
                            <div className="p-col-6 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                          </div>
                        </div>
                      }

                      {resConfig.allowUsingVoucher && this.state.invoice && 
                        <Fieldset legend={trans('res.menu.voucher_section')} className="p-fieldset-sm" style={{paddingLeft: '7.5px', paddingRight: '7.5px'}}>
                          <div className="p-grid">
                              {this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ?
                                <React.Fragment>
                                  <div className="p-col-12 p-lg-6 p-c">
                                    <Message severity="info" text={trans('res.menu.voucher_applied')} />
                                  </div>
                                  <div className="p-col-12 p-lg-6 p-c">
                                      <div className="p-margin-bottom-5">{trans('res.menu.voucher_value')}: <strong>{moneyFormat(this.state.appliedVoucher.value)}</strong></div>
                                      <div>{trans('res.menu.remain_total')}: <strong>{this.state.invoice && moneyFormat(this.getFinalAmount(this.state.invoice, this.state.appliedVoucher))}</strong></div>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <div className="p-col-12 p-xl-6 p-lg-8">
                                    <div className="p-inputgroup p-fluid">
                                        <InputText value={this.state.voucherCode} onChange={(e) => this.setState({voucherCode: e.target.value})} style={{marginTop: '-20px'}}/>
                                        <span className="p-inputgroup-addon">
                                            {this.state.isVoucherConfirm ? 
                                              <React.Fragment>
                                                <Button className="btn-apply-voucher" icon="pi pi-check" label={trans('res.menu.voucher_confirm')} onClick={() => this.confirmVoucher(trans)} style={{width: 'auto'}} />
                                                <Button className="btn-cancel-voucher" icon="pi pi-times" label={trans('res.menu.cancel')} onClick={() => this.setState({appliedVoucher: null, voucherCode: '', isVoucherConfirm: false})} style={{width: 'auto'}} />
                                              </React.Fragment>
                                              :
                                              <Button className="btn-apply-voucher" disabled={this.state.voucherCode?false:true} label={trans('res.menu.apply_coupon')} onClick={() => this.applyVoucher(trans)} style={{width: 'auto'}} />
                                            }
                                        </span>
                                    </div>
                                  </div>
                                  {this.state.appliedVoucher &&
                                    <div className="p-col-12 p-xl-6 p-lg-4">
                                      <div>{trans('res.menu.voucher_value')}: <strong>{moneyFormat(this.state.appliedVoucher.value)}</strong></div>
                                      <div>{trans('res.menu.remain_total')}: <strong>{this.state.invoice && moneyFormat(this.getFinalAmount(this.state.invoice, this.state.appliedVoucher))}</strong>
                                      </div>
                                    </div>
                                  }
                                </React.Fragment>
                              }
                          </div>
                        </Fieldset>
                      }

                      {resConfig.allowUsingCoupon && 
                      <Fieldset legend={trans('res.menu.coupon_section')} className="p-fieldset-sm" style={{paddingLeft: '7.5px', paddingRight: '7.5px'}}>
                        <div className="p-grid p-fluid">
                          {this.state.orderSummary.couponDiscount?
                            <div className="p-col-12">
                              <div>
                                {trans('res.menu.coupon_applied')}: <strong>{this.state.orderSummary.couponDiscount.coupon.code}</strong> - {this.state.orderSummary.couponDiscount.trans?this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale]?this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale]:this.state.orderSummary.couponDiscount.trans.nameDisplay[defaultLocale]:this.state.orderSummary.couponDiscount.rule.nameDisplay}
                                <Button className="p-display-inline-block btn-sm p-margin-left-20 p-button-danger" label={trans('res.menu.cancel_coupon')} onClick={(e) => this.cancelPromotionCoupon(trans, this.state.orderSummary.couponDiscount.coupon.code)} style={{width: 'auto'}} />
                              </div>
                            </div>
                            :
                            <div className="p-col-12 p-md-6">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">{trans('res.menu.enter_coupon')}: </span>
                                <InputText value={this.state.couponCode} onChange={(e) => this.setState({couponCode: e.target.value})} />
                                <span className="p-inputgroup-addon">
                                  <Button className="btn-sm" disabled={this.state.couponCode ? false : true} label={trans('res.menu.apply_coupon')} onClick={(e) => this.applyPromotionCoupon(trans)} style={{width: 'auto'}} />
                                </span>
                              </div>
                              <div className="p-form-error">{this.state.errors.coupon && parseMessage(trans, this.state.errors.coupon.code)}</div>
                            </div>
                            }
                        </div>
                      </Fieldset>
                      }

                      <Fieldset legend={trans('res.menu.payment')} style={{paddingLeft: '7.5px', paddingRight: '7.5px'}}>
                        <div className="p-grid">
                          {(()=>{
                            if(!resConfig.useOnSitePayment && !resConfig.useOnlinePayment){
                              return (
                                <div className="p-col-12 p-c">
                                  <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                </div>
                              );
                            }else{
                              return (
                                <React.Fragment>
                                  {this.getFinalAmount(this.state.invoice, this.state.appliedVoucher) > 0 && this.state.hasMultiPaymentOptions &&
                                    <div className="p-col-12 ">
                                      <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_option')}:</label>
                                      <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                          <RadioButton inputId="rbPayOption1" name="paymentOption" value={PAYMENT_OPTION.Online} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Online} />
                                          <label htmlFor="rbPayOption1" className="p-radiobutton-label">{trans('res.menu.online_payment')}</label>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                          <RadioButton inputId="rbPayOption0" name="paymentOption" value={PAYMENT_OPTION.Onsite} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Onsite} />
                                          <label htmlFor="rbPayOption0" className="p-radiobutton-label">{trans('res.menu.service_counter')}</label>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  {(()=>{
                                    if(!this.state.ableMakePayment){
                                      return (
                                        <div className="p-col-12 p-c">
                                          <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                        </div>
                                      );
                                    }else{
                                      return (
                                        <React.Fragment>
                                          {(()=>{
                                            if(this.getFinalAmount(this.state.invoice, this.state.appliedVoucher) > 0 && this.state.paymentOption===PAYMENT_OPTION.Onsite){
                                              if(this.state.onsiteMethodOptions.length > 1){
                                                return (
                                                  <div className="p-col-12">
                                                    <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_method')}:</label>
                                                    <div className="p-grid">
                                                      {this.state.generalOnsiteMethods.length>0 && this.state.generalOnsiteMethods.map((method, mIdx) => {
                                                        return (
                                                          <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                              <RadioButton inputId={"rbPayMethod"+mIdx} name="paymentMethod" value={method} onChange={(e) => this.setState({ paymentMethod: e.value, tipAmount: '' })} checked={this.state.paymentMethod === method} />
                                                              <label htmlFor={"rbPayMethod"+mIdx} className="p-radiobutton-label p-margin-right-20">{trans(PAYMENT_METHODS_OBJECT[method].label)}</label>
                                                            </div>
                                                          </React.Fragment>
                                                        )
                                                      })}
                                                      
                                                      {this.state.gatewayOnsiteMethods.length>0 && 
                                                      <React.Fragment>
                                                        <div className="p-col-12 p-padding-bottom-0">
                                                          <label className="p-label">{trans('res.menu.methods_via_gateway_with_staff_assisted', {gateway: this.state.onlinePaymentGateway.gateway})}:</label>
                                                        </div>

                                                        {this.state.gatewayOnsiteMethods.map((method, mIdx) => {
                                                          return (
                                                            <React.Fragment>
                                                              <div className="p-col-12 p-md-6">
                                                                <RadioButton inputId={"rbPayMethod"+mIdx} name="paymentMethod" value={method} onChange={(e) => this.setState({ paymentMethod: e.value, tipAmount: '' })} checked={this.state.paymentMethod === method} />
                                                                <label htmlFor={"rbPayMethod"+mIdx} className="p-radiobutton-label p-margin-right-20">{trans(PAYMENT_METHODS_OBJECT[method].label)}</label>
                                                              </div>
                                                            </React.Fragment>
                                                          )
                                                        })}
                                                      </React.Fragment>
                                                      }
                                                    </div>
                                                  </div>
                                                )
                                              }else{
                                                return (
                                                  <div className="p-col-12">
                                                    <label>{trans('res.menu.payment_method')}: <strong>{trans(PAYMENT_METHODS_OBJECT[this.state.paymentMethod].label)}</strong></label>
                                                  </div>
                                                )
                                              }
                                            }
                                          })()}

                                          {/* {((this.state.paymentOption===PAYMENT_OPTION.Onsite && resConfig.useTipInOnSitePayment) || (this.state.paymentOption===PAYMENT_OPTION.Online && resConfig.useTipInOnlinePayment)) &&
                                            <div className="p-col-12">
                                              <Fieldset legend={trans('res.menu.tip')} style={{paddingLeft: '7.5px', paddingRight: '7.5px', width: '100%'}}>
                                                <div className="p-grid p-fluid">
                                                  <div className="p-col-7"><label>{trans('res.menu.tip_amount')}:</label></div>
                                                  <div className="p-col-5 p-r">
                                                    <InputText value={this.state.tipAmount} className="p-r" 
                                                      keyfilter="money" maxLength="12"
                                                      onFocus={(e) => this.setState({ tipAmount: e.target.value=='0'?'':e.target.value})}
                                                      onBlur={(e) => this.setState({ tipAmount: e.target.value?toFixed(parseFloat(e.target.value), getDecimalPlaces()):'0'})}
                                                      onChange={(e) => this.setState({ tipAmount: e.target.value })} style={{width: '100%'}} 
                                                      placeholder={trans('res.menu.input_tip_here')}/>
                                                  </div>
                                                  {resConfig.showTipSuggestion && resConfig.suggestedTipRates.length>0 &&
                                                  <div className="p-col-12">
                                                    <div><label>{trans('res.menu.suggested_tip')}</label></div>
                                                    {resConfig.suggestedTipRates.map(tip => {
                                                      return (<div className="p-margin-top-5"><label>{trans('res.menu.tip')} {tip}% :</label><label className="p-r p-margin-left-20">{moneyFormat(((this.state.orderSummary.orderTotal*parseFloat(tip))/100))}</label></div>);
                                                    })}
                                                  </div>
                                                  }
                                                </div>
                                              </Fieldset>
                                            </div>
                                          } */}

                                          <div className="p-col-12">
                                            <CheckoutPayment trans={trans} 
                                              tableType={this.state.table.type}
                                              invoice={this.state.invoice}
                                              voucher={this.state.appliedVoucher}
                                              orderNumber={this.state.table.orderNumber} 
                                              gateway={this.state.paymentOption===PAYMENT_OPTION.Onsite?PAYMENT_GATEWAY.ONSITE:this.state.onlinePaymentGateway?this.state.onlinePaymentGateway.gateway:null}
                                              paymentGateway={this.state.onlinePaymentGateway}
                                              method={this.state.paymentMethod}
                                              tipAmount={this.state.tipAmount}
                                              onCancel={() => this.cancelPayment()}
                                              />
                                          </div>
                                        </React.Fragment>
                                      )
                                    }
                                  })()}
                                </React.Fragment>
                              )
                            }
                          })()}
                        </div>
                      </Fieldset>
                    </React.Fragment>
                    }
                  </div>
                </div>

                {this.renderOrderItemView(this.state.groupOrderItems, true, trans, defaultLocale, selectedLocale)}

                {this.state.order && this.state.order.items.length > 0 &&
                <React.Fragment>
                  <div className="p-grid p-margin-bottom-10">
                    <div className="p-col-7 p-w-bold p-size-14">{trans('res.menu.item_total')}</div>
                    <div className="p-col-1 p-w-bold p-r p-size-14">{this.state.orderSummary.qtyTotal}</div>
                    <div className="p-col-4 p-w-bold p-r p-size-14">{(!isPriceIncludedTaxOrService()) ? moneyFormat(this.state.orderSummary.subTotal + this.state.orderSummary.itemDiscount): moneyFormat(this.state.orderSummary.subTotal)}</div>
                  </div>
                  
                  {isPriceIncludedTaxOrService() && (this.state.orderSummary.taxTotal > 0 || this.state.orderSummary.serviceTotal > 0) &&
                    <div className="p-grid">
                        <div className="p-col-8 p-w-bold">{trans('res.menu.total_without_tax')}</div>
                        <div className="p-col-4 p-w-bold p-r">{moneyFormat(this.state.orderSummary.subTotalNoTax + this.state.orderSummary.itemDiscount)}</div>
                    </div>
                  }
                  
                  {this.state.orderSummary.orderAdjusts.length>0 && this.state.orderSummary.orderAdjusts.map(adjust => {
                    return (
                      <div className="p-grid">
                        <div className="p-col-8">{adjust.label}</div>
                        <div className="p-col-4 p-r">{moneyFormat(adjust.amount)}</div>
                      </div>
                    )
                  })}

                  {this.state.orderSummary.orderDiscounts.length>0 && this.state.orderSummary.orderDiscounts.map(discount => {
                    return (
                      <div className="p-grid">
                        <div className="p-col-8">{discount.trans?discount.trans.nameDisplay[selectedLocale]?discount.trans.nameDisplay[selectedLocale]:discount.trans.nameDisplay[defaultLocale]:discount.rule.nameDisplay}</div>
                        <div className="p-col-4 p-r">{moneyFormat(discount.amount)}</div>
                      </div>
                    )
                  })}

                  {this.state.orderSummary.serviceTotal > 0 &&
                    <div className="p-grid">
                      <div className="p-col-8">{trans('res.menu.service')} ({this.state.orderSummary.serviceRate}%)</div>
                      <div className="p-col-4 p-r">{moneyFormat(this.state.orderSummary.serviceTotal)}</div>
                    </div>
                  }

                  {this.state.orderSummary.taxs &&
                    Object.values(this.state.orderSummary.taxs).map((item, idx) => {
                        return (
                          <div className="p-grid">
                            <div className="p-col-8">{item.name} ({item.rate}%)</div>
                            <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                            </div>    
                        )
                    })
                  }

                  {this.state.orderSummary.tipTotal > 0 &&
                    <div className="p-grid p-margin-top-5">
                      <div className="p-col-8 p-w-bold">{trans('res.menu.tip')}</div>
                      <div className="p-col-4 p-w-bold p-r">{moneyFormat(this.state.orderSummary.tipTotal)}</div>
                    </div>
                  }

                  <div className="p-hr" />

                  <div className="p-grid p-margin-top-5 p-margin-bottom-10">
                    <div className="p-col-8 p-w-bold p-size-16">{trans('res.menu.order_total')}</div>
                    <div className="p-col-4 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                  </div>
                </React.Fragment>
                }
              </React.Fragment>
            }
          </Sidebar>

          <div className="layout-main customer">
            <CustomerOrderTopbar layoutMode={this.state.layoutMode}
              config={resConfig?resConfig:{}}
              blockOrdering={blockOrdering}
              useFor={USE_FOR_PURPOSE.res_take_out}
              cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
              isOrderPage={true} setHeight={(e) => this.setState({topbarHeight: e})}
              table={this.state.table}
              goToOrderTracking={()=>this.props.history.push(`/delivery/take-away/inform`)}
              onCartClick={(e) => this.setState({ frmCartVisible: true })} cartItemCount={this.getTotalCartItemCount()}
              trans={trans}
              totalQty={this.countTotalQty(this.state.order)}
            />
            <div className="layout-content" id="layout-content-container">
              <LoadingOverlay />
              {this.state.isRequirePinCode ?
                <PinAccess trans={trans}
                  padding={layoutPadding}
                  isValid={this.state.isValidPinCode}
                  access={(e) => this.getTableDetails(e)}/>
              :
                <MenuOrdering ref={(el) => this.menuOrdering = el} cLang={this.state.language} trans={trans}
                  config={resConfig?resConfig:{}}
                  useFor={USE_FOR_PURPOSE.res_take_out}
                  blockOrdering={blockOrdering}
                  table={this.state.table} currency={currency} orderId={this.state.order.id} 
                  padding={layoutPadding}
                  updateCartOrder={(e) => this.updateDraftOrder(e)}
                  topbarHeight={this.state.topbarHeight- 30}
                />
              }
            </div>
          </div>
        </div>
      </PageVisibility>
    );
  }
}

export default withNamespaces("message")(TakeAwayOrder);
