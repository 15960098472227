import axios from 'axios';
import { TIMEORDER_BASE_URL } from '../constants';

export function getTimeOrderTable(customerId=null, pinCode){
    return axios.get(`${TIMEORDER_BASE_URL}/customer/get-table/time-order`, {params: {customerId, pinCode}}).then(res => res.data).catch(error => console.log(error));
}

export function getTimeOrderTimes(date, tableId){
    return axios.get(`${TIMEORDER_BASE_URL}/customer/get-times/time-order`, {params: {date, tableId}}).then(res => res.data).catch(error => console.log(error));
}

export function getUnitRacialOrderTimes(date, tableId, orderId){
    return axios.get(`${TIMEORDER_BASE_URL}/customer/get-unit-racial/time-order`, {params: {date, tableId, orderId}}).then(res => res.data).catch(error => console.log(error));
}

export function completeOrderPaymentForTimeOrder(orderId, orderNumber, method, gateway, token, cusId, cusName, cusPhone, invoiceId, tipAmount, pickupTime, pickupDate){
    return axios.post(`${TIMEORDER_BASE_URL}/customer/time-order/${orderId}/${orderNumber}/complete-payment`, {}, {
        params: {method, gateway, token, cusId, cusName, cusPhone, invoiceId, tipAmount, pickupTime, pickupDate}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function saveDateForTimeOrder(orderId, pickupTime, pickupDate){
    return axios.post(`${TIMEORDER_BASE_URL}/customer/time-order/${orderId}/pickup-date`, {}, {
        params: {pickupTime, pickupDate}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function getDateForTimeOrder(orderId){
    return axios.get(`${TIMEORDER_BASE_URL}/customer/time-order/${orderId}/pickup-date`, {}, {})
    .then(res => res.data).catch(error => console.log(error));
}

export function createSquareOrder(orderNumber) {
    return axios.post(`${TIMEORDER_BASE_URL}/customer/square/create-order/${orderNumber}`, {}, {
        params: {orderNumber}
    })
    .then(res => res.data).catch(error => console.log(error));
}