import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
export class QRCodeForm extends Component {

    static propTypes = {
        href: PropTypes.string
    }

    static defaultProps = {
        href: localStorage.getItem('resApplicationUrl')
    }

    constructor(props) {
        super(props);
        this.state={
            data: {},
            href: '',
            visible: false
        }
    }

    show = (data, href) => {
        this.setState({
            data: data,
            href: href?href:this.props.href,
            visible: true
        }, () => {
            let img = this.refs.img;
            const qr = document.getElementById(this.state.data.code);
            var qrCtx = qr.getContext("2d");
            img.addEventListener('load', () => {
                qrCtx.drawImage(img, 15, 15, 8, 8);
            });
        })
    }

    downloadQR(id) {
        const canvas = document.getElementById(id);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `table-${id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    onHide = () =>{
        this.setState({visible: false})
    }


    render(){
        const trans = this.props.trans;
        const qrLink = "<strong>qr.click</strong>";
        
        const footer = (
            <div>
                <Button label={trans('res.mgt.close')}icon="pi-md-close" className="p-button-secondary" onClick={this.onHide} />
            </div>
        );
        return (
            <Dialog footer={footer} visible={this.state.visible} baseZIndex={1000000} contentStyle={styles.dialogContent} responsive={true} modal={true} dismissableMask={true} onHide={this.onHide}
                header={this.state.data && this.state.data.name ? this.state.data.name : ''}
            >
                <div className="p-grid">
                    <div className="p-col-12 p-c">
                        {!this.props.hideCode&&
                        <div dangerouslySetInnerHTML={{__html:trans('res.mgt.qr_description', {link: qrLink})}}></div>
                        }
                        <QRCode size="256" className="qr-box" id={this.state.data.code} imageSettings={{width: 95}} value={this.state.href} includeMargin={true} level="M"/>
                        <img ref="img" src="/assets/fromlabs/QR-Logo.png" width="200" height="200" alt="" style={{display : "none"}} />
                        {(!this.props.hideUrl || !this.props.hideDownload) &&
                        <div className="p-c">
                            {!this.props.hideUrl&&
                            <p>
                                <a href={this.state.href} target="_blank" rel="noopener noreferrer">{this.state.href}</a>
                            </p>
                            }
                            {!this.props.hideDownload &&
                            <Button label="Download QR" onClick={() => this.downloadQR(this.state.data.code)}/>
                            }
                        </div>
                        }
                    </div>
                </div>
            </Dialog>            
        );
    }
}

const styles = {
    dialogContent: {
        minHeight: '50px',
        overflow: 'hidden',
        maxWidth: '400px'
    }
}