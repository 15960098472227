export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const USER_ID = process.env.REACT_APP_USER_ID;
export const USERNAME = process.env.REACT_APP_USERNAME;

let loginURL = '';
if (process.env.REACT_APP_LOGIN_BASE_URL) {
  loginURL = process.env.REACT_APP_LOGIN_BASE_URL;
} else {
  loginURL = process.env.REACT_APP_API_BASE_URL;
};
export const LOGIN_BASE_URL = loginURL;

let menuUrl = '';
if (process.env.REACT_APP_MENU_BASE_URL) {
  menuUrl = process.env.REACT_APP_MENU_BASE_URL;
} else {
  menuUrl = process.env.REACT_APP_API_BASE_URL;
};

let pimUrl = '';
if (process.env.REACT_APP_API_PIM_URL) {
  pimUrl = process.env.REACT_APP_API_PIM_URL;
} else {
  pimUrl = process.env.REACT_APP_API_BASE_URL;
};

let scpUrl = '';
if (process.env.REACT_APP_API_SCP_URL) {
  scpUrl = process.env.REACT_APP_API_SCP_URL;
} else {
  scpUrl = process.env.REACT_APP_API_BASE_URL;
};

let tableUrl = '';
if (process.env.REACT_APP_TABLE_BASE_URL) {
  tableUrl = process.env.REACT_APP_TABLE_BASE_URL;
} else {
  tableUrl = process.env.REACT_APP_API_BASE_URL;
};

let orderUrl = '';
if (process.env.REACT_APP_ORDER_BASE_URL) {
  orderUrl = process.env.REACT_APP_ORDER_BASE_URL;
} else {
  orderUrl = process.env.REACT_APP_API_BASE_URL;
};

let grabUrl = '';
if (process.env.REACT_APP_GRAB_BASE_URL) {
  grabUrl = process.env.REACT_APP_GRAB_BASE_URL;
} else {
  grabUrl = process.env.REACT_APP_API_BASE_URL;
};

let googleUrl = '';
if (process.env.REACT_APP_GOOGLE_PREDICT_LOCATION_BASE_URL) {
  googleUrl = process.env.REACT_APP_GOOGLE_PREDICT_LOCATION_BASE_URL;
} else {
  googleUrl = process.env.REACT_APP_API_BASE_URL;
};

let timeOrderUrl = '';
if (process.env.REACT_APP_TIMEORDER_BASE_URL) {
  timeOrderUrl = process.env.REACT_APP_TIMEORDER_BASE_URL;
} else {
  timeOrderUrl = process.env.REACT_APP_API_BASE_URL;
};


let taxUrl = '';
if (process.env.REACT_APP_TAX_BASE_URL) {
  taxUrl = process.env.REACT_APP_TAX_BASE_URL;
} else {
  taxUrl = process.env.REACT_APP_API_BASE_URL;
};

let tagUrl = '';
if (process.env.REACT_APP_TAG_BASE_URL) {
  tagUrl = process.env.REACT_APP_TAG_BASE_URL;
} else {
  tagUrl = process.env.REACT_APP_API_BASE_URL;
};

let uploadUrl = '';
if (process.env.REACT_APP_UPLOAD_BASE_URL) {
  uploadUrl = process.env.REACT_APP_UPLOAD_BASE_URL;
} else {
  uploadUrl = process.env.REACT_APP_API_BASE_URL;
};

let userUrl = '';
if (process.env.REACT_APP_USER_BASE_URL) {
  userUrl = process.env.REACT_APP_USER_BASE_URL;
} else {
  userUrl = process.env.REACT_APP_API_BASE_URL;
};

let paymentUrl = '';
if (process.env.REACT_APP_PAYMENT_BASE_URL) {
  paymentUrl = process.env.REACT_APP_PAYMENT_BASE_URL;
} else {
  paymentUrl = process.env.REACT_APP_API_BASE_URL;
};

let tableOrderUrl = '';
if (process.env.REACT_APP_TABLE_ORDER_BASE_URL) {
  tableOrderUrl = process.env.REACT_APP_TABLE_ORDER_BASE_URL;
} else {
  tableOrderUrl = process.env.REACT_APP_API_BASE_URL;
};

let reportUrl = '';
if (process.env.REACT_APP_REPORT_BASE_URL) {
  reportUrl = process.env.REACT_APP_REPORT_BASE_URL;
} else {
  reportUrl = process.env.REACT_APP_API_BASE_URL;
};

let invoiceUrl = '';
if (process.env.REACT_APP_INVOICE_BASE_URL) {
  invoiceUrl = process.env.REACT_APP_INVOICE_BASE_URL;
} else {
  invoiceUrl = process.env.REACT_APP_API_BASE_URL;
};

let promotionUrl = '';
if (process.env.REACT_APP_PROMOTION_BASE_URL) {
  promotionUrl = process.env.REACT_APP_PROMOTION_BASE_URL;
} else {
  promotionUrl = process.env.REACT_APP_API_BASE_URL;
};

let ratingUrl = '';
if (process.env.REACT_APP_RATING_BASE_URL) {
  ratingUrl = process.env.REACT_APP_RATING_BASE_URL;
} else {
  ratingUrl = process.env.REACT_APP_API_BASE_URL;
};

let voucherUrl = '';
if (process.env.REACT_APP_VOUCHER_BASE_URL) {
  voucherUrl = process.env.REACT_APP_VOUCHER_BASE_URL;
} else {
  voucherUrl = process.env.REACT_APP_API_BASE_URL;
};

let orderActivityUrl = '';
if (process.env.REACT_APP_PATH_ORDER_ACTIVITY) {
  orderActivityUrl = process.env.REACT_APP_API_BASE_URL;
} else {
  orderActivityUrl = process.env.REACT_APP_API_BASE_URL;
}

let configUrl = '';
if(process.env.REACT_APP_CONFIGURATION_BASE_URL){
  configUrl = process.env.REACT_APP_CONFIGURATION_BASE_URL;
} else {
  configUrl = process.env.REACT_APP_API_BASE_URL;
}

export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;
export const WS_FALLBACK_BASE_URL = process.env.REACT_APP_WS_FALLBACK_BASE_URL;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
export const TENANT = process.env.REACT_APP_TENANT;

export const SCP_PATH = '/scp/api';
export const MENU_PATH = '/menu/api';
export const TABLE_PATH = '/api/table';
export const ORDER_PATH = '/api/orders';
export const TIMEORDER_PATH = '/api/delivery-orders';
export const PIM_PATH = '/pim/api';
export const TAX_PATH = '/api/taxs';
export const TAG_PATH = '/api/tag';
export const UPLOAD_PATH = '/api/upload';
export const USER_PATH = '/api/user';
export const PAYMENT_PATH = '/payment/api';
export const TABLE_ORDER_PATH = '/api/table-order';
export const INVOICE_PATH = '/api/invoice';
export const PROMOTION_PATH = '/api/promotions';
export const REPORT_PATH = '/api/reports';
export const PAYMENT_TRANSACTION_PATH = '/api/payment-transaction';
export const RATING_PATH = '/api/rating';
export const VOUCHER_PATH = '/api/voucher';
export const ORDER_ACTIVITY_PATH = '/api/order-notification';
export const GRAB_PATH = '/api/grab';
export const GOOGLE_PATH = '/api/google';

export const MENU_BASE_URL = menuUrl + MENU_PATH;
export const PIM_BASE_URL = pimUrl + PIM_PATH;
export const TABLE_BASE_URL = tableUrl + TABLE_PATH;
export const ORDER_BASE_URL = orderUrl + ORDER_PATH;
export const TIMEORDER_BASE_URL = timeOrderUrl + TIMEORDER_PATH;
export const TAX_BASE_URL = taxUrl + TAX_PATH;
export const TAG_BASE_URL = tagUrl + TAG_PATH;
export const UPLOAD_BASE_URL = uploadUrl + UPLOAD_PATH;
export const USER_BASE_URL = userUrl + USER_PATH;
export const PAYMENT_BASE_URL = paymentUrl + PAYMENT_PATH;
export const TABLE_ORDER_BASE_URL = tableOrderUrl + TABLE_ORDER_PATH;
export const INVOICE_BASE_URL = invoiceUrl + INVOICE_PATH;
export const PROMOTION_BASE_URL = promotionUrl + PROMOTION_PATH;
export const REPORT_BASE_URL = reportUrl + REPORT_PATH;
export const RATING_BASE_URL = ratingUrl + RATING_PATH;
export const VOUCHER_BASE_URL = voucherUrl + VOUCHER_PATH;
export const ORDER_ACTIVITY_BASE_URL = orderActivityUrl + ORDER_ACTIVITY_PATH;
export const GRAB_BASE_URL = grabUrl + GRAB_PATH;
export const GOOGLE_BASE_URL = googleUrl + GOOGLE_PATH;
export const SCP_BASE_URL = scpUrl + SCP_PATH;
export const CONFIGURATION_BASE_URL = configUrl;

export const RECAPTCHA_GG_SITEKEY = process.env.REACT_APP_RECAPTCHA_GG_SITEKEY;

export const APP_FEP = 'fep'
export const APP_RES = 'res'

export const PAYMENT_GATEWAY = {
  ONSITE: "ONSITE",
  STRIPE: "STRIPE",
  SQUARE: "SQUARE",
  PAYPAL: "PAYPAL",
  MIDTRANS: "MIDTRANS",
  HITPAY: "HITPAY",
  // BRAINTREE: "BRAINTREE",
  // SLIM_CD: "SLIM_CD",
  // LUMINATE_DONATION_API: "LUMINATE_DONATION_API",
  // RED_DOT: "RED_DOT",
  // FREE: "FREE",
  // USER_CREDIT: "USER_CREDIT"
};

export const PAYMENT_OPTION = {
  Onsite: 'Onsite',
  Online: 'Online'
}

export const PAYMENT_METHOD = {
  cash: "cash",
  swipe_card: "swipe_card",
  paynow_offline: "paynow_offline",
  online: "online",
  card: "card",
  paynow_online: "paynow_online",
  alipay: "alipay",
  wechat: "wechat",
  grabpay: "grabpay"
};

export const PAYMENT_METHODS_OBJECT = {
  cash: {value: PAYMENT_METHOD.cash, label: 'res.menu.cash'},
  swipe_card: {value: PAYMENT_METHOD.swipe_card, label: 'res.menu.swipe_card'},
  paynow_offline: {value: PAYMENT_METHOD.paynow_offline, label: 'res.menu.paynow_offline'},
  paynow_online: {value: PAYMENT_METHOD.paynow_online, label: 'res.menu.paynow_online'},
  alipay: {value: PAYMENT_METHOD.alipay, label: 'res.menu.alipay'},
  wechat: {value: PAYMENT_METHOD.wechat, label: 'res.menu.wechat_pay'},
  grabpay: {value: PAYMENT_METHOD.grabpay, label: 'res.menu.grabpay'}
}

export const HITPAY_PAYMENT_METHODS = [
  PAYMENT_METHOD.card,
  PAYMENT_METHOD.paynow_online,
  PAYMENT_METHOD.alipay,
  PAYMENT_METHOD.wechat,
  PAYMENT_METHOD.grabpay
]

export const ORDER_STATE = {
  cart: 'cart',
  new_order: 'new_order',
  viewed: 'viewed',
  canceled: 'canceled'
};

export const PAYMENT_STATE = {
  pending: "pending",
  awaiting: "awaiting",
  completing: "completing",
  completed: "completed"
};

export const PAYMENT_STATES = [
  {value: PAYMENT_STATE.pending, label: 'res.mgt.pending'},
  {value: PAYMENT_STATE.awaiting, label: 'res.mgt.awaiting'},
  {value: PAYMENT_STATE.completing, label: 'res.mgt.awating_complete'},
  {value: PAYMENT_STATE.completed, label: 'res.mgt.completed'}
];

export const CHECKOUT_STATE = {
  cart: 'cart',
  waiting_participant: 'waiting_participant',
  waiting_address: 'waiting_address',
  waiting_checkout: 'waiting_checkout',
  completed: 'completed'
};

export const SHIPPING_STATE = {
  pending: 'pending',
  // waiting: 'waiting',
  preparing: 'preparing',
  ready: 'ready',
  shipping: 'shipping',
  shipped: 'shipped',
  completed: 'completed'
}

export const CHANNEL_TYPE = {
  store: "store",
  restaurant: "restaurant",
  tip: "tip",
  restaurant_2: "restaurant_2",
  restaurant_3: "restaurant_3",
  restaurant_4: "restaurant_4"
};

export const PRODUCT_TYPE = {
  dish: "dish"
};

export const ORDER_ITEM_STATUS = {
  added: 'added',
  confirming: 'confirming',
  confirmed: 'confirmed',
  pending: 'pending',
  waiting: 'waiting',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled'
}

export const ORDER_ITEM_STATUSES = [
  {value: ORDER_ITEM_STATUS.pending, label:'res.mgt.order_item_status_pending'},
  {value: ORDER_ITEM_STATUS.waiting, label:'res.mgt.order_item_status_waiting'},
  {value: ORDER_ITEM_STATUS.processing, label:'res.mgt.order_item_status_processing'},
  {value: ORDER_ITEM_STATUS.completed, label:'res.mgt.order_item_status_completed'},
  {value: ORDER_ITEM_STATUS.canceled, label:'res.mgt.order_item_status_canceled'}
]

export const TABLE_STATUS = {
  available: "available",
  occupied: "occupied"
}

export const TAXON_TYPE = {
  category: 'category',
  menu: 'menu',
  location: 'location'
};

export const TABLE_REQUEST_STATUS = {
  confirm_request: 'confirm_request',
  check_request: 'check_request',
  waiting_payment: 'waiting_payment',
  splitting_payment: 'splitting_payment',
  payment_splitted: 'payment_splitted',
  making_payment: 'making_payment',
  pay_by_cash_request: 'pay_by_cash_request',
  pay_by_swipe_card_request: 'pay_by_swipe_card_request',
  completing_payment: 'completing_payment',
  complete_payment: 'complete_payment',
  order_confirmed: 'order_confirmed',
  cleaned: 'cleaned',
  offline: 'offline',
  pay_by_paynow_request: 'pay_by_paynow_request',
  pay_by_alipay_request: 'pay_by_alipay_request',
  pay_by_wechatpay_request: 'pay_by_wechatpay_request'
}

export const USER_EVENTS = {
  message: 'message',
  newCustomer: 'newCustomer',
  tableCustomer: 'tableCustomer',
  tableRequestStatusChanged: 'tableRequestStatusChanged',
  tableOrderPlaced: 'tableOrderPlaced',
  waiterRequested: 'waiterRequested',
  waiterResponded: 'waiterResponded',
  tableProcessPayment: 'tableProcessPayment',
  itemAdded: 'itemAdded',
  itemUpdated: 'itemUpdated',
  itemRemoved: 'itemRemoved',
  orderPlaced: 'orderPlaced',
  orderUpdated: 'orderUpdated',
  orderPaymentStatusChange: 'orderPaymentStatusChange',
  orderShippingStatusChange: 'orderShippingStatusChange',
  orderStatusChange: 'orderStatusChange',
  requestMakePaymentForInvoice: 'requestMakePaymentForInvoice',
  invoiceStatusChange: 'invoiceStatusChange',
  newBatch: 'newBatch',
  batchStatusChange: 'batchStatusChange',
  batchItemStatusChange: 'batchItemStatusChange',
  makingPrepaymentWhileEating: 'makingPrepaymentWhileEating',
  completePrepaymentWhileEating: 'completePrepaymentWhileEating',
  tableNotification: 'tableNotification',
  tableNotifyMessage: 'tableNotifyMessage'
}

export const ORDER_ADJUSTMENT_TYPE = {
  promotion: 'promotion',
  order_adjust: 'order_adjust',
  manual_discount: 'manual_discount'
}

export const ITEM_ORDER_TYPE = {
  order: 'order',
  adjustment: 'adjustment'
}

export const ADJUSTMENT_TYPE = {
  add_item: 'add_item',
  order: 'order',
  discount: 'discount',
  service: 'service'
}

export const ADJUSTMENT_TYPES = [
  {value: 'discount', label: 'res.mgt.manual_discount'},
  {value: 'add_item', label: 'res.mgt.adjust_add_item'},
  // {value: 'order', label: 'res.mgt.adjust_order'},
  // {value: 'service', label: 'res.mgt.adjust_service'},
]

export const PRODUCT_OPTION_TYPE = {
  variant: 'variant',
  properties: 'properties'
}

export const PRODUCT_VARIANT_TYPE = {
  simple: 'simple',
  configuration: 'configuration'
}

export const USE_FOR_PURPOSE = {
  res_dine_in: 'res_dine_in',
  res_take_out: 'res_take_out',
  res_time_order: 'res_time_order',
  res_delivery_now: 'res_delivery_now'
}

export const TABLE_TYPE = {
  dine_in: 'dine_in',
  take_away: 'take_away',
  time_order: 'time_order',
  delivery_now: 'delivery_now'
}

export const INFORM_TYPE = {
  thankyou: 'thankyou',
  access_denied: 'access_denied',
  order_inform: 'order_inform',
  not_found: 'not_found'
}

export const PAYMENT_USE_MODE = { 
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION'
}

export const OBJECT_TYPE = {
  product: 'product',
  order: 'order'
}

export const PAYMENT_STATUS = {
  pending: 'pending',
  processing: 'processing',
  cancel: 'cancel',
  completed: 'completed'
}

export const PRICING_MODE = {
  normal: "normal",
  included_tax_service: "included_tax_service",
  included_tax: "included_tax",
  included_service: "included_service"
}

export const CURRENCY_OBJECT = {
  USD: {symbol: '$', decimal: 2},
  EUR: {symbol: '€', decimal: 2},
  SGD: {symbol: 'S$', decimal: 2},
  IDR: {symbol: 'Rp', decimal: 0}
}

export const ORDER_PAY_MODE = {
  pre_pay: 'pre_pay',
  post_pay: 'post_pay'
}

export const PROMOTION_RULE_TYPE = {
  cart_adjustment: 'cart_adjustment',
  product_adjustment: 'product_adjustment',
  product_dependent: 'product_dependent',
  product_quantity: 'product_quantity',
  product_bxgx: 'product_bxgx',
  product_bxgy: 'product_bxgy',
  manual_order_discount: 'manual_order_discount'
}

export const VOUCHER_STATUS = {
  New: 'New',
  Used: 'Used'
}

export const RULE_FILTER_OBJECT = {
  all_product: 'all_product',
  specific_products: 'specific_products',
  specific_menus: 'specific_menus',
  specific_categories: 'specific_categories'
}

export const RULE_FILTER_TYPE = {
  in_list: 'in_list',
  not_in_list: 'not_in_list'
}

export const RULE_CONDITION_TYPE = {
  subtotal: "subtotal",
  item_quantity: "item_quantity",
  line_item_count: "line_item_count",
  week_days: "week_days",
  month_days: "month_days",
  times: "times"
}

export const ORDER_ACTIVITY_TYPE = {
  resMgt: 'resMgt',
  cusMgt: 'cusMgt',
  orderPlaced: 'orderPlaced',
  orderConfirmed: 'orderConfirmed',
  orderCheckout: 'orderCheckout',
  orderPaymentVerified: 'orderPaymentVerified',
  orderCompleted: 'orderCompleted',
  orderDelivered: 'orderDelivered',
  orderCanceled: 'orderCanceled'
}

export const DEVICE_TYPE = {
  printer: 'printer'
}

export const PRODUCT_CLASSIFICATION = {
  simple: 'simple',
  variable: 'variable',
  configuration: 'configuration'
}

export const USE_PURPOSE = {
  dineIn: 'dineIn',
  takeAway: 'takeAway',
  dineInSplit: 'dineInSplit',
  deliveryNow: 'deliveryNow'
}

export const GRAB_DELIVERY_STATUS = {
  QUEUEING: 'QUEUEING',
  ALLOCATING: 'ALLOCATING',
  PICKING_UP: 'PICKING_UP',
  IN_DELIVERY: 'IN_DELIVERY',
  IN_RETURN: 'IN_RETURN',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  RETURNED: 'RETURNED',
  FAILED: 'FAILED'
}