import React, { useEffect, forwardRef, useImperativeHandle, useContext, useState } from 'react';

import { showErrorNotify } from '../../service/NotificationService';
import { createHitPayPayment } from '../../../service/PaymentService';
import { moneyFormat } from '../../service/CommonService';
import { Button } from 'primereact/button';
import { PAYMENT_USE_MODE } from '../../../constants';
import { PaymentContext } from '../../context/PaymentContext';

export const HitPayPayment = forwardRef((props, ref) => {
    const paymentContext = useContext(PaymentContext)

    const trans = paymentContext.trans
    const invoice = paymentContext.invoice
    const paymentGateWay = paymentContext.paymentGateway
    const amount = paymentContext.amount
    const isProcessing = paymentContext.isProcessing

    const [token, setToken] = useState(null)
    const [isCompletePayment, setCompletePayment] = useState(false)

    useEffect(() => {
        if(paymentGateWay){
            let hitpayJs = document.getElementById('hitpaySnapJs');
            if (!hitpayJs) {
                hitpayJs = document.createElement('script');
                hitpayJs.src = paymentGateWay.useMode === PAYMENT_USE_MODE.TEST ? 'https://sandbox.hit-pay.com/hitpay.js' : 'https://hit-pay.com/hitpay.js';
                hitpayJs.id = 'hitpaySnapJs';
                hitpayJs.async = true;
                document.body.appendChild(hitpayJs);
            }
        }

        return () => {document.getElementById('hitpaySnapJs').remove()};
    }, [])

    // useEffect(() => {
    //     if(isCompletePayment && token)
    //         paymentContext.onCompletePayment(token)
    // }, [isCompletePayment])

    useImperativeHandle(ref, () => ({
        async showHitPayPayment(){
            let hitPayRequest = {
                reference_number: invoice.id,
                purpose: 'Pay for invoice #: ' + invoice.id,
                payment_methods: paymentGateWay.settings.onlineMethods,
                // redirect_url: `${getApplicationUrl()}order/${orderNumber}?tCode=${tableCode}&ref=${paymentGateWay.id}_${PAYMENT_GATEWAY.HITPAY}`
            }

            const resHitPay = await createHitPayPayment(paymentGateWay.id, hitPayRequest)
            
            if(!resHitPay.errorCode && paymentGateWay.settings.paymentLink) {
                setToken(resHitPay.id)

                if(!window.HitPay.inited){
                    window.HitPay.init(paymentGateWay.settings.paymentLink, {
                        domain: paymentGateWay.useMode === PAYMENT_USE_MODE.TEST ? 'sandbox.hit-pay.com' : 'hit-pay.com',
                        apiDomain: paymentGateWay.useMode === PAYMENT_USE_MODE.TEST ? 'sandbox.hit-pay.com' : 'hit-pay.com'
                    },
                    {
                        onClose: () => paymentContext.stopProcess(),
                        onSuccess: () => {
                            setCompletePayment(true)
                        },
                        onError: () => {
                            showErrorNotify('Can not process your payment at the moment. Please refresh page and try again!')
                            paymentContext.stopProcess()
                        }
                    })
                }

                window.HitPay.toggle({
                    paymentRequest: resHitPay.id,
                    // Optional
                    //method: 'card',
                    // Default method params
                    // Optional
                    //amount: 500
                })
            } else {
                setToken(null)
                showErrorNotify('Can not init payment at the moment. Please refresh page and try again!', 'Error');
                paymentContext.stopProcess()
            }
        }
    }))

    return (
        <Button icon={isProcessing?"pi pi-spin pi-spinner":"pi-md-local-atm"} disabled={isProcessing} 
            label={isProcessing?trans('res.menu.processing'):(trans('res.menu.pay') + " (" + moneyFormat(amount) + ")")} 
            onClick={() => paymentContext.onCheckout()}
            className="btn-make-payment"/>
    )
})
