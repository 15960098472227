import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import classNames from "classnames";
import { createBrowserHistory } from "history";
import "../ripple.js";
import "../CustomerOrder.scss";
import "../common.css";
import "./DeliveryNow.scss"
import { logOut } from "../core/security/auth.jsx";
import PageVisibility from 'react-page-visibility';
import LoadingOverlay from "../core/components/LoadingOverlay.js";
import { MenuOrdering } from "../order/MenuOrdering.js";
import CustomerOrderTopbar from "../core/layout/CustomerOrderTopbar.js";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as notify from "../core/service/NotificationService";
import { showloading, stoploading } from "../core/service/LoadingService";
import { loadPublicConfiguration } from "../service/ConfigurationService";
import * as socketService from '../core/service/CustomerWebEventService';
import { TABLE_REQUEST_STATUS, PAYMENT_METHOD, PAYMENT_OPTION, PAYMENT_METHODS_OBJECT, USE_FOR_PURPOSE, VOUCHER_STATUS, ORDER_ACTIVITY_TYPE, USE_PURPOSE, PAYMENT_GATEWAY } from "../constants.js";
import { RadioButton } from "primereact/radiobutton";
import { Fieldset } from "primereact/fieldset";
import StripeCheckoutForm from "../core/components/payment/StripeCheckoutForm";
import { Message } from "primereact/message";
import { moneyFormat, getGroupOrderItems, getTableOrderSummary, getCustomerId, isPriceIncludedTaxOrService } from "../core/service/CommonService.js";
import { ConfirmNotification } from '../core/components/ConfirmNotification';
import { OrderItemForm } from "../order/OrderItemForm.js";
import { completeOrderPaymentForTakeAway } from "../service/TakeAwayOrderService.js";
import { getDeliveryNowTable, updateDeliveryId, updateShippingFee, updateGrabDeliveryStatus } from "../service/DeliveryNowService";
import { removeOrderItems, applyPromotionCoupon, cancelPromotionCoupon, applyVoucherToOrder, confirmVoucherToOrder, processInvoiceForOrder } from "../service/OrderService.js";
import { getOnlinePaymentGateway } from "../service/PaymentService.js";
import SquareCheckoutForm from "../core/components/payment/SquareCheckoutForm.js";
import { parseMessage } from "../core/utils/TranslationUtils.js";
import PayPalCheckoutForm from "../core/components/payment/PayPalCheckoutForm.js";
import MidtransCheckoutForm from "../core/components/payment/MidtransCheckoutForm.js";
import { PinAccess } from "../order/PinAccess.js";
import { getMenuData } from "../service/MenuService.js";
import { getAppliedVoucherByInvoice } from "../service/VoucherService.js";
import { saveNotification } from "../service/OrderActivityService";
import moment from 'moment';
import HitPayCheckoutForm from "../core/components/payment/HitPayCheckoutForm.js";
import { createQuote, createDeliveryRequest, getLocaPrediction, getLocaGeometry} from "../service/GrabService";
import { AutoComplete } from 'primereact/autocomplete';

class DeliveryNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resConfig: {},
            topbarHeight: 0,
            layoutMode: "static",
            mobileMenuActive: null,
            isRequirePinCode: false,
            isValidPinCode: true,
            table: {
                name: null
            },
            tableSize: 0,
            menuData: null,
            order: {
                items: [],
                shippingFee: 0
            },
            groupOrderItems: [],
            orderSummary: {},
            language: 'en-US',
            frmCartVisible: false,
            frmOrderItemVisible: false,
            cFlagImage: '',
            isPayment: false,
            paymentOption: '',
            paymentMethod: '',
            activePayment: {},
            refToken: '',
            cusName: '',
            cusAddress: '',
            cusPhone: '',
            defaultCountry: 'us',
            errors: {},
            snapResult: {},
            couponCode: null,
            invoice: null,
            voucherCode: null,
            isVoucherConfirm: false,
            appliedVoucher: null,
            orderTime: '',
            deliveryOption: "timeOrder",
            timeList: [],
            timeSelect: '',
            pickupForm: false,
            grabQuote: {
                serviceType: "INSTANT",
                packages: [],
                origin: {
                    address: "1 IJK View, Singapore 018936",
                    coordinates: {
                        latitude: null,
                        longitude: null
                    }
                },
                destination: {
                    address: null,
                    coordinates: {
                        latitude: null,
                        longitude: null
                    }
                }
            },
            deliveryRequest: {
                merchantOrderID: '',
                serviceType: "INSTANT",
                packages: [],
                origin: {},
                destination:{},
                recipient:{
                    firstName: "John",
                    lastName: "Tan",
                    email: "john@gmail.com",
                    phone: "91526655",
                    smsEnabled: true
                },
                sender: {
                    firstName: "Jewel Changi Branch",
                    companyName: "Shake Shack",
                    email: "ssburger@gmail.com",
                    phone: "0124355834",
                    smsEnabled: true
                }
            },
            latitude: null,
            longtude: null,
            locaPredict:[],
            suggestions: null
        };

        this.notificationCount = 0;

        this._history = createBrowserHistory();
        this.unlisten = this._history.listen((location, action) => { });
        this.autocomplete = null;
        this.autocompleteInput = React.createRef()
    }

    async componentDidMount() {
        const config = await loadPublicConfiguration();
        let lat = config.data.address.lat;
        let lng = config.data.address.lng
        let grabQuote =  this.state.grabQuote;
        grabQuote.origin.coordinates.latitude= lat;
        grabQuote.origin.coordinates.longitude = lng;
        
        this.setState({
            defaultCountry: localStorage.getItem("countryCode"),
            cusPhoneCode: localStorage.getItem("countryCode"),
            address: config.address,
            grabQuote: grabQuote
        });

        this.getTableDetails();

        // this.getTableDetails((tCode) => {
        //   socketService.initConnection(tCode);
        // });

        this.getActivePayment();

        const { i18n } = this.props;
        let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

        i18n.changeLanguage(locale);
        this.setState({ language: locale });

        const resConfig = config.data;

        let paymentOption = '';
        let paymentMethod = '';

        if (resConfig.useOnlinePayment && resConfig.useOnSitePayment) {
            paymentOption = PAYMENT_OPTION.Online;
            paymentMethod = PAYMENT_METHOD.online;
        } else if (resConfig.useOnlinePayment || resConfig.useOnSitePayment) {
            if (resConfig.useOnlinePayment) {
                paymentOption = PAYMENT_OPTION.Online;
                paymentMethod = PAYMENT_METHOD.online;
            } else {
                paymentOption = PAYMENT_OPTION.Onsite;
                paymentMethod = resConfig.onSiteMethods.length > 0 ? resConfig.onSiteMethods[0] : '';
            }
        }
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1)
        
        this.setState({
            resConfig: resConfig,
            paymentOption: paymentOption,
            paymentMethod: paymentMethod,
            minDate: yesterday,
            address: config.address,
            grabQuote: grabQuote
        });

    }

    componentWillUnmount() {
        this.unlisten();
    }

    onFocus = () => {
        if (!socketService.connected()) {
            window.location.reload();
        }
    }

    countTotalQty = (order) => {
        let qty = 0
        order.items && order.items.length > 0 && order.items.map((item) => {
            qty += item.quantity
        })
        return qty
    }

    hadleVisibilityChagne = isVisible => {
        if (isVisible) {
            if (!socketService.connected()) {
                window.location.reload();
            }
        }
    }

    handleLogout = (e, redirectTo = "/login") => {
        e.preventDefault();
        logOut();
        this._history.push(redirectTo);
    };

    getTableDetails = (pinCode) => {
        this.getOrder(pinCode);
    };

    getActivePayment = () => {
        getOnlinePaymentGateway()
            .then(res => {
                this.setState({ activePayment: res });
            });
    }

    setGrabQuote = (items) => {
        let packages = [];
        items.map(i => {
            let pack = {
                name: i.productName,
                description: "",
                quantity: i.quantity,
                price: i.unitPrice,
                dimensions: {
                    height: 0,
                    width: 0,
                    depth: 0,
                    weight: 0
                }
            }
            packages.push(pack)
        })
        let grabQuote = this.state.grabQuote;
        grabQuote.packages = packages;
        this.setState({...this.state.grabQuote,
            grabQuote: grabQuote
        })
    }

    getOrder = (pinCode) => {
        showloading();
        getDeliveryNowTable(getCustomerId(), pinCode)
            .then((res) => {
                if (res) {
                    if (res.resTable) {
                        this.setState({ isRequirePinCode: false });
                        const deliveryRequest = this.state.deliveryRequest;
                        deliveryRequest.merchantOrderID = res.resTable.orderNumber;
                        const table = res.resTable;
                        const order = table.draftOrder;
                        if (!this.props.match.params.orderNumber) {
                            let href = localStorage.getItem('resApplicationUrl') + 'delivery/delivery-now/' + res.resTable.orderNumber;
                            window.location.replace(href);
                            return
                        }
                        showloading()
                        if(order.items.length > 0){
                            this.setGrabQuote(order.items);
                        }
                        if (!this.state.menuData) {
                            getMenuData(table.id, true).then(menuData => {
                                this.setState({ menuData: menuData });

                                this.menuOrdering.popularMenuData(menuData);

                                this.setState({
                                    table: table,
                                    order: order,
                                    groupOrderItems: order.items.length > 0 ? getGroupOrderItems(order.items, menuData.categories, true) : [],
                                    orderSummary: getTableOrderSummary([order]),
                                    cusName: table.customer && table.customer.customerName ? table.customer.customerName : '',
                                    cusPhone: table.customer && table.customer.customerPhone ? table.customer.customerPhone : '',
                                    deliveryRequest: deliveryRequest
                                });
                            })
                        } else {
                            this.setState({
                                table: table,
                                order: order,
                                groupOrderItems: order.items.length > 0 ? getGroupOrderItems(order.items, this.state.menuData.categories, true) : [],
                                orderSummary: getTableOrderSummary([order]),
                                cusName: table.customer && table.customer.customerName ? table.customer.customerName : '',
                                cusPhone: table.customer && table.customer.customerPhone ? table.customer.customerPhone : '',
                                deliveryRequest: deliveryRequest
                            });
                        }

                        if (table.customer && table.customer.customerId && table.customer.customerId !== getCustomerId()) {
                            localStorage.setItem("customerId", table.customer.customerId);
                            // localStorage.setItem("customerColor", res.customer.customerColor);
                        }
                    } else {
                        this.setState({
                            isRequirePinCode: res.isRequirePinCode,
                            isValidPinCode: res.isValidPinCode !== undefined ? res.isValidPinCode : true
                        });
                    }
                    
                } else {
                    this.props.history.push(`/404`);
                }
            }).finally(() => stoploading())
            
    }

    updateDraftOrder = (data) => {
        this.setGrabQuote(data.items);
        this.setState({
            order: data,
            groupOrderItems: data.items.length > 0 ? getGroupOrderItems(data.items, this.state.menuData.categories, true) : [],
            orderSummary: getTableOrderSummary([data])
        }, () => {
            if (data.items.length === 0) {
                this.setState({ frmCartVisible: false });
            }
        });
    }

    changeLanguage = (e) => {
        this.setState({ language: e });
        const { i18n } = this.props;
        i18n.changeLanguage(e);
        localStorage.setItem('selectedLocale', e);
    };

    onRemoveCartItems = (items) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        let removeData = items.map(i => (i.id));

        showloading();
        removeOrderItems(removeData, true, localStorage.getItem("customerId"), this.state.order.id)
            .then(res => {
                if (!res.errorCode) {
                    this.updateDraftOrder(res.order);
                    notify.showSuccessNotify(trans('res.menu.updated_food_order'));
                } else {
                    notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
                }
            })
            .finally(() => {
                stoploading();
            });
    }

    getTotalCartItemCount = () => {
        let qty = 0;

        this.state.order.items.map((item) => {
            qty += item.quantity;
        });

        return qty;
    };

    askForConfirmAction = (type) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        let content = '';

        switch (type) {
            case TABLE_REQUEST_STATUS.confirm_request:
                content = trans('res.menu.place_order_confirmation_content');
                break;
            default:
                break;
        }

        this.confirmDialog.confirm(type, content);
    }

    validationCustomerInput = () => {
        let errors = {};
        if (!this.state.cusName)
            errors.cusName = { code: 2030 };
        if (!this.state.cusPhone)
            errors.cusPhone = { code: 2030 };

        return errors;
    }

    processControlError = (errors) => {
        this.setState({ errors: errors });

        const firstErr = Object.keys(errors)[0];
        switch (firstErr) {
            case 'cusName':
                this.txtCusName.element.focus();
                break
            case 'cusPhone':
                this.txtCusPhone.element.focus();
                break;
            default:
                break;
        }
    }

    requestPayOnsite = () => {
        // const errors = this.validationCustomerInput();
        // if(Object.keys(errors).length>0){
        //   this.processControlError(errors);
        //   return;
        // }

        // this.setState({ btnPayOnsiteDisable: true });
        // const { t } = this.props;
        // const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        // requestPayOnsiteForTakeAway(this.state.order.id, this.state.table.orderNumber, this.state.paymentMethod, localStorage.getItem("customerId"), this.state.cusName, this.state.cusPhone)
        //     .then((res) => {
        //         if (!res.errorCode) {
        //             this.props.history.push(`/delivery/delivery-now/inform`);
        //         } else {
        //             notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        //         }
        //     })
        //     .finally(() =>
        //         this.setState({ btnPayOnsiteDisable: false })
        //     );
    }

    handleCardSuccess = (refToken) => {
        if (refToken) {
            this.setState({
                refToken: refToken,
                paymentMethod: PAYMENT_METHOD.online,
            }, () => {
                this.handleCompletePayment();
            });
        }
    }

    handleCompletePayment = () => {
        showloading();
        completeOrderPaymentForTakeAway(this.state.order.id, this.state.table.orderNumber, this.state.paymentMethod, this.state.activePayment.gateway, this.state.refToken, localStorage.getItem("customerId"), this.state.cusName, this.state.cusPhone, this.state.invoice?this.state.invoice.id:null)
        .then(res => {
        if (!res.errorCode){
            let deliveryRequest = this.state.deliveryRequest;
            createDeliveryRequest(deliveryRequest).then(res =>{
                let deliveryId = res.deliveryID;
                let orderNumber = res.merchantOrderID;
                let grabDeliveryStatus = res.status;
                updateDeliveryId(orderNumber, deliveryId);
                updateGrabDeliveryStatus(orderNumber, grabDeliveryStatus);
            })
            this.props.history.push(`/delivery/delivery-now/inform`);
        }else{
            notify.showErrorNotify(res.errorMessage);
        }
        })
        .finally(stoploading());
    }

    setCusName = (name) => {
        let deliveryRequest = this.state.deliveryRequest;
        deliveryRequest.recipient.firstName = name;
        this.setState({
            cusName: name,
            deliveryRequest: deliveryRequest
        })
    }

    setCusPhone = (phone) => {
        let deliveryRequest = this.state.deliveryRequest;
        deliveryRequest.recipient.phone = phone;
        this.setState({
            cusPhone: phone,
            deliveryRequest: deliveryRequest
        })
    }

    setCusAddress = (address) => {
        let order = this.state.order;   
        updateShippingFee(order.orderNumber, 0);
        order.shippingFee = 0;
        this.setState({
            cusAddress: address,
            latitude: null,
            longitude: null,
            order : order,
            orderSummary: getTableOrderSummary([order]),
        })
        getLocaPrediction(address).then(res => {
            let predicts = [];
            let suggestions = [];
            if(res.predictions.length > 0){
                let predictions = res.predictions;
                predictions.map(i =>{
                    let item = {
                        name: i.description,
                        place_id: i.place_id
                    }
                    suggestions.push(i.description)
                    predicts.push(item);
                })
                let grabQuote = this.state.grabQuote;
                grabQuote.destination.coordinates.latitude = null;
                grabQuote.destination.coordinates.longitude = null;
                this.setState({
                    locaPredict: predicts,
                    suggestions: suggestions,
                    grabQuote: grabQuote,
                    latitude: null,
                    longitude: null,
                })
            }else{
                this.setState({
                    suggestions: null
                })
            }
        })
    }

    searchLocations = (event) => {
        setTimeout(() => {
            let results = this.state.suggestions
            this.setState({
                suggestions: results
            })
        }, 300);
    }


    setCoordinates = (event) => {
        let address = event.value;
        let predictions = this.state.locaPredict;
        let placeId = null;
        predictions.find(i => {
            if(i.name === address){
                placeId = i.place_id;
            }
        })
        if(placeId !== null){
            getLocaGeometry(placeId).then(res => {
                if(res){
                    let lat = res.result.geometry.location.lat;
                    let lng = res.result.geometry.location.lng;
                    let grabQuote = this.state.grabQuote;
                    grabQuote.destination.coordinates.latitude = lat;
                    grabQuote.destination.coordinates.longitude = lng;
                    grabQuote.destination.address = address;
                    this.setState({
                        latitude: lat,
                        longtude: lng,
                        grabQuote: grabQuote
                    })
                    this.updateShippingFee();
                }
            })
        }
    }

    updateShippingFee = () => {
        createQuote(this.state.grabQuote).then(res => {
            let shippingFee = res.quotes[0].amount;
            let order = this.state.order;
            let deliveryRequest = this.state.deliveryRequest;
            deliveryRequest.destination = res.destination;
            deliveryRequest.packages = res.packages;
            deliveryRequest.origin = res.origin;
            updateShippingFee(order.orderNumber, shippingFee);
            order.shippingFee = shippingFee;
            
            this.setState({ 
                frmCartVisible: true,
                order : order,
                orderSummary: getTableOrderSummary([order]),
                deliveryRequest: res,
                deliveryRequest: deliveryRequest
            });
            this.onConfirmAndPay();
            
        })
    }

    onCloseCartDialog = () => {
        this.setState({ frmCartVisible: false });
    };

    buttonActionItems = (order) => {
        let tempButton = {};
        order.items && order.items.map(item => {
            tempButton = { ...tempButton, [item.id]: { btnUpdateDisable: false, btnRemoveDisable: false } }
        })
        return tempButton;
    }

    isBlockOrdering = () => {
        return false;
    }

    onPaymentOptionChange = (e) => {
        const paymentOption = e.value;
        let paymentMethod = '';
        const resConfig = this.state.resConfig;

        if (paymentOption === PAYMENT_OPTION.Online) {
            paymentMethod = PAYMENT_METHOD.online;
        } else {
            paymentMethod = resConfig.onSiteMethods.length > 0 ? resConfig.onSiteMethods[0] : '';
        }

        this.setState({
            paymentOption: paymentOption,
            paymentMethod: paymentMethod
        });
    }

    onDeliveryTypeChange = (e) => {
        const deliveryOption = e.value;

        this.setState({
            deliveryOption: deliveryOption
        });
    }

    onCartClick = () => {
        // createQuote(this.state.grabQuote).then(res => {
            // let shippingFee = res.quotes[0].amount;
            let order = this.state.order;
            // let deliveryRequest = this.state.deliveryRequest;
            // deliveryRequest.destination = res.destination;
            // deliveryRequest.packages = res.packages;
            // deliveryRequest.origin = res.origin;
            // updateShippingFee(order.orderNumber, shippingFee);
            // order.shippingFee = shippingFee;
            
            this.setState({ 
                frmCartVisible: true,
                order : order,
                orderSummary: getTableOrderSummary([order]),
                // deliveryRequest: res,
                // deliveryRequest: deliveryRequest
            });
            this.onConfirmAndPay();
            
        // })
        
    }

    renderOrderItemView = (orderData, isDraftOrder, trans, defaultLocale, selectedLocale) => {
        return (
            <React.Fragment>
                {/* {isDraftOrder?
    <h3 className="p-margin-bottom-5">{trans('res.menu.current_order')}</h3>
    :
    <h3 className="p-margin-bottom-5 p-margin-top-30">{trans('res.menu.order_history')}</h3>
    } */}

                <div className="p-hr p-margin-bottom-10" />

                <div className="p-grid">
                    <div className={isDraftOrder ? "p-col-2" : "p-col-1"}>{!isDraftOrder && '#'}</div>
                    <div className={(isDraftOrder ? "p-col-5" : "p-col-6") + " p-w-bold"}>{trans('res.mgt.food')}</div>
                    <div className="p-col-1 p-w-bold p-r">{trans('res.mgt.qty')}</div>
                    <div className="p-col-4 p-w-bold p-r">{trans('res.mgt.total')}</div>
                </div>

                <div className="p-hr p-padding-top-0 p-margin-bottom-10" />

                {isDraftOrder && orderData.length === 0 &&
                    <div className="p-grid">
                        <div className="p-col-12">{trans('res.menu.no_food_selected')}</div>
                    </div>
                }

                {(() => {
                    let num = 0;

                    return (
                        <React.Fragment>
                            {orderData.map((v, i) => {
                                return (
                                    <React.Fragment key={"cate_" + i}>
                                        <div className="order-item-view">
                                            {(v.products.length > 0 || (!isDraftOrder && v.adjustItems && v.adjustItems.length > 0)) &&
                                                <h3 className="cate-title">{v.cateName ? v.cateNames[selectedLocale] ? v.cateNames[selectedLocale] : v.cateNames[defaultLocale] ? v.cateNames[defaultLocale] : v.cateName : trans('res.menu.cate_other')}</h3>
                                            }

                                            {v.products.length > 0 && v.products.map((p, pIdx) => {
                                                num += 1;

                                                return (
                                                    <React.Fragment key={"prod_" + pIdx}>
                                                        {(() => {
                                                            if (p.items.length === 1) {
                                                                const item = p.items[0];

                                                                return (
                                                                    <div className="p-grid p-vertical-c">
                                                                        {isDraftOrder ?
                                                                            <div className="p-col-2 p-nowrap">
                                                                                <Button icon="pi-md-edit" className="p-button-info btn-xxs" onClick={() => { this.frmOrderItems.openForm(item.orderItems); this.setState({ frmOrderItemVisible: true }) }} />
                                                                                <Button icon="pi-md-close" className="p-button-danger btn-xxs" onClick={() => this.onRemoveCartItems(item.orderItems)} />
                                                                            </div>
                                                                            :
                                                                            <div className="p-col-1">#{num}</div>
                                                                        }

                                                                        <div className={isDraftOrder ? "p-col-5" : "p-col-6"}>
                                                                            <div>{Object.keys(p.names).length > 0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}</div>
                                                                            {item.itemOptionValueTrans.length > 0 &&
                                                                                <div className="p-size-11 p-margin-top-5">
                                                                                    - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ? opt.names[selectedLocale] : opt.names[defaultLocale])).join(', ')}
                                                                                </div>
                                                                            }
                                                                            {item.note && <div className="p-size-11 p-margin-top-5">- {item.note}</div>}
                                                                        </div>
                                                                        <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="p-grid">
                                                                            <div className={isDraftOrder ? "p-col-2" : "p-col-1"}>{!isDraftOrder && "#" + num}</div>
                                                                            <div className={(isDraftOrder ? "p-col-10" : "p-col-11") + " p-margin-bottom-5"}>
                                                                                {Object.keys(p.names).length > 0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}
                                                                            </div>
                                                                        </div>

                                                                        {p.items.map((item, idx) => {
                                                                            return (
                                                                                <div key={i + "_" + idx + "_" + item.id} className="p-grid p-vertical-c">
                                                                                    {isDraftOrder ?
                                                                                        <div className="p-col-2 p-nowrap">
                                                                                            <Button icon="pi-md-edit" className="p-button-info btn-xxs" onClick={() => { this.frmOrderItems.openForm(item.orderItems); this.setState({ frmOrderItemVisible: true }) }} />
                                                                                            <Button icon="pi-md-close" className="p-button-danger btn-xxs" onClick={() => this.onRemoveCartItems(item.orderItems)} />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="p-col-1">#{num}</div>
                                                                                    }
                                                                                    <div className={isDraftOrder ? "p-col-5" : "p-col-6"} style={{ borderBottom: idx < p.items.length - 1 ? '1px solid #d8d8d8' : '' }}>
                                                                                        {item.itemOptionValueTrans.length === 0 && !item.note ?
                                                                                            <div className="p-size-11">{trans('res.mgt.food_select_standard')}</div>
                                                                                            :
                                                                                            <>
                                                                                                {item.itemOptionValueTrans.length > 0 &&
                                                                                                    <div className="p-size-11 p-margin-top-5">
                                                                                                        - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ? opt.names[selectedLocale] : opt.names[defaultLocale])).join(', ')}
                                                                                                    </div>
                                                                                                }
                                                                                                {item.note && <div className={"p-size-11 " + (item.productVariantName || item.productVariantNames ? "p-margin-top-5" : "")}>- {item.note}</div>}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                                                                    <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        })()}

                                                        {p.adjustments.length > 0 && p.adjustments.map(adjust => {
                                                            return (
                                                                <div className="p-grid p-vertical-c p-size-12" style={{ marginTop: '-1em' }}>
                                                                    <div className="p-col-2"></div>
                                                                    <div className="p-col-5 p-fs-italic">
                                                                        {adjust.trans ? adjust.trans.nameDisplay[selectedLocale] ? adjust.trans.nameDisplay[selectedLocale] : adjust.trans.nameDisplay[defaultLocale] : adjust.rule.nameDisplay} {adjust.coupon ? ' (' + adjust.coupon.code + ')' : ''}
                                                                    </div>
                                                                    <div className="p-col-2 p-r"></div>
                                                                    <div className="p-col-3 p-r">
                                                                        {moneyFormat(adjust.amount)}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}

                                            {!isDraftOrder && v.adjustItems && v.adjustItems.length > 0 && v.adjustItems.map(item => {
                                                num += 1;

                                                return (
                                                    <div className="p-grid p-vertical-c">
                                                        <div className="p-col-1">#{num}</div>
                                                        <div className="p-col-6">
                                                            <div>{item.productName}</div>
                                                        </div>
                                                        <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                            <div className="p-hr p-padding-top-0 p-margin-bottom-10 "></div>
                        </React.Fragment>
                    )
                })()}
            </React.Fragment>
        )
    }

    buildPhoneObj = (status, value, countryData) => {
        let phoneValue = "";
        if (value !== "") {
            if (value.indexOf("+" + countryData.dialCode) !== -1) {
                phoneValue = value.substr(("+" + countryData.dialCode).length);
            } else {
                phoneValue = value;
            }
        }
        let phoneObj = {
            phoneNumber: phoneValue,
            dialCode: countryData.dialCode,
            countryCode: countryData.iso2.toUpperCase(),
            phoneValid: status
        }

        return phoneObj;
    }

    onChangePhone = (status, value, countryData) => {
        let phoneObj = this.buildPhoneObj(status, value, countryData);

        this.setState({
            cusPhone: phoneObj.phoneNumber ? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber : '',
            cusPhoneValue: phoneObj.phoneNumber,
            cusPhoneCode: phoneObj.countryCode,
        });
    }

    applyPromotionCoupon = (trans) => {
        showloading();
        applyPromotionCoupon(this.state.order.id, this.state.order.orderNumber, this.state.couponCode)
            .then(res => {
                if (!res.errorCode) {
                    this.updateDraftOrder(res);
                    notify.showSuccessNotify(trans('res.menu.coupon_applied'));
                } else {
                    if (res.errorCode === 400)
                        this.setState({ errors: res.errorObj })
                    notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
                }
            })
            .finally(() => {
                stoploading();
            });
    }

    cancelPromotionCoupon = (trans, couponCode) => {
        showloading();
        cancelPromotionCoupon(this.state.order.id, this.state.order.orderNumber, couponCode)
            .then(res => {
                if (!res.errorCode) {
                    this.updateDraftOrder(res);
                    this.setState({ couponCode: '' });
                    notify.showSuccessNotify(trans('res.menu.coupon_canceled'));
                } else {
                    notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
                }
            })
            .finally(() => {
                stoploading();
            });
    }

    onConfirmAndPay = () => {
        processInvoiceForOrder(this.state.table.orderNumber, localStorage.getItem("customerId"))
        .then(res => {
            this.setState({
                isPayment: true,
                invoice: res
            }, () => {
                this.getAppliedVoucher(res.id);
                saveNotification(this.state.table.orderNumber, ORDER_ACTIVITY_TYPE.orderPlaced, "");
            });
        });
    }

    applyVoucher = (trans) => {
        applyVoucherToOrder(this.state.voucherCode)
            .then(res => {
                if (!res.errorCode) {
                    this.getTableDetails();
                    this.setState({
                        appliedVoucher: res,
                        isVoucherConfirm: true
                    });
                    notify.showSuccessNotify(trans('res.menu.voucher_verified'));
                } else {
                    if (res.errorCode === 409)
                        notify.showErrorNotify(trans('res.menu.voucher_used'));
                    else
                        notify.showErrorNotify(trans('res.menu.errors.invalid_voucher'));
                }
            });
    }

    confirmVoucher = (trans) => {
        confirmVoucherToOrder(
            this.state.voucherCode,
            this.state.invoice.id,
            this.state.order.orderNumber)
            .then(res => {
                if (!res.errorCode) {
                    if (this.state.orderSummary.orderTotal <= res.value) {
                        this.props.history.push(`/delivery/delivery-now/inform`);
                    } else {
                        this.setState({
                            isVoucherConfirm: false,
                            voucherCode: null,
                            appliedVoucher: res
                        });
                    }

                    notify.showSuccessNotify(trans('res.menu.voucher_applied'));
                } else {
                    notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
                }
            });
    }

    getAppliedVoucher = (invoiceId) => {
        getAppliedVoucherByInvoice(invoiceId)
            .then(res => {
                if (res) {
                    this.setState({
                        appliedVoucher: res
                    });
                }
            })
    }

    calculateTotalWhenVoucherApplied = (total, voucherValue) => {
        if (total <= voucherValue) {
            return 0;
        } else {
            return total - voucherValue;
        }
    }
    onPickupTime = (orderId) => {
        this.pickupForm.action(this.state.orderTime, this.state.timeSelect, this.state.table.id, orderId)
    }

    onSavePickup = (date, time) => {
        this.setState({
            ...this.state,
            orderTime: moment(date).format('yyyy-MM-DD'),
            timeSelect: time
        })
    }

    render() {
        let layoutPadding = (this.state.topbarHeight + 'px 15px 0px 15px');

        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        let wrapperClass = classNames("layout-wrapper", {
            "layout-wrapper-static": this.state.layoutMode === "static",
            "layout-wrapper-active": this.state.mobileMenuActive,
            "layout-menu-horizontal": this.state.layoutMode === "horizontal",
        });

        const currency = localStorage.getItem('currencySymbol');
        const selectedLocale = localStorage.getItem('selectedLocale');
        const defaultLocale = localStorage.getItem('defaultLocale');
        const currencyCode = localStorage.getItem('currency');
        const applicationUrl = localStorage.getItem('resApplicationUrl');

        const resConfig = localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {};
        const blockOrdering = this.isBlockOrdering();

        return (
            <PageVisibility onChange={this.hadleVisibilityChagne}>
                <div className={wrapperClass}>
                    <ConfirmNotification ref={(el) => this.confirmDialog = el}
                        header={trans('res.menu.confirmation')}
                        submitText={trans('res.menu.submit_confirm')}
                        cancelText={trans('res.menu.cancel')}
                        submitConfirm={(e) => e === TABLE_REQUEST_STATUS.confirm_request ? this.setState({ isPayment: true }) : ''}
                    />
                    <OrderItemForm ref={(el) => this.frmOrderItems = el}
                        visible={this.state.frmOrderItemVisible} currency={currency} isCustomer={true}
                        trans={trans} useFor={USE_FOR_PURPOSE.res_delivery_now}
                        updateCartOrder={(data, isAdd, isUpdate, isRemove) => this.updateDraftOrder(data, isAdd, isUpdate, isRemove)}
                        onHide={() => this.setState({ frmOrderItemVisible: false })} />
                    <Sidebar visible={this.state.frmCartVisible} position="right" style={{ overflowY: "auto", paddingLeft: '7.5px', paddingRight: '7.5px' }} className="p-sidebar-lg customer"
                        blockScroll={true} baseZIndex={1000000} onHide={(e) => this.onCloseCartDialog()}
                    >
                        <h2>{trans('res.mgt.delivery_now')}</h2>
                        {this.state.order && this.state.order.items.length > 0 &&
                            <React.Fragment>
                                <div className="p-grid">
                                    <div className="p-col-12" style={{ paddingTop: "3%" }}>
                                            <React.Fragment>
                                                {this.state.order && this.state.order.items.length > 0 &&
                                                    <div className="p-margin-bottom-10" style={{ border: '1px solid #d8d8d8', borderRadius: '3px', backgroundColor: '#d8d8d8', boxShadow: '0px 5px 8px #888888' }}>
                                                        <div className="p-grid p-margin-none">
                                                            <div className="p-col-7 p-w-bold p-size-16">{trans('res.menu.order_total')}:</div>
                                                            <div className="p-col-5 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.invoice &&
                                                    <Fieldset legend={trans('res.menu.voucher_section')} className="p-fieldset-sm" style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                                        <div className="p-grid">
                                                            {this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ?
                                                                <div className="p-col-12 p-c">
                                                                    <Message severity="info" text={trans('res.menu.voucher_applied')} />
                                                                </div>
                                                                :
                                                                <React.Fragment>
                                                                    <div className="p-col-12 p-md-6">
                                                                        <div className="p-inputgroup p-fluid">
                                                                            <span className="p-inputgroup-addon">{trans('res.menu.enter_voucher')}: </span>
                                                                            <InputText value={this.state.voucherCode} onChange={(e) => this.setState({ voucherCode: e.target.value })} />
                                                                            <span className="p-inputgroup-addon">
                                                                                {this.state.isVoucherConfirm ?
                                                                                    <Button icon="pi-md-check" label={trans('res.menu.voucher_confirm')} onClick={() => this.confirmVoucher(trans)} style={{ width: 'auto' }} />
                                                                                    :
                                                                                    <Button disabled={this.state.voucherCode ? false : true} label={trans('res.menu.apply_voucher')} onClick={() => this.applyVoucher(trans)} style={{ width: 'auto' }} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.appliedVoucher &&
                                                                        <div className="p-col-12 p-md-6 p-l">
                                                                            <div>{trans('res.menu.voucher_value')}: <strong>{moneyFormat(this.state.appliedVoucher.value)}</strong></div>
                                                                            <div>{trans('res.menu.remain_total')}: <strong>{moneyFormat(this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value))}
                                                                            </strong>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </Fieldset>
                                                }

                                                <Fieldset legend={trans('res.menu.payment')} style={{paddingLeft: '7.5px', paddingRight: '7.5px'}}>
                                                    <div className="p-grid">
                                                    {(()=>{
                                                        if(!resConfig.useOnSitePayment && !resConfig.useOnlinePayment){
                                                        return (
                                                            <div className="p-col-12 p-c">
                                                            <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                                            </div>
                                                        );
                                                        }else{
                                                        return (
                                                            <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">{trans('res.menu.your_name')}</label>
                                                                <InputText ref={(el) => this.txtCusName = el} value={this.state.cusName} onChange={(e) => this.setCusName(e.target.value)} style={{width: '100%'}}/>
                                                                <div className="p-form-error">{this.state.errors.cusName && parseMessage(trans, this.state.errors.cusName.code)}</div>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">{trans('res.menu.your_phone')}</label>
                                                                <InputText ref={(el) => this.txtCusPhone = el} value={this.state.cusPhone} keyfilter="int" onChange={(e) => this.setCusPhone(e.target.value)} style={{width: "100%"}}/>
                                                                <div className="p-form-error">{this.state.errors.cusPhone && parseMessage(trans, this.state.errors.cusPhone.code)}</div>
                                                                {/* <IntlTelInput autoPlaceholder={false} format={true} value={this.state.cusPhone} onPhoneNumberChange={(s,v,c,n) => this.onChangePhone(s,v,c,'cellPhone')} onSelectFlag={(v,c,n,s) => this.onChangePhone(s,v,c,'cellPhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{width: "100%"}}/> */}
                                                                {/* <IntlTelInput autoPlaceholder={true} defaultCountry={this.state.defaultCountry} preferredCountries={[]} 
                                                                onPhoneNumberChange={(s,v,c,n) => this.onChangePhone(s,v,c)} onSelectFlag={(v,c,n,s) => this.onChangePhone(s,v,c)}
                                                                format={true} value={this.state.cusPhoneValue} css={['intl-tel-input', 'p-inputtext  p-component']} style={{width: "100%"}}/> */}
                                                            </div>
                                                            <div className="p-col-12 p-md-12">
                                                                <label className="p-label">{trans('res.menu.address')}</label>
                                                                <AutoComplete value={this.state.cusAddress} suggestions={this.state.suggestions} completeMethod={this.searchLocations} onSelect={this.setCoordinates} onChange={(e) => this.setCusAddress(e.target.value) } style={{width: '100%'}}/>
                                                                <div className="p-form-error">{(this.state.latitude === null || this.state.longtude === null) && trans('res.mgt.enter_correct_address')}</div>
                                                            </div>
                                                            {resConfig.useOnSitePayment && resConfig.useOnlinePayment &&
                                                                <div className="p-col-12 p-margin-bottom-10 p-margin-top-10">
                                                                <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_option')}:</label>
                                                                <div className="p-grid">
                                                                    <div className="p-col-6">
                                                                    <RadioButton inputId="rbPayOption1" name="paymentOption" value={PAYMENT_OPTION.Online} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Online} />
                                                                    <label htmlFor="rbPayOption1" className="p-radiobutton-label">{trans('res.menu.online_payment')}</label>
                                                                    </div>
                                                                    {/* <div className="p-col-6">
                                                                    <RadioButton inputId="rbPayOption0" name="paymentOption" value={PAYMENT_OPTION.Onsite} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Onsite} />
                                                                    <label htmlFor="rbPayOption0" className="p-radiobutton-label p-margin-right-20">{trans('res.menu.service_counter')}</label>
                                                                    </div> */}
                                                                </div>
                                                                </div>
                                                            }

                                                            {(()=>{
                                                                if((this.state.paymentOption===PAYMENT_OPTION.Onsite && resConfig.onSiteMethods.length===0) || (this.state.paymentOption===PAYMENT_OPTION.Online && !this.state.activePayment.id)){
                                                                return (
                                                                    <div className="p-col-12 p-c">
                                                                    <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                                                    </div>
                                                                );
                                                                }else{
                                                                return (
                                                                    <React.Fragment>
                                                                    {(()=>{
                                                                        if(this.state.paymentOption===PAYMENT_OPTION.Onsite && this.state.latitude !== null && this.state.longtude !== null){
                                                                        if(resConfig.onSiteMethods.length > 1  ){
                                                                            return (
                                                                            <div className="p-col-12">
                                                                                <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_method')}:</label>
                                                                                <div className="p-grid">
                                                                                {resConfig.onSiteMethods.map((method, mIdx) => {
                                                                                    return (
                                                                                    <div className="p-col-6">
                                                                                        <RadioButton inputId={"rbPayMethod"+mIdx} name="paymentMethod" value={method} onChange={(e) => this.setState({ paymentMethod: e.value })} checked={this.state.paymentMethod === method} />
                                                                                        <label htmlFor={"rbPayMethod"+mIdx} className="p-radiobutton-label p-margin-right-20">{trans(PAYMENT_METHODS_OBJECT[method].label)}</label>
                                                                                    </div>
                                                                                    )
                                                                                })}
                                                                                </div>
                                                                                

                                                                                {this.state.activePayment.gateway === PAYMENT_GATEWAY.HITPAY && (this.state.paymentMethod === PAYMENT_METHOD.paynow_online || this.state.paymentMethod === PAYMENT_METHOD.alipay || this.state.paymentMethod === PAYMENT_METHOD.wechat) &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label">{trans('res.menu.your_email')} *</label>
                                                                                    <InputText ref={(el) => this.txtCusEmail = el} value={this.state.cusEmail} keyfilter="email" onChange={(e) => this.setState({cusEmail: e.target.value})} style={{width: "100%"}}/>
                                                                                    <div className="p-form-error">{this.state.errors && this.state.errors.email && parseMessage(trans, this.state.errors.email.code)}</div>
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                            )
                                                                        }else{
                                                                            return (
                                                                            <div className="p-col-12">
                                                                                <label>{trans('res.menu.payment_method')}: <strong>{trans(PAYMENT_METHODS_OBJECT[this.state.paymentMethod].label)}</strong></label>
                                                                            </div>
                                                                            )
                                                                        }
                                                                        }
                                                                    })()}

                                                                    {/* {((this.state.paymentOption===PAYMENT_OPTION.Onsite && resConfig.useTipInOnSitePayment) || (this.state.paymentOption===PAYMENT_OPTION.Online && resConfig.useTipInOnlinePayment)) &&
                                                                        <div className="p-col-12">
                                                                        <Fieldset legend={trans('res.menu.tip')} style={{paddingLeft: '7.5px', paddingRight: '7.5px', width: '100%'}}>
                                                                            <div className="p-grid p-fluid">
                                                                            <div className="p-col-7"><label>{trans('res.menu.tip_amount')}:</label></div>
                                                                            <div className="p-col-5 p-r">
                                                                                <InputText value={this.state.tipAmount} className="p-r" 
                                                                                keyfilter="money" maxLength="12"
                                                                                onFocus={(e) => this.setState({ tipAmount: e.target.value=='0'?'':e.target.value})}
                                                                                onBlur={(e) => this.setState({ tipAmount: e.target.value?parseFloat(e.target.value).toFixed(2):0})}
                                                                                onChange={(e) => this.setState({ tipAmount: e.target.value })} style={{width: '100%'}} 
                                                                                placeholder={trans('res.menu.input_tip_here')}/>
                                                                            </div>
                                                                            {resConfig.showTipSuggestion && resConfig.suggestedTipRates.length>0 &&
                                                                            <div className="p-col-12">
                                                                                <div><label>{trans('res.menu.suggested_tip')}</label></div>
                                                                                {resConfig.suggestedTipRates.map(tip => {
                                                                                return (<div className="p-margin-top-5"><label>{trans('res.menu.tip')} {tip}% :</label><label className="p-r p-margin-left-20">{moneyFormat(((this.state.orderSummary.orderTotal*parseFloat(tip))/100))}</label></div>);
                                                                                })}
                                                                            </div>
                                                                            }
                                                                            </div>
                                                                        </Fieldset>
                                                                        </div>
                                                                    } */}

                                                                    {this.state.paymentOption === PAYMENT_OPTION.Onsite && this.state.latitude !== null && this.state.longtude !== null ? 
                                                                    <div className="p-col-12 p-margin-top-10">
                                                                        <Button icon={this.state.btnPayOnsiteDisable?"pi pi-spin pi-spinner":"pi-md-local-atm"} disabled={this.state.btnPayOnsiteDisable} label={this.state.btnPayOnsiteDisable?trans('res.menu.processing'):(trans('res.menu.pay') + " (" + moneyFormat((this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used?this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value):this.state.orderSummary.orderTotal) + (this.state.tipAmount?parseFloat(this.state.tipAmount):0)) + ")")} className="p-margin-right-20 btn-make-payment" onClick={() => this.requestPayOnsite()} />
                                                                        <Button icon="pi-md-close" label="Cancel" className="p-button-secondary btn-cancel" onClick={()=>this.setState({isPayment: false, invoice: null})}/>
                                                                    </div>
                                                                    :
                                                                    this.state.activePayment.id && this.state.latitude !== null && this.state.longtude !== null &&
                                                                        <div className="p-col-12">
                                                                        <Fieldset legend={trans('res.menu.online_payment')} style={{paddingLeft: '7.5px', paddingRight: '7.5px'}}>
                                                                            <div className="p-grid">
                                                                            <div className="p-col-12">
                                                                                {this.state.activePayment.gateway === 'STRIPE' &&
                                                                                <StripeCheckoutForm
                                                                                    trans={trans}
                                                                                    onHandleCardSuccess={(e) => this.handleCardSuccess(e.id)}
                                                                                    onHandleCancel={() => this.setState({isPayment: false})}
                                                                                    customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used?this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value):this.state.orderSummary.orderTotal) + (this.state.tipAmount?parseFloat(this.state.tipAmount):0)}
                                                                                    currency={currency}
                                                                                    stripeKey={this.state.activePayment.settings.publishableKey}
                                                                                />
                                                                                }
                                                                                {this.state.activePayment.gateway === 'SQUARE' && 
                                                                                <SquareCheckoutForm
                                                                                    trans={trans}
                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used?this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value):this.state.orderSummary.orderTotal) + (this.state.tipAmount?parseFloat(this.state.tipAmount):0)}
                                                                                    currency={currency}
                                                                                    onHandleCardSuccess={(nonce) => this.handleCardSuccess({nonce: nonce})}
                                                                                    onHandleCancel={() => this.setState({isPayment: false})}
                                                                                    applicationId={this.state.activePayment.settings.applicationId}
                                                                                    locationId={this.state.activePayment.settings.locationId}
                                                                                    customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                    activePayment={this.state.activePayment}
                                                                                />
                                                                                }
                                                                                {this.state.activePayment.gateway === 'PAYPAL' &&
                                                                                <PayPalCheckoutForm
                                                                                    trans={trans}
                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used?this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value):this.state.orderSummary.orderTotal) + (this.state.tipAmount?parseFloat(this.state.tipAmount):0)}
                                                                                    currencyCode={currencyCode}
                                                                                    onHandleCardSuccess={(orderId) => this.handleCardSuccess(orderId)}
                                                                                    onHandleCancel={() => this.setState({isPayment: false})}
                                                                                    clientId={this.state.activePayment.settings.clientId}
                                                                                    customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                />
                                                                                }
                                                                                {this.state.activePayment.gateway === 'MIDTRANS' &&
                                                                                <MidtransCheckoutForm
                                                                                    trans={trans}
                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used?this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value):this.state.orderSummary.orderTotal) + (this.state.tipAmount?parseFloat(this.state.tipAmount):0)}
                                                                                    onHandleCancel={() => this.setState({isPayment: false})}
                                                                                    customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)} 
                                                                                    orderNumber={this.state.order.orderNumber}
                                                                                    onCloseSidebar={() => this.setState({ frmCartVisible: false })}
                                                                                    currency={currency}
                                                                                    onHandleCardSuccess={(result) => this.handleCardSuccess(result)}
                                                                                    clientKey={this.state.activePayment.settings.clientKey}
                                                                                    activePayment={this.state.activePayment}
                                                                                    invoiceId={this.state.invoice?this.state.invoice.id:''}
                                                                                />
                                                                                }
                                                                                {this.state.activePayment.gateway === 'HITPAY' &&
                                                                                <HitPayCheckoutForm
                                                                                    orderNumber={this.state.order.orderNumber}
                                                                                    method={this.state.paymentMethod}
                                                                                    gateway={this.state.activePayment.gateway}
                                                                                    // tipAmount={resConfig.useTipInOnlinePayment?this.state.tipAmount:null}
                                                                                    trans={trans}
                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used?this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value):this.state.orderSummary.orderTotal) + (this.state.tipAmount?parseFloat(this.state.tipAmount):0)}
                                                                                    onHandleCancel={() => this.setState({isPayment: false})}
                                                                                    onCloseSidebar={() => this.setState({ frmCartVisible: false })}
                                                                                    currency={currency}
                                                                                    currencyCode={currencyCode}
                                                                                    onHandleCardSuccess={(result) => this.handleCardSuccess(result)}
                                                                                    // clientKey={this.state.activePayment.settings.clientKey}
                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)} 
                                                                                    activePayment={this.state.activePayment}
                                                                                    invoiceId={this.state.invoice?this.state.invoice.id:''}
                                                                                    customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                    redirectUrl={`${applicationUrl}take-away/inform`}
                                                                                    orderId={this.state.order.id}
                                                                                    useFor={USE_PURPOSE.takeAway}
                                                                                    cusId={getCustomerId()}
                                                                                    email={this.state.cusEmail}
                                                                                />
                                                                                }
                                                                            </div>
                                                                            </div>
                                                                        </Fieldset>
                                                                        </div>
                                                                    }
                                                                    </React.Fragment>
                                                                )
                                                                }
                                                            })()}
                                                            </React.Fragment>
                                                        )
                                                        }
                                                    })()}
                                                    </div>
                                                </Fieldset>
                                            </React.Fragment>
                                    </div>
                                </div>

                                {this.renderOrderItemView(this.state.groupOrderItems, true, trans, defaultLocale, selectedLocale)}

                                {this.state.order && this.state.order.items.length > 0 &&
                                    <React.Fragment>
                                        <div className="p-grid p-margin-bottom-10">
                                            <div className="p-col-7 p-w-bold p-size-14">{trans('res.menu.item_total')}</div>
                                            <div className="p-col-1 p-w-bold p-r p-size-14">{this.state.orderSummary.qtyTotal}</div>
                                            <div className="p-col-4 p-w-bold p-r p-size-14">{(!isPriceIncludedTaxOrService()) ? moneyFormat(this.state.orderSummary.subTotal + this.state.orderSummary.itemDiscount) : moneyFormat(this.state.orderSummary.subTotal)}</div>
                                        </div>

                                        {isPriceIncludedTaxOrService() && (this.state.orderSummary.taxTotal > 0 || this.state.orderSummary.serviceTotal > 0) &&
                                            <div className="p-grid">
                                                <div className="p-col-8 p-w-bold">{trans('res.menu.total_without_tax')}</div>
                                                <div className="p-col-4 p-w-bold p-r">{moneyFormat(this.state.orderSummary.subTotalNoTax + this.state.orderSummary.itemDiscount)}</div>
                                            </div>
                                        }

                                        {this.state.orderSummary.shippingFee !== null &&
                                            <div className="p-grid">
                                                <div className="p-col-8">{trans('res.menu.shipping_fee')}</div>
                                                <div className="p-col-4 p-r">{moneyFormat(this.state.orderSummary.shippingFee)}</div>
                                            </div>
                                        }

                                        {this.state.orderSummary.orderAdjusts.length > 0 && this.state.orderSummary.orderAdjusts.map(adjust => {
                                            return (
                                                <div className="p-grid">
                                                    <div className="p-col-8">{adjust.label}</div>
                                                    <div className="p-col-4 p-r">{moneyFormat(adjust.amount)}</div>
                                                </div>
                                            )
                                        })}

                                        {this.state.orderSummary.orderDiscounts.length > 0 && this.state.orderSummary.orderDiscounts.map(discount => {
                                            return (
                                                <div className="p-grid">
                                                    <div className="p-col-8">{discount.trans ? discount.trans.nameDisplay[selectedLocale] ? discount.trans.nameDisplay[selectedLocale] : discount.trans.nameDisplay[defaultLocale] : discount.rule.nameDisplay}</div>
                                                    <div className="p-col-4 p-r">{moneyFormat(discount.amount)}</div>
                                                </div>
                                            )
                                        })}

                                        {this.state.orderSummary.serviceTotal > 0 &&
                                            <div className="p-grid">
                                                <div className="p-col-8">{trans('res.menu.service')} ({this.state.orderSummary.serviceRate}%)</div>
                                                <div className="p-col-4 p-r">{moneyFormat(this.state.orderSummary.serviceTotal)}</div>
                                            </div>
                                        }

                                        {this.state.orderSummary.taxs &&
                                            Object.values(this.state.orderSummary.taxs).map((item, idx) => {
                                                return (
                                                    <div className="p-grid">
                                                        <div className="p-col-8">{item.name} ({item.rate}%)</div>
                                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {this.state.orderSummary.tipTotal > 0 &&
                                            <div className="p-grid p-margin-top-5">
                                                <div className="p-col-8 p-w-bold">{trans('res.menu.tip')}</div>
                                                <div className="p-col-4 p-w-bold p-r">{moneyFormat(this.state.orderSummary.tipTotal)}</div>
                                            </div>
                                        }

                                        <div className="p-hr" />

                                        <div className="p-grid p-margin-top-5 p-margin-bottom-10">
                                            <div className="p-col-8 p-w-bold p-size-16">{trans('res.menu.order_total')}</div>
                                            <div className="p-col-4 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                                        </div>

                                        <Fieldset legend={trans('res.menu.coupon_section')} className="p-fieldset-sm" style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                            <div className="p-grid p-fluid">
                                                {this.state.orderSummary.couponDiscount ?
                                                    <div className="p-col-12">
                                                        <div>
                                                            {trans('res.menu.coupon_applied')}: <strong>{this.state.orderSummary.couponDiscount.coupon.code}</strong> - {this.state.orderSummary.couponDiscount.trans ? this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale] ? this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale] : this.state.orderSummary.couponDiscount.trans.nameDisplay[defaultLocale] : this.state.orderSummary.couponDiscount.rule.nameDisplay}
                                                            <Button className="p-display-inline-block btn-sm p-margin-left-20 p-button-danger" label={trans('res.menu.cancel_coupon')} onClick={(e) => this.cancelPromotionCoupon(trans, this.state.orderSummary.couponDiscount.coupon.code)} style={{ width: 'auto' }} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="p-col-12 p-md-6">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">{trans('res.menu.enter_coupon')}: </span>
                                                            <InputText value={this.state.couponCode} onChange={(e) => this.setState({ couponCode: e.target.value })} />
                                                            <span className="p-inputgroup-addon">
                                                                <Button className="btn-sm" disabled={this.state.couponCode ? false : true} label={trans('res.menu.apply_coupon')} onClick={(e) => this.applyPromotionCoupon(trans)} style={{ width: 'auto' }} />
                                                            </span>
                                                        </div>
                                                        <div className="p-form-error">{this.state.errors.coupon && parseMessage(trans, this.state.errors.coupon.code)}</div>
                                                    </div>
                                                }

                                            </div>
                                        </Fieldset>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Sidebar>

                    <div className="layout-main customer">
                        <CustomerOrderTopbar layoutMode={this.state.layoutMode}
                            config={resConfig ? resConfig : {}}
                            blockOrdering={blockOrdering}
                            useFor={USE_FOR_PURPOSE.res_delivery_now}
                            cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
                            isOrderPage={true} setHeight={(e) => this.setState({ topbarHeight: e })}
                            table={this.state.table}
                            goToOrderTracking={() => this.props.history.push(`/delivery/delivery-now/inform`)}
                            onCartClick={(e) => this.onCartClick()} cartItemCount={this.getTotalCartItemCount()}
                            trans={trans}
                            totalQty={this.countTotalQty(this.state.order)}
                        />
                        <div className="layout-content" id="layout-content-container">
                            <LoadingOverlay />
                            {this.state.isRequirePinCode ?
                                <PinAccess trans={trans}
                                    padding={layoutPadding}
                                    isValid={this.state.isValidPinCode}
                                    access={(e) => this.getTableDetails(e)} />
                                :
                                <MenuOrdering ref={(el) => this.menuOrdering = el} cLang={this.state.language} trans={trans}
                                    config={resConfig ? resConfig : {}}
                                    useFor={USE_FOR_PURPOSE.res_delivery_now}
                                    blockOrdering={blockOrdering}
                                    table={this.state.table} currency={currency} orderId={this.state.order.id}
                                    padding={layoutPadding}
                                    updateCartOrder={(e) => this.updateDraftOrder(e)}
                                    topbarHeight={this.state.topbarHeight - 30}
                                    orderTime={this.state.orderTime}
                                    timeSelect= {this.state.timeSelect}
                                    changeDateTime={() => this.onPickupTime(this.state.order.id)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </PageVisibility>
        );
    }
}

export default withNamespaces("message")(DeliveryNow);
