import { createContext } from "react";

export const PaymentContext = createContext({
	trans: null,
	paymentGateway: null,
	invoice: null,
	isProcessing: false,
	stopProcess: () => {},
	onCheckout: () => {},
	onCompletePayment: () => {}
})