import axios from 'axios';
import { ORDER_ACTIVITY_BASE_URL } from '../constants';

export function getNotifications(orderNumber, type){
    return axios.get(`${ORDER_ACTIVITY_BASE_URL}/getNotifications/${orderNumber}`,{
        params: {type}
    }).then(res => res.data).catch(error => console.log(error));
}

export function saveNotification(orderNumber, type, content){
    return axios.post(`${ORDER_ACTIVITY_BASE_URL}/saveNotification/${orderNumber}`, {}, {
        params: {type, content}
    }).then(res => res.data).catch(error => console.log(error));
}