import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import { moneyFormat } from "../core/service/CommonService";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "../core/components/inputnumber/InputNumber";
import { InputTextarea } from "primereact/inputtextarea";
import * as notify from "../core/service/NotificationService";
import { Dropdown } from "primereact/dropdown";
import { saveOrderItem } from "../service/OrderService";
import { PRODUCT_CLASSIFICATION, USE_FOR_PURPOSE, PRODUCT_OPTION_TYPE, PROMOTION_RULE_TYPE, RULE_FILTER_OBJECT, RULE_FILTER_TYPE } from "../constants";
import { parseMessage } from "../core/utils/TranslationUtils";
import Tippy from '@tippy.js/react';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './AddFoodItem.scss';

export class AddFoodItem extends Component {constructor(props) {
  super(props);
    this.state = {
      visible: false,
      frmOrderItem: {},
      food: null,
      variantOptions: [],
      propertyOptions: [],
      discountBars: [],
      errors: {}
    };
  }

  optionValueIdsTranslate = (item, optionValueId) => {
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    let value = item.options && item.options[0].values.find(x => x.id === optionValueId[0])
    if(value){
      if(value.trans){
        if(value.trans.names !== {}){
          if(value.trans.names[selectedLocale]) return value.trans.names[selectedLocale];
          else if(value.trans.names[defaultLocale]) return value.trans.names[defaultLocale];
          else return value.name;
        }
      } else return value.name;
    }
  }

  popularFrmOrderItem = (food, category, disableOrder) => {
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const trans = this.props.trans;

    const propertyOptions = this.getFoodPropertyOptions(food.options, trans, defaultLocale, selectedLocale);

    this.setState({       
      visible: true,
      food: food,
      propertyOptions: propertyOptions,
      variantOptions : food.classification===PRODUCT_CLASSIFICATION.variable ? this.getFoodVariantOptions(food, defaultLocale, selectedLocale) : null, // need translate - optionValueIds
      discountBars: this.getDiscountBarForProduct(food, this.props.discountBars),
      frmOrderItem: {
        taxonId: category?category.id:'',
        prodId: food.id,
        prodName: food.names[defaultLocale],
        classification: food.classification,
        variantSelectionType: 1,
        variantId: food.classification === PRODUCT_CLASSIFICATION.simple ? food.variants[0].id : '',
        // variantSelectionType: food.variantSelectionType, //1-get variant by id; 0-get variant by option value;
        optValues: propertyOptions.length>0 ? new Array(propertyOptions.length) : [],
        quantity: 1,
        note: '',
        tagAllergens: food.tagAllergens ? food.tagAllergens : [],
        tagIngredients: food.tagIngredients ? food.tagIngredients : [],
      },
      btnAddToCartDisable: false
    });
  }

  getDiscountBarForProduct = (food, discountRules) => {
    let prodDiscountBars = [];

    if(discountRules.length>0){
      discountRules.map(r => {
        let valid = false;

        if([PROMOTION_RULE_TYPE.cart_adjustment, PROMOTION_RULE_TYPE.manual_order_discount].includes(r.type)){
          valid = true;
        }else{
          if(r.type!==PROMOTION_RULE_TYPE.product_bxgy){
            if(r.filters.length>0){
              r.filters.map(f => {
                if(f.applyObject===RULE_FILTER_OBJECT.all_product){
                  valid = true;
                }else{
                  if(f.applyTo && f.applyTo.length>0){
                    if(f.applyObject===RULE_FILTER_OBJECT.specific_products){
                      valid = (f.applyType===RULE_FILTER_TYPE.in_list && f.applyTo.includes(food.id)) || (f.applyType===RULE_FILTER_TYPE.not_in_list && !f.applyTo.includes(food.id)) ? true : false;
                    }else if(f.applyObject===RULE_FILTER_OBJECT.specific_categories){
                      valid = (f.applyType===RULE_FILTER_TYPE.in_list && food.categoryIds.some(v => f.applyTo.includes(v))) || (f.applyType===RULE_FILTER_TYPE.not_in_list && food.categoryIds.some(v => !f.applyTo.includes(v))) ? true : false;
                    }else if(f.applyObject===RULE_FILTER_OBJECT.specific_menus){
                      valid = (f.applyType===RULE_FILTER_TYPE.in_list && food.menuIds.some(v => f.applyTo.includes(v))) || (f.applyType===RULE_FILTER_TYPE.not_in_list && food.menuIds.some(v => !f.applyTo.includes(v))) ? true : false;
                    }
                  }else{
                    valid = false;
                  }
                }
              })
            }
          }else{
            if(r.discounts.length>0){
              r.discounts.map(d => {
                if(d.applyForProduct===food.id)
                  valid = true;
              })
            }
          }
        }

        if(valid) prodDiscountBars.push(r);
      })
    }

    return prodDiscountBars;
  }

  getFoodPropertyOptions = (options, trans, defaultLocale, selectedLocale) => {
    let result = [];

    options.map(o => {
      if(o.type===PRODUCT_OPTION_TYPE.properties){
        let opt = {...o};
        let tmpValues = [];

        o.values.map(v => {
          const price = this.props.useFor===USE_FOR_PURPOSE.res_dine_in?v.extraPrice01:v.extraPrice02;
          
          let name = v.trans.names[selectedLocale] ? v.trans.names[selectedLocale] : v.trans.names[defaultLocale] ? v.trans.names[defaultLocale] : v.name;

          if(opt.extraPrice && price!=0){
            name += ' (' + moneyFormat(price) + ' ' + trans('res.menu.more') + ')';
          }

          tmpValues.push({
            value: v.id, 
            label: name
          });
        });

        opt.values = tmpValues;
        result.push(opt);
      }
    });

    return result;
  }

  getFoodVariantOptions = (food, defaultLocale, selectedLocale) => {
    let result = {};

    const variantOpts = food.options.filter(o => o.type===PRODUCT_OPTION_TYPE.variant);
    let variants = [...food.variants];

    if (variantOpts.length > 0 && variants.length>0) {
      variants.map(v => {
        let optValueNames = [];

        v.optionValueIds.map((oValId, oValIdx) => {
          const optValue = variantOpts[oValIdx].values.find(v => v.id===oValId);

          optValueNames.push(optValue.trans ? optValue.trans.names[selectedLocale] ? optValue.trans.names[selectedLocale] : optValue.trans.names[defaultLocale] ? optValue.trans.names[defaultLocale] : optValue.name : optValue.name);
        })

        v.optValueNames = optValueNames;
      });

      result.options = variantOpts;
      result.variants = variants;
    }

    return result;
  }

  onCloseDialog = () => {
    this.setState({
      visible: false,
      food: null,
      frmOrderItem: { quantity: 1 },
      errors: {}
    });
  }

  addFoodToCart = () => {
    this.setState({ btnAddToCartDisable: true });

    const trans = this.props.trans;
    // let optValueObjs = [...this.state.frmOrderItem.optValues];
    // let optValues = optValueObjs.map(opt => opt ? opt.id : '');

    let data = { ...this.state.frmOrderItem, orderId: this.props.orderId };
    // data.optValues = optValues;

    saveOrderItem(data, true, localStorage.getItem("customerId"))
      .then(res => {
        if (!res.errorCode) {
          if(this.props.useFor===USE_FOR_PURPOSE.res_take_out){
            this.props.updateCartOrder(res.order);
            notify.showSuccessNotify(trans('res.menu.add_food_order'));
          }
          if(this.props.useFor===USE_FOR_PURPOSE.res_time_order){
            this.props.updateCartOrder(res.order);
            notify.showSuccessNotify(trans('res.menu.add_food_order'));
          }
          if(this.props.useFor===USE_FOR_PURPOSE.res_delivery_now){
            this.props.updateCartOrder(res.order);
            notify.showSuccessNotify(trans('res.menu.add_food_order'));
          }
          this.setState({ frmOrderItem: { optValues: [], quantity: 1 }, errors: {} });
          this.onCloseDialog();
        } else {
          if (res.errorCode === 400)
            this.setState({ errors: res.errorObj })
          notify.showErrorNotify(trans('res.menu.errors.unable_add_order'));
        }
      })
      .finally(()=>{
        this.setState({ btnAddToCartDisable: false })
      });
  }

  onFoodOptionChange = (e, idx) => {
    let optValues = [...this.state.frmOrderItem.optValues];

    optValues[idx] = e;

    this.setState({ frmOrderItem: { ...this.state.frmOrderItem, optValues: optValues } });
  }

  onPropOptionChange = (e, idx) => {
    let optValues = [...this.state.frmOrderItem.optValues];

    optValues[idx] = e;

    this.setState({ frmOrderItem: { ...this.state.frmOrderItem, optValues: optValues } });
  }

  onChangeInputNumber = (e) => {
    this.setState({ 
      frmOrderItem: { ...this.state.frmOrderItem, quantity: e.value }
    })
  }

  render(){
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const useFor = this.props.useFor;
    const trans = this.props.trans;
    const resConfig = this.props.config;

    return (
      <Sidebar visible={this.state.visible} position="right" style={{ overflowY: 'auto', padding: 'unset' }} className="p-sidebar-md customer" blockScroll={true} baseZIndex={1000000} onHide={(e) => this.onCloseDialog()}>
        {this.state.food &&
        <React.Fragment>
          {this.state.food.images&&this.state.food.images.length>0 &&
            <div className="sidebar-dish-image">
              <LazyLoadImage src={this.state.food.images[0].url} 
              alt={this.state.food.images[0].fileName} className="food-picture" />
            </div>
          } 

          <div className="p-grid p-fluid form-group" style={{padding:'.5em 1em'}}>
            <div className="p-col-8 p-size-18">
              <div className={"p-w-bold food-title" + (this.state.food.images.length>0?" p-margin-top-  0":"")}>
                {this.state.food.names[selectedLocale] ? this.state.food.names[selectedLocale] : this.state.food.names[defaultLocale] ? this.state.food.names[defaultLocale] : this.state.food.name}</div>
                
              {(this.state.food.tagAllergens.length>0 || this.state.food.tagIngredients.length>0) &&
                <div className="p-margin-5-0">
                  {this.state.food.tagAllergens.map(tag => {
                    return (
                      <Tippy
                        content={tag.trans.descs && tag.trans.descs[selectedLocale] ? tag.trans.descs[selectedLocale] : tag.trans.descs[defaultLocale]}
                        animation="fade"
                        placement="top"
                        trigger="mouseenter focus"
                        interactive={true}
                        arrow={true}
                        maxWidth='none'
                        duration={[350, 200]}
                      >
                        <span className="item-tag item-tag-red" style={{backgroundColor: resConfig.resTagAllergenColor}}>{tag && tag.name ? tag.name : ''}</span>
                      </Tippy>
                    );
                  })}
                  {this.state.food.tagIngredients.map(tag => {
                    return (
                      <Tippy
                        content={tag.trans && tag.trans.descs && tag.trans.descs[selectedLocale] ? tag.trans.descs[selectedLocale] : tag.trans.descs[defaultLocale]}
                        animation="fade"
                        placement="top"
                        trigger="mouseenter focus"
                        interactive={true}
                        arrow={true}
                        maxWidth='none'
                        duration={[350, 200]}>
                        <span className="item-tag item-tag-blue" style={{backgroundColor: resConfig.resTagIngredientColor}}>{tag && tag.name ? tag.name : ''}</span>
                      </Tippy>
                    );
                  })}
                </div>
              }
            </div>       
            <div className="p-col-4">
              {!this.state.food.pricedByVariant &&
                  <div className="p-w-bold money-format">{moneyFormat(useFor===USE_FOR_PURPOSE.res_dine_in?this.state.food.price01:this.state.food.price02)}</div>
                }
            </div>

            <div className="p-col-12 p-size-18">
              <div className={`c-food-item-desc { ${this.state.discountBars.length>0?"p-margin-top-0":""}`}>{this.state.food.descs[selectedLocale] ? this.state.food.descs[selectedLocale] : this.state.food.descs[defaultLocale] ? this.state.food.descs[defaultLocale] : this.state.food.desc}</div>
              {this.state.discountBars.length>0 && this.state.discountBars.map(r => {
                const barContent = r.trans?r.trans.discountBarContent[selectedLocale]?r.trans.discountBarContent[selectedLocale]:r.trans.discountBarContent[defaultLocale]:'';
                if(barContent){
                  return (
                    <div className="p-product-discount-bar p-size-18" style={{textAlign:"center", backgroundColor: r.discountBarBackgroundColor?'#'+r.discountBarBackgroundColor:'#ffc107', color: r.discountBarTextColor?'#'+r.discountBarTextColor:'#fff'}}>
                      <div>{barContent}</div>
                    </div>
                  )
                }
              })}
            </div>
          </div>

          {!this.props.disableOrder && 
          <div className="p-grid input-order" style={{padding:'.5em 1em'}}>
            <div className="p-col-2 food-item-top">
              <a className="quantity">{trans('res.menu.qty')}</a>
            </div>
            <div className="p-col-9 p-c food-item-top">
              <InputNumber className="input-quantity" value={this.state.frmOrderItem.quantity} onChange={(e) => this.onChangeInputNumber(e)} showButtons buttonLayout="horizontal" spinnerMode="horizontal" step={1} min={0} max={99}
                decrementButtonClassName="p-button-default btn-dec no-radius btn-sm p-margin-right-0" incrementButtonClassName="p-button-default btn-inc no-radius btn-sm p-margin-right-0" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" 
              />
              <div className="p-form-error p-margin-top-5">{this.state.errors.quantity && parseMessage(trans, this.state.errors.quantity.code)}</div>
            </div>
          </div>
          }

          { this.state.food.classification === PRODUCT_CLASSIFICATION.variable && this.state.variantOptions && this.state.variantOptions.variants && this.state.variantOptions.variants.length>0 ?
            <div className="p-col-12 p-size-20">
              <label className="p-label">
                {this.state.variantOptions.options.length>1?
                  trans('res.menu.select_an_option') + ":"
                :
                  this.state.variantOptions.options[0].trans.noneLabels[selectedLocale] ? this.state.variantOptions.options[0].trans.noneLabels[selectedLocale] : this.state.variantOptions.options[0].trans.noneLabels[defaultLocale] ? this.state.variantOptions.options[0].trans.noneLabels[defaultLocale] : this.state.variantOptions.options[0].noneLabel ? this.state.variantOptions.options[0].noneLabel + ":" : trans('res.menu.select_an_option') + ":"
                }
              </label>

              <div className="p-grid p-margin-top-5" style={{padding:'.5em 1em'}}>
                {this.state.variantOptions.variants.map((variant) => {
                  return (
                    <div key={"variant_" + variant.id} className="p-col-12">
                      <RadioButton disabled={this.props.disableOrder} inputId={"variant_" + variant.id} value={variant.id} onChange={(e) => this.setState({ frmOrderItem: { ...this.state.frmOrderItem, variantId: e.value } })} checked={this.state.frmOrderItem.variantId === variant.id} />
                      <label htmlFor={"variant_" + variant.id} className="p-radiobutton-label">{variant.optValueNames.join(', ')} ({moneyFormat(useFor===USE_FOR_PURPOSE.res_dine_in?variant.extraPrice:variant.extraPrice02)}{!this.state.food.pricedByVariant?' '+trans('res.menu.more'):''})</label>
                    </div>
                  )
                })}
              </div>

              <div className="p-form-error p-margin-top-5">{this.state.errors.variantId && parseMessage(trans, this.state.errors.variantId.code)}</div>
            </div>
            : ''
          }

          {this.state.propertyOptions.length>0 && this.state.propertyOptions.map((option, optIdx) => {
            return (
              <div className="p-col-12 p-size-20">
                <label className="p-label">
                  {option.trans.noneLabels[selectedLocale] ? option.trans.noneLabels[selectedLocale] : option.trans.noneLabels[defaultLocale] ? option.trans.noneLabels[defaultLocale] : trans('res.menu.select_an_option')}
                  {option.mandatory === true ? " (" + trans('res.menu.required') + ")" + ":" : " (" + trans('res.menu.optional') + ")" + ":"}
                </label>
                <div className="p-grid p-margin-top-5">
                  {option.values.map(val => {
                    return (
                      <div key={"propOption_" + val.value} className="p-col-12">
                        <RadioButton disabled={this.props.disableOrder} inputId={"propOption_" + val.value} value={val.value} onChange={(e) => this.onPropOptionChange(e.value, optIdx)} checked={this.state.frmOrderItem.optValues[optIdx] === val.value} />
                        <label htmlFor={"propOption_" + val.value} className="p-radiobutton-label">{val.label}</label>
                      </div>
                    )
                  })}
                </div>
                <div className="p-form-error p-margin-top-5">{this.state.errors["props_opt_" + optIdx] && parseMessage(trans, this.state.errors["props_opt_" + optIdx].code)}</div>
              </div>
            )
          })}
          
          {!this.props.disableOrder && this.state.food.allowNote &&
          <div className="p-grid p-fluid form-group" style={{padding:'.5em 1em'}}>
            <div className="p-col-12">
              <label className="p-label p-margin-bottom-10">{trans('res.menu.note')}:</label>
              <InputTextarea value={this.state.frmOrderItem.note} onChange={(e) => this.setState({ frmOrderItem: { ...this.state.frmOrderItem, note: e.target.value.replace(/\s/g, '').length ? e.target.value : '' } })} />
            </div>
          </div>
          }

          {!this.props.disableOrder &&
          <div className="p-grid form-group">
            <div className="p-col-12 p-margin-top-15 p-c">
              <Button className="add-to-order-btn" label={this.state.btnAddToCartDisable?trans('res.menu.processing'):trans('res.menu.add_order')} 
                icon={this.state.btnAddToCartDisable?"pi pi-spin pi-spinner p-size-18":"pi pi-check"} 
                disabled={this.state.btnAddToCartDisable} onClick={() => this.addFoodToCart()} />
            </div>
          </div>
          }
        </React.Fragment>
        }
      </Sidebar>
    )
  }
}

export default withNamespaces("message")(AddFoodItem);