import React, {Component} from "react";
import moment from "moment";

export default class Timer extends Component{
    constructor(props){
        super(props)
        this.state = {
            time: this.props.time
        }
    }
    componentDidMount() {
        this.setState({
            age: this.calcAge(this.state.time)
        }, () => {
            setInterval(() => {
                this.setState({
                    age: this.calcAge(this.state.time)
                })
            }, 1000);
        })
    }
    calcAge = time => {
        if(time !== null){
            let a = moment.utc(time, 'YYYY-MM-DD HH:mm:ss')
            let b = moment.utc()
            return this.formatDuration(moment.duration(b.diff(a)))
        }
        return ''
    }

    formatDuration = duration => {
        let year = duration.years()?(duration.years()*525600):'';
        let month = duration.months()?(duration.months()*43800):'';
        let day = duration.days()?(duration.days()*1440):'';
        let hour = duration.hours()?(duration.hours()*60):'';
        
        let minute = duration.minutes()?(duration.minutes()):'';
        if(year){
            minute = minute + year

        }if(month){
            minute = minute + month
            
        }if(day){
            minute = minute + day
            
        }if(hour){
            minute = minute + hour
        }
        let second = duration.seconds()?(duration.seconds()+'s '):'';
        if(minute){
            return minute + '.min ' + second;
        }else{
            return second;
        }
    }

    render(){
        return(
        <span>{this.state.age} {this.props.suffix}</span>
        )
    }
}