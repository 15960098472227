import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { TABLE_REQUEST_STATUS, PAYMENT_STATE, APP_FEP } from '../constants'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { isAllowCombineInvoice } from '../core/service/CommonService'
import { MergeInvoice } from './MergeInvoice'

export const OrderConfirmAction = forwardRef ((props, ref) => {
	const trans = props.trans
	const [visible, setVisible] = useState(false)
	const [actionType, setActionType] = useState(null)
	const [content, setContent] = useState(null)

	const [isMergeInvoice, setMergeInvoice] = useState(false)
	const [selectedInvoices, setSelectedInvoices] = useState([])

	useImperativeHandle(ref, () => ({
		open(action, content) {
			setVisible(true)
			setActionType(action)
			setContent(content)
		}
	}))

	const close = () => {
		setVisible(false)
	}

	const confirm = () => {
		props.accept({type: actionType, mergeInvoiceIds: selectedInvoices})
		close()
	}

	const footer = (
		<div>
			<Button label={props.submitText} icon="pi pi-check" disabled={isMergeInvoice && selectedInvoices.length===0} onClick={confirm} />
			<Button label={props.cancelText} icon="pi-md-close" className="p-button-secondary" onClick={close} />
		</div>
	);

	const onMergeInvoiceChange = (e) => {
		switch(e.key){
			case 'isMerge':
				setMergeInvoice(e.value)
				break
			case 'selectedInvoices':
				setSelectedInvoices(e.value)
				break
			default: break
		}
	}

	return (
		<Dialog visible={visible} baseZIndex={1000000} responsive={true} modal={true} dismissableMask={true} onHide={close}
			header={props.header ? props.header : "Confirmation"} footer={footer}>
			<div className="p-grid">
				<div className="p-col-12 p-margin-15-0">
					{content ? content : ''}
				</div>
				{actionType===TABLE_REQUEST_STATUS.check_request && isAllowCombineInvoice() &&
				<div className="p-col-12">
					<MergeInvoice trans={trans} isMergeInvoice={isMergeInvoice} selectedInvoices={selectedInvoices} onchange={(e) => onMergeInvoiceChange(e)}/>
				</div>
				}
			</div>
		</Dialog>
	)
})