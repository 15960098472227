import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import classNames from "classnames";
import { createBrowserHistory } from "history";
import "../ripple.js";
import "../CustomerOrder.scss";
import "../common.css";
import "./TimeOrder.scss"
import { logOut } from "../core/security/auth.jsx";
import PageVisibility from 'react-page-visibility';
import LoadingOverlay from "../core/components/LoadingOverlay.js";
import { MenuOrdering } from "../order/MenuOrdering.js";
import CustomerOrderTopbar from "../core/layout/CustomerOrderTopbar.js";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as notify from "../core/service/NotificationService";
import { showloading, stoploading } from "../core/service/LoadingService";
import { loadPublicConfiguration } from "../service/ConfigurationService";
import * as socketService from '../core/service/CustomerWebEventService';
import { TABLE_REQUEST_STATUS, PAYMENT_METHOD, PAYMENT_OPTION, USE_FOR_PURPOSE, VOUCHER_STATUS, ORDER_ACTIVITY_TYPE, PAYMENT_GATEWAY, PAYMENT_USE_MODE } from "../constants.js";
import { Fieldset } from "primereact/fieldset";
import StripeCheckoutForm from "../core/components/payment/StripeCheckoutForm";
import { Message } from "primereact/message";
import { moneyFormat, getGroupOrderItems, getTableOrderSummary, getCustomerId, isPriceIncludedTaxOrService } from "../core/service/CommonService.js";
import { ConfirmNotification } from '../core/components/ConfirmNotification';
import { OrderItemForm } from "../order/OrderItemForm.js";
import { requestPayOnsiteForTakeAway } from "../service/TakeAwayOrderService.js";
import { getTimeOrderTable, getTimeOrderTimes, completeOrderPaymentForTimeOrder, getDateForTimeOrder, getUnitRacialOrderTimes } from "../service/TimeOrderService.js";
import { removeOrderItems, applyPromotionCoupon, cancelPromotionCoupon, applyVoucherToOrder, confirmVoucherToOrder, processInvoiceForOrder } from "../service/OrderService.js";
import { getOnlinePaymentGateway } from "../service/PaymentService.js";
import SquareCheckoutForm from "../core/components/payment/SquareCheckoutForm.js";
import { parseMessage } from "../core/utils/TranslationUtils.js";
import PayPalCheckoutForm from "../core/components/payment/PayPalCheckoutForm.js";
import MidtransCheckoutForm from "../core/components/payment/MidtransCheckoutForm.js";
import { PinAccess } from "../order/PinAccess.js";
import { getMenuData } from "../service/MenuService.js";
import { getAppliedVoucherByInvoice } from "../service/VoucherService.js";
import { saveNotification } from "../service/OrderActivityService";
import moment from 'moment';
import { PickupForm } from "../PickupForm.js";

class TimeOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resConfig: {},
            topbarHeight: 0,
            layoutMode: "static",
            mobileMenuActive: null,
            isRequirePinCode: false,
            isValidPinCode: true,
            table: {
                name: null
            },
            tableSize: 0,
            menuData: null,
            order: {
                items: []
            },
            groupOrderItems: [],
            orderSummary: {},
            language: 'en-US',
            frmCartVisible: false,
            frmOrderItemVisible: false,
            cFlagImage: '',
            isPayment: false,
            paymentOption: '',
            paymentMethod: '',
            activePayment: {},
            refToken: '',
            cusName: '',
            cusPhone: '',
            defaultCountry: 'us',
            errors: {},
            snapResult: {},
            couponCode: null,
            invoice: null,
            voucherCode: null,
            isVoucherConfirm: false,
            appliedVoucher: null,
            orderTime: '',
            deliveryOption: "timeOrder",
            timeList: [],
            timeSelect: '',
            pickupForm: false,
            unlimitUnit: false,
            reachUnitLimit: false,
            unit: null
        };

        this.notificationCount = 0;

        this._history = createBrowserHistory();
        this.unlisten = this._history.listen((location, action) => { });
    }

    async componentDidMount() {
        const config = await loadPublicConfiguration();

        this.setState({
            defaultCountry: localStorage.getItem("countryCode"),
            cusPhoneCode: localStorage.getItem("countryCode")
        });

        this.getTableDetails();

        // this.getTableDetails((tCode) => {
        //   socketService.initConnection(tCode);
        // });

        this.getActivePayment((res) => {
            console.log(res);
            if(res.gateway === PAYMENT_GATEWAY.SQUARE) {
                let squareJs = document.createElement('script');
                squareJs.src = res.useMode === PAYMENT_USE_MODE.PRODUCTION ? 'https://web.squarecdn.com/v1/square.js' : 'https://sandbox.web.squarecdn.com/v1/square.js';
                squareJs.id = 'squarev1';
                squareJs.async = true;
                document.body.appendChild(squareJs);
            }
        });

        const { i18n } = this.props;
        let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

        i18n.changeLanguage(locale);
        this.setState({ language: locale });

        const resConfig = config.data;

        let paymentOption = '';
        let paymentMethod = '';

        if (resConfig.useOnlinePayment && resConfig.useOnSitePayment) {
            paymentOption = PAYMENT_OPTION.Online;
            paymentMethod = PAYMENT_METHOD.online;
        } else if (resConfig.useOnlinePayment || resConfig.useOnSitePayment) {
            if (resConfig.useOnlinePayment) {
                paymentOption = PAYMENT_OPTION.Online;
                paymentMethod = PAYMENT_METHOD.online;
            } else {
                paymentOption = PAYMENT_OPTION.Onsite;
                paymentMethod = resConfig.onSiteMethods.length > 0 ? resConfig.onSiteMethods[0] : '';
            }
        }
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1)
        this.setState({
            resConfig: resConfig,
            paymentOption: paymentOption,
            paymentMethod: paymentMethod,
            minDate: yesterday
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    onFocus = () => {
        if (!socketService.connected()) {
            window.location.reload();
        }
    }

    countTotalQty = (order) => {
        let qty = 0
        order.items && order.items.length > 0 && order.items.map((item) => {
            qty += item.quantity
        })
        return qty
    }

    hadleVisibilityChagne = isVisible => {
        if (isVisible) {
            if (!socketService.connected()) {
                window.location.reload();
            }
        }
    }

    handleLogout = (e, redirectTo = "/login") => {
        e.preventDefault();
        logOut();
        this._history.push(redirectTo);
    };

    getTableDetails = (pinCode) => {
        this.getOrder(pinCode);
    };

    getActivePayment = (cb) => {
        getOnlinePaymentGateway()
            .then(res => {
                this.setState({ activePayment: res?res:{} }, () => cb&&cb(res));
            });
    }

    getOrder = (pinCode) => {
        showloading();
        getTimeOrderTable(getCustomerId(), pinCode)
            .then((res) => {
                if (res) {
                    if (res.resTable) {
                        this.setState({ isRequirePinCode: false });
                        
                        const table = res.resTable;
                        const order = table.draftOrder;
                        if (!this.props.match.params.orderNumber) {
                            let href = localStorage.getItem('resApplicationUrl') + 'delivery/time-order/' + res.resTable.orderNumber;
                            window.location.replace(href);
                            return
                        }
                        showloading()
                        if (!this.state.menuData) {
                            getMenuData(table.id, true).then(menuData => {
                                this.setState({ menuData: menuData });

                                this.menuOrdering.popularMenuData(menuData);

                                this.setState({
                                    table: table,
                                    order: order,
                                    groupOrderItems: order.items.length > 0 ? getGroupOrderItems(order.items, menuData.categories, true) : [],
                                    orderSummary: getTableOrderSummary([order]),
                                    cusName: table.customer && table.customer.customerName ? table.customer.customerName : '',
                                    cusPhone: table.customer && table.customer.customerPhone ? table.customer.customerPhone : ''
                                });
                            })
                        } else {
                            this.setState({
                                table: table,
                                order: order,
                                groupOrderItems: order.items.length > 0 ? getGroupOrderItems(order.items, this.state.menuData.categories, true) : [],
                                orderSummary: getTableOrderSummary([order]),
                                cusName: table.customer && table.customer.customerName ? table.customer.customerName : '',
                                cusPhone: table.customer && table.customer.customerPhone ? table.customer.customerPhone : ''
                            });
                        }

                        if (table.customer && table.customer.customerId && table.customer.customerId !== getCustomerId()) {
                            localStorage.setItem("customerId", table.customer.customerId);
                            // localStorage.setItem("customerColor", res.customer.customerColor);
                        }
                    } else {
                        this.setState({
                            isRequirePinCode: res.isRequirePinCode,
                            isValidPinCode: res.isValidPinCode !== undefined ? res.isValidPinCode : true
                        });
                    }
                    if(this.state.orderTime === '' || this.state.timeSelect === ''){
                        let orderId = res.resTable.draftOrder.id;
                        getDateForTimeOrder(res.resTable.draftOrder.id).then(res => {
                            if(res.pickUpDate !== null && res.pickUpDate !== undefined){
                                var today = new Date();
                                today.setTime(0);
                                var currenDate = new Date(res.pickUpDate)
                                currenDate.setTime(0)
                                if(today > currenDate){
                                    this.pickupForm.action(null, null, res.table.id, res.draftOrderId);
                                }else{
                                    let date = new Date(res.pickUpDate);
                                    this.setState({
                                        orderTime: moment(res.pickUpDate).format('yyyy-MM-DD hh:mm A'),
                                        orderTimeVal: res.pickUpDate,
                                        timeSelect: res.pickUpTime
                                    })
                                }
                            }else{
                                this.onPickupTime(res.draftOrderId);
                            }
                        })
                    }
                } else {
                    this.props.history.push(`/404`);
                }
            }).finally(() => stoploading())
    }

    updateDraftOrder = (data) => {
        this.getUnitRacial();
        this.setState({
            order: data,
            groupOrderItems: data.items.length > 0 ? getGroupOrderItems(data.items, this.state.menuData.categories, true) : [],
            orderSummary: getTableOrderSummary([data])
        }, () => {
            if (data.items.length === 0) {
                this.setState({ frmCartVisible: false });
            }
        });
    }

    changeLanguage = (e) => {
        this.setState({ language: e });
        const { i18n } = this.props;
        i18n.changeLanguage(e);
        localStorage.setItem('selectedLocale', e);
    };

    onRemoveCartItems = (items) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        let removeData = items.map(i => (i.id));

        showloading();
        removeOrderItems(removeData, true, localStorage.getItem("customerId"), this.state.order.id)
            .then(res => {
                if (!res.errorCode) {
                    this.updateDraftOrder(res.order);
                    notify.showSuccessNotify(trans('res.menu.updated_food_order'));
                    this.getUnitRacial();
                } else {
                    notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
                }
            })
            .finally(() => {
                stoploading();
            });
    }

    getTotalCartItemCount = () => {
        let qty = 0;

        this.state.order.items.map((item) => {
            qty += item.quantity;
        });

        return qty;
    };

    askForConfirmAction = (type) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        let content = '';

        switch (type) {
            case TABLE_REQUEST_STATUS.confirm_request:
                content = trans('res.menu.place_order_confirmation_content');
                break;
            default:
                break;
        }

        this.confirmDialog.confirm(type, content);
    }

    validationCustomerInput = () => {
        let errors = {};
        if (!this.state.cusName)
            errors.cusName = { code: 2030 };
        if (!this.state.cusPhone)
            errors.cusPhone = { code: 2030 };

        return errors;
    }

    processControlError = (errors) => {
        this.setState({ errors: errors });

        const firstErr = Object.keys(errors)[0];
        switch (firstErr) {
            case 'cusName':
                this.txtCusName.element.focus();
                break
            case 'cusPhone':
                this.txtCusPhone.element.focus();
                break;
            default:
                break;
        }
    }

    requestPayOnsite = () => {
        // const errors = this.validationCustomerInput();
        // if(Object.keys(errors).length>0){
        //   this.processControlError(errors);
        //   return;
        // }

        this.setState({ btnPayOnsiteDisable: true });
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        requestPayOnsiteForTakeAway(this.state.order.id, this.state.table.orderNumber, this.state.paymentMethod, localStorage.getItem("customerId"), this.state.cusName, this.state.cusPhone)
            .then((res) => {
                if (!res.errorCode) {
                    this.props.history.push(`/delivery/time-order/inform`);
                } else {
                    notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
                }
            })
            .finally(() =>
                this.setState({ btnPayOnsiteDisable: false })
            );
    }

    handleCardSuccess = (refToken) => {
        if (refToken) {
            this.setState({
                refToken: refToken,
                paymentMethod: PAYMENT_METHOD.online,
            }, () => {
                this.handleCompletePayment();
            });
        }
    }

    handleCompletePayment = () => {
        showloading();
        completeOrderPaymentForTimeOrder(this.state.order.id, this.state.table.orderNumber, this.state.paymentMethod, this.state.activePayment.gateway, this.state.refToken, localStorage.getItem("customerId"), this.state.cusName, this.state.cusPhone, this.state.invoice ? this.state.invoice.id : null, null, this.state.timeSelect, this.state.orderTime)
            .then(res => {
                if (!res.errorCode) {
                    this.props.history.push(`/delivery/time-order/inform`);
                } else {
                    notify.showErrorNotify(res.errorMessage);
                }
            })
            .finally(stoploading());
    }

    onCloseCartDialog = () => {
        this.setState({ frmCartVisible: false });
    };

    buttonActionItems = (order) => {
        let tempButton = {};
        order.items && order.items.map(item => {
            tempButton = { ...tempButton, [item.id]: { btnUpdateDisable: false, btnRemoveDisable: false } }
        })
        return tempButton;
    }

    isBlockOrdering = () => {
        return false;
    }

    onPaymentOptionChange = (e) => {
        const paymentOption = e.value;
        let paymentMethod = '';
        const resConfig = this.state.resConfig;

        if (paymentOption === PAYMENT_OPTION.Online) {
            paymentMethod = PAYMENT_METHOD.online;
        } else {
            paymentMethod = resConfig.onSiteMethods.length > 0 ? resConfig.onSiteMethods[0] : '';
        }

        this.setState({
            paymentOption: paymentOption,
            paymentMethod: paymentMethod
        });
    }

    onDeliveryTypeChange = (e) => {
        const deliveryOption = e.value;

        this.setState({
            deliveryOption: deliveryOption
        });
    }

    onCartClick = () => {
        this.getUnitRacial();
        this.setState({ frmCartVisible: true });
    }

    getUnitRacial = () => {
        let date = moment(this.state.orderTime).format('yyyy-MM-DD HH:mm:ss');
        getUnitRacialOrderTimes(date, this.state.table.id, this.state.order.id).then(res => {
            if(res === "unlimited"){
                this.setState({
                    unlimitUnit: true
                })
            }else{
                if(res) {
                    let reachLimited = res.unitLimit - res.currentTotalUnit;
                    this.setState({
                        unit: res,
                        reachUnitLimit: reachLimited < 0 ? true : false,
                        unlimitUnit: false
                    })
                }
            }
        })
    }

    renderLimit = () => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        let racial = this.state.unit;
        if(this.state.unlimitUnit || racial === null){
            return('');
        }
        if(this.state.reachUnitLimit){
            return(
                <div className="p-col-12 p-md-6 p-form-error">
                    <label>{`${trans('res.menu.available_dishes_to_order')}: ${racial.currentTotalUnit}/${racial.unitLimit}`}</label>
                </div>
            );
        }else{
            return(
                <div className="p-col-12 p-md-6">
                    <label>{`${trans('res.menu.available_dishes_to_order')}: ${racial.currentTotalUnit}/${racial.unitLimit}`}</label>
                </div>
            );
        }
    }

    onStartTimeChange = (e) => {
        let value = e.value;
        let date = moment(value).format('yyyy-MM-DD');
        let list = [];
        this.setState({
            orderTime: e.value
        })
        getTimeOrderTimes(date, this.state.table.id).then(res => {
            if (res) {
                res.map((i) => {
                    let time = i.s + " - " + i.e;
                    list.push(time);
                })
                this.setState({
                    timeList: list ? list : []
                })
            }
        })
    }

    onChageTimeSelect = (e) => {
        this.setState(
            {
                timeSelect: e.value
            }
        );
    }


    renderOrderItemView = (orderData, isDraftOrder, trans, defaultLocale, selectedLocale) => {
        return (
            <React.Fragment>
                {/* {isDraftOrder?
    <h3 className="p-margin-bottom-5">{trans('res.menu.current_order')}</h3>
    :
    <h3 className="p-margin-bottom-5 p-margin-top-30">{trans('res.menu.order_history')}</h3>
    } */}

                <div className="p-hr p-margin-bottom-10" />

                <div className="p-grid">
                    <div className={isDraftOrder ? "p-col-2" : "p-col-1"}>{!isDraftOrder && '#'}</div>
                    <div className={(isDraftOrder ? "p-col-5" : "p-col-6") + " p-w-bold"}>{trans('res.mgt.food')}</div>
                    <div className="p-col-1 p-w-bold p-r">{trans('res.mgt.qty')}</div>
                    <div className="p-col-4 p-w-bold p-r">{trans('res.mgt.total')}</div>
                </div>

                <div className="p-hr p-padding-top-0 p-margin-bottom-10" />

                {isDraftOrder && orderData.length === 0 &&
                    <div className="p-grid">
                        <div className="p-col-12">{trans('res.menu.no_food_selected')}</div>
                    </div>
                }

                {(() => {
                    let num = 0;

                    return (
                        <React.Fragment>
                            {orderData.map((v, i) => {
                                return (
                                    <React.Fragment key={"cate_" + i}>
                                        <div className="order-item-view">
                                            {(v.products.length > 0 || (!isDraftOrder && v.adjustItems && v.adjustItems.length > 0)) &&
                                                <h3 className="cate-title">{v.cateName ? v.cateNames[selectedLocale] ? v.cateNames[selectedLocale] : v.cateNames[defaultLocale] ? v.cateNames[defaultLocale] : v.cateName : trans('res.menu.cate_other')}</h3>
                                            }

                                            {v.products.length > 0 && v.products.map((p, pIdx) => {
                                                num += 1;

                                                return (
                                                    <React.Fragment key={"prod_" + pIdx}>
                                                        {(() => {
                                                            if (p.items.length === 1) {
                                                                const item = p.items[0];

                                                                return (
                                                                    <div className="p-grid p-vertical-c">
                                                                        {isDraftOrder ?
                                                                            <div className="p-col-2 p-nowrap">
                                                                                <Button icon="pi-md-edit" className="p-button-info btn-xxs" onClick={() => { this.frmOrderItems.openForm(item.orderItems); this.setState({ frmOrderItemVisible: true }) }} />
                                                                                <Button icon="pi-md-close" className="p-button-danger btn-xxs" onClick={() => this.onRemoveCartItems(item.orderItems)} />
                                                                            </div>
                                                                            :
                                                                            <div className="p-col-1">#{num}</div>
                                                                        }

                                                                        <div className={isDraftOrder ? "p-col-5" : "p-col-6"}>
                                                                            <div>{Object.keys(p.names).length > 0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}</div>
                                                                            {item.itemOptionValueTrans.length > 0 &&
                                                                                <div className="p-size-11 p-margin-top-5">
                                                                                    - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ? opt.names[selectedLocale] : opt.names[defaultLocale])).join(', ')}
                                                                                </div>
                                                                            }
                                                                            {item.note && <div className="p-size-11 p-margin-top-5">- {item.note}</div>}
                                                                        </div>
                                                                        <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="p-grid">
                                                                            <div className={isDraftOrder ? "p-col-2" : "p-col-1"}>{!isDraftOrder && "#" + num}</div>
                                                                            <div className={(isDraftOrder ? "p-col-10" : "p-col-11") + " p-margin-bottom-5"}>
                                                                                {Object.keys(p.names).length > 0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}
                                                                            </div>
                                                                        </div>

                                                                        {p.items.map((item, idx) => {
                                                                            return (
                                                                                <div key={i + "_" + idx + "_" + item.id} className="p-grid p-vertical-c">
                                                                                    {isDraftOrder ?
                                                                                        <div className="p-col-2 p-nowrap">
                                                                                            <Button icon="pi-md-edit" className="p-button-info btn-xxs" onClick={() => { this.frmOrderItems.openForm(item.orderItems); this.setState({ frmOrderItemVisible: true }) }} />
                                                                                            <Button icon="pi-md-close" className="p-button-danger btn-xxs" onClick={() => this.onRemoveCartItems(item.orderItems)} />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="p-col-1">#{num}</div>
                                                                                    }
                                                                                    <div className={isDraftOrder ? "p-col-5" : "p-col-6"} style={{ borderBottom: idx < p.items.length - 1 ? '1px solid #d8d8d8' : '' }}>
                                                                                        {item.itemOptionValueTrans.length === 0 && !item.note ?
                                                                                            <div className="p-size-11">{trans('res.mgt.food_select_standard')}</div>
                                                                                            :
                                                                                            <>
                                                                                                {item.itemOptionValueTrans.length > 0 &&
                                                                                                    <div className="p-size-11 p-margin-top-5">
                                                                                                        - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale] ? opt.names[selectedLocale] : opt.names[defaultLocale])).join(', ')}
                                                                                                    </div>
                                                                                                }
                                                                                                {item.note && <div className={"p-size-11 " + (item.productVariantName || item.productVariantNames ? "p-margin-top-5" : "")}>- {item.note}</div>}
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                                                                    <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        })()}

                                                        {p.adjustments.length > 0 && p.adjustments.map(adjust => {
                                                            return (
                                                                <div className="p-grid p-vertical-c p-size-12" style={{ marginTop: '-1em' }}>
                                                                    <div className="p-col-2"></div>
                                                                    <div className="p-col-5 p-fs-italic">
                                                                        {adjust.trans ? adjust.trans.nameDisplay[selectedLocale] ? adjust.trans.nameDisplay[selectedLocale] : adjust.trans.nameDisplay[defaultLocale] : adjust.rule.nameDisplay} {adjust.coupon ? ' (' + adjust.coupon.code + ')' : ''}
                                                                    </div>
                                                                    <div className="p-col-2 p-r"></div>
                                                                    <div className="p-col-3 p-r">
                                                                        {moneyFormat(adjust.amount)}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}

                                            {!isDraftOrder && v.adjustItems && v.adjustItems.length > 0 && v.adjustItems.map(item => {
                                                num += 1;

                                                return (
                                                    <div className="p-grid p-vertical-c">
                                                        <div className="p-col-1">#{num}</div>
                                                        <div className="p-col-6">
                                                            <div>{item.productName}</div>
                                                        </div>
                                                        <div className="p-col-1 p-r"><span>{item.quantity}</span></div>
                                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                            <div className="p-hr p-padding-top-0 p-margin-bottom-10 "></div>
                        </React.Fragment>
                    )
                })()}
            </React.Fragment>
        )
    }

    buildPhoneObj = (status, value, countryData) => {
        let phoneValue = "";
        if (value !== "") {
            if (value.indexOf("+" + countryData.dialCode) !== -1) {
                phoneValue = value.substr(("+" + countryData.dialCode).length);
            } else {
                phoneValue = value;
            }
        }
        let phoneObj = {
            phoneNumber: phoneValue,
            dialCode: countryData.dialCode,
            countryCode: countryData.iso2.toUpperCase(),
            phoneValid: status
        }

        return phoneObj;
    }

    onChangePhone = (status, value, countryData) => {
        let phoneObj = this.buildPhoneObj(status, value, countryData);

        this.setState({
            cusPhone: phoneObj.phoneNumber ? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber : '',
            cusPhoneValue: phoneObj.phoneNumber,
            cusPhoneCode: phoneObj.countryCode,
        });
    }

    applyPromotionCoupon = (trans) => {
        showloading();
        applyPromotionCoupon(this.state.order.id, this.state.order.orderNumber, this.state.couponCode)
            .then(res => {
                if (!res.errorCode) {
                    this.updateDraftOrder(res);
                    notify.showSuccessNotify(trans('res.menu.coupon_applied'));
                } else {
                    if (res.errorCode === 400)
                        this.setState({ errors: res.errorObj })
                    notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
                }
            })
            .finally(() => {
                stoploading();
            });
    }

    cancelPromotionCoupon = (trans, couponCode) => {
        showloading();
        cancelPromotionCoupon(this.state.order.id, this.state.order.orderNumber, couponCode)
            .then(res => {
                if (!res.errorCode) {
                    this.updateDraftOrder(res);
                    this.setState({ couponCode: '' });
                    notify.showSuccessNotify(trans('res.menu.coupon_canceled'));
                } else {
                    notify.showErrorNotify(trans('res.menu.errors.invalid_coupon'));
                }
            })
            .finally(() => {
                stoploading();
            });
    }

    onConfirmAndPay = () => {
        processInvoiceForOrder(this.state.table.orderNumber, localStorage.getItem("customerId"))
        .then(res => {
            this.setState({
                isPayment: true,
                invoice: res
            }, () => {
                this.getAppliedVoucher(res.id);
                saveNotification(this.state.table.orderNumber, ORDER_ACTIVITY_TYPE.orderPlaced, "");
                // if(this.state.activePayment.gateway === PAYMENT_GATEWAY.SQUARE && this.state.activePayment.settings.allowUsingSquareOnline) {
                //     createSquareOrder(this.state.table.orderNumber)
                // }
            });
        });
    }

    applyVoucher = (trans) => {
        applyVoucherToOrder(this.state.voucherCode)
            .then(res => {
                if (!res.errorCode) {
                    this.getTableDetails();
                    this.setState({
                        appliedVoucher: res,
                        isVoucherConfirm: true
                    });
                    notify.showSuccessNotify(trans('res.menu.voucher_verified'));
                } else {
                    if (res.errorCode === 409)
                        notify.showErrorNotify(trans('res.menu.voucher_used'));
                    else
                        notify.showErrorNotify(trans('res.menu.errors.invalid_voucher'));
                }
            });
    }

    confirmVoucher = (trans) => {
        confirmVoucherToOrder(this.state.voucherCode, this.state.invoice.id, this.state.order.orderNumber)
        .then(res => {
            if (!res.errorCode) {
                if (this.state.orderSummary.orderTotal <= res.value) {
                    this.props.history.push(`/delivery/time-order/inform`);
                } else {
                    this.setState({
                        isVoucherConfirm: false,
                        voucherCode: null,
                        appliedVoucher: res
                    });
                }

                notify.showSuccessNotify(trans('res.menu.voucher_applied'));
            } else {
                notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
            }
        });
    }

    getAppliedVoucher = (invoiceId) => {
        getAppliedVoucherByInvoice(invoiceId)
            .then(res => {
                if (res) {
                    this.setState({
                        appliedVoucher: res
                    });
                }
            })
    }

    calculateTotalWhenVoucherApplied = (total, voucherValue) => {
        if (total <= voucherValue) {
            return 0;
        } else {
            return total - voucherValue;
        }
    }
    onPickupTime = (orderId) => {
        this.pickupForm.action(this.state.orderTime, this.state.timeSelect, this.state.table.id, orderId)
    }

    onSavePickup = (date, time, currentTotalUnit, totalLimit) => {
        this.setState({
            orderTime: moment(date).format('yyyy-MM-DD hh:mm A'),
            orderTimeVal: date,
            timeSelect: time,
            currentTotalUnit: currentTotalUnit,
            totalLimit: totalLimit
        }, () => {
            this.getUnitRacial();
        })
    }

    render() {
        let layoutPadding = (this.state.topbarHeight + 'px 15px 0px 15px');

        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        let wrapperClass = classNames("layout-wrapper", {
            "layout-wrapper-static": this.state.layoutMode === "static",
            "layout-wrapper-active": this.state.mobileMenuActive,
            "layout-menu-horizontal": this.state.layoutMode === "horizontal",
        });

        const currency = localStorage.getItem('currencySymbol');
        const selectedLocale = localStorage.getItem('selectedLocale');
        const defaultLocale = localStorage.getItem('defaultLocale');
        const currencyCode = localStorage.getItem('currency');

        const resConfig = localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {};
        const blockOrdering = this.isBlockOrdering();

        return (
            <PageVisibility onChange={this.hadleVisibilityChagne}>
                <div className={wrapperClass}>
                    <ConfirmNotification ref={(el) => this.confirmDialog = el}
                        header={trans('res.menu.confirmation')}
                        submitText={trans('res.menu.submit_confirm')}
                        cancelText={trans('res.menu.cancel')}
                        submitConfirm={(e) => e === TABLE_REQUEST_STATUS.confirm_request ? this.setState({ isPayment: true }) : ''}
                    />

                    <OrderItemForm ref={(el) => this.frmOrderItems = el}
                        visible={this.state.frmOrderItemVisible} currency={currency} isCustomer={true}
                        trans={trans} useFor={USE_FOR_PURPOSE.res_time_order}
                        updateCartOrder={(data, isAdd, isUpdate, isRemove) => this.updateDraftOrder(data, isAdd, isUpdate, isRemove)}
                        onHide={() => this.setState({ frmOrderItemVisible: false })} />
                    <PickupForm ref={(el) => this.pickupForm = el}
                        trans={trans} updateDateTime={(date, time, currentTotalUnit, totalLimit) => this.onSavePickup(date, time, currentTotalUnit, totalLimit)}
                        onHide={() => this.setState({ pickupForm: false })} />
                    <Sidebar visible={this.state.frmCartVisible} position="right" style={{ overflowY: "auto", paddingLeft: '7.5px', paddingRight: '7.5px' }} className="p-sidebar-lg customer"
                                blockScroll={true} baseZIndex={1000000} onHide={(e) => this.onCloseCartDialog()}
                            >
                        <h2>{trans('res.mgt.time_order')}</h2>
                        <Fieldset legend={trans('res.menu.date_time')} className="p-fieldset-sm" style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                            <div>
                                <div className="p-col-12 p-md-6">
                                    <label>{trans('res.mgt.date')}: {this.state.orderTime?moment(this.state.orderTimeVal).format("yyyy-MM-DD"):''}</label>
                                    {/* <MaskedCalendar value={ new Date (this.state.orderTime)} minDate={this.state.minDate} showIcon={true} style={{ paddingLeft: "1%" }} onChange={this.onStartTimeChange} hourFormat="12" />
                                    <div className="p-form-error">{this.state.errors.start}</div> */}
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label>{trans('res.menu.pick_up_at')}: {this.state.orderTime?moment(this.state.orderTimeVal).format("hh:mm A"):''}</label>
                                </div>
                                {this.renderLimit()}
                                {/* {this.state.orderTime !== '' &&
                                    <div className="p-col-12">
                                        <label>{trans('res.mgt.pick_time')}: </label>
                                        {this.state.timeList.length > 0 ?
                                            <Dropdown
                                                className="p-datatable-length-selector p-c"
                                                value={this.state.timeSelect}
                                                options={this.state.timeList}
                                                onChange={e => this.onChageTimeSelect(e)}
                                                style={{ width: "20%", paddingLeft: "1%" }}
                                            /> :
                                            <label>{trans('res.mgt.not_available')}</label>
                                        }
                                    </div>
                                } */}
                            </div>
                            <div className="p-col-12 p-r">
                                <Button label={trans('res.mgt.change')} style={{width: "inherit"}} onClick={() =>this.onPickupTime(this.state.order.id)} />
                            </div>
                        </Fieldset>
                        {this.state.order && this.state.order.items.length > 0 &&
                            <React.Fragment>
                                <div className="p-grid">
                                    <div className="p-col-12" style={{ paddingTop: "3%" }}>
                                        {this.state.isPayment &&
                                            // ?
                                            // <Button label={trans('res.menu.confirm_and_pay')} icon="pi pi-check" disabled={this.state.timeSelect === ''} className="btn-sm p-margin-right-15" onClick={() => this.onConfirmAndPay()} />
                                            // :
                                            <React.Fragment>
                                                {this.state.order && this.state.order.items.length > 0 &&
                                                    <div className="p-margin-bottom-10" style={{ border: '1px solid #d8d8d8', borderRadius: '3px', backgroundColor: '#d8d8d8', boxShadow: '0px 5px 8px #888888' }}>
                                                        <div className="p-grid p-margin-none">
                                                            <div className="p-col-7 p-w-bold p-size-16">{trans('res.menu.order_total')}:</div>
                                                            <div className="p-col-5 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                                                        </div>
                                                    </div>
                                                }

                                                {resConfig.allowUsingVoucher && this.state.invoice &&
                                                    <Fieldset legend={trans('res.menu.voucher_section')} className="p-fieldset-sm" style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                                        <div className="p-grid">
                                                            {this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ?
                                                                <div className="p-col-12 p-c">
                                                                    <Message severity="info" text={trans('res.menu.voucher_applied')} />
                                                                </div>
                                                                :
                                                                <React.Fragment>
                                                                    <div className="p-col-12 p-md-6">
                                                                        <div className="p-inputgroup p-fluid">
                                                                            <span className="p-inputgroup-addon">{trans('res.menu.enter_voucher')}: </span>
                                                                            <InputText value={this.state.voucherCode} onChange={(e) => this.setState({ voucherCode: e.target.value })} />
                                                                            <span className="p-inputgroup-addon">
                                                                                {this.state.isVoucherConfirm ?
                                                                                    <Button icon="pi-md-check" label={trans('res.menu.voucher_confirm')} onClick={() => this.confirmVoucher(trans)} style={{ width: 'auto' }} />
                                                                                    :
                                                                                    <Button disabled={this.state.voucherCode ? false : true} label={trans('res.menu.apply_voucher')} onClick={() => this.applyVoucher(trans)} style={{ width: 'auto' }} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.appliedVoucher &&
                                                                        <div className="p-col-12 p-md-6 p-l">
                                                                            <div>{trans('res.menu.voucher_value')}: <strong>{moneyFormat(this.state.appliedVoucher.value)}</strong></div>
                                                                            <div>{trans('res.menu.remain_total')}: <strong>{moneyFormat(this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value))}
                                                                            </strong>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </Fieldset>
                                                }
                                                {this.state.reachUnitLimit ? 
                                                    <div className="p-col-12  p-form-error">
                                                        <label>{trans('res.menu.reach_limit')}</label>
                                                    </div>:
                                                    <Fieldset legend={trans('res.menu.payment')} style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                                        <div className="p-grid">
                                                            {(() => {
                                                                if (!resConfig.useOnSitePayment && !resConfig.useOnlinePayment) {
                                                                    return (
                                                                        <div className="p-col-12 p-c">
                                                                            <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <div className="p-col-12 p-md-6">
                                                                                <label className="p-label">{trans('res.menu.your_name')}</label>
                                                                                <InputText ref={(el) => this.txtCusName = el} value={this.state.cusName} onChange={(e) => this.setState({ cusName: e.target.value })} style={{ width: '100%' }} />
                                                                                <div className="p-form-error">{this.state.errors.cusName && parseMessage(trans, this.state.errors.cusName.code)}</div>
                                                                            </div>
                                                                            <div className="p-col-12 p-md-6">
                                                                                <label className="p-label">{trans('res.menu.your_phone')}</label>
                                                                                <InputText ref={(el) => this.txtCusPhone = el} value={this.state.cusPhone} keyfilter="int" onChange={(e) => this.setState({ cusPhone: e.target.value })} style={{ width: "100%" }} />
                                                                                <div className="p-form-error">{this.state.errors.cusPhone && parseMessage(trans, this.state.errors.cusPhone.code)}</div>
                                                                                {/* <IntlTelInput autoPlaceholder={false} format={true} value={this.state.cusPhone} onPhoneNumberChange={(s,v,c,n) => this.onChangePhone(s,v,c,'cellPhone')} onSelectFlag={(v,c,n,s) => this.onChangePhone(s,v,c,'cellPhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{width: "100%"}}/> */}
                                                                                {/* <IntlTelInput autoPlaceholder={true} defaultCountry={this.state.defaultCountry} preferredCountries={[]} 
                                                                                    onPhoneNumberChange={(s,v,c,n) => this.onChangePhone(s,v,c)} onSelectFlag={(v,c,n,s) => this.onChangePhone(s,v,c)}
                                                                                    format={true} value={this.state.cusPhoneValue} css={['intl-tel-input', 'p-inputtext  p-component']} style={{width: "100%"}}/> */}
                                                                                                                        </div>
                                                                                                                        {/* {resConfig.useOnSitePayment && resConfig.useOnlinePayment &&
                                                                                    <div className="p-col-12 p-margin-bottom-10 p-margin-top-10">
                                                                                    <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_option')}:</label>
                                                                                    <RadioButton inputId="rbPayOption0" name="paymentOption" value={PAYMENT_OPTION.Onsite} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Onsite} />
                                                                                    <label htmlFor="rbPayOption0" className="p-radiobutton-label p-margin-right-20">{trans('res.menu.pay_onsite')}</label>
                                                                                    <RadioButton inputId="rbPayOption1" name="paymentOption" value={PAYMENT_OPTION.Online} onChange={(e) => this.onPaymentOptionChange(e)} checked={this.state.paymentOption === PAYMENT_OPTION.Online} />
                                                                                    <label htmlFor="rbPayOption1" className="p-radiobutton-label">{trans('res.menu.pay_online')}</label>
                                                                                    </div>
                                                                                } */}

                                                                            {(() => {
                                                                                if (this.state.paymentOption === PAYMENT_OPTION.Online && !this.state.activePayment) {
                                                                                    return (
                                                                                        <div className="p-col-12 p-c">
                                                                                            <Message severity="warn" text={trans('res.menu.unable_making_payment')} />
                                                                                        </div>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                            {/* {(()=>{
                                                                                    if(this.state.paymentOption===PAYMENT_OPTION.Onsite){
                                                                                    if(resConfig.onSiteMethods.length > 1){
                                                                                        return (
                                                                                        <div className="p-col-12">
                                                                                            <label className="p-label p-margin-bottom-10">{trans('res.menu.choice_payment_method')}:</label>
                                                                                            {resConfig.onSiteMethods.map((method, mIdx) => {
                                                                                            return (
                                                                                                <React.Fragment>
                                                                                                <RadioButton inputId={"rbPayMethod"+mIdx} name="paymentMethod" value={method} onChange={(e) => this.setState({ paymentMethod: e.value })} checked={this.state.paymentMethod === method} />
                                                                                                <label htmlFor={"rbPayMethod"+mIdx} className="p-radiobutton-label p-margin-right-20">{trans(PAYMENT_METHODS_OBJECT[method].label)}</label>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                            })}
                                                                                        </div>
                                                                                        )
                                                                                    }else{
                                                                                        return (
                                                                                        <div className="p-col-12">
                                                                                            <label>{trans('res.menu.payment_method')}: <strong>{trans(PAYMENT_METHODS_OBJECT[this.state.paymentMethod].label)}</strong></label>
                                                                                        </div>
                                                                                        )
                                                                                    }
                                                                                    }
                                                                                })()} */}

                                                                                            {/* {((this.state.paymentOption===PAYMENT_OPTION.Onsite && resConfig.useTipInOnSitePayment) || (this.state.paymentOption===PAYMENT_OPTION.Online && resConfig.useTipInOnlinePayment)) &&
                                                                                        <div className="p-col-12">
                                                                                        <Fieldset legend={trans('res.menu.tip')} style={{paddingLeft: '7.5px', paddingRight: '7.5px', width: '100%'}}>
                                                                                            <div className="p-grid p-fluid">
                                                                                            <div className="p-col-7"><label>{trans('res.menu.tip_amount')}:</label></div>
                                                                                            <div className="p-col-5 p-r">
                                                                                                <InputText value={this.state.tipAmount} className="p-r" 
                                                                                                keyfilter="money" maxLength="12"
                                                                                                onFocus={(e) => this.setState({ tipAmount: e.target.value=='0'?'':e.target.value})}
                                                                                                onBlur={(e) => this.setState({ tipAmount: e.target.value?parseFloat(e.target.value).toFixed(2):0})}
                                                                                                onChange={(e) => this.setState({ tipAmount: e.target.value })} style={{width: '100%'}} 
                                                                                                placeholder={trans('res.menu.input_tip_here')}/>
                                                                                            </div>
                                                                                            {resConfig.showTipSuggestion && resConfig.suggestedTipRates.length>0 &&
                                                                                            <div className="p-col-12">
                                                                                                <div><label>{trans('res.menu.suggested_tip')}</label></div>
                                                                                                {resConfig.suggestedTipRates.map(tip => {
                                                                                                return (<div className="p-margin-top-5"><label>{trans('res.menu.tip')} {tip}% :</label><label className="p-r p-margin-left-20">{moneyFormat(((this.state.orderSummary.orderTotal*parseFloat(tip))/100))}</label></div>);
                                                                                                })}
                                                                                            </div>
                                                                                            }
                                                                                            </div>
                                                                                        </Fieldset>
                                                                                        </div>
                                                                                    } */}
                                                                                            <div className="p-col-12">
                                                                                                <Fieldset legend={trans('res.menu.online_payment')} style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                                                                                    <div className="p-grid">
                                                                                                        <div className="p-col-12">
                                                                                                            {this.state.activePayment.gateway === 'STRIPE' &&
                                                                                                                <StripeCheckoutForm
                                                                                                                    trans={trans}
                                                                                                                    onHandleCardSuccess={(e) => this.handleCardSuccess(e.id)}
                                                                                                                    onHandleCancel={() => this.setState({ isPayment: false })}
                                                                                                                    // customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ? this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value) : this.state.orderSummary.orderTotal) + (this.state.tipAmount ? parseFloat(this.state.tipAmount) : 0)}
                                                                                                                    currency={currency}
                                                                                                                    stripeKey={this.state.activePayment.settings.publishableKey}
                                                                                                                />
                                                                                                            }
                                                                                                            {this.state.activePayment.gateway === 'SQUARE' &&
                                                                                                                <SquareCheckoutForm
                                                                                                                    trans={trans}
                                                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ? this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value) : this.state.orderSummary.orderTotal) + (this.state.tipAmount ? parseFloat(this.state.tipAmount) : 0)}
                                                                                                                    currency={currency}
                                                                                                                    onHandleCardSuccess={(nonce) => this.handleCardSuccess({ nonce: nonce })}
                                                                                                                    onHandleCancel={() => this.setState({ isPayment: false })}
                                                                                                                    applicationId={this.state.activePayment.settings.applicationId}
                                                                                                                    locationId={this.state.activePayment.settings.locationId}
                                                                                                                    customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                                                    activePayment={this.state.activePayment}
                                                                                                                />
                                                                                                            }
                                                                                                            {this.state.activePayment.gateway === 'PAYPAL' &&
                                                                                                                <PayPalCheckoutForm
                                                                                                                    trans={trans}
                                                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ? this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value) : this.state.orderSummary.orderTotal) + (this.state.tipAmount ? parseFloat(this.state.tipAmount) : 0)}
                                                                                                                    currencyCode={currencyCode}
                                                                                                                    onHandleCardSuccess={(orderId) => this.handleCardSuccess(orderId)}
                                                                                                                    onHandleCancel={() => this.setState({ isPayment: false })}
                                                                                                                    clientId={this.state.activePayment.settings.clientId}
                                                                                                                    // customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                                                />
                                                                                                            }
                                                                                                            {this.state.activePayment.gateway === 'MIDTRANS' &&
                                                                                                                <MidtransCheckoutForm
                                                                                                                    trans={trans}
                                                                                                                    amount={(this.state.appliedVoucher && this.state.appliedVoucher.status === VOUCHER_STATUS.Used ? this.calculateTotalWhenVoucherApplied(this.state.orderSummary.orderTotal, this.state.appliedVoucher.value) : this.state.orderSummary.orderTotal) + (this.state.tipAmount ? parseFloat(this.state.tipAmount) : 0)}
                                                                                                                    onHandleCancel={() => this.setState({ isPayment: false })}
                                                                                                                    // customerData={{cusName: this.state.cusName, cusPhone: this.state.cusPhone}}
                                                                                                                    onCustomerDataError={(errors) => this.processControlError(errors)}
                                                                                                                    orderNumber={this.state.table.orderNumber}
                                                                                                                    onCloseSidebar={() => this.setState({ frmCartVisible: false })}
                                                                                                                    currency={currency}
                                                                                                                    onHandleCardSuccess={(result) => this.handleCardSuccess(result)}
                                                                                                                    clientKey={this.state.activePayment.settings.clientKey}
                                                                                                                    activePayment={this.state.activePayment}
                                                                                                                    invoiceId={this.state.invoice ? this.state.invoice.id : ''}
                                                                                                                />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Fieldset>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            })()}
                                                        </div>
                                                    </Fieldset>
                                                        
                                                }
                                                
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>

                                {this.renderOrderItemView(this.state.groupOrderItems, true, trans, defaultLocale, selectedLocale)}

                                {this.state.order && this.state.order.items.length > 0 &&
                                    <React.Fragment>
                                        <div className="p-grid p-margin-bottom-10">
                                            <div className="p-col-7 p-w-bold p-size-14">{trans('res.menu.item_total')}</div>
                                            <div className="p-col-1 p-w-bold p-r p-size-14">{this.state.orderSummary.qtyTotal}</div>
                                            <div className="p-col-4 p-w-bold p-r p-size-14">{(!isPriceIncludedTaxOrService()) ? moneyFormat(this.state.orderSummary.subTotal + this.state.orderSummary.itemDiscount) : moneyFormat(this.state.orderSummary.subTotal)}</div>
                                        </div>

                                        {isPriceIncludedTaxOrService() && (this.state.orderSummary.taxTotal > 0 || this.state.orderSummary.serviceTotal > 0) &&
                                            <div className="p-grid">
                                                <div className="p-col-8 p-w-bold">{trans('res.menu.total_without_tax')}</div>
                                                <div className="p-col-4 p-w-bold p-r">{moneyFormat(this.state.orderSummary.subTotalNoTax + this.state.orderSummary.itemDiscount)}</div>
                                            </div>
                                        }

                                        {this.state.orderSummary.orderAdjusts.length > 0 && this.state.orderSummary.orderAdjusts.map(adjust => {
                                            return (
                                                <div className="p-grid">
                                                    <div className="p-col-8">{adjust.label}</div>
                                                    <div className="p-col-4 p-r">{moneyFormat(adjust.amount)}</div>
                                                </div>
                                            )
                                        })}

                                        {this.state.orderSummary.orderDiscounts.length > 0 && this.state.orderSummary.orderDiscounts.map(discount => {
                                            return (
                                                <div className="p-grid">
                                                    <div className="p-col-8">{discount.trans ? discount.trans.nameDisplay[selectedLocale] ? discount.trans.nameDisplay[selectedLocale] : discount.trans.nameDisplay[defaultLocale] : discount.rule.nameDisplay}</div>
                                                    <div className="p-col-4 p-r">{moneyFormat(discount.amount)}</div>
                                                </div>
                                            )
                                        })}

                                        {this.state.orderSummary.serviceTotal > 0 &&
                                            <div className="p-grid">
                                                <div className="p-col-8">{trans('res.menu.service')} ({this.state.orderSummary.serviceRate}%)</div>
                                                <div className="p-col-4 p-r">{moneyFormat(this.state.orderSummary.serviceTotal)}</div>
                                            </div>
                                        }

                                        {this.state.orderSummary.taxs &&
                                            Object.values(this.state.orderSummary.taxs).map((item, idx) => {
                                                return (
                                                    <div className="p-grid">
                                                        <div className="p-col-8">{item.name} ({item.rate}%)</div>
                                                        <div className="p-col-4 p-r">{moneyFormat(item.total)}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {this.state.orderSummary.tipTotal > 0 &&
                                            <div className="p-grid p-margin-top-5">
                                                <div className="p-col-8 p-w-bold">{trans('res.menu.tip')}</div>
                                                <div className="p-col-4 p-w-bold p-r">{moneyFormat(this.state.orderSummary.tipTotal)}</div>
                                            </div>
                                        }

                                        <div className="p-hr" />

                                        <div className="p-grid p-margin-top-5 p-margin-bottom-10">
                                            <div className="p-col-8 p-w-bold p-size-16">{trans('res.menu.order_total')}</div>
                                            <div className="p-col-4 p-w-bold p-r p-size-16">{moneyFormat(this.state.orderSummary.orderTotal)}</div>
                                        </div>

                                        {resConfig.allowUsingCoupon &&
                                            <Fieldset legend={trans('res.menu.coupon_section')} className="p-fieldset-sm" style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>
                                                <div className="p-grid p-fluid">
                                                    {this.state.orderSummary.couponDiscount ?
                                                        <div className="p-col-12">
                                                            <div>
                                                                {trans('res.menu.coupon_applied')}: <strong>{this.state.orderSummary.couponDiscount.coupon.code}</strong> - {this.state.orderSummary.couponDiscount.trans ? this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale] ? this.state.orderSummary.couponDiscount.trans.nameDisplay[selectedLocale] : this.state.orderSummary.couponDiscount.trans.nameDisplay[defaultLocale] : this.state.orderSummary.couponDiscount.rule.nameDisplay}
                                                                <Button className="p-display-inline-block btn-sm p-margin-left-20 p-button-danger" label={trans('res.menu.cancel_coupon')} onClick={(e) => this.cancelPromotionCoupon(trans, this.state.orderSummary.couponDiscount.coupon.code)} style={{ width: 'auto' }} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="p-col-12 p-md-6">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">{trans('res.menu.enter_coupon')}: </span>
                                                                <InputText value={this.state.couponCode} onChange={(e) => this.setState({ couponCode: e.target.value })} />
                                                                <span className="p-inputgroup-addon">
                                                                    <Button className="btn-sm" disabled={this.state.couponCode ? false : true} label={trans('res.menu.apply_coupon')} onClick={(e) => this.applyPromotionCoupon(trans)} style={{ width: 'auto' }} />
                                                                </span>
                                                            </div>
                                                            <div className="p-form-error">{this.state.errors.coupon && parseMessage(trans, this.state.errors.coupon.code)}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </Fieldset>
                                        }
                                        
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Sidebar>

                    <div className="layout-main customer">
                        <CustomerOrderTopbar layoutMode={this.state.layoutMode}
                            config={resConfig ? resConfig : {}}
                            blockOrdering={blockOrdering}
                            useFor={USE_FOR_PURPOSE.res_time_order}
                            cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
                            isOrderPage={true} setHeight={(e) => this.setState({ topbarHeight: e })}
                            table={this.state.table}
                            goToOrderTracking={() => this.props.history.push(`/delivery/time-order/inform`)}
                            onCartClick={(e) => {
                                this.setState({ frmCartVisible: true }, () => {
                                    this.getUnitRacial();
                                    this.onConfirmAndPay()
                                })
                            }} 
                            cartItemCount={this.getTotalCartItemCount()}
                            trans={trans}
                            totalQty={this.countTotalQty(this.state.order)}
                        />
                        <div className="layout-content" id="layout-content-container">
                            <LoadingOverlay />
                            {this.state.isRequirePinCode ?
                                <PinAccess trans={trans}
                                    padding={layoutPadding}
                                    isValid={this.state.isValidPinCode}
                                    access={(e) => this.getTableDetails(e)} />
                                :
                                <MenuOrdering ref={(el) => this.menuOrdering = el} cLang={this.state.language} trans={trans}
                                    config={resConfig ? resConfig : {}}
                                    useFor={USE_FOR_PURPOSE.res_time_order}
                                    blockOrdering={blockOrdering}
                                    table={this.state.table} currency={currency} orderId={this.state.order.id}
                                    padding={layoutPadding}
                                    updateCartOrder={(e) => this.updateDraftOrder(e)}
                                    topbarHeight={this.state.topbarHeight - 30}
                                    orderTime={this.state.orderTime}
                                    timeSelect= {this.state.timeSelect}
                                    changeDateTime={() => this.onPickupTime(this.state.order.id)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </PageVisibility>
        );
    }
}

export default withNamespaces("message")(TimeOrder);
