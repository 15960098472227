import React, { Component } from "react";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { Button } from "primereact/button";

import { showloading, stoploading } from "../core/service/LoadingService";
import { InputNumber } from "../core/components/inputnumber/InputNumber";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import * as notify from "../core/service/NotificationService";
import * as customerSocketService from '../core/service/CustomerWebEventService';
import * as mgtSocketService from '../core/service/ManagementWebEventService';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { moneyFormat } from "../core/service/CommonService";
import { RadioButton } from "primereact/radiobutton";
import { USER_EVENTS, PRODUCT_OPTION_TYPE, USE_FOR_PURPOSE, PRODUCT_VARIANT_TYPE, ORDER_ITEM_STATUS, PRODUCT_CLASSIFICATION } from "../constants";
import { getDish, saveOrderItem, removeOrderItems } from "../service/OrderService";
import { DeleteReasonNotification } from "../core/components/DeleteReasonNotification";
import { parseMessage } from "../core/utils/TranslationUtils";

export class OrderItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isConfirmedOrder: false,
      food: null,
      variantOptions: [],
      propertyOptions: [],
      frmOrderItem: {
        quantity: 1
      },
      isAdd: false,
      btnSaveItemDisabled: false,
      errors: {}
    };
  }

  componentDidMount(){}

  updateItems = (data, isAdd, isUpdate, isRemove) => {
    if(this.state.visible){
      let items = [...this.state.items];
    
      if((isAdd || isUpdate) && data.productId===this.state.food.id){
        if(isAdd)
        items.push(data);

        if(isUpdate){
          const idx = this.state.items.findIndex(o => o.id===data.id);
          if(idx!==-1){
            items[idx]=data;
          }
        }
      }else if(isRemove){
        const removeSet = new Set(data);

        items = items.filter(item => !removeSet.has(item.id));
      }

      if(items.length>0){
        this.setState({items: items});
      }else{
        this.onCloseDialog();
      }
    }
  }

  openForm = (items, regionStatus=ORDER_ITEM_STATUS.added) => {
    const productId = items[0].productId;

    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const trans = this.props.trans;

    if(!this.state.food || this.state.food.id!==productId){
      getDish(productId, this.props.isCustomer)
      .then(food => {
        if(food){
          const propertyOptions = this.getFoodPropertyOptions(food.options, trans, defaultLocale, selectedLocale);
          
          this.setState({
            food: food,
            propertyOptions: propertyOptions,
            variantOptions : food.classification===PRODUCT_CLASSIFICATION.variable ? this.getFoodVariantOptions(food, defaultLocale, selectedLocale) : null,
            items: items,
            regionStatus: regionStatus,
            visible: true
          })
        }else{
          notify.showErrorNotify(trans('res.menu.fail_to_fetch_data'));
        }
      })
    }else{
      this.setState({
        items: items,
        regionStatus: regionStatus
      });
    }
  }

  popularFormData = (item) => {
    this.setState({
      frmOrderItem: {
        id: item?item.id:'',
        orderId: item?item.orderId:this.state.items[0].orderId,
        prodId: item?item.productId:this.state.items[0].productId,
        prodName: item?item.productName:this.state.items[0].productName,
        taxonId: item?item.refObject.taxonId:this.state.items[0].refObject.taxonId,
        variantSelectionType: 1,
        variantId: item?item.variant.id:this.state.food.classification === PRODUCT_CLASSIFICATION.simple ? this.state.food.variants[0].id : '',
        optValues: item?item.itemOptionValues.map(oVal => (oVal.id)):this.state.propertyOptions.length>0 ? new Array(this.state.propertyOptions.length) : [],
        quantity: item?item.quantity:1,
        note: item?item.note:'',
        status: this.state.regionStatus
      },
      error:{}
    });
  }

  getFoodPropertyOptions = (options, trans, defaultLocale, selectedLocale) => {
    let result = [];

    options.map(o => {
      if(o.type===PRODUCT_OPTION_TYPE.properties){
        let opt = {...o};
        let tmpValues = [];

        o.values.map(v => {
          const price = this.props.useFor===USE_FOR_PURPOSE.res_dine_in?v.extraPrice01:v.extraPrice02;
          
          let name = v.trans.names[selectedLocale] ? v.trans.names[selectedLocale] : v.trans.names[defaultLocale] ? v.trans.names[defaultLocale] : v.name;

          if(opt.extraPrice && price!=0){
            name += ' (' + moneyFormat(price) + ' ' + trans('res.menu.more') + ')';
          }

          tmpValues.push({
            value: v.id, 
            label: name
          });
        });

        opt.values = tmpValues;
        result.push(opt);
      }
    });

    return result;
  }

  getFoodVariantOptions = (food, defaultLocale, selectedLocale) => {
    let result = {};

    const variantOpts = food.options.filter(o => o.type===PRODUCT_OPTION_TYPE.variant);
    let variants = [...food.variants];

    if (variantOpts.length > 0 && variants.length>0) {
      variants.map(v => {
        let optValueNames = [];

        v.optionValueIds.map((oValId, oValIdx) => {
          const optValue = variantOpts[oValIdx].values.find(v => v.id===oValId);

          optValueNames.push(optValue.trans.names[selectedLocale] ? optValue.trans.names[selectedLocale] : optValue.trans.names[defaultLocale] ? optValue.trans.names[defaultLocale] : optValue.name);
        })

        v.optValueNames = optValueNames;
      });

      result.options = variantOpts;
      result.variants = variants;
    }

    return result;
  }

  onCancelSaveCartItem = () => {
    this.setState({
      frmOrderItem: {quantity: 1},
      isAdd: false,
      error: {}
    });
  }

  onCloseDialog = () => {
    this.setState({
      items: [],
      frmOrderItem: {quantity: 1},
      isAdd: false,
      errors: {}
    }, () => this.props.onHide());
  }

  saveFoodToCart = () => {
    this.setState({ btnSaveItemDisabled: true });

    const trans = this.props.trans;

    let data = { ...this.state.frmOrderItem };

    saveOrderItem(data, this.props.isCustomer, this.props.isCustomer && localStorage.getItem("customerId"))
      .then(res => {
        if (!res.errorCode) {
          if(this.props.useFor===USE_FOR_PURPOSE.res_take_out || this.props.useFor===USE_FOR_PURPOSE.res_time_order || this.props.useFor===USE_FOR_PURPOSE.res_delivery_now){
            this.updateItems(res.item, data.id?false:true, data.id?true:false, false);
            this.props.updateCartOrder(res.order);
            notify.showSuccessNotify(trans(data.id?'res.menu.updated_food_order':'res.menu.add_food_order'));
          }

          this.setState({ isAdd: false, frmOrderItem: {quantity: 1}, errors: {} });
        } else {
          if (res.errorCode === 400)
            this.setState({ errors: res.errorObj })
          notify.showErrorNotify(trans(data.id?'res.menu.errors.unable_update_order':'res.menu.errors.unable_add_order'));
        }
      })
      .finally(()=>{
        this.setState({ btnSaveItemDisabled: false })
      });
  }

  onChangeInputNumber = (e) => {
    this.setState({ 
      frmOrderItem: { ...this.state.frmOrderItem, quantity: e.value }
    })
  }

  onPropOptionChange = (e, idx) => {
    let optValues = [...this.state.frmOrderItem.optValues];

    optValues[idx] = e;

    this.setState({ frmOrderItem: { ...this.state.frmOrderItem, optValues: optValues } });
  }
  
  onRemoveOrderItems = (item, isConfirmedOrder, reason) => {
    showloading();
    const trans = this.props.trans;

    const orderId = item.orderId;
    const removeData = [item.id];

    removeOrderItems(removeData, this.props.isCustomer, this.props.isCustomer && localStorage.getItem("customerId"), orderId, isConfirmedOrder, reason)
      .then(res => {
        if(!res.errorCode){
          if(this.props.useFor===USE_FOR_PURPOSE.res_take_out || this.props.useFor===USE_FOR_PURPOSE.res_time_order || this.props.useFor===USE_FOR_PURPOSE.res_delivery_now){
            this.updateItems(res.itemIds, false, false, true);
            this.props.updateCartOrder(res.order);
            notify.showSuccessNotify(trans('res.menu.updated_food_order'));
          }
        }else {
          notify.showErrorNotify(trans('res.menu.errors.request_cannot_process_and_refresh_page'));
        }
        stoploading();
      });
  }

  render() {
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const trans = this.props.trans;
    const useFor = this.props.useFor;

    return (
      <>
        <DeleteReasonNotification ref={(el) => this.frmRemoveItems = el} trans={trans}
            header={trans('res.mgt.confirmation')}
            submitText={trans('res.mgt.delete_submit')}
            cancelText={trans('res.mgt.delete_cancel')}
            confirmDelete={(e, reason) => this.onRemoveOrderItems(e, true, reason)}
        />

        <Sidebar visible={this.props.visible} position="right" style={{ overflowY: 'auto' }} className="p-sidebar-lg customer" blockScroll={true} baseZIndex={1000000} onHide={(e) => this.onCloseDialog()}>
          <h1>{trans('res.menu.edit_order_item')}</h1>
          
          {this.state.food &&
          <div className="p-grid">
            {!this.state.isAdd?
            <div className="p-col-12">
              <Button icon="pi-md-plus" label={trans('res.menu.add_food')} className="btn-sm" onClick={() => {this.popularFormData(null); this.setState({isAdd: true})}} />
            </div>
            :
            <div className="p-col-12">
              <Fieldset legend={trans('res.menu.add_food')}>
                <div className="p-grid">
                  <div className="p-col-12">
                  <div className="p-w-bold p-margin-bottom-10">{trans('res.menu.food')}: {this.state.food.names[selectedLocale] ? this.state.food.names[selectedLocale] : this.state.food.names[defaultLocale] ? this.state.food.names[defaultLocale] : this.state.food.name}</div>
                  <div>{this.state.food.descs[selectedLocale] ? this.state.food.descs[selectedLocale] : this.state.food.descs[defaultLocale] ? this.state.food.desc[defaultLocale] : this.state.food.desc}</div>

                  <div className="p-hr"></div>
                  </div>
                </div>
                <div className="p-grid p-fluid form-group p-margin-top-15">
                  {this.state.food.classification === PRODUCT_CLASSIFICATION.variable && this.state.variantOptions && this.state.variantOptions.variants.length>0 ?
                  <div className="p-col-12">
                    <label className="p-label">
                      {this.state.variantOptions.options.length>1?
                        trans('res.menu.select_an_option') + ":"
                      :
                        this.state.variantOptions.options[0].trans.noneLabels[selectedLocale] ? this.state.variantOptions.options[0].trans.noneLabels[selectedLocale] : this.state.variantOptions.options[0].trans.noneLabels[defaultLocale] ? this.state.variantOptions.options[0].trans.noneLabels[defaultLocale] : this.state.variantOptions.options[0].noneLabel ? this.state.variantOptions.options[0].noneLabel + ":" : trans('res.menu.select_an_option') + ":"
                      }
                    </label>
                    <div className="p-grid p-margin-top-5">
                      {this.state.variantOptions.variants.map((variant) => {
                        return (
                          <div key={"variant_" + variant.id} className="p-col-12">
                            <RadioButton inputId={"variant_" + variant.id} value={variant.id} onChange={(e) => this.setState({ frmOrderItem: { ...this.state.frmOrderItem, variantId: e.value } })} checked={this.state.frmOrderItem.variantId === variant.id} />
                            <label htmlFor={"variant_" + variant.id} className="p-radiobutton-label">{variant.optValueNames.join(', ')} ({moneyFormat(useFor===USE_FOR_PURPOSE.res_dine_in?variant.extraPrice:variant.extraPrice02)}{!this.state.food.pricedByVariant?' '+trans('res.menu.more'):''})</label>
                          </div>
                        )
                      })}
                    </div>
                    <div className="p-form-error p-margin-top-5">{this.state.errors.variantId && parseMessage(trans, this.state.errors.variantId.code)}</div>
                  </div>
                  : ''}

                  {this.state.propertyOptions.length>0 && this.state.propertyOptions.map((option, optIdx) => {
                    return (
                      <div className="p-col-12">
                        <label className="p-label">
                          {option.trans.noneLabels[selectedLocale] ? option.trans.noneLabels[selectedLocale] : option.trans.noneLabels[defaultLocale] ? option.trans.noneLabels[defaultLocale] : option.noneLabel + ":"}
                        </label>
                        <div className="p-grid p-margin-top-5">
                          {option.values.map(val => {
                            return (
                              <div key={"propOption_" + val.value} className="p-col-12">
                                <RadioButton inputId={"propOption_" + val.value} value={val.value} onChange={(e) => this.onPropOptionChange(e.value, optIdx)} checked={this.state.frmOrderItem.optValues[optIdx] === val.value} />
                                <label htmlFor={"propOption_" + val.value} className="p-radiobutton-label">{val.label}</label>
                              </div>
                            )
                          })}
                        </div>
                        <div className="p-form-error p-margin-top-5">{this.state.errors["props_opt_" + optIdx] && parseMessage(trans, this.state.errors["props_opt_" + optIdx].code)}</div>
                      </div>
                    )
                  })}

                  <div className="p-col-12">
                    <label className="p-label p-margin-bottom-10">{trans('res.menu.quantity')}:</label>
                    <InputNumber className="input-quantity" value={this.state.frmOrderItem.quantity} onChange={(e) => this.onChangeInputNumber(e)} showButtons buttonLayout="horizontal" spinnerMode="horizontal" step={1} min={0} max={1000}
                      decrementButtonClassName="p-button-default no-radius btn-sm p-margin-right-0" incrementButtonClassName="p-button-default no-radius btn-sm p-margin-right-0" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" 
                      />
                    <div className="p-form-error p-margin-top-5">{this.state.errors.quantity && parseMessage(trans, this.state.errors.quantity.code)}</div>
                  </div>
                  {this.state.food.allowNote &&
                  <div className="p-col-12">
                    <label className="p-label p-margin-bottom-10">{trans('res.menu.note')}:</label>
                    <InputTextarea value={this.state.frmOrderItem.note} onChange={(e) => this.setState({ frmOrderItem: { ...this.state.frmOrderItem, note: e.target.value } })} />
                  </div>
                  }
                </div>
                <div className="p-grid">
                  <div className="p-col-12 p-r p-margin-top-20">
                    <Button label={trans('res.menu.cancel')} icon="pi-md-close" className="p-button-secondary btn-sm" onClick={(e) => this.onCancelSaveCartItem()} />
                    <Button label={this.state.btnSaveItemDisabled?trans('res.menu.processing'):trans('res.menu.save')} icon={this.state.btnSaveItemDisabled?"pi pi-spin pi-spinner p-size-18":"pi pi-check"} disabled={this.state.btnSaveItemDisabled} className="btn-sm" onClick={() => this.saveFoodToCart()} />
                  </div>
                </div>
              </Fieldset>
            </div>
            }
          </div>
          }

          <div className="p-hr p-margin-bottom-10" />
          <div className="p-grid">
            <div className="p-col-1 p-w-bold">#</div>
            {/* <div className="p-col-2 p-w-bold">{trans('res.menu.customer')}</div> */}
            <div className="p-col-7 p-w-bold">{trans('res.menu.food')}</div>
            <div className="p-col-1 p-w-bold p-c">{trans('res.menu.qty')}</div>
          </div>
          <div className="p-hr p-padding-top-0 p-margin-bottom-10" />

          {this.state.items.length>0 && this.state.items.map((item, idx) => {
            return (
              <>
                <div className="p-grid p-vertical-c">
                  <div className="p-col-1">#{idx + 1}</div>
                  {/* <div className="p-col-2">
                    {this.state.table.customers && Object.entries(this.state.table.customers).map(([key, customer]) => {
                        return parseInt(key)===item.customerId && 
                          <div style={{height: 20, width: 20, background: customer.customerColor}}></div>
                      })
                    }
                  </div> */}
                  <div className="p-col-7">
                    <div>{item.productNames ? (item.productNames[selectedLocale] ? item.productNames[selectedLocale] : item.productNames[defaultLocale] ? item.productNames[defaultLocale] : item.productName) : item.productName}</div>
                    {item.itemOptionValueTrans.length>0 && 
                    <div className="p-size-11 p-margin-top-5">
                        - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale]?opt.names[selectedLocale]:opt.names[defaultLocale])).join(', ')}
                    </div>
                    }                    
                    {item.note && <div className="p-size-11 p-margin-top-5">{trans('res.menu.note')}: {item.note}</div>}
                  </div>
                  <div className="p-col-1 p-r">
                    {item.quantity}
                  </div>
                  <div className="p-col-3 p-r">
                    <Button icon="pi-md-edit" disabled={this.state.isAdd || this.state.frmOrderItem.id===item.id} className="btn-xxs" onClick={() => this.popularFormData(item)} />
                    <Button icon="pi-md-close" disabled={this.state.isAdd || this.state.frmOrderItem.id===item.id} className="p-button-danger btn-xxs" onClick={() => this.state.regionStatus===ORDER_ITEM_STATUS.confirmed?this.frmRemoveItems.delete(item):this.onRemoveOrderItems(item)} />
                  </div>
                </div>

                {!this.state.isAdd && this.state.frmOrderItem.id===item.id &&
                <div className="p-grid p-margin-bottom-15">
                  <div className="p-col-12">
                    <Fieldset legend={trans('res.menu.edit_order_item')}>
                      <div className="p-grid p-fluid form-group">
                        {this.state.food.classification === PRODUCT_CLASSIFICATION.variable && this.state.variantOptions && this.state.variantOptions.variants.length>0 ?
                        <div className="p-col-12">
                          <label className="p-label">
                            {this.state.variantOptions.options.length>1?
                              trans('res.menu.select_an_option') + ":"
                            :
                              this.state.variantOptions.options[0].trans.noneLabels[selectedLocale] ? this.state.variantOptions.options[0].trans.noneLabels[selectedLocale] : this.state.variantOptions.options[0].trans.noneLabels[defaultLocale] ? this.state.variantOptions.options[0].trans.noneLabels[defaultLocale] : this.state.variantOptions.options[0].noneLabel ? this.state.variantOptions.options[0].noneLabel + ":" : trans('res.menu.select_an_option') + ":"
                            }
                          </label>
                          <div className="p-grid p-margin-top-5">
                            {this.state.variantOptions.variants.map((variant) => {
                              return (
                                <div key={"variant_" + variant.id} className="p-col-12">
                                  <RadioButton inputId={"variant_" + variant.id} value={variant.id} onChange={(e) => this.setState({ frmOrderItem: { ...this.state.frmOrderItem, variantId: e.value } })} checked={this.state.frmOrderItem.variantId === variant.id} />
                                  <label htmlFor={"variant_" + variant.id} className="p-radiobutton-label">{variant.optValueNames.join(', ')} ({moneyFormat(useFor===USE_FOR_PURPOSE.res_dine_in?variant.extraPrice:variant.extraPrice02)}{!this.state.food.pricedByVariant?' '+trans('res.menu.more'):''})</label>
                                </div>
                              )
                            })}
                          </div>
                          <div className="p-form-error p-margin-top-5">{this.state.errors.variantId && parseMessage(trans, this.state.errors.variantId.code)}</div>
                        </div>
                        : ''}

                        {this.state.propertyOptions.length>0 && this.state.propertyOptions.map((option, optIdx) => {
                          return (
                            <div className="p-col-12">
                              <label className="p-label">
                                {option.trans.noneLabels[selectedLocale] ? option.trans.noneLabels[selectedLocale] : option.trans.noneLabels[defaultLocale] ? option.trans.noneLabels[defaultLocale] : option.noneLabel + ":"}
                              </label>
                              <div className="p-grid p-margin-top-5">
                                {option.values.map(val => {
                                  return (
                                    <div key={"propOption_" + val.value} className="p-col-12">
                                      <RadioButton inputId={"propOption_" + val.value} value={val.value} onChange={(e) => this.onPropOptionChange(e.value, optIdx)} checked={this.state.frmOrderItem.optValues[optIdx] === val.value} />
                                      <label htmlFor={"propOption_" + val.value} className="p-radiobutton-label">{val.label}</label>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="p-form-error p-margin-top-5">{this.state.errors["props_opt_" + optIdx] && parseMessage(trans, this.state.errors["props_opt_" + optIdx].code)}</div>
                            </div>
                          )
                        })}

                        <div className="p-col-12">
                          <label className="p-label p-margin-bottom-10">{trans('res.menu.quantity')}:</label>
                          <InputNumber className="input-quantity" value={this.state.frmOrderItem.quantity} onChange={(e) => this.onChangeInputNumber(e)} showButtons buttonLayout="horizontal" spinnerMode="horizontal" step={1} min={0} max={1000}
                            decrementButtonClassName="p-button-default no-radius btn-sm p-margin-right-0" incrementButtonClassName="p-button-default no-radius btn-sm p-margin-right-0" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" 
                            />
                          <div className="p-form-error p-margin-top-5">{this.state.errors.quantity && parseMessage(trans, this.state.errors.quantity.code)}</div>
                        </div>
                        {this.state.food.allowNote && 
                        <div className="p-col-12">
                          <label className="p-label p-margin-bottom-10">{trans('res.menu.note')}:</label>
                          <InputTextarea value={this.state.frmOrderItem.note} onChange={(e) => this.setState({ frmOrderItem: { ...this.state.frmOrderItem, note: e.target.value } })} />
                        </div>
                        }
                      </div>
                      
                      <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-20">
                          <Button label={trans('res.menu.cancel')} icon="pi-md-close" className="p-button-secondary btn-sm" onClick={(e) => this.onCancelSaveCartItem()} />
                          <Button label={this.state.btnSaveItemDisabled?trans('res.menu.processing'):trans('res.menu.save')} icon={this.state.btnSaveItemDisabled?"pi pi-spin pi-spinner p-size-18":"pi pi-check"} disabled={this.state.btnSaveItemDisabled} className="btn-sm" onClick={() => this.saveFoodToCart()} />
                        </div>
                      </div>
                    </Fieldset>
                  </div>
                </div>
                }
              </>
            );
          })}

          <div className="p-hr"></div>
          <div className="p-grid">
            <div className="p-col-12 p-margin-top-20">
              <Button label={trans('res.menu.close')} icon="pi-md-close" className="p-button-secondary btn-sm" onClick={(e) => this.onCloseDialog()} />
            </div>
          </div>
        </Sidebar>
      </>
    );
  }
}

export default withNamespaces("message")(OrderItemForm);
