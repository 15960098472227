import React, {useContext, useEffect, useState} from "react"
import { Button } from "primereact/button"
import { Spinner } from "primereact/spinner"
import { moneyFormat, roundUp } from "../../service/CommonService"
import { Slider } from 'primereact/slider'
import './SplitPayment.scss'
import { submitPaymentPlan } from "../../../service/CustomerOrderService"
import { InputSwitch } from 'primereact/inputswitch'
import { saveNotification } from "../../../service/OrderActivityService"
import { ORDER_ACTIVITY_TYPE } from '../../../constants'
import { InputText } from "primereact/inputtext"
import classNames from "classnames"
import { AppContext } from "../../context/AppContext"

export const SplitPayment = (props) => {
    const {trans} = useContext(AppContext)
    const orderNumber = props.orderNumber
    const orderTotal = props.orderTotal??0
    const paidTotal = props.paidTotal??0
    const total = orderTotal - paidTotal

    const [splitNumber, setSplitNumber] = useState(props.splitNumber)
    const [isSubmitting, setSubmitting] = useState(false)
    const [handlerPositions, setHandlersPositions] = useState([])
    const [spValues, setSpValues] = useState([])

    useEffect(() => {
        calculatePositions()
    }, [splitNumber, total])

    useEffect(() => {
        calculatePositionState()
    }, [handlerPositions])

    useEffect(() => {
        if(isSubmitting)
            handleSubmitPlan()
    }, [isSubmitting])

    const calculatePositions = () => {
        let tmpPositions = [];
        let splitValue = Number((100/splitNumber).toFixed());
        let handlerPosition = splitValue;
        
        for(var i = 0; i < splitNumber-1; i++) {
            tmpPositions.push(handlerPosition);
            handlerPosition += splitValue;
        }

        setHandlersPositions(tmpPositions)
    }

    const calculatePositionState = () => {
        let prevPos = 0;
        let spValueStates = [];

        handlerPositions.length > 0 && handlerPositions.forEach((position, index) => {
            spValueStates.push({value: [prevPos, position], amount: roundUp(total * (position - prevPos) / 100, 2)})

            if(index===handlerPositions.length - 1){
                spValueStates.push({value: [position, 100], amount: roundUp(total * (100 - position) / 100, 2)})
            }

            prevPos = position
        });
    
        setSpValues(spValueStates)
    }

    const onChangeSplitNumber = (e) => {
        let val = e ? isNaN(e) ? props.splitLimit : e : 2;

        if(val>props.splitLimit)
            val = props.splitLimit
        else if(val<2)
            val = 2

        setSplitNumber(val)
    }

    const onCheckedOffline = (e, index) => {
        let tmpSpValues = [...spValues];
        tmpSpValues[index].offline = e.value;
        setSpValues(tmpSpValues)
    }

    const onRangeChange = (value, index) => {
        let spValueStates = [...spValues];
        let next = spValueStates[index+1] ? spValueStates[index+1] : null;
        let prev = spValueStates[index-1] ? spValueStates[index-1] : null;
        if(next !== null && value[1] > next.value[1])
            value[1] = next.value[1];
        if(prev !== null && value[0] < prev.value[0])
            value[0] = prev.value[0];
        if(prev === null) {
            spValueStates[index].value = [0, value[1]];
            next.value = [value[1], next.value[1]];
        } else if (next === null) {
            prev.value = [prev.value[0], value[0]];
            spValueStates[index].value = [value[0], 100];
        } else {
            prev.value = [prev.value[0], value[0]];
            spValueStates[index].value = value;
            next.value = [value[1], next.value[1]];
        }
        spValueStates[index].amount = roundUp(total * (value[1] - value[0]) / 100, 2);
        if(next !== null) {
            next.amount = roundUp(total * (next.value[1] - next.value[0]) / 100, 2);
            spValueStates[index+1] = next; 
        }
        if(prev !== null) {
            prev.amount = roundUp(total * (prev.value[1] - prev.value[0]) / 100, 2);
            spValueStates[index-1] = prev;
        }

        setSpValues(spValueStates)
    }

    const handleSubmitPlan = () => {
        submitPaymentPlan(orderNumber, spValues).then(res => {
            if(!res.errorCode){
                saveNotification(orderNumber, ORDER_ACTIVITY_TYPE.orderPaymentVerified, "");
            }

            setSubmitting(false)
        })
    }

    return (
        <div>
            <div className="p-grid">
                {paidTotal > 0 &&
                    <div className="p-col-12 p-l">
                        <div>{trans('res.menu.remain_total')}: <strong>{moneyFormat(total)}</strong></div>
                        <div>{trans('res.menu.paid_total')}: <strong>{moneyFormat(paidTotal)}</strong></div>
                    </div>
                }

                <div className="p-col-12">
                    <span style={{ marginBottom:'20px', color:'#0a0a0a', fontSize:'20px'}}>{trans('res.menu.number_of_person')}:</span>
                    <Spinner className="p-display-inline-block p-split p-margin-top-15 num-person" value={splitNumber} onChange={(e) => onChangeSplitNumber(e.value)} min={2} max={props.splitLimit} step={1} />
                </div>

                <div className="p-col-12 p-margin-bottom-10">
                    <div className="p-grid">
                        {spValues.map((state, index) => {
                            return (           
                                <React.Fragment key={index}>
                                    <div className="p-col-12">
                                        <div className="p-grid p-align-center p-margin-none">
                                            <label className="p-margin-right-10">{trans('res.menu.person')} {index + 1}: </label>
                                            <InputSwitch className="p-margin-left-10 p-margin-right-10" checked={state.offline} onChange={(e) => onCheckedOffline(e, index)} />
                                            <label className="p-checkbox-label">{trans('res.menu.is_offline_pay')}</label>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-margin-5-0">
                                        <Slider className={classNames({'p-split-slider-first': index===0}, {'p-split-slider-last': index===spValues.length-1})} value={state.value} onChange={(e) => onRangeChange(e.value, index)} range/>
                                    </div>
                                    <div className="p-col-12 num-percentage  p-c">{state.value[1] - state.value[0]}% ({(state.amount)})</div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>

                <div className="p-col-12 p-c">
                    <Button disabled={isSubmitting} label={isSubmitting?trans('res.menu.processing'):trans('res.menu.pay')} 
                    className="btn-make-payment" icon={isSubmitting?"pi pi-spin pi-spinner p-size-18":"pi-md-check"} onClick={() => setSubmitting(true)}/>
                    <Button className="btn-cancel p-button-secondary" label={trans('res.menu.cancel')} onClick={() => props.cancel()}/>
                </div>
            </div>
        </div>
    )
}