import axios from 'axios';
import { TIMEORDER_BASE_URL } from '../constants';

export function getDeliveryNowTable(customerId=null, pinCode){
    return axios.get(`${TIMEORDER_BASE_URL}/customer/get-table/delivery-now`, {params: {customerId, pinCode}}).then(res => res.data).catch(error => console.log(error));
}

export function updateDeliveryId(orderNumber, deliveryId){
    return axios.post(`${TIMEORDER_BASE_URL}/customer/update-delivery-id/${orderNumber}`, {}, {params: {deliveryId: deliveryId}}).then(res => res.data).catch(error => console.log(error));
}

export function updateShippingFee(orderNumber, shippingFee){
    return axios.post(`${TIMEORDER_BASE_URL}/customer/update-shipping-fee/${orderNumber}`, {}, {params: {shippingFee: shippingFee}}).then(res => res.data).catch(error => console.log(error));
}

export function updateGrabDeliveryStatus(orderNumber, status){
    return axios.post(`${TIMEORDER_BASE_URL}/customer/update-grab-delivery-status/${orderNumber}`, {}, {params: {grabDeliveryStatus: status}}).then(res => res.data).catch(error => console.log(error));
}