export const whitelist = [
  'login', 
  'api/orders/customer', 
  'api/rating/customer', 
  'api/orders/take-away/customer', 
  'configs/public/restaurant', 
  'menu/api/menu', 
  'api/payment/active', 
  'api/invoice', 
  'api/voucher/customer', 
  'api/order-notification', 
  'api/delivery-orders/customer', 
  'api/grab/', 
  '/api/delivery-orders', 
  'api/google',
  'pim/api',
  'payment/api'
]
