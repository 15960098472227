import axios from 'axios';
import { ORDER_BASE_URL } from '../constants';

export function getOrderTableByCode(code, customerId, pinCode){
    return axios.get(`${ORDER_BASE_URL}/customer/get-table/${code}`, {params: {customerId, pinCode}}).then(res => res.data).catch(error => console.log(error));
}

export function placeOrder(orderNumber, orderId){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/place-order`, {}, {
        params: {orderId}
    }).then(res => res.data).catch(error => console.log(error));
}

export function requestChecking(orderNumber, invoiceIds){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/request-checking`, invoiceIds)
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelPaymentProgress(orderNumber){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/cancel-payment-progress`)
    .then(res => res.data).catch(error => console.log(error));
}

export function makePayment(orderNumber, customerId, invoiceId){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/customer-making-payment`, {}, {params: {customerId, invoiceId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelPayment(orderNumber, invoiceId, orderItemIds){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/customer-cancel-payment`, orderItemIds, {params: {invoiceId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function requestPayOnsite(orderNumber, invoiceId, paymentMethod, tipAmount, customerId, cusName, cusPhone, cusEmail){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/request-pay-onsite`, {}, {params: {invoiceId, paymentMethod, tipAmount, customerId, cusName, cusPhone, cusEmail}})
    .then(res => res.data).catch(error => console.log(error));
}

export function completeOrderPayment(orderNumber, invoiceId, method, gateway, token, tipAmount, customerId, cusName, cusPhone, cusEmail){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/complete`, {}, {
        params: {invoiceId, method, gateway, token, tipAmount, customerId, cusName, cusPhone, cusEmail}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function callWaiter(tableCode){
    return axios.post(`${ORDER_BASE_URL}/customer/${tableCode}/call-waiter`)
    .then(res => res.data).catch(error => console.log(error));
}  

export function getDineInTableOrder(orderNumber, customerId){
    return axios.get(`${ORDER_BASE_URL}/customer/get-dine-in-order/${orderNumber}`, {
        params: {customerId}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function submitPaymentPlan(orderNumber, splitValues) {
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/split-request`, splitValues)
    .then(res => res.data).catch(error => console.log(error));
}

export function splitPayment(orderNumber, customerId) {
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/customer-splitting-payment`, {}, {params: {customerId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function customerCancelAndReplanSplit(tableId, customerId) {
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/split-payment/replan`, {}, {params: {customerId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function customerMakingPrepaymentWhileEating(orderNumber, tableId, customerId, orderItemIds, amount) {
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/prepayment/customer-making-payment`, orderItemIds, {params: {orderNumber, customerId, amount}})
    .then(res => res.data).catch(error => console.log(error));
}

export function sendNotify(id, messNum, customText){
    return axios.post(`${ORDER_BASE_URL}/send-notify/${id}`, {}, {
        params: {messNum, customText}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function sendNotification(id, noti){
    return axios.post(`${ORDER_BASE_URL}/send-notification/${id}`, noti)
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelPaymentRequest(orderNumber, customerId, invoiceId = null){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/cancel-request-payment`, null, {
        params: {customerId, invoiceId}
    })
    .then(res => res.data).catch(error => console.log(error))
}