import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { parseMessage } from '../utils/TranslationUtils';

export class DeleteReasonNotification extends Component {

    constructor(props) {
        super(props);
        this.state={
            data: '',
            message: '',
            reason: '',
            visible: false,
            errors: ''
        }
    }

    delete = (data, message) =>{
        this.setState({
            data: data,
            message: message,
            reason: '',
            visible: true
        });
    }
    
    confirmDelete = () => {
        if(this.state.reason){
            this.props.confirmDelete(this.state.data, this.state.reason);
            this.closeDialog();
        }else{
            this.setState({
                errors: {
                    reason: {
                        code: 2030
                    }
                }
            })
        }
    }

    closeDialog = () =>{
        this.setState({
            data: '',
            reason: '',
            visible: false
        })
    }

    render(){
        const trans = this.props.trans;

        const footer = (
            <div>
                <Button label={this.props.submitText} icon="pi pi-check" onClick={this.confirmDelete} />
                <Button label={this.props.cancelText} icon="pi-md-close" className="p-button-secondary" onClick={this.closeDialog} />
            </div>
        );

        return (
            <Dialog header={this.props.header ? this.props.header : "Confirmation"} footer={footer} visible={this.state.visible} baseZIndex={1000000} contentStyle={styles.dialogContent} responsive={true} modal={true} dismissableMask={true} showHeader={true} onHide={this.closeDialog}>
                <div className="p-grid p-fluid form-group">
                    {this.state.message && 
                    <div className="p-col-12">{this.state.message}</div>
                    }
                    
                    <div className="p-col-12">
                        <label className="p-label">* {trans('res.mgt.reason')}</label>
                        <InputTextarea rows={4} value={this.state.reason} onChange={(e) => this.setState({ reason: e.target.value })} placeholder={trans('res.mgt.enter_reason')} />
                        <span className="p-form-error">{this.state.errors.reason && parseMessage(trans, this.state.errors.reason.code)}</span>
                    </div>
                </div>
            </Dialog>            
        );
    }
}

const styles = {
    dialogContent: {
        minWidth: '200px',
        maxHeight: '200px',
        minHeight: '70px',
        overflow: 'hidden'
    }
}