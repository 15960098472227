import axios from 'axios';
import { ORDER_BASE_URL } from '../constants';

export function getTakeAwayOrderTable(customerId=null, pinCode){
    return axios.get(`${ORDER_BASE_URL}/customer/get-table/take-away`, {params: {customerId, pinCode}}).then(res => res.data).catch(error => console.log(error));
}

export function requestPayOnsiteForTakeAway(orderId, orderNumber, paymentMethod, cusId, cusName, cusPhone, email, tipAmount){
    return axios.post(`${ORDER_BASE_URL}/customer/take-away/${orderId}/${orderNumber}/request-pay-onsite`, {}, {params: {paymentMethod, cusId, cusName, cusPhone, email, tipAmount}})
    .then(res => res.data).catch(error => console.log(error));
}

export function completeOrderPaymentForTakeAway(orderId, orderNumber, method, gateway, token, cusId, cusName, cusPhone, invoiceId, tipAmount, pickupTime, pickupDate){
    return axios.post(`${ORDER_BASE_URL}/customer/take-away/${orderId}/${orderNumber}/complete-payment`, {}, {
        params: {method, gateway, token, cusId, cusName, cusPhone, invoiceId, tipAmount, pickupTime, pickupDate}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function getAllTakeAwayOrderByCustomer(customerId){
    return axios.get(`${ORDER_BASE_URL}/customer/${customerId}/get-all-take-away-order`).then(res => res.data).catch(error => console.log(error));
}

export function getAllTimeOrderByCustomer(customerId){
    return axios.get(`${ORDER_BASE_URL}/customer/${customerId}/get-all-time-order`).then(res => res.data).catch(error => console.log(error));
}

export function getAllDeliveryNowByCustomer(customerId){
    return axios.get(`${ORDER_BASE_URL}/customer/${customerId}/get-all-delivery-now`).then(res => res.data).catch(error => console.log(error));
}