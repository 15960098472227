
export const parseMessage = (trans, code, args = {}) => {
  let message = trans(errorMap[code], args);
  return message;
}

export const parseMessage2 = (trans, property) => {
  let message = '';
  if(property){
    if(typeof property === 'number') message = errorMap[property];
    else message = property.toString();
    if(property.code) message = trans(errorMap[property.code]);
    if(property.info) message = trans(errorMap[property.code], {count: property.info[0]});
  }
  return message;
}

const errorMap = {
  400: 'res.mgt.errors.bad_request',
  423: 'res.mgt.errors.action_locked',
  500: 'res.mgt.errors.internal_server',
  2000: 'res.mgt.errors.duplicated_value',
  2010: 'res.mgt.errors.exceed_character',
  2011: 'res.mgt.errors.langs.exceed_character',
  2020: 'res.mgt.errors.default_translation_missing',
  2030: 'res.mgt.errors.require_value',
  2031: 'res.mgt.errors.langs.field_required',
  2040: 'res.mgt.errors.require_select_value',
  2050: 'res.mgt.errors.invaid_price',
  2060: 'res.mgt.errors.already_exist',
  2080: 'res.mgt.errors.remove_child',
  2090: 'res.mgt.errors.value_lower_min',
  2091: 'res.mgt.errors.value_not_negative',
  2100: 'res.mgt.errors.not_found',
  2110: 'res.mgt.errors.invalid_quantity',
  2111: 'res.mgt.errors.quantity_greater_than_zero',
  2120: 'res.mgt.errors.invalid_email',
  2200: 'res.mgt.errors.invalid_coupon',
  2201: 'res.mgt.errors.invalid_coupon_expired',
  2202: 'res.mgt.errors.invalid_coupon_max_used',
  2203: 'res.mgt.errors.invalid_coupon_applied',
  2204: 'res.mgt.errors.invalid_coupon_not_applied',
  2205: 'res.mgt.errors.invalid_coupon_exclusive',
  2206: 'res.mgt.errors.invalid_invoice',
  2999: 'res.mgt.errors.captcha_required'
}
