import axios from 'axios';
import { PAYMENT_BASE_URL } from '../constants';

export function getOnlinePaymentGateway(id) {
    return axios.get(`${PAYMENT_BASE_URL}/tenant-payment/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function createHitPayPayment(gateWayId, data){
    return axios.post(`${PAYMENT_BASE_URL}/hitpay/${gateWayId}/create-payment`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function createMidTransSnapToken(gateWayId, invoiceId){
    return axios.post(`${PAYMENT_BASE_URL}/midtrans/${gateWayId}/create-snap-token`, null, {params: {invoiceId}})
    .then(res => res.data).catch(error => console.log(error));
}