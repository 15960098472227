import React, { Component } from 'react';
import { getSnapToken } from '../../../service/OrderService';
import { moneyFormat } from '../../service/CommonService';
import { PAYMENT_USE_MODE } from '../../../constants';

export default class MidtransCheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numOfMakingPayment: localStorage.getItem("numOfMakingPayment")?localStorage.getItem("numOfMakingPayment"):0
        };
    }

    componentDidMount() {
        let snapJs = document.getElementById('snapJs');
        if (!snapJs) {
            snapJs = document.createElement('script');
            snapJs.src = this.props.activePayment.useMode === PAYMENT_USE_MODE.TEST ? 'https://app.sandbox.midtrans.com/snap/snap.js' : 'https://app.midtrans.com/snap/snap.js';
            snapJs.id = 'snapJs';
            snapJs.setAttribute('data-client-key', this.props.clientKey);
            snapJs.async = true;
            document.body.appendChild(snapJs);
        }

        window.onbeforeunload = () => {
            localStorage.setItem("numOfMakingPayment", Number(this.state.numOfMakingPayment)+1)
        }
    }

    validationCustomerInput = (cusData) => {
        let errors = {};
        if (!cusData.cusName)
            errors.cusName = { code: 2030 };
        if (!cusData.cusPhone)
            errors.cusPhone = { code: 2030 };

        return errors;
    }

    retrieveSnapToken = () => {
        if(this.props.customerData){
            const cusErrors = this.validationCustomerInput(this.props.customerData);

            if(Object.keys(cusErrors).length>0){
                this.props.onCustomerDataError(cusErrors);
                return;
            }
        }

        getSnapToken(this.props.orderNumber, this.props.amount, this.props.invoiceId, this.state.numOfMakingPayment)
            .then(res => {
                this.props.onCloseSidebar();
                window.snap.pay(res, {
                    onSuccess: (result) => {
                        localStorage.removeItem("numOfMakingPayment");
                        this.props.onHandleCardSuccess(result);
                    },
                    onError: () => {
                        localStorage.setItem("numOfMakingPayment", Number(this.state.numOfMakingPayment) + 1)
                        this.setState({
                            numOfMakingPayment: Number(this.state.numOfMakingPayment) + 1
                        });
                        
                    },
                    onClose: () => {
                        localStorage.setItem("numOfMakingPayment", Number(this.state.numOfMakingPayment) + 1)
                        this.setState({
                            numOfMakingPayment: Number(this.state.numOfMakingPayment) + 1
                        });
                    }
                });
            });
    }



    render() {
        const { amount, activePayment, trans } = this.props;

        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12">
                        {this.props.otherProducts ? 
                            <button class="p-button p-component p-button-text-icon-left btn-make-payment" onClick={() => this.retrieveSnapToken()}>
                                <span class="pi-md-payment p-c p-button-icon-left"></span>
                                <span class="p-button-text p-c">{trans('res.menu.pay')} ({moneyFormat(this.props.otherProducts)})</span>
                            </button>
                        :
                        <button class="p-button p-component p-button-text-icon-left btn-make-payment" onClick={() => this.retrieveSnapToken()}>
                            <span class="pi-md-payment p-c p-button-icon-left"></span>
                            <span class="p-button-text p-c">{trans('res.menu.pay')} ({moneyFormat(amount)})</span>
                        </button>
                        }
                        
                        <button class="p-button p-component p-button-secondary  btn-cancel" type="button" onClick={() => this.props.onHandleCancel()}>
                            {/* <span class="pi-md-close p-c p-button-icon-left"></span>p-button-text-icon-left */}
                            <span class="p-button-text p-c">{trans('res.menu.cancel')}</span>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
