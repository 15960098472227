import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { loadPublicConfiguration } from "./service/ConfigurationService";
import { Fieldset } from "primereact/fieldset";
import { RadioButton } from "primereact/radiobutton";
import CustomerOrderTopbar from "./core/layout/CustomerOrderTopbar.js";
import classNames from "classnames";
class DeliveryMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryOption: "delivery",
            timeOption: "now"
        }
    }

    getLangName = (key) => {
        switch (key) {
            case 'en-US':
                return 'English';
            case 'en-UK':
                return 'English';
            case 'fr-FR':
                return 'Français';
            case 'fi':
                return 'Suomi';
            case 'sv-SE':
                return 'Svenska';
            case 'de':
                return 'Deutsch';
            case 'vi':
                return 'Tiếng Việt';
            case 'es':
                return 'Español';
            case 'in':
                return 'Bahasa';
            case 'pt-PT':
                return 'Português';
            case 'pt-BR':
                return 'Português';
            case 'zh-CN':
                return '中文';
            case 'ja':
                return '日本語';
            case 'ko':
                return '한국어';
            case 'nl':
                return 'Dutch';
            case 'no':
                return 'Norwegian';
            case 'tr':
                return 'Turkish';
            case 'zh-TW':
                return 'Taiwanese';
            case 'da':
                return 'Danish';
            case 'ru':
                return 'русский язык';
            default:
                return '';
        }
    }

    async componentDidMount() {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        const config = await loadPublicConfiguration();
        const { i18n } = this.props;
        let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

        i18n.changeLanguage(locale);
        this.setState({ language: locale });

        const resConfig = config.data;
        this.setState({
            resConfig: resConfig
        });
        //this.getTopbarHeight();
    }

    // changeLanguage = (e) => {
    //     this.setState({ language: e });
    //     const { i18n } = this.props;
    //     i18n.changeLanguage(e);
    //     localStorage.setItem('selectedLocale', e);
    // };


    // getFlagPath = (locale) => {
    //     if (locale === null) return;
    //     let style = this.props.flagStyle ? this.props.flagStyle : 'rectangle';
    //     return require('./core/components/flags/flag-' + style + '-' + locale + '.png');
    // }

    // getTopbarHeight = () => {
    //     this.setState({
    //         topbarHeight: this.topBar.clientHeight + 5
    //     });
    // }

    onDeliveryTypeChange = (value, optionType) => {
        // if(page === "pickupLater"){
        //     this.props.history.push('/delivery/time-order');
        // }else if(page === "pickupNow"){
        //     this.props.history.push('/delivery/take-away');
        // }
        if(optionType === 'mode'){
            this.setState({
                ...this.state,
                deliveryOption: value
            })
        }else if(optionType === 'type'){
            this.setState({
                ...this.state,
                timeOption: value
            })
        }
    }



    changePage = () => {
        let mode = this.state.deliveryOption;
        let time = this.state.timeOption;
        if(mode === "pickup" && time === 'later'){
            this.props.history.push('/delivery/time-order');
        }else if(mode === "pickup" && time === 'now'){
            this.props.history.push('/delivery/take-away');
        }else if(mode === "delivery" && time === 'now'){
            this.props.history.push('/delivery/delivery-now');
        }
    }

    render() {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        const resConfig = localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {};
        // const languages = localStorage.getItem('enabledLocales');
        // const applicationUrl = localStorage.getItem('resApplicationUrl');
        // const selectLocale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');
        // const cFlagImage = this.getFlagPath(selectLocale);
        // const activeMenu = localStorage.getItem('selectedMenu') ? parseInt(localStorage.getItem('selectedMenu')) : null;

        // const currency = localStorage.getItem('currencySymbol');
        // const selectedLocale = localStorage.getItem('selectedLocale');
        // const defaultLocale = localStorage.getItem('defaultLocale');

        let wrapperClass = classNames("layout-wrapper", {
            "layout-wrapper-static": this.state.layoutMode === "static",
            "layout-wrapper-active": this.state.mobileMenuActive,
            "layout-menu-horizontal": this.state.layoutMode === "horizontal",
        });

        return(
            <div>
                <div className="layout-main customer">
                <CustomerOrderTopbar layoutMode={this.state.layoutMode}
                config={resConfig?resConfig:{}}
                cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
                isOrderPage={true} setHeight={(e) => this.setState({topbarHeight: e})}
                trans={trans}
                />

                </div>
                {/* <div className="layout-topbar customer" ref={(el) => this.topBar = el}>
                        <Dialog className="lang-dialog" header={trans('res.menu.change_lang')} visible={this.state.languageDialogVisible} modal={true} onHide={() => this.setState({ languageDialogVisible: false })}
                            footer="">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    {languages && languages.split(',').map((option) => {
                                        return <div key={option} className="p-col-12 language-button">
                                            <button style={{ display: "inline-flex", width: "100%" }} onClick={() => { this.changeLanguage(option); this.setState({ languageDialogVisible: false, cFlagImage: this.getFlagPath(option), language: option }) }}>
                                                <img width={27} alt={this.state.activeLocale} src={this.getFlagPath(option)} />
                                                <label style={{ margin: "auto", paddingLeft: "3px" }}>{this.getLangName(option)}</label>
                                            </button>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </Dialog>

                        <div className="topbar-action sticky">
                            <button className="p-button p-button-secondary p-button-language p-margin-right-5" style={{ float: 'left' }} title={trans('res.menu.change_lang')} onClick={() => this.setState({ languageDialogVisible: true })}>
                                <img width={35} alt={this.state.language} src={cFlagImage} />
                            </button>
                        </div>

                        <div className="topbar-header p-c" style={{ backgroundColor: resConfig.resTopbarBackgroundColor, color: resConfig.resTopbarTextColor, paddingTop: '85px' }}>
                            {resConfig.resLogo &&
                                <img className="topbar-header-logo" height={resConfig.resLogoHeight ? resConfig.resLogoHeight : 65} alt="LOGO" src={`/restaurant/assets/restaurant_logo.png`} />
                            }
                            {resConfig.showResName &&
                                <h1 className="topbar-header-title">{resConfig.resName}</h1>
                            }
                            {resConfig.showBrandingName &&
                                <h2 className="topbar-header-branding">{resConfig.brandingName}</h2>
                            }
                        </div>
                    </div> */}
                    <div className="p-padding-bottom-20 p-c card" style={{marginTop: '165px'}}>
                        <div className="p-col-12 p-margin-bottom-10 p-margin-top-10">
                            <Fieldset legend={trans('res.mgt.how_would_you_like_to_receive_your_dishes')}>
                                <div className="p-grid p-margin-bottom-10">
                                    {/* <Button label={trans('res.mgt.delivery_now')} disabled={true} onClick={(e) =>  this.onDeliveryTypeChange("deliveryNow")}/>
                                    <Button label={trans('res.mgt.delivery_later')} disabled={true} onClick={(e) =>  this.onDeliveryTypeChange("deliveryLater")}/>
                                    <Button label={trans('res.mgt.pickup_now')} onClick={(e) =>  this.onDeliveryTypeChange("pickupNow")}/>
                                    <Button label={trans('res.mgt.pickup_later')} onClick={(e) =>  this.onDeliveryTypeChange("pickupLater")}/> */}
                                    
                                    <RadioButton inputId="deliveryType1" name="deliveryType" value="delivery" onChange={(e) => this.onDeliveryTypeChange(e.value, 'mode')} checked={this.state.deliveryOption === "delivery"} />
                                    <label className="p-label p-margin-bottom-10">{trans('res.mgt.delivery')}</label>
                                </div>
                                <div className="p-grid p-margin-bottom-10">
                                    <RadioButton inputId="deliveryType2" name="deliveryType" value="pickup" onChange={(e) => this.onDeliveryTypeChange(e.value, 'mode')} checked={this.state.deliveryOption === "pickup"} />
                                    <label className="p-label p-margin-bottom-10">{trans('res.mgt.pickup')}</label>
                                </div>
                            </Fieldset>
                            <Fieldset legend={trans('res.mgt.when_would_you_like_to_receive_your_dishes')}>
                                <div className="p-grid p-margin-bottom-10">
                                    <RadioButton inputId="deliveryType2" name="deliveryType" value="now" onChange={(e) => this.onDeliveryTypeChange(e.value, 'type')} checked={this.state.timeOption === "now"} />
                                    <label className="p-label p-margin-bottom-10">{trans('res.mgt.now')}</label>
                                </div>
                                <div className="p-grid p-margin-bottom-10">
                                    <RadioButton inputId="deliveryType2" name="deliveryType" value="later" onChange={(e) => this.onDeliveryTypeChange(e.value, 'type')} checked={this.state.timeOption === "later"} />
                                    <label className="p-label p-margin-bottom-10">{trans('res.mgt.later')}</label>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-r">
                            <Button label={trans('res.mgt.next')} onClick={(e) => this.changePage()}/>
                        </div>
                    </div>
            </div>
        )
    }
}
export default withNamespaces("message")(DeliveryMode);