import React from "react";
import classNames from "classnames";
import PinField from "react-pin-field";

export const PinAccess = (props) => {
  const trans = props.trans

  return (
    <div className="p-col-12" style={{ padding: props.padding }}>
      <div className="p-margin-top-30 p-padding-bottom-20 p-c pin">
        <h1 className="p-margin-top-30 p-size-36">{trans('res.menu.enter_pin_code')}</h1>
        <p className="p-size-14">{trans('res.menu.enter_pin_code_notify')}</p>
        <div className="container-a">
          <PinField
            className={classNames("field-a", {"field-a-error": !props.isValid})}
            type="number"
            length={4}
            onComplete={(e) => props.access(e)}
            validate="0123456789"
            autoFocus
            />
        </div>
        {!props.isValid && <div className="p-form-error p-size-14 p-margin-top-10">{trans('res.menu.pin_code_invalid')}</div>}
      </div>
    </div>
  )
}