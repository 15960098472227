import axios from 'axios';
import { GRAB_BASE_URL, GOOGLE_BASE_URL } from '../constants';

export function createQuote(requestQuote){
    return axios.post(`${GRAB_BASE_URL}/quote/grabtest`, requestQuote,{}).then(res => res.data).catch(error => console.log(error));
}

export function createDeliveryRequest(requestDelivery){
    return axios.post(`${GRAB_BASE_URL}/request-delivery/grabtest`, requestDelivery,{}).then(res => res.data).catch(error => console.log(error));
}

export function getDeliveryRequest(deliveryId){
    return axios.get(`${GRAB_BASE_URL}/get-delivery/grabtest`, {params: {deliveryId}}).then(res => res.data).catch(error => console.log(error));
}

export function getLocaPrediction(input){
    return axios.get(`${GOOGLE_BASE_URL}/get-predictions`, {params: {address: input}}).then(res => res.data).catch(error => console.log(error));
}

export function getLocaGeometry(placeid){
    return axios.get(`${GOOGLE_BASE_URL}/get-geometry`, {params: {placeid: placeid}}).then(res => res.data).catch(error => console.log(error));
}