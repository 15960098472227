import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
export class ConfirmNotification extends Component {

    constructor(props) {
        super(props);
        this.state={
            id: '',
            content: '',
            visible: false,
            type: 'default'
        }
    }

    confirm = (id, content, type) =>{
        this.setState({
            id: id,
            content: content,
            visible: true,
            type: type ? type : 'default',

        })
    } 

    actionSubmit = () => {
        this.props.submitConfirm(this.state.id, this.state.invoiceIds);
        this.closeDialog();
    }

    actionCancel = () => {
        this.props.cancelConfirm(this.state.id);
        this.closeDialog();
    }

    closeDialog = () =>{
        this.setState({
            id: '',
            content: '',
            visible: false
        })
    }

    render(){
        const footer = (
            <div>
                <Button label={this.props.submitText} icon="pi pi-check" onClick={this.actionSubmit} />
                <Button label={this.props.cancelText} icon="pi-md-close" className="p-button-secondary" onClick={this.closeDialog} />
            </div>
        );
        return (
            <div className="dialog">
            <Dialog classname="confirm" header={this.props.header ? this.props.header : ''} 
            showHeader={this.props.header ? true : false} footer={footer} visible={this.state.visible} 
            baseZIndex={1000000} responsive={true} modal={true} 
            dismissableMask={true} onHide={this.closeDialog}>
                <div className="p-grid" >
                    <div className="p-col-12 p-margin-15-0">
                        {this.state.content ? this.state.content : ''}
                    </div>
                </div>
            </Dialog>      
            </div>      
        );
    }
}