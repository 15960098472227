import React, { useEffect, forwardRef, useImperativeHandle, useContext, useState } from 'react';

import { showErrorNotify } from '../../service/NotificationService';
import { createMidTransSnapToken } from '../../../service/PaymentService';
import { moneyFormat } from '../../service/CommonService';
import { Button } from 'primereact/button';
import { PAYMENT_USE_MODE } from '../../../constants';
import { PaymentContext } from '../../context/PaymentContext';

export const MidtransPayment = forwardRef((props, ref) => {
    const paymentContext = useContext(PaymentContext)

    const trans = paymentContext.trans
    const invoice = paymentContext.invoice
    const paymentGateWay = paymentContext.paymentGateway
    const amount = paymentContext.amount
    const isProcessing = paymentContext.isProcessing
    
    const [resSnapToken, setResSnapToken] = useState(null)
    const [isCompletingOrder, setCompletingOrder] = useState(true)
    const [isProcessOrder, setProcessOrder] = useState(false)
    const [midtransResponse, setMidtransResponse] = useState(null)

    useEffect(() => {
        if(paymentGateWay){
            let snapJs = document.getElementById('midTransSnapJs');
            if (!snapJs) {
                snapJs = document.createElement('script');
                snapJs.src = paymentGateWay.useMode === PAYMENT_USE_MODE.TEST ? 'https://app.sandbox.midtrans.com/snap/snap.js' : 'https://app.midtrans.com/snap/snap.js';
                snapJs.id = 'midTransSnapJs';
                snapJs.setAttribute('data-client-key', paymentGateWay.settings.clientKey);
                snapJs.async = true;
                document.body.appendChild(snapJs);
            }
        }
    }, [])

    // useEffect(() => {
    //     if(isProcessOrder){
    //         if(!isCompletingOrder){
    //             paymentContext.onCompletePayment({order_id: resSnapToken.order_id, trans_id: midtransResponse.transaction_id})
    //         }
    //     }
    // }, [isProcessOrder])
    
    useImperativeHandle(ref, () => ({
        async retrieveSnapToken(){
            const res = await createMidTransSnapToken(paymentGateWay.id, invoice.id)
            
            if(!res.errorCode) {
                setResSnapToken(res)

                window.snap.pay(res.snapToken, {
                    onSuccess: (e) => {
                        setMidtransResponse(e)
                        setCompletingOrder(false)
                        setProcessOrder(true)
                    },
                    onPending: (e) => {
                        setMidtransResponse(e)
                        setCompletingOrder(false)
                        setProcessOrder(true)
                    },
                    onError: (e) => {
                        showErrorNotify(e, 'Error')
                        paymentContext.stopProcess()
                    },
                    onClose: (e) => paymentContext.stopProcess()
                });
            } else {
                showErrorNotify('Can not init payment at the moment. Please refresh page and try again!', 'Error');
                paymentContext.stopProcess()
            }
        }
    }))

    return (
        <Button icon={isProcessing?"pi pi-spin pi-spinner":"pi-md-local-atm"} disabled={isProcessing} 
            label={isProcessing?trans('res.menu.processing'):(trans('res.menu.pay') + " (" + moneyFormat(amount) + ")")} 
            onClick={() => paymentContext.onCheckout()}
            className="btn-make-payment"/>
    )
})
