import EventEmitter from 'eventemitter3';
import * as io from 'socket.io-client';
import { connect } from 'socket.io-client';
import { WS_BASE_URL, USER_EVENTS } from "../../constants";

let socket = null;
export let customerEvent = null;

export function initConnection(table) {
  if (!socket) {
    customerEvent = new EventEmitter();

    const options = {
      reconnection: true,
      query: `token=${localStorage.getItem('access_token')}`,
    }
    socket = io(WS_BASE_URL + '/restaurant', options);
    socket.emit('joinTable', table);
    handleMessage();
  }
}

export function connected() {
  return socket && socket.connected;
}

export function checkConnection(table) {
  const connection = connected();
  if(connection === null || connection === false){
    initConnection(table);
  }
}

// export const customerEvent = new EventEmitter();

// Add events here so we could know what will be handled
const userEvents = [
  USER_EVENTS.message,
  USER_EVENTS.newCustomer,
  USER_EVENTS.tableCustomer,
  USER_EVENTS.waiterRequested,
  USER_EVENTS.waiterResponded,
  USER_EVENTS.tableRequestStatusChanged,
  USER_EVENTS.tableOrderPlaced,
  USER_EVENTS.tableProcessPayment,
  USER_EVENTS.itemAdded,
  USER_EVENTS.itemUpdated,
  USER_EVENTS.itemRemoved,
  USER_EVENTS.orderUpdated,
  USER_EVENTS.orderPaymentStatusChange,
  USER_EVENTS.orderShippingStatusChange,
  USER_EVENTS.requestMakePaymentForInvoice,
  USER_EVENTS.invoiceStatusChange,
  USER_EVENTS.makingPrepaymentWhileEating,
  USER_EVENTS.completePrepaymentWhileEating,
  USER_EVENTS.tableNotification,
  USER_EVENTS.tableNotifyMessage,
];

function handleMessage() {
  for (const userEvent of userEvents) {
    socket.on(userEvent, (data) => {
      customerEvent && customerEvent.emit(userEvent, data);
    });
  }

  socket.on('disconnect', reason => {
    console.log(reason);
    socket = null;
    customerEvent = null;
  })

  socket.on('connect_error', (error) => {
    console.log('connect_error', error);
    socket = null;
    customerEvent = null;
  });

  socket.on('connect_timeout', (error) => {
    console.log('connect_timeout', error);
    socket = null;
    customerEvent = null;
  });

  socket.on('error', (error) => {
    console.log('error', error);
    socket = null;
    customerEvent = null;
  });
}

export function close() {
  if (socket) {
    socket.disconnect();
    socket = null;
    customerEvent = null;
  }
}


